import { useEffect, useMemo, useState, Fragment, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../components/table";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import Form from "./components/Form";

import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";
import { toNestedTable } from "../../../utils";

type Department = {
  id: string;
  name: string;
  description: string;
  parent: any;
  imageUrl: string;
  createdAt: string;
  status: boolean;
};

const FETCH_DEPARTMENTS = gql`
  query FetchDepartments {
    fetchDepartments {
      id
      name
      description
      parent {
        id
        name
      }
      imageUrl
      createdAt
      status
    }
  }
`;

const DepartmentList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchDepartments, { loading }] = useLazyQuery(FETCH_DEPARTMENTS);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [activeDepartment, setActiveDepartment] = useState<
    Department | undefined
  >(undefined);
  const [newDepartment, setNewDepartment] = useState<boolean | undefined>(
    undefined
  );

  const reloadQuery = useCallback(() => {
    fetchDepartments()
      .then(({ data, error }) => {
        if (data?.fetchDepartments) {
          const fetchedDepartments = data.fetchDepartments as Department[];
          setDepartments(toNestedTable(fetchedDepartments));
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Departments read department(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDepartments]);

  useEffect(() => {
    reloadQuery();
  }, [reloadQuery]);

  const UPDATE_DEPARTMENT = gql`
    mutation DepartmentUpdate(
      $id: ID!
      $name: String!
      $description: String
      $parentId: Int
      $imageUrl: String
      $status: Boolean!
    ) {
      departmentUpdate(
        input: {
          id: $id
          params: {
            name: $name
            description: $description
            parentId: $parentId
            imageUrl: $imageUrl
            status: $status
          }
        }
      ) {
        department {
          id
          name
          description
          parent {
            id
            name
          }
          imageUrl
          createdAt
          status
        }
      }
    }
  `;
  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateDepartment({
      variables: {
        id: activeDepartment?.id,
        name: values.name,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        imageUrl: values.imageUrl,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.departmentUpdate) {
          // const newDepartments = [...departments];
          // const index = newDepartments.findIndex(
          //   (department) => department.id === activeDepartment?.id
          // );
          // newDepartments[index] = data?.departmentUpdate?.department;
          // setDepartments(newDepartments);
          reloadQuery();
          setActiveDepartment(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Department update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Department update failed",
          message: error.message,
        });
      });
  };

  const CREATE_DEPARTMENT = gql`
    mutation DepartmentCreate(
      $name: String!
      $description: String
      $parentId: Int
      $imageUrl: String
      $status: Boolean!
    ) {
      departmentCreate(
        input: {
          params: {
            name: $name
            description: $description
            parentId: $parentId
            imageUrl: $imageUrl
            status: $status
          }
        }
      ) {
        department {
          id
          name
          description
          parent {
            id
            name
          }
          imageUrl
          createdAt
          status
        }
      }
    }
  `;
  const [createDepartment] = useMutation(CREATE_DEPARTMENT);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createDepartment({
      variables: {
        name: values.name,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        imageUrl: values.imageUrl,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.departmentCreate) {
          // setDepartments([...departments, data?.departmentCreate.department]);
          reloadQuery();
          setNewDepartment(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Department created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Department create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Department create failed",
          message: error.message,
        });
      });
  };

  const DELETE_DEPARTMENT = gql`
    mutation DepartmentDelete($id: ID!) {
      departmentDelete(input: { id: $id }) {
        department {
          id
          name
        }
      }
    }
  `;
  const [deleteDepartment] = useMutation(DELETE_DEPARTMENT);
  const handleDelete = async (id: string) => {
    deleteDepartment({
      variables: {
        id: Number(id),
      },
    })
      .then(({ data }) => {
        if (data?.departmentDelete) {
          const deletedDepartment = data.departmentDelete?.department;
          // const newDepartments = [...departments];
          // const index = newDepartments.findIndex(
          //   (department) => department.id === id
          // );
          // newDepartments.splice(index, 1);

          reloadQuery();

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Department ${deletedDepartment.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Department deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Department deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<
    ColumnDef<Department & { expand: null; depth: number }>[]
  >(
    () => [
      {
        accessorKey: "expand",
        header: ({ table }) => {
          return (
            <button
              {...{
                onClick: table.getToggleAllRowsExpandedHandler(),
              }}
            >
              {table.getIsAllRowsExpanded() ? (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-300 bg-gray-50 transition-all">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-gray-700"
                  />
                </span>
              ) : (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-200 transition-all">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-gray-700"
                  />
                </span>
              )}
            </button>
          );
        },
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() ? (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-100 bg-gray-50 transition-all">
                  {row.original.depth === 0 ? (
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-4 w-4 text-gray-700"
                    />
                  ) : (
                    <span
                      aria-hidden="true"
                      className="bi bi-arrow-bar-down h-4 w-4 text-gray-700"
                    />
                  )}
                </span>
              ) : (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-50 transition-all">
                  {row.original.depth === 0 ? (
                    <ChevronRightIcon
                      aria-hidden="true"
                      className="h-4 w-4 text-gray-700"
                    />
                  ) : (
                    <span
                      aria-hidden="true"
                      className="bi bi-arrow-return-right h-4 w-4 text-gray-700"
                    />
                  )}
                </span>
              )}
            </button>
          ) : row.original.depth !== 0 ? (
            ""
          ) : null;
        },
        enableSorting: false,
        meta: {
          className: "text-center md:w-8",
        },
      },
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => {
          return (
            <span className={`ml-${row.original.depth * 1}`}>
              {`${Array.from(
                Array(
                  typeof row.original.depth === "number"
                    ? row.original.depth
                    : 0
                ).keys()
              )
                .map((_) => "-")
                .join("")} ${row.original.name}`}
            </span>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (info) =>
          DateTime.fromISO(info.getValue(), {
            locale: i18n.language,
          }).toLocaleString(DateTime.DATE_MED),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-departments"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => setActiveDepartment(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-departments"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Departments"
        heading="Departments"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: DepartmentListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Departments List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the departments in your account including their
              name, title, email and department.
            </p>
          </div>
        </div>

        <TablePaginate
          data={departments}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-departments"]}>
              <Button onClick={() => setNewDepartment(true)}>
                Add department
              </Button>
            </AuthShield>
          )}
        />
      </div>

      <Transition.Root
        show={activeDepartment ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveDepartment(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Department - "
                  initialValues={{
                    id: Number(activeDepartment?.id),
                    name: activeDepartment?.name ?? "",
                    description: activeDepartment?.description ?? "",
                    parent: activeDepartment?.parent
                      ? {
                          label: activeDepartment?.parent.name,
                          value: activeDepartment?.parent.id,
                        }
                      : null,
                    imageUrl: activeDepartment?.imageUrl ?? "",
                    status: activeDepartment?.status ? true : false,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveDepartment(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={newDepartment ? true : false} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewDepartment(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Department - "
                  initialValues={{
                    name: "",
                    description: "",
                    parent: null,
                    imageUrl: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewDepartment(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DepartmentList;
export const DepartmentListResource: ResourceProps = {
  name: "Departments List",
  description: "A list of departments",
  access: ["create-departments", "read-departments"],
  path: "departments",
};
