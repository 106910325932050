import { useEffect, useMemo, useState, Fragment } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../components/table";

import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type Resource = {
  id: string;
  resourceName: string;
  resourceDescription: string;
  createdAt: string;
  status: boolean;
};

const FETCH_RESOURCES = gql`
  query FetchResources {
    fetchResources {
      id
      resourceName
      resourceDescription
      createdAt
      status
    }
  }
`;

const ResourceList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchResources, { loading }] = useLazyQuery(FETCH_RESOURCES);
  const [resources, setResources] = useState<Resource[]>([]);
  const [activeResource, setActiveResource] = useState<Resource | undefined>(
    undefined
  );
  const [newResource, setNewResource] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    fetchResources()
      .then(({ data, error }) => {
        if (data?.fetchResources) {
          const updatedResources = data.fetchResources.map(
            (item: Resource) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setResources(updatedResources);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Resources read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResources]);

  const UPDATE_RESOURCE = gql`
    mutation ResourceUpdate(
      $id: ID!
      $resourceName: String!
      $resourceDescription: String!
      $status: Boolean!
    ) {
      resourceUpdate(
        input: {
          id: $id
          params: {
            resourceName: $resourceName
            resourceDescription: $resourceDescription
            status: $status
          }
        }
      ) {
        resource {
          id
          resourceName
          resourceDescription
          createdAt
          status
        }
      }
    }
  `;
  const [updateResource] = useMutation(UPDATE_RESOURCE);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateResource({
      variables: {
        id: activeResource?.id,
        resourceName: values.resourceName,
        resourceDescription: values.resourceDescription,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);

        if (data?.resourceUpdate) {
          const newResources = [...resources];
          const index = newResources.findIndex(
            (resource) => resource.id === activeResource?.id
          );
          newResources[index] = {
            ...data.resourceUpdate.resource,
            createdAt: DateTime.fromISO(
              data.resourceUpdate.resource.createdAt,
              {
                locale: i18n.language,
              }
            ).toLocaleString(DateTime.DATE_MED),
          };
          setResources([...newResources]);
          setActiveResource(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Resource updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Resource update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Resource update failed",
          message: error.message,
        });
      });
  };

  const CREATE_RESOURCE = gql`
    mutation ResourceCreate(
      $resourceName: String!
      $resourceDescription: String!
      $status: Boolean!
    ) {
      resourceCreate(
        input: {
          params: {
            resourceName: $resourceName
            resourceDescription: $resourceDescription
            status: $status
          }
        }
      ) {
        resource {
          id
          resourceName
          resourceDescription
          createdAt
          status
        }
      }
    }
  `;
  const [createResource] = useMutation(CREATE_RESOURCE);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createResource({
      variables: {
        resourceName: values.resourceName,
        resourceDescription: values.resourceDescription,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.resourceCreate) {
          setResources([
            ...resources,
            {
              ...data.resourceCreate?.resource,
              createdAt: DateTime.fromISO(
                data.resourceCreate?.resource.createdAt,
                {
                  locale: i18n.language,
                }
              ).toLocaleString(DateTime.DATE_MED),
            },
          ]);
          setNewResource(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Resource created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Resource create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Resource create failed",
          message: error.message,
        });
      });
  };

  const DELETE_RESOURCE = gql`
    mutation ResourceDelete($id: ID!) {
      resourceDelete(input: { id: $id }) {
        resource {
          id
          resourceName
        }
      }
    }
  `;
  const [deleteResource] = useMutation(DELETE_RESOURCE);
  const handleDelete = async (id: string) => {
    deleteResource({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.resourceDelete) {
          const deletedResource = data?.resourceDelete?.resource;
          const newResources = [...resources];
          const index = newResources.findIndex(
            (resource) => resource.id === id
          );
          newResources.splice(index, 1);
          setResources(newResources);

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Resource ${deletedResource.resourceName} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Resource delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Resource delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<Resource>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "resourceName",
        header: "Name",
      },
      {
        accessorKey: "resourceDescription",
        header: "Description",
        cell: (info: any) => (
          <div dangerouslySetInnerHTML={{ __html: info.getValue() }} />
        ),
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-resources"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => setActiveResource(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.resourceName}
                </span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-resources"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.resourceName,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.resourceName}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Resources"
        heading="Resources"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ResourceListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Resources List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the resources in your account including their name,
              title, email and resource.
            </p>
          </div>
        </div>

        <TablePaginate
          data={resources}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-resources"]}>
              <Button
                className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => setNewResource(true)}
              >
                Add resource
              </Button>
            </AuthShield>
          )}
        />
      </div>

      <Transition.Root
        show={activeResource ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveResource(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Resource - "
                  initialValues={{
                    resourceName: activeResource?.resourceName ?? "",
                    resourceDescription:
                      activeResource?.resourceDescription ?? "",
                    status: activeResource?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveResource(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newResource ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewResource(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Resource - "
                  initialValues={{
                    resourceName: "",
                    resourceDescription: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewResource(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* <Transition.Root show={activeResource ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveResource(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="Edit Resource - "
                      initialValues={{
                        resourceName: activeResource?.resourceName ?? "",
                        resourceDescription:
                          activeResource?.resourceDescription ?? "",
                        status: activeResource?.status ?? true,
                      }}
                      onSubmit={handleUpdate}
                      submitLabel={t("text_update")}
                      onCancel={() => setActiveResource(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}

      {/* <Transition.Root show={newResource ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewResource(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="New Resource - "
                      initialValues={{
                        resourceName: "",
                        resourceDescription: "",
                        status: true,
                      }}
                      onSubmit={handleCreate}
                      submitLabel={t("text_create")}
                      onCancel={() => setNewResource(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
};

export default ResourceList;
export const ResourceListResource: ResourceProps = {
  name: "Resources List",
  description: "A list of resources",
  access: ["create-resources", "read-resources"],
  path: "resources",
};
