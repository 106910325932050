import { Link } from "react-router-dom";
import { ErrorDebug } from "./";
import { isDeveloper, useAuth } from "../../modules/auth";

const { REACT_APP_DEBUG } = process.env;

export function NotAuthorized({ error }: { error: Error }) {
  const { currentRole } = useAuth();

  return (
    <div className="py-16">
      <div className="text-center">
        <p className="text-base font-medium text-primary-600">Oops</p>
        <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Unauthorized
        </h1>
        <p className="mt-2 text-base text-gray-500">
          Sorry, you don’t have permission to access this page. Please contact
          your administrator.
        </p>
        <div className="mt-6">
          <Link
            to="/"
            className="text-base font-medium text-primary-600 hover:text-primary-500"
          >
            Go back home <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
      {error && (isDeveloper(currentRole) || REACT_APP_DEBUG === "true") && (
        <ErrorDebug error={error} className="mt-10 md:mt-16 mx-auto w-full max-w-3xl" />
      )}
    </div>
  );
}
