import { DepartmentsResource } from "./departments";
import { CategoriesResource } from "./categories";
import { LocationsResource } from "./locations";
import { ProductsResource } from "./products";
import { PricelistResource } from "./pricelist";
import { SettingsResource } from "./settings";

export * from "./Layout";

export const InventoryResource = {
  name: "Inventory",
  description: "Inventory management module",
  path: "/inventory",
  icon: "bi bi-shop-window",
  access: [
    "read-departments",
    "read-categories",
    "read-binlocations",
    "read-products",
    "read-pricelist",
    "read-inventorysettings",
  ],
  children: [
    DepartmentsResource,
    CategoriesResource,
    LocationsResource,
    ProductsResource,
    PricelistResource,
    SettingsResource,
  ],
};
