import { useEffect, useMemo, useState, Fragment } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../../components/table";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Form from "./components/Form";

import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";

import { Button } from "../../../../components/form";
import { AuthShield } from "../../../auth/core";
import { Head } from "../../../../components/core";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id?: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

const FETCH_PRICINGMETHODS = gql`
  query FetchPricingMethods {
    fetchPricingMethods {
      id
      name
      sellPriceFormula
      pricingMethodFields {
        fieldType
        fieldKey
        fieldName
        fieldOptions
      }
      createdAt
      status
    }
  }
`;

const PricingMethodList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchPricingMethods, { loading }] = useLazyQuery(FETCH_PRICINGMETHODS);
  const [pricingMethods, setPricingMethods] = useState<PricingMethod[]>([]);
  const [activePricingMethod, setActivePricingMethod] = useState<
    PricingMethod | undefined
  >(undefined);
  const [newPricingMethod, setNewPricingMethod] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    fetchPricingMethods()
      .then(({ data, error }) => {
        if (data?.fetchPricingMethods) {
          const updatedPricingMethods = data.fetchPricingMethods.map(
            (item: PricingMethod) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setPricingMethods(updatedPricingMethods);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing methods read pricingMethod(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingMethods]);

  const UPDATE_PRICINGMETHOD = gql`
    mutation PricingMethodUpdate(
      $id: ID!
      $name: String!
      $sellPriceFormula: [String!]!
      $pricingMethodFields: [PricingmethodfieldInput!]
      $status: Boolean
    ) {
      pricingMethodUpdate(
        input: {
          id: $id
          params: {
            name: $name
            sellPriceFormula: $sellPriceFormula
            pricingMethodFields: $pricingMethodFields
            status: $status
          }
        }
      ) {
        pricingMethod {
          id
          name
          sellPriceFormula
          pricingMethodFields {
            fieldType
            fieldKey
            fieldName
            fieldOptions
          }
          createdAt
          status
        }
      }
    }
  `;
  const [updatePricingMethod] = useMutation(UPDATE_PRICINGMETHOD);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updatePricingMethod({
      variables: {
        id: activePricingMethod?.id,
        name: values.name,
        sellPriceFormula: values.sellPriceFormula,
        pricingMethodFields: values.pricingMethodFields
          ? values.pricingMethodFields.map((item: PricingMethodField) => ({
              fieldType: item.fieldType,
              fieldKey: item.fieldKey,
              fieldName: item.fieldName,
              fieldOptions: item.fieldOptions,
            }))
          : [],
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingMethodUpdate) {
          const newPricingMethods = [...pricingMethods];
          const index = newPricingMethods.findIndex(
            (pricingMethod) => pricingMethod.id === activePricingMethod?.id
          );
          newPricingMethods[index] = {
            ...data.pricingMethodUpdate.pricingMethod,
            createdAt: DateTime.fromISO(
              data.pricingMethodUpdate.pricingMethod.createdAt,
              {
                locale: i18n.language,
              }
            ).toLocaleString(DateTime.DATE_MED),
          };
          setPricingMethods(newPricingMethods);
          setActivePricingMethod(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing method update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing method update failed",
          message: error.message,
        });
      });
  };

  const CREATE_PRICINGMETHOD = gql`
    mutation PricingMethodCreate(
      $name: String!
      $sellPriceFormula: [String!]!
      $pricingMethodFields: [PricingmethodfieldInput!]
      $status: Boolean
    ) {
      pricingMethodCreate(
        input: {
          params: {
            name: $name
            sellPriceFormula: $sellPriceFormula
            pricingMethodFields: $pricingMethodFields
            status: $status
          }
        }
      ) {
        pricingMethod {
          id
          name
          sellPriceFormula
          pricingMethodFields {
            fieldType
            fieldKey
            fieldName
            fieldOptions
          }
          createdAt
          status
        }
      }
    }
  `;
  const [createPricingMethod] = useMutation(CREATE_PRICINGMETHOD);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createPricingMethod({
      variables: {
        name: values.name,
        sellPriceFormula: values.sellPriceFormula,
        pricingMethodFields: values.pricingMethodFields
          ? values.pricingMethodFields.map((item: PricingMethodField) => ({
              fieldType: item.fieldType,
              fieldKey: item.fieldKey,
              fieldName: item.fieldName,
              fieldOptions: item.fieldOptions,
            }))
          : [],
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingMethodCreate) {
          setPricingMethods([
            ...pricingMethods,
            {
              ...data.pricingMethodCreate?.pricingMethod,
              createdAt: DateTime.fromISO(
                data.pricingMethodCreate.pricingMethod.createdAt,
                {
                  locale: i18n.language,
                }
              ).toLocaleString(DateTime.DATE_MED),
            },
          ]);
          setNewPricingMethod(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing method created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing method create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing method create failed",
          message: error.message,
        });
      });
  };

  const DELETE_PRICINGMETHOD = gql`
    mutation PricingMethodDelete($id: ID!) {
      pricingMethodDelete(input: { id: $id }) {
        pricingMethod {
          id
          name
        }
      }
    }
  `;
  const [deletePricingMethod] = useMutation(DELETE_PRICINGMETHOD);
  const handleDelete = async (id: string) => {
    deletePricingMethod({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.pricingMethodDelete) {
          const deletedPricingMethod = data.pricingMethodDelete?.pricingMethod;
          const newPricingMethods = [...pricingMethods];
          const index = newPricingMethods.findIndex(
            (pricingMethod) => pricingMethod.id === id
          );
          newPricingMethods.splice(index, 1);
          setPricingMethods(newPricingMethods);

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Pricing method ${deletedPricingMethod.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing method deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing method deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<PricingMethod>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-pricingmethods"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => setActivePricingMethod(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-pricingmethods"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Pricing Methods"
        heading="Pricing Methods"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: PricingMethodListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Pricing Methods List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the pricing methods in your account including their
              name, title, email and pricing method.
            </p>
          </div>
        </div>

        <TablePaginate
          data={pricingMethods}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-pricingmethods"]}>
              <Button onClick={() => setNewPricingMethod(true)}>
                Add pricing method
              </Button>
            </AuthShield>
          )}
        />
      </div>

      <Transition.Root
        show={activePricingMethod ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePricingMethod(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Pricing Method - "
                  initialValues={{
                    id: activePricingMethod?.id,
                    name: activePricingMethod?.name ?? "",
                    pricingMethodFields:
                      activePricingMethod?.pricingMethodFields ?? [],
                    sellPriceFormula:
                      activePricingMethod?.sellPriceFormula ?? [],
                    status: activePricingMethod?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActivePricingMethod(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newPricingMethod ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPricingMethod(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Pricing Method - "
                  initialValues={{
                    name: "",
                    pricingMethodFields: [],
                    sellPriceFormula: [],
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewPricingMethod(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default PricingMethodList;
export const PricingMethodListResource: ResourceProps = {
  name: "Pricing Methods List",
  description: "A list of pricing methods",
  access: ["read-pricingmethods"],
  path: "pricing-methods",
};
