import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormCreate from "./components/FormCreate";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";

import { Head } from "../../../../components/core";

const CREATE_PRICINGCATEGORY = gql`
  mutation PricingCategoryCreate(
    $name: String!
    $description: String
    $productType: Int
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingCategoryCreate(
      input: {
        params: {
          name: $name
          description: $description
          productType: $productType
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingCategory {
        id
        name
      }
    }
  }
`;

const PricingCategoryCreate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createPricingCategory] = useMutation(CREATE_PRICINGCATEGORY);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createPricingCategory({
      variables: {
        name: values.name,
        description: values.description,
        productType: values.productType,
        productIds: values.productIds,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingCategoryCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing category created successfully",
          });
          return navigate(
            `/inventory/pricelist/pricing-categories/${data?.pricingCategoryCreate?.pricingCategory?.id}`
          );
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing category creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing category creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Pricing Category"
        heading="Pricing Categories"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing Categories",
            href: "/inventory/pricelist/pricing-categories",
          },
          {
            name: PricingCategoryCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_pricing_category")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_pricing_category")}
              </p>
            </div>
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link
                  to="/inventory/pricelist/pricing-categories/add"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add pricing category
                </Link>
              </div> */}
          </div>

          <FormCreate
            initialValues={{
              name: "",
              description: "",
              productType: 0,
              productIds: [],
              status: true,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default PricingCategoryCreate;
export const PricingCategoryCreateResource: ResourceProps = {
  name: "Create Pricing Category",
  description: "Create a new pricing category",
  access: ["create-pricingcategories"],
  path: "pricing-categories/add",
};
