import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";

type RoleProps = {
  readonly label: string;
  readonly value: string;
};

const FETCH_ROLES = gql`
  query FetchUser($id: ID!) {
    fetchUser(id: $id) {
      id
      firstName
      lastName
      userName
      email
      phoneNumber
      roles {
        id
        roleName
      }
      createdAt
      status
    }
  }
`;

const UserUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type Role = {
    id: string;
    roleName: string;
  };

  type User = {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    phoneNumber: string;
    roles: Role[];
    createdAt: string;
    status: boolean;
  };

  const [user, setUser] = useState<User>();

  const [fetchUser] = useLazyQuery(FETCH_ROLES);

  useEffect(() => {
    fetchUser({
      variables: {
        id: userId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchUser) {
          setUser(data?.fetchUser as User);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "User read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUser, userId]);

  const UPDATE_USER = gql`
    mutation UserUpdate(
      $id: ID!
      $firstName: String!
      $lastName: String!
      $email: String!
      $phoneNumber: String!
      $userName: String!
      $roles: [Int!]!
      $status: Boolean!
    ) {
      userUpdate(
        input: {
          id: $id
          params: {
            firstName: $firstName
            lastName: $lastName
            email: $email
            phoneNumber: $phoneNumber
            userName: $userName
            roles: $roles
            status: $status
          }
        }
      ) {
        user {
          id
          userName
        }
      }
    }
  `;
  const [updateUser] = useMutation(UPDATE_USER);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateUser({
      variables: {
        id: user?.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        userName: values.userName,
        roles: values.roles.map((role: RoleProps) => parseInt(role.value)),
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.userUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "User updated successfully",
          });
          return navigate(`/user-management/users`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "User update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "User update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={UserUpdateResource.name}
        heading="Users"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Users",
            href: "/user-management/users",
          },
          {
            name: UserUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-6 sm:flex sm:items-center md:mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_update_user")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_update_user")}
            </p>
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link
                  to="/user-management/users/add"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add user
                </Link>
              </div> */}
        </div>

        <Form
          initialValues={{
            firstName: user?.firstName ?? "",
            lastName: user?.lastName ?? "",
            userName: user?.userName ?? "",
            email: user?.email ?? "",
            phoneNumber: user?.phoneNumber ?? "",
            roles:
              user?.roles.map((r) => {
                return {
                  label: r.roleName,
                  value: r.id,
                };
              }) ?? [],
            status: user?.status ?? true,
          }}
          onSubmit={handleSubmit}
          actionLabel={t("text_update")}
        />
      </div>
    </>
  );
};

export default UserUpdate;
export const UserUpdateResource: ResourceProps = {
  name: "Edit User",
  description: "Update an existing user",
  access: ["update-users"],
  path: "users/:userId",
};
