import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { TablePaginate } from "../../../components/table";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type Customer = {
  id: string;
  customerName: string;
};
type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: Customer[];
  createdAt: string;
  status: boolean;
};

const FETCH_CUSTOMERGROUPS = gql`
  query FetchCustomerGroups {
    fetchCustomerGroups {
      id
      name
      description
      customers {
        id
        customerName
      }
      createdAt
      status
    }
  }
`;

const CustomerGroupList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchCustomerGroups, { loading }] = useLazyQuery(FETCH_CUSTOMERGROUPS);
  const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);

  useEffect(() => {
    fetchCustomerGroups()
      .then(({ data, error }) => {
        if (data?.fetchCustomerGroups) {
          const updatedCustomerGroups = data.fetchCustomerGroups.map(
            (item: CustomerGroup) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setCustomerGroups(updatedCustomerGroups);
        } else {
          console.error(error);
          // return navigate("/error/401", {
          //   state: {
          //     message: error
          //       ? error.message
          //       : "Customer Group read permission(s) is required to access this page.",
          //   },
          // });
        }
      })
      .catch((error) => {
        console.error(error);
        // return navigate("/error/500", {
        //   state: {
        //     error,
        //     message: error.message,
        //   },
        // });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerGroups]);

  const DELETE_CUSTOMERGROUP = gql`
    mutation CustomerGroupDelete($id: ID!) {
      customerGroupDelete(input: { id: $id }) {
        customerGroup {
          id
          name
        }
      }
    }
  `;
  const [deleteCustomerGroup] = useMutation(DELETE_CUSTOMERGROUP);
  const handleDelete = async (id: string) => {
    return await deleteCustomerGroup({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.customerGroupDelete) {
          const deletedCustomerGroup = data?.customerGroupDelete?.customerGroup;
          const newCustomerGroups = [...customerGroups];
          const index = newCustomerGroups.findIndex(
            (customerGroup) => customerGroup.id === id
          );
          newCustomerGroups.splice(index, 1);
          setCustomerGroups(newCustomerGroups);
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Customer Group ${deletedCustomerGroup.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer Group delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer Group delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<CustomerGroup>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "customers",
        header: "Customers",
        cell: ({ row }) => {
          return row.original.customers.map((customer) => (
            <span key={customer.id}>{customer.customerName}</span>
          ));
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-customergroups"]}>
              <Link
                to={`/sales/customer-groups/${props.row.original.id}`}
                className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Link>
            </AuthShield>
            <AuthShield access={["destroy-customergroups"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Customer Groups"
        heading="Customer Groups"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customer Groups",
            href: "/sales/customer-groups",
          },
          {
            name: CustomerGroupListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Customer Groups List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the customer groups in your account including their
              name, title, description and customer group.
            </p>
          </div>
        </div>
        <TablePaginate
          data={customerGroups}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-customergroups"]}>
              <Link to="/sales/customer-groups/add">
                <Button>Add customer group</Button>
              </Link>
            </AuthShield>
          )}
        />
      </div>
    </>
  );
};

export default CustomerGroupList;
export const CustomerGroupListResource: ResourceProps = {
  name: "Customer Groups List",
  description: "A list of customer groups",
  access: ["read-customergroups"],
  path: "customer-groups",
};
