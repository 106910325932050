import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";
import { Button } from "../../../components/form";

type VariantItem = {
  id: number;
  stockCode: string;
  basePrice: number;
  comparePrice: number;
  cost: number;
  marketCost: number;
  minimumQuantity: number;
  inventory: number;
  variantImageUrl: string;
  optionValues: {
    option: string;
    optionValue: string;
  }[];
};

const ProductCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CREATE_CATEGORY = gql`
    mutation ProductCreate(
      $name: String!
      $description: String
      $departmentId: Int
      $categoryIds: [Int!]
      $vendorId: Int
      $binLocationId: Int
      $featureImageUrl: String
      $options: [String!]
      $hasVariants: Boolean
      $variants: [ProductskuInput!]
      $returnableItem: Boolean
      $relatedIds: [Int!]
      $galleryImageUrls: [String!]
      $status: Int!
    ) {
      productCreate(
        input: {
          params: {
            name: $name
            description: $description
            departmentId: $departmentId
            categoryIds: $categoryIds
            vendorId: $vendorId
            binLocationId: $binLocationId
            featureImageUrl: $featureImageUrl
            options: $options
            hasVariants: $hasVariants
            variants: $variants
            returnableItem: $returnableItem
            relatedIds: $relatedIds
            galleryImageUrls: $galleryImageUrls
            status: $status
          }
        }
      ) {
        product {
          id
          name
        }
      }
    }
  `;
  const [createProduct] = useMutation(CREATE_CATEGORY);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    console.log(values);
    createProduct({
      variables: {
        name: values.name,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        departmentId: values.department
          ? parseInt(values.department.value)
          : null,
        categoryIds: values.category
          ? values.category.map((item: any) => parseInt(item.value))
          : [],
        vendorId: values.vendor ? parseInt(values.vendor.value) : null,
        binLocationId: values.binLocation
          ? parseInt(values.binLocation.value)
          : null,
        featureImageUrl: values.featureImageUrl,
        options: values.options,
        hasVariants: values.hasVariants,
        variants: values.hasVariants
          ? values.variants.map((v: VariantItem) => {
              return {
                stockCode: v.stockCode,
                basePrice: v.basePrice,
                comparePrice: v.comparePrice,
                optionValues: v.optionValues,
                cost: v.cost,
                marketCost: v.marketCost,
                minimumQuantity: v.minimumQuantity,
                inventory: v.inventory,
                variantImageUrl: v.variantImageUrl,
              };
            })
          : [
              {
                stockCode: values.stockCode,
                basePrice: values.basePrice,
                comparePrice: values.comparePrice,
                optionValues: [],
                cost: values.cost,
                marketCost: values.marketCost,
                minimumQuantity: values.minimumQuantity,
                inventory: values.inventory,
                variantImageUrl: "",
              },
            ],
        returnableItem: values.returnableItem,
        relatedIds: values.relatedIds
          ? values.relatedIds.map((item: any) => parseInt(item.value))
          : [],
        galleryImageUrls: values.galleryImageUrls
          ? values.galleryImageUrls
          : [],
        status: values.status ? parseInt(values.status.value) : null,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.productCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Product created successfully",
          });
          return navigate(`/inventory/products`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Product creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Product creation failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_create_product")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_create_product")}
          </p>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-16">
          <Link to="/inventory/products" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_create")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title="Create Product"
        heading="Products"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Products",
            href: "/inventory/products",
          },
          {
            name: ProductCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-6xl py-6 sm:py-8">
        <Form
          initialValues={{
            name: "",
            description: "",
            basePrice: 0,
            binLocation: null,
            category: [],
            comparePrice: 0,
            department: null,
            featureImageUrl: "",
            pricingLevel: null,
            variants: [],
            cost: 0,
            marketCost: 0,
            minimumQuantity: 0,
            inventory: 0,
            options: [],
            hasVariants: false,
            returnableItem: false,
            stockCode: "",
            vendor: null,
            relatedIds: [],
            galleryImageUrls: [],
            status: null,
          }}
          onSubmit={handleSubmit}
          header={<FormHeader />}
          actionType="create"
        />
      </div>
    </>
  );
};

export default ProductCreate;
export const ProductCreateResource: ResourceProps = {
  name: "Create Product",
  description: "Create a new product",
  access: ["create-products"],
  path: "products/add",
};
