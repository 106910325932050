import { useState, useEffect, useMemo, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { classNames } from "../../utils";
import { Button } from "./Button";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Customer = {
  id: string;
  customerName: string;
  pricingStructure: {
    id: string;
    name: string;
  } | null;
  status: number;
};

type Structure = {
  id?: string;
  name: string;
  pricingMethod: {
    id: string;
    name: string;
  };
  customerIds: number[];
  status: boolean;
};

export function FieldStructureCustomers({
  loading,
  customers: initialCustomers = [],
  occupiedIds = [],
  pricingStructure,
  values: customerIds,
  onChange,
}: {
  loading?: boolean;
  customers: Customer[];
  occupiedIds: number[];
  pricingStructure: Structure;
  values: number[];
  onChange: (customerIds: number[]) => void;
}) {
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);

  const [query, setQuery] = useState<string | undefined>(undefined);

  const isDirty = useMemo(() => {
    const selectedCustomerIds = selectedCustomers.flatMap((c) =>
      parseInt(c.id)
    );
    const isArray =
      Array.isArray(customerIds) && Array.isArray(selectedCustomerIds);
    const isLength =
      isArray && selectedCustomerIds.length !== customerIds.length;
    return (
      isLength ||
      (isArray && selectedCustomerIds.some((id) => !customerIds.includes(id)))
    );
  }, [selectedCustomers, customerIds]);

  const customers = useMemo(() => {
    return initialCustomers;
  }, [initialCustomers]);

  useEffect(() => {
    if (Array.isArray(customerIds) && customerIds.length > 0) {
      const updateSelectedCustomers = customers.filter((customer) =>
        customerIds.includes(parseInt(customer.id))
      );
      setSelectedCustomers(updateSelectedCustomers);
    }
  }, [customerIds, initialCustomers]);

  return (
    <>
      <Button
        variant="primary"
        className="w-full justify-center"
        onClick={() => {
          setQuery("");
        }}
      >
        Assign Customers
      </Button>
      <Transition.Root
        show={query === undefined ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setQuery(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="py-8 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          Select customers
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setQuery(undefined);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new department.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="relative mb-6 overflow-hidden border border-gray-200 md:rounded-lg">
                          <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="relative w-12 px-6 sm:w-16 sm:px-8"
                                ></th>
                                <th
                                  scope="col"
                                  className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font- v text-gray-900"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Pricing Structure
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                >
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {customers.map((customer) => {
                                const isDisabled =
                                  (customer.pricingStructure &&
                                    customer.pricingStructure.id !==
                                      pricingStructure.id) ||
                                  occupiedIds.includes(parseInt(customer.id))
                                    ? true
                                    : false;

                                return (
                                  <tr
                                    key={customer.id}
                                    className={
                                      selectedCustomers.includes(customer)
                                        ? "bg-gray-50"
                                        : undefined
                                    }
                                  >
                                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                      {selectedCustomers.includes(customer) && (
                                        <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-600" />
                                      )}
                                      <input
                                        type="checkbox"
                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                                        value={customer.id}
                                        checked={selectedCustomers.includes(
                                          customer
                                        )}
                                        onChange={(e) =>
                                          setSelectedCustomers(
                                            e.target.checked
                                              ? [...selectedCustomers, customer]
                                              : selectedCustomers.filter(
                                                  (p) => p !== customer
                                                )
                                          )
                                        }
                                        disabled={isDisabled}
                                      />
                                    </td>
                                    <td
                                      className={classNames(
                                        "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                        selectedCustomers.includes(customer)
                                          ? "text-primary-600"
                                          : "text-gray-900"
                                      )}
                                    >
                                      {customer.customerName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {customer.pricingStructure
                                        ? customer.pricingStructure.name
                                        : occupiedIds.includes(
                                            parseInt(customer.id)
                                          )
                                        ? "Assigned to another structure"
                                        : "--"}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                      {!isDisabled &&
                                      !selectedCustomers.includes(customer) ? (
                                        <Button
                                          variant="text"
                                          className="text-primary-600 hover:text-primary-900"
                                          onClick={() => {
                                            onChange([parseInt(customer.id)]);
                                            setQuery(undefined);
                                          }}
                                        >
                                          Insert
                                          <span className="sr-only">
                                            , {customer.id}
                                          </span>
                                        </Button>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setQuery(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!isDirty}
                      onClick={() => {
                        onChange(selectedCustomers.map((c) => parseInt(c.id)));
                        setQuery(undefined);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
