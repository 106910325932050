import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";

const CustomerGroupCreate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CREATE_CUSTOMERGROUP = gql`
    mutation CustomerGroupCreate(
      $name: String!
      $description: String
      $customerIds: [Int!]
      $status: Boolean!
    ) {
      customerGroupCreate(
        input: {
          params: {
            name: $name
            description: $description
            customerIds: $customerIds
            status: $status
          }
        }
      ) {
        customerGroup {
          id
          name
          description
          customers {
            id
            customerName
          }
          status
        }
      }
    }
  `;
  const [createCustomerGroup] = useMutation(CREATE_CUSTOMERGROUP);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCustomerGroup({
      variables: {
        name: values.name,
        description: values.description,
        customerIds: values.customers ? values.customers : [],
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerGroupCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer Group created successfully",
          });
          return navigate(`/sales/customer-groups`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer Group creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer Group creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Customer Group"
        heading="Customer Groups"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customer Groups",
            href: "/sales/customer-groups",
          },
          {
            name: CustomerGroupCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_customer_group")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_customer_group")}
              </p>
            </div>
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link
                  to="/sales/customer-groups/add"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add customer group
                </Link>
              </div> */}
          </div>

          <Form
            initialValues={{
              name: "",
              description: "",
              customers: [],
              status: true,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerGroupCreate;
export const CustomerGroupCreateResource: ResourceProps = {
  name: "Create CustomerGroup",
  description: "Create a new customer group",
  access: ["create-customergroups"],
  path: "customer-groups/add",
};
