import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";

const FETCH_CATEGORIES = gql`
  query FetchCategory($id: ID!) {
    fetchCategory(id: $id) {
      id
      name
      description
      parent {
        id
        name
      }
      products {
        id
        name
      }
      imageUrl
      categoryType
      conditionType
      conditions {
        conditionTitle
        conditionSymbol
        conditionValue
      }
      createdAt
      status
    }
  }
`;

const CategoryUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { categoryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type Product = {
    id: number | string;
    name: string;
  };
  type Category = {
    id: string;
    name: string;
    description: string;
    parent: Category;
    products: Product[];
    imageUrl: string;
    categoryType: number;
    conditionType: number;
    conditions: any[];
    createdAt: string;
    status: boolean;
  };

  const [category, setCategory] = useState<Category>();

  const [fetchCategory] = useLazyQuery(FETCH_CATEGORIES);

  useEffect(() => {
    fetchCategory({
      variables: {
        id: categoryId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCategory) {
          setCategory(data.fetchCategory);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Category read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategory, categoryId]);

  const UPDATE_CATEGORY = gql`
    mutation CategoryUpdate(
      $id: ID!
      $name: String!
      $parentId: Int
      $description: String
      $productsIds: [Int!]
      $imageUrl: String
      $categoryType: Int
      $conditionType: Int
      $conditions: [CategoryproductInput!]
      $status: Boolean!
    ) {
      categoryUpdate(
        input: {
          id: $id
          params: {
            name: $name
            description: $description
            parentId: $parentId
            productsIds: $productsIds
            imageUrl: $imageUrl
            categoryType: $categoryType
            conditionType: $conditionType
            conditions: $conditions
            status: $status
          }
        }
      ) {
        category {
          id
          name
          description
          parent {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateCategory({
      variables: {
        id: category?.id,
        name: values.name,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        productsIds: values?.productsIds ? values.productsIds : [],
        imageUrl: values.imageUrl,
        categoryType: values.categoryType,
        conditionType: values.conditionType,
        conditions: values.conditions,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.categoryUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Category updated successfully",
          });
          return navigate(`/inventory/categories`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Category update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Category update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={CategoryUpdateResource.name}
        heading="Categories"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Categories",
            href: "/inventory/categories",
          },
          {
            name: CategoryUpdateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          {/* <div className="sm:flex sm:items-center hidden">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_category")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_category")}
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/inventory/categories/add"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
            >
              Add category
            </Link>
          </div>
          </div> */}
          <div className="flex flex-col">
            <div className="md:col-span-2">
              <Form
                initialValues={{
                  name: category?.name ?? "",
                  description: category?.description ?? "",
                  parent: category?.parent
                    ? {
                        label: category?.parent.name,
                        value: category?.parent.id,
                      }
                    : null,
                  productsIds: category?.products
                    ? category.products?.map((p) =>
                        typeof p.id === "string" ? parseInt(p.id) : p.id
                      )
                    : [],
                  imageUrl: category?.imageUrl ?? "",
                  categoryType: category?.categoryType ?? 0,
                  conditionType: category?.conditionType ?? 0,
                  conditions: category?.conditions
                    ? category.conditions.map((c) => {
                        const {
                          conditionTitle,
                          conditionSymbol,
                          conditionValue,
                        } = c;
                        return {
                          conditionTitle,
                          conditionSymbol,
                          conditionValue,
                        };
                      })
                    : [],
                  status: category?.status ? true : false,
                }}
                onSubmit={handleSubmit}
                actionLabel={t("text_update")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryUpdate;
export const CategoryUpdateResource: ResourceProps = {
  name: "Edit Category",
  description: "Update an existing category",
  access: ["update-categories"],
  path: "categories/:categoryId",
};
