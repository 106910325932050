import { useEffect, useMemo, useState, Fragment } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../components/table";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Form from "./components/Form";

import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type CustomerContactType = {
  id?: string;
  name: string;
  createdAt: string;
  status: boolean;
};

const FETCH_CUSTOMERCONTACTTYPES = gql`
  query FetchCustomerContactTypes {
    fetchCustomerContactTypes {
      id
      name
      createdAt
      status
    }
  }
`;

const CustomerContactTypeList = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchCustomerContactTypes, { loading }] = useLazyQuery(
    FETCH_CUSTOMERCONTACTTYPES
  );
  const [customerContactTypes, setCustomerContactTypes] = useState<
    CustomerContactType[]
  >([]);
  const [activeCustomerContactType, setActiveCustomerContactType] = useState<
    CustomerContactType | undefined
  >(undefined);
  const [newCustomerContactType, setNewCustomerContactType] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    fetchCustomerContactTypes()
      .then(({ data, error }) => {
        if (data?.fetchCustomerContactTypes) {
          const updatedCustomerContactTypes =
            data.fetchCustomerContactTypes.map((item: CustomerContactType) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            }));
          setCustomerContactTypes(updatedCustomerContactTypes);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Customer contact types read is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerContactTypes]);

  const UPDATE_CUSTOMERCONTACTTYPE = gql`
    mutation CustomerContactTypeUpdate(
      $id: ID!
      $name: String!
      $status: Boolean!
    ) {
      customerContactTypeUpdate(
        input: { id: $id, params: { name: $name, status: $status } }
      ) {
        contactType {
          id
          name
          createdAt
          status
        }
      }
    }
  `;
  const [updateCustomerContactType] = useMutation(UPDATE_CUSTOMERCONTACTTYPE);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateCustomerContactType({
      variables: {
        id: activeCustomerContactType?.id,
        name: values.name,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerContactTypeUpdate) {
          const newCustomerContactTypes = [...customerContactTypes];
          const index = newCustomerContactTypes.findIndex(
            (customerContactType) =>
              customerContactType.id === activeCustomerContactType?.id
          );
          newCustomerContactTypes[index] = {
            ...data.customerContactTypeUpdate.contactType,
            createdAt: DateTime.fromISO(
              data.customerContactTypeUpdate.contactType.createdAt,
              {
                locale: i18n.language,
              }
            ).toLocaleString(DateTime.DATE_MED),
          };
          setCustomerContactTypes(newCustomerContactTypes);
          setActiveCustomerContactType(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer contact type update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer contact type update failed",
          message: error.message,
        });
      });
  };

  const CREATE_CUSTOMERCONTACTTYPE = gql`
    mutation CustomerContactTypeCreate($name: String!, $status: Boolean!) {
      customerContactTypeCreate(
        input: { params: { name: $name, status: $status } }
      ) {
        contactType {
          id
          name
          createdAt
          status
        }
      }
    }
  `;
  const [createCustomerContactType] = useMutation(CREATE_CUSTOMERCONTACTTYPE);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCustomerContactType({
      variables: {
        name: values.name,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerContactTypeCreate) {
          setCustomerContactTypes([
            ...customerContactTypes,
            {
              ...data.customerContactTypeCreate?.contactType,
              createdAt: DateTime.fromISO(
                data.customerContactTypeCreate.contactType.createdAt,
                {
                  locale: i18n.language,
                }
              ).toLocaleString(DateTime.DATE_MED),
            },
          ]);
          setNewCustomerContactType(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer contact type created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer contact type create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer contact type create failed",
          message: error.message,
        });
      });
  };

  const DELETE_CUSTOMERCONTACTTYPE = gql`
    mutation CustomerContactTypeDelete($id: ID!) {
      customerContactTypeDelete(input: { id: $id }) {
        contactType {
          id
          name
        }
      }
    }
  `;
  const [deleteCustomerContactType] = useMutation(DELETE_CUSTOMERCONTACTTYPE);
  const handleDelete = async (id: string) => {
    deleteCustomerContactType({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.customerContactTypeDelete) {
          const deletedCustomerContactType =
            data.customerContactTypeDelete?.contactType;
          const newCustomerContactTypes = [...customerContactTypes];
          const index = newCustomerContactTypes.findIndex(
            (customerContactType) => customerContactType.id === id
          );
          newCustomerContactTypes.splice(index, 1);
          setCustomerContactTypes(newCustomerContactTypes);

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Customer contact type ${deletedCustomerContactType.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer contact type deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer contact type deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<CustomerContactType>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-customercontacttypes"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => setActiveCustomerContactType(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-customercontacttypes"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Customer Contact Types"
        heading="Customer Contact Types"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: CustomerContactTypeListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Customer Contact Types List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the customerContactTypes in your account including
              their name, title, email and customerContactType.
            </p>
          </div>
        </div>

        <TablePaginate
          data={customerContactTypes}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-customercontacttypes"]}>
              <Button onClick={() => setNewCustomerContactType(true)}>
                Add customerContactType
              </Button>
            </AuthShield>
          )}
        />
      </div>

      <Transition.Root
        show={activeCustomerContactType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveCustomerContactType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Customer contact type - "
                  initialValues={{
                    id: activeCustomerContactType?.id,
                    name: activeCustomerContactType?.name ?? "",
                    status: activeCustomerContactType?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveCustomerContactType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newCustomerContactType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewCustomerContactType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Customer contact type - "
                  initialValues={{
                    name: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewCustomerContactType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CustomerContactTypeList;
export const CustomerContactTypeListResource: ResourceProps = {
  name: "Customer Contact Types List",
  description: "A list of customerContactTypes",
  access: ["read-customercontacttypes"],
  path: "customer-contact-types",
};
