import React, { CSSProperties } from "react";
import { useDraggable } from "@dnd-kit/core";
import { useTranslation } from "react-i18next";
import { IField } from "./types";
import { classNames } from "../../../utils";

export const DraggableItem = ({
  id,
  fieldType,
  fieldName,
  fieldKey,
  disabled,
  icon,
  fieldOptions,
  description,
}: IField) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: id,
      data: {
        id,
        fieldType,
        fieldName,
        fieldKey,
        disabled,
        icon,
        fieldOptions,
        description,
      },
    });

  const { t } = useTranslation();

  const style: CSSProperties | undefined = isDragging
    ? {
        position: "absolute",
        transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
        cursor: "move",
      }
    : {
        cursor: "pointer",
      };

  return (
    <>
      <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
        <div className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-50"
          >
            <span
              className={classNames(
                "-ml-1 mr-2 h-5 w-5 text-gray-400",
                icon ? icon : ""
              )}
              aria-hidden="true"
            ></span>
            {t(
              fieldKey === "$minimumMarkup" || fieldKey === "$maximumMarkup"
                ? fieldName
                : fieldType
            )}
          </button>
          <div className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-xs font-normal text-gray-700 hover:bg-gray-50">
            <span className="bi bi-arrows-move"></span>
          </div>
        </div>
      </div>
      {isDragging && (
        <div style={{ display: "none !important" }}>
          <div className="isolate inline-flex rounded-md opacity-60 shadow-sm">
            <div className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-50">
              <span
                className={classNames(
                  "-ml-1 mr-2 h-5 w-5 text-gray-400",
                  icon ? icon : ""
                )}
                aria-hidden="true"
              ></span>
              {t(
                fieldKey === "$minimumMarkup" || fieldKey === "$maximumMarkup"
                  ? fieldName
                  : fieldType
              )}
            </div>
            <div className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-xs font-normal text-gray-700 hover:bg-gray-50">
              <span className="bi bi-arrows-move"></span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
