import { useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import * as Yup from "yup";
import { useAuth } from "../../auth";
import { Button, Field, FilePreview, Response } from "../../../components/form";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";

// const SUPPORTED_FORMATS = [
//   "image/jpg",
//   "image/jpeg",
//   "image/gif",
//   "image/png",
// ];

// const validateImageType = (value: Blob) => {
//   if (value) {
//     console.log("value", value);
//     let type = value.type;
//     console.log("type", type);
//     return SUPPORTED_FORMATS.includes(type);
//   }
// };

// const FILE_SIZE = 3 * 1024 * 1024; // 3MB

const PersonalSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(80, "Too Long!")
    .required("Required"),
  lastName: Yup.string().max(80, "Too Long!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneNumber: Yup.string().required("Required"),
  userName: Yup.string()
    .min(2, "Too Short!")
    .max(80, "Too Long!")
    .required("Required"),
  // avatar: Yup.mixed()
  //   .nullable()
  //   .test("fileSize", "File is too large (max 3MB)", (value) => {
  //     console.log("value", value);
  //     return value && value.size <= FILE_SIZE;
  //   })
  //   .test(
  //     "fileType",
  //     "File type is not supported (jpg, jpeg, png)",
  //     (value) => {
  //       console.log("value", value);
  //       return value && validateImageType(value);
  //     }
  //   ),
});

const UPDATE_PROFILE = gql`
  mutation profileUpdate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $userName: String!
  ) {
    profileUpdate(
      input: {
        params: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          userName: $userName
        }
      }
    ) {
      user {
        id
        userName
      }
    }
  }
`;

const Account = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [response, setResponse] = useState<FormResponse | null>(null);

  const [profileUpdate, { loading }] = useMutation(UPDATE_PROFILE);

  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.firstName ?? "",
      lastName: currentUser?.lastName ?? "",
      email: currentUser?.email ?? "",
      phoneNumber: currentUser?.phoneNumber ?? "",
      userName: currentUser?.userName ?? "",
      // avatar: currentUser?.avatar ?? "",
    },
    validationSchema: PersonalSchema,
    onSubmit: (values, actions) => {
      profileUpdate({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          userName: values.userName,
        },
      })
        .then(({ data }) => {
          setResponse(null);
          actions.setSubmitting(false);

          if (data?.profileUpdate) {
            setResponse({
              type: "success",
              message: "Your profile has been updated successfully",
            });
          } else {
            setResponse({
              type: "error",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  const { errors, touched, isSubmitting, setFieldValue } = formik;

  return (
    <>
      <Head
        title="Personal Information"
        heading="Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Account",
            href: "/settings/account",
          },
        ]}
      />
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <form onSubmit={formik.handleSubmit}>
          <div className="shadow-sm sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Use a permanent address where you can recieve mail.
                </p>
              </div>

              <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
                <div className="col-span-12 sm:col-span-6 md:col-span-3">
                  <Field
                    title={t("text_firstname")}
                    autoComplete="given-name"
                    type="text"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    touched={touched.firstName}
                    errors={errors.firstName}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-3">
                  <Field
                    title={t("text_lastname")}
                    autoComplete="family-name"
                    type="text"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    touched={touched.lastName}
                    errors={errors.lastName}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-3">
                  <Field
                    title={t("text_email")}
                    autoComplete="email"
                    type="text"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    touched={touched.email}
                    errors={errors.email}
                  />
                </div>

                {/* <div className="col-span-12 sm:col-span-6 md:col-span-3">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_photo")}
                </label>
                <div className="mt-1 flex items-center">
                  {formik.values?.avatar ? (
                    <FilePreview
                      file={formik.values.avatar}
                      format={SUPPORTED_FORMATS}
                      className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100"
                    />
                  ) : (
                    <div className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </div>
                  )}

                  <input
                    name="avatar"
                    id="avatar"
                    type="file"
                    hidden
                    onChange={(e) => {
                      setFieldValue(
                        "avatar",
                        e.currentTarget.files ? e.currentTarget.files[0] : null
                      );
                    }}
                  />
                  <label
                    htmlFor="avatar"
                    className="ml-5 rounded-md border border-gray-300 bg-white px-3 py-2.5 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  >
                    {t("text_choose_file")}
                  </label>
                </div>
                {touched.avatar && errors.avatar ? (
                  <p className="mt-2 text-sm text-red-600" id="avatar-error">
                    {errors.avatar}
                  </p>
                ) : null}
              </div> */}

                <div className="col-span-12 sm:col-span-6 md:col-span-3">
                  <Field
                    title={t("text_phone")}
                    autoComplete="tel"
                    type="text"
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    touched={touched.phoneNumber}
                    errors={errors.phoneNumber}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-3">
                  <Field
                    title={t("text_username")}
                    autoComplete="username"
                    type="text"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    touched={touched.userName}
                    errors={errors.userName}
                  />
                </div>
              </div>

              <Response
                response={response}
                onDismiss={() => {
                  setResponse(null);
                }}
                className="mt-5"
              />
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-4 text-right md:py-6 md:px-6">
              <Button
                variant="primary"
                type="submit"
                className="md:w-36 justify-center"
                loading={loading}
              >
                {isSubmitting ? t("text_processing") : t("text_update")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Account;
export const AccountResource: ResourceProps = {
  name: "Account",
  description: "Account page",
  access: [],
  path: "account",
  icon: "bi bi-person",
};
