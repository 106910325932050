import { Fragment } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button, Field, FieldUploader } from "../../../../components/form";

import { Spinner } from "../../../../animations";

type VariantProps = {
    index?: number;
    stockCode?: string;
    basePrice?: number;
    comparePrice?: number;
    cost?: number;
    marketCost?: number;
    minimumQuantity?: number;
    inventory?: number;
    variantImageUrl?: string;
  };

export default function Variants({
  heading,
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: VariantProps;
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();

  const DepartmentSchema = Yup.object().shape({
    stockCode: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    basePrice: Yup.number().nullable(),
    comparePrice: Yup.number().nullable(),
    cost: Yup.number().nullable(),
    marketCost: Yup.number().nullable(),
    minimumQuantity: Yup.number().nullable(),
    inventory: Yup.number().nullable(),
    variantImageUrl: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: DepartmentSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="py-8 px-4 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.stockCode
                    ? formik.values.stockCode
                    : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Edit the variant details below.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <Field
                      title={t("text_stock_code")}
                      name="stockCode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.stockCode}
                      touched={touched.stockCode}
                      errors={errors.stockCode}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_base_price")}
                      name="basePrice"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.basePrice}
                      touched={touched.basePrice}
                      errors={errors.basePrice}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_compare_price")}
                      name="comparePrice"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.comparePrice}
                      touched={touched.comparePrice}
                      errors={errors.comparePrice}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_cost")}
                      name="cost"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cost}
                      touched={touched.cost}
                      errors={errors.cost}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_market_cost")}
                      name="marketCost"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.marketCost}
                      touched={touched.marketCost}
                      errors={errors.marketCost}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_inventory")}
                      name="inventory"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.inventory}
                      touched={touched.inventory}
                      errors={errors.inventory}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_minimum_quantity")}
                      name="minimumQuantity"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.minimumQuantity}
                      touched={touched.minimumQuantity}
                      errors={errors.minimumQuantity}
                    />
                  </div>

                  <div className="sm:col-span-6">
                    <FieldUploader
                      title={t("text_image")}
                      name="variantImageUrl"
                      directory="inventories/products/variants"
                      onChange={(value: any) => {
                        formik.setFieldValue("variantImageUrl", value);
                      }}
                      value={formik.values.variantImageUrl}
                      touched={touched.variantImageUrl}
                      errors={errors.variantImageUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit">
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
