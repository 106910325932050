import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Field } from "../../../../components/form";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { Head } from "../../../../components/core";

// const AutoSubmitToken = () => {
//   const { values, submitForm }: { values: any; submitForm: any } =
//     useFormikContext();
//   useEffect(() => {
//     if (values.code.length === 6) {
//       submitForm();
//     }
//   }, [values, submitForm]);
//   return null;
// };

const CODE_LENGTH = 6;

export default function Totp({
  title,
  description,
  challengeHandler,
  verifyHandler,
}: {
  title: string;
  description: string;
  challengeHandler: () => void;
  verifyHandler: ({
    code,
    type,
  }: {
    code: string;
    type: string;
  }) => Promise<any>;
}) {
  const { t } = useTranslation();
  /* useEffect(() => {
    if ("OTPCredential" in window) {
      console.log("OTPCredential is available");
    }
    const onDOMContentLoaded = (e: any) =>
      console.info("DOMContentLoaded e:", e);
    window.addEventListener("DOMContentLoaded", onDOMContentLoaded);
    return () => {
      window.removeEventListener("DOMContentLoaded", onDOMContentLoaded);
    };
  }, []); */

  const TotpSchema = Yup.object().shape({
    code: Yup.string()
      .matches(/^[0-9]{6}$/, `Code must be ${CODE_LENGTH} digits long`)
      .required("Please enter your OTP code from your authenticator app"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: TotpSchema,
    onSubmit: (values, actions) => {
      return verifyHandler({ code: values.code, type: "otp" });
    },
  });

  const { errors, touched, isSubmitting, setFieldValue } = formik;

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .replace(/[^0-9]/g, "")
        .slice(0, CODE_LENGTH);
      if (pastedData) {
        setFieldValue("code", pastedData);
      }
    },
    [setFieldValue]
  );

  return (
    <>
      <Head title={title} heading={title} />
      <div className="mt-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        <p className="text-sm">{description}</p>
        <form className="mt-2 space-y-6" onSubmit={formik.handleSubmit}>
          <div className="-space-y-px">
            <div>
              <Field
                title={t("Enter your OTP")}
                name="code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.code}
                touched={touched.code}
                errors={errors.code}
                label={false}
                autoComplete="off"
                inputMode="numeric"
                required
                placeholder="000-000"
                onPaste={handleOnPaste}
              />
            </div>
            {/* <AutoSubmitToken /> */}
          </div>

          <div className="grid grid-cols-5 items-center gap-4">
            <div className="col-span-3">
              <Button
                variant="text"
                className="justify-start border-0 pl-0 pr-0 text-primary-700"
                type="button"
                onClick={challengeHandler}
              >
                Try another way
              </Button>
            </div>
            <Button
              type="submit"
              variant="primary"
              className="group relative col-span-2 flex justify-center rounded-md border border-transparent bg-primary-600 py-2.5 px-4 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
              disabled={isSubmitting}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
