import { lazy, Suspense } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import { useAuth, isAuthorizedForResource } from "../auth";

import {
  LayoutSplashScreen,
  NotFound,
  NotAuthorized,
} from "../../components/core";

import { SettingsResource } from "./settings/Settings";

const Settings = lazy(() => import("./settings/Settings"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
];

export function FleetsPage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      children: [
        {
          index: true,
          element: <Navigate to={SettingsResource.path} replace={true} />,
        },
        {
          path: SettingsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SettingsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Settings breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SettingsResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
