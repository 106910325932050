import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { evaluate } from "mathjs";

import { Button, Field } from "./";

import { SpinnerInline } from "../../animations";
import { classNames } from "../../utils";

import placeholder from "../../assets/placeholder.svg";
import { Link, useParams } from "react-router-dom";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/outline";

type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
};

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
  pricingLevelDetails?: Details[];
};

type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id?: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type FieldWithValue = PricingMethodField & {
  fieldValue: string;
};

type IFormatedFormula = {
  key: string;
  value: string;
};

enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

type ProductPricing = {
  id: string;
  pricingId?: string;
  pricingLevelId: number;
  productId: number;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};

export function FieldPriceLevelCalculator({
  product,
  removeProduct,
  pricingMethod,
  className,
  productPricing,
  setProductPricing,
}: {
  product: Product;
  removeProduct: (id: string) => void;
  pricingMethod: PricingMethod;
  className?: string;
  productPricing: ProductPricing[];
  setProductPricing: Dispatch<SetStateAction<ProductPricing[]>>;
}) {
  const { variants } = product;

  return (
    <div
      className={classNames(
        "flex divide-x rounded-md border border-gray-100 bg-white p-4",
        className ?? ""
      )}
    >
      <div className="flex w-72 max-w-full flex-col pr-4">
        <div className="mt-2">
          <div className="h-16 w-16">
            <img
              className="h-16 w-16 rounded-md"
              src={
                product.featureImageUrl ? product.featureImageUrl : placeholder
              }
              alt={product.name}
            />
          </div>
          <div className="mt-4">
            <Link
              to={`/inventory/products/${product.id}`}
              target="_blank"
              className="overflow-ellipsis text-sm font-normal text-gray-900"
            >
              {product.name}
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full pl-6">
        <div className="mb-4 flex justify-end">
          <Button
            variant="icon"
            onClick={() => {
              removeProduct(product.id);
            }}
            className="rounded-md p-2 text-sm text-gray-700 transition-all hover:bg-white hover:text-gray-800"
          >
            <TrashIcon aria-hidden="true" className="h-5 w-5" />
            <span className="sr-only">Remove product, {product.name}</span>
          </Button>
        </div>
        <div className="divide-y divide-gray-100">
          {variants.map((variant) => (
            <ProductVariant
              key={variant.id}
              variant={variant}
              pricingMethod={pricingMethod}
              productPricing={productPricing}
              setProductPricing={setProductPricing}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const ProductVariant = ({
  variant,
  pricingMethod,
  productPricing,
  setProductPricing,
}: {
  variant: Variant;
  pricingMethod: PricingMethod;
  productPricing: ProductPricing[];
  setProductPricing: Dispatch<SetStateAction<ProductPricing[]>>;
}) => {
  const { t } = useTranslation();

  const { pricingLevelId } = useParams();

  const [priceFields, setPriceFields] = useState<FieldWithValue[]>([]);
  const [formatedFormula, setFormatedFormula] = useState<IFormatedFormula[]>(
    []
  );
  const [sellPrice, setSellPrice] = useState<number>(0);
  const [pricingId, setPricingId] = useState<string | undefined>(undefined);

  const didInitialLoad = useRef(false);

  const initialLoading = useCallback(() => {
    if (!pricingLevelId) return;

    if (pricingMethod) {
      let pricingLevelDetails: Details;
      if (
        variant.pricingLevelDetails &&
        variant.pricingLevelDetails.length > 0
      ) {
        pricingLevelDetails = variant.pricingLevelDetails[0];
        if (pricingLevelDetails) {
          setPricingId(pricingLevelDetails.id);
          setSellPrice(pricingLevelDetails.sellPrice);
        } else {
          setSellPrice(0);
          setPricingId(undefined);
        }
      }else{
        setSellPrice(0);
        setPricingId(undefined);
      }

      if (pricingMethod?.pricingMethodFields) {
        const updatedPriceFields = pricingMethod.pricingMethodFields?.map(
          (field: PricingMethodField) => {
            let updatedValue = "";
            if (pricingLevelDetails) {
              let findValue = pricingLevelDetails.priceFields.find(
                (pf: any) => pf.fieldKey === field.fieldKey
              );
              if (findValue) {
                updatedValue = findValue.fieldValue;
              }
            }

            return {
              ...field,
              fieldValue: updatedValue,
            };
          }
        );
        setPriceFields(updatedPriceFields);
      }else{
        setPriceFields([]);
      }

      const updatedFormatedFormula = pricingMethod?.sellPriceFormula?.map(
        (formula: string) => {
          let updatedValue = "";
          if (/^\$[a-zA-Z0-9]+/.test(formula)) {
            if (pricingLevelDetails) {
              let findValue = pricingLevelDetails.priceFields.find(
                (pf: any) => pf.fieldKey === formula
              );
              if (findValue) {
                updatedValue = findValue.fieldValue;
              }
            }
          } else {
            updatedValue = formula;
          }

          return {
            key: formula,
            value: updatedValue,
          };
        }
      );
      setFormatedFormula(updatedFormatedFormula);
    } else {
      setSellPrice(0);
      setPricingId(undefined);
      setPriceFields([]);
      setFormatedFormula([]);
    }
  }, [pricingMethod, variant]);

  useEffect(() => {
    if (!didInitialLoad.current) {
      initialLoading();
      didInitialLoad.current = true;
    }
  }, [initialLoading]);

  const handleCalculate = useCallback(
    (formatedFormula: IFormatedFormula[], priceFields: any[]) => {
      if (!pricingLevelId) return;
      if (formatedFormula.length > 0) {
        if (formatedFormula.flatMap((f) => f.value).some((v) => v === ""))
          return setSellPrice(0);

        let updatedPrice: number = evaluate(
          formatedFormula.flatMap((ff) => ff.value).join(" ")
        );

        const isMinimumMarkup = pricingMethod?.pricingMethodFields?.some(
          (field) => field.fieldKey === "$minimumMarkup"
        );
        const isMaximumMarkup = pricingMethod?.pricingMethodFields?.some(
          (field) => field.fieldKey === "$maximumMarkup"
        );
        const cost = priceFields.find((pf: any) => pf.fieldKey === "$cost");

        if (isMinimumMarkup && cost) {
          const minimumMarkup = priceFields.find(
            (pf: any) => pf.fieldKey === "$minimumMarkup"
          );
          const costValue = parseFloat(cost.fieldValue);

          if (minimumMarkup) {
            if (
              updatedPrice - costValue <
              parseFloat(minimumMarkup.fieldValue)
            ) {
              updatedPrice = costValue + parseFloat(minimumMarkup.fieldValue);
            }
          }
        }

        if (isMaximumMarkup && cost) {
          const maximumMarkup = priceFields.find(
            (pf: any) => pf.fieldKey === "$maximumMarkup"
          );
          const costValue = parseFloat(cost.fieldValue);

          if (maximumMarkup) {
            if (
              updatedPrice - costValue >
              parseFloat(maximumMarkup.fieldValue)
            ) {
              updatedPrice = costValue + parseFloat(maximumMarkup.fieldValue);
            }
          }
        }

        let newPrice = Number((Math.ceil(updatedPrice * 20) / 20).toFixed(2));
        newPrice = Number(updatedPrice.toFixed(2));
        setSellPrice(newPrice);

        const updatedProductPrice: ProductPricing = {
          id: `${variant.productId}-${variant.id}`,
          pricingId,
          pricingLevelId: parseInt(pricingLevelId),
          productId: variant.productId,
          productSkuId: parseInt(variant.id),
          priceFields: priceFields.map((pf) => ({
            fieldType: pf.fieldType,
            fieldKey: pf.fieldKey,
            fieldValue: pf.fieldValue,
          })),
          sellPrice: newPrice,
          status: ProductPricingStatus.pending,
        };

        const updatedProductPricing = [...productPricing];
        const index = updatedProductPricing.findIndex(
          (pp) => pp.id === updatedProductPrice.id
        );
        if (index > -1) {
          updatedProductPricing[index] = updatedProductPrice;
        } else {
          updatedProductPricing.push(updatedProductPrice);
        }
        setProductPricing(updatedProductPricing);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [pricingMethod, productPricing, pricingId]
  );

  const renderStatus = useCallback(() => {
    const findStatus = productPricing.find(
      (pp) => pp.id === `${variant.productId}-${variant.id}`
    );

    switch (findStatus?.status) {
      case ProductPricingStatus.pending:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full border border-gray-200 bg-white">
            <ExclamationTriangleIcon
              className="h-5 w-5 -rotate-45 text-yellow-400"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.success:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full border border-gray-200 bg-white">
            <CheckIcon
              className="h-5 w-5 -rotate-45 text-green-500"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.error:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full border border-gray-200 bg-white">
            <XMarkIcon
              className="h-5 w-5 -rotate-45 text-red-500"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.loading:
        return (
          <div className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white">
            <SpinnerInline className="text-green-500" />
          </div>
        );

      default:
        return null;
    }
  }, [productPricing, variant]);

  const handleFieldChange = (key: string, value: string) => {
    const updatedPriceFields = priceFields.map((field) => {
      if (field.fieldKey === key) {
        return {
          ...field,
          fieldValue: value,
        };
      }
      return field;
    });
    setPriceFields(updatedPriceFields);

    const updatedFormatedFormula = formatedFormula.map((f) => {
      if (f.key === key) {
        return {
          ...f,
          value,
        };
      }
      return f;
    });
    setFormatedFormula(updatedFormatedFormula);
    handleCalculate(updatedFormatedFormula, updatedPriceFields);
  };

  // console.log(1, evaluate("10 / (1 - 0.25)"));
  // console.log(2, evaluate("10 / (1 - 25%)"));

  return priceFields?.length ? (
    <div className="relative flex w-full flex-wrap py-3 pr-6">
      <div className="py-1 px-2">
        <Field
          title={t("text_stock_code")}
          value={variant.stockCode}
          disabled
          className="max-w-[7rem]"
        />
      </div>
      {priceFields.map((f) => {
        return (
          <div key={`${variant.id}-${f.fieldKey}`} className="py-1 px-2">
            <Field
              title={t(f.fieldName)}
              name={f.fieldKey}
              type={f.fieldType === "percentage" ? "text" : f.fieldType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = e.target;
                if (f.fieldType === "number") {
                  if (!isNaN(parseFloat(value))) {
                    handleFieldChange(name, value);
                  }
                } else if (f.fieldType === "percentage") {
                  if (!isNaN(parseFloat(value))) {
                    const updateValue = value.replace(/[^0-9.]/g, "");
                    handleFieldChange(name, `${updateValue}%`);
                  }
                } else {
                  handleFieldChange(name, value);
                }
              }}
              min={f.fieldType === "number" ? 0 : undefined}
              value={
                f.fieldType === "percentage"
                  ? f.fieldValue.replace(/[^0-9.]/g, "")
                  : f.fieldValue
              }
              className="max-w-[7rem]"
            />
          </div>
        );
      })}

      <div className="ml-auto py-1 text-right">
        <Field
          title={t("text_sell_price")}
          value={sellPrice}
          disabled
          className="max-w-[6rem] border-0 text-right"
        />
      </div>
      <div className="absolute inset-y-0 -left-10 m-auto h-5 w-5">
        {renderStatus()}
      </div>
    </div>
  ) : (
    <p className="font-medium text-gray-900">No price fields found</p>
  );
};
