import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  FieldPricingProducts,
  FieldSpecialPriceCalculator,
} from "../../../../components/form";
import { Head } from "../../../../components/core";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";
import { Spinner } from "../../../../animations";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
};

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
  specialPriceDetails?: Details[];
};

type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
};

type SpecialPrice = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  products: Product[];
  createdAt: string;
  status: boolean;
};

enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

type ProductPricing = {
  id: string;
  pricingId?: string;
  specialPriceId: number;
  productId: number;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};

const FETCH_SPECIALPRICE = gql`
  query FetchSpecialPrice($id: ID!, $specialPriceId: Int!) {
    fetchSpecialPrice(id: $id) {
      id
      name
      pricingMethod {
        id
        name
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
      products {
        id
        name
        featureImageUrl
        hasVariants
        variants {
          id
          stockCode
          productId
          specialPriceDetails(specialPriceId: $specialPriceId) {
            id
            priceFields
            sellPrice
          }
        }
        status
      }
      createdAt
      status
    }
  }
`;

const CREATE_SPECIALPRICEPRICING = gql`
  mutation SpecialPricePricingCreate(
    $specialPriceId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    specialPricePricingCreate(
      input: {
        params: {
          specialPriceId: $specialPriceId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const UPDATE_SPECIALPRICEPRICING = gql`
  mutation SpecialPricePricingUpdate(
    $id: ID!
    $specialPriceId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    specialPricePricingUpdate(
      input: {
        id: $id
        params: {
          specialPriceId: $specialPriceId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const DELETE_PRODUCTPRICING = gql`
  mutation SpecialPricePricingDelete($productId: Int!, $specialPriceId: Int!) {
    specialPricePricingDelete(
      input: { productId: $productId, specialPriceId: $specialPriceId }
    ) {
      message
    }
  }
`;

const UPDATE_SPECIALPRICE = gql`
  mutation SpecialPriceUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productIds: [Int!]
    $status: Boolean
  ) {
    specialPriceUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productIds: $productIds
          status: $status
        }
      }
    ) {
      specialPrice {
        id
        name
        pricingMethod {
          id
          name
        }
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

const SpecialPricePricing = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { specialPriceId } = useParams();

  const [specialPrice, setSpecialPrice] = useState<SpecialPrice>();
  const [fetchSpecialPrice] = useLazyQuery(FETCH_SPECIALPRICE);

  const [deleteProductPricing] = useMutation(DELETE_PRODUCTPRICING);

  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);
  const [createSpecialPricePricing, { loading: loadingCreate }] = useMutation(
    CREATE_SPECIALPRICEPRICING
  );
  const [updateSpecialPricePricing, { loading: loadingUpdate }] = useMutation(
    UPDATE_SPECIALPRICEPRICING
  );

  const [updateSpecialPrice] = useMutation(UPDATE_SPECIALPRICE);

  const didFetchSpecialPrice = useRef(false);
  const fetchingSpecialPrice = useCallback(() => {
    if (!specialPriceId) return;

    fetchSpecialPrice({
      variables: {
        id: specialPriceId,
        specialPriceId: parseInt(specialPriceId),
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchSpecialPrice) {
          console.log(data.fetchSpecialPrice);
          setSpecialPrice(data.fetchSpecialPrice);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Special price read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSpecialPrice, specialPriceId]);

  useEffect(() => {
    if (!didFetchSpecialPrice.current) {
      fetchingSpecialPrice();
      didFetchSpecialPrice.current = true;
    }
  }, [fetchingSpecialPrice]);

  async function handleSave() {
    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const {
        pricingId,
        specialPriceId,
        productId,
        productSkuId,
        priceFields,
        sellPrice,
      } = product;

      if (pricingId) {
        await updateSpecialPricePricing({
          variables: {
            id: pricingId,
            specialPriceId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.specialPricePricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createSpecialPricePricing({
          variables: {
            specialPriceId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.specialPricePricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    setProductPricing(newProductPricing);
  }

  const handleCancel = () => {
    return navigate(`/inventory/pricelist/special-prices/${specialPriceId}`);
  };

  const handleRemoveProduct = (productId: string) => {
    if (!specialPriceId || !specialPrice) return;
    const updatedLevel = {
      ...specialPrice,
      products: specialPrice.products.filter((item) => item.id !== productId),
    };

    deleteProductPricing({
      variables: {
        productId: parseInt(productId),
        specialPriceId: parseInt(specialPriceId),
      },
    })
      .then(({ data }) => {
        console.log(data);
        if (data?.specialPricePricingDelete) {
          setSpecialPrice(updatedLevel);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Product removed successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcut remove failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Prodcut remove failed",
          message: error.message,
        });
      });
  };

  const handleAddProduct = (products: Product[]) => {
    if (!specialPrice) return;

    const updatedSpecialPrice = {
      ...specialPrice,
      products: [...specialPrice.products, ...products],
    };

    updateSpecialPrice({
      variables: {
        id: specialPrice.id,
        name: specialPrice.name,
        pricingMethodId: parseInt(specialPrice.pricingMethod.id),
        productIds: products.map((item) => parseInt(item.id)),
        status: specialPrice.status,
      },
    })
      .then(({ data }) => {
        if (data?.specialPriceUpdate) {
          setSpecialPrice(updatedSpecialPrice);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Products updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcuts update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Prodcuts update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Manage Pricing"
        heading="Manage Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Manage Pricing",
            href: "/inventory/pricelist/special-prices",
          },
          {
            name: specialPrice?.name || "Special Price",
            href: `/inventory/pricelist/special-prices/${specialPriceId}`,
          },
          {
            name: SpecialPricePricingResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {SpecialPricePricingResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {SpecialPricePricingResource.description}
            </p>
          </div>
        </div>
        {specialPrice ? (
          <>
            <div className="mt-4 space-y-2">
              <div className="flex justify-end">
                <FieldPricingProducts
                  onChange={handleAddProduct}
                  exceptIds={
                    specialPrice.products.map((item) => parseInt(item.id)) ?? []
                  }
                />
              </div>
              {specialPrice.products.map((product) => (
                <FieldSpecialPriceCalculator
                  key={product.variants.flatMap((v) => v.stockCode).join("-")}
                  product={product}
                  removeProduct={handleRemoveProduct}
                  pricingMethod={specialPrice.pricingMethod}
                  productPricing={productPricing}
                  setProductPricing={setProductPricing}
                />
              ))}
            </div>
            <div className="mt-4 flex flex-wrap justify-end space-x-4">
              <Button
                variant="secondary"
                className="min-w-[10rem] justify-center"
                onClick={handleCancel}
              >
                {t("text_back")}
              </Button>
              {productPricing.filter(
                (pp) =>
                  pp.status === ProductPricingStatus.pending ||
                  pp.status === ProductPricingStatus.error
              ).length ? (
                <Button
                  onClick={handleSave}
                  className="min-w-[16rem] justify-center"
                >
                  {loadingCreate || loadingUpdate ? (
                    <>
                      <Spinner />
                      {t("text_save")}
                    </>
                  ) : (
                    t("text_save")
                  )}
                </Button>
              ) : null}
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default SpecialPricePricing;
export const SpecialPricePricingResource: ResourceProps = {
  name: "Manage Pricing",
  description: "Manage pricing list for special price",
  access: ["read-specialprices"],
  path: "special-prices/:specialPriceId/pricing",
};
