import { Role } from "../@types/auth";

const ACCESS_LOCAL_STORAGE_KEY = "afa-access";
const REFRESH_LOCAL_STORAGE_KEY = "afa-refresh";

const { REACT_APP_PROTECTED_ROLES } = process.env;
const adminRoles = REACT_APP_PROTECTED_ROLES
  ? REACT_APP_PROTECTED_ROLES.split(",")
  : ["Developer"];

const getAccess = (): string | undefined => {
  if (!localStorage) {
    return;
  }
  try {
    const lsValue = localStorage.getItem(ACCESS_LOCAL_STORAGE_KEY);
    if (lsValue) {
      return lsValue;
    } else {
      return;
    }
  } catch (error) {
    console.error("ACCESS LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAccess = (lsValue: string) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem(ACCESS_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("ACCESS LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAccess = () => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(ACCESS_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("ACCESS LOCAL STORAGE REMOVE ERROR", error);
  }
};

const getRefresh = (): string | undefined => {
  if (!localStorage) {
    return;
  }
  try {
    const lsValue = localStorage.getItem(REFRESH_LOCAL_STORAGE_KEY);
    if (lsValue) {
      return lsValue;
    } else {
      return;
    }
  } catch (error) {
    console.error("REFRESH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setRefresh = (lsValue: string) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem(REFRESH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("REFRESH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeRefresh = () => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(REFRESH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("REFRESH LOCAL STORAGE REMOVE ERROR", error);
  }
};

const isAdmin = (role: { roleName: string } | undefined) => {
  if (!role) return false;
  return adminRoles.includes(role.roleName);
};

const isAdministrator = (role: { roleName: string } | undefined) => {
  if (!role) return false;
  return role.roleName === "Administrator";
};

const isDeveloper = (role: { roleName: string } | undefined) => {
  if (!role) return false;
  return role.roleName === "Developer";
};

const PROTECTED_RESOURCE_LIST: string[] = [
  "protected",
  "Permissions",
  "Resources",
  "create-permissions",
  "update-permissions",
  "destroy-permissions",
  "create-resources",
  "update-resources",
  "destroy-resources",
];
const isAuthorizedForResource = (
  role: Role | undefined,
  resources?: string[]
) => {
  if (!resources || resources?.length === 0) return true;
  if (!role) return false;
  if (isDeveloper(role)) return true;
  if (resources.some((resource) => PROTECTED_RESOURCE_LIST.includes(resource)))
    return false;
  if (isAdmin(role)) return true;
  return resources.some((resource) => {
    return role.permissions.some((p) => p.permissionName === resource);
  });
};

const isAuthorizedForProtectedResource = (
  role: Role | undefined,
  resources?: string[]
) => {
  if (!resources || resources?.length === 0) return true;
  if (!role) return false;
  if (isDeveloper(role)) return true;
  return resources.some(
    (resource) => !PROTECTED_RESOURCE_LIST.includes(resource)
  );
};

export {
  getAccess,
  setAccess,
  removeAccess,
  ACCESS_LOCAL_STORAGE_KEY,
  getRefresh,
  setRefresh,
  removeRefresh,
  REFRESH_LOCAL_STORAGE_KEY,
  isAdmin,
  isAdministrator,
  isDeveloper,
  isAuthorizedForResource,
  isAuthorizedForProtectedResource,
  PROTECTED_RESOURCE_LIST,
};
