import { Fragment, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  Combobox,
  Listbox,
  Transition,
  Switch,
} from "@headlessui/react";
import Select, { MultiValue } from "react-select";
import {
  XMarkIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  Field,
  FieldEditor,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { classNames } from "../../../../utils";

import { useNavigate } from "react-router-dom";
import { PROTECTED_RESOURCE_LIST } from "../../../auth";
import { Spinner } from "../../../../animations";

type Resource = {
  id: string;
  resourceName: string;
  resourceDescription: string;
  status: string;
};

type DependProps = {
  label: string;
  value: string;
};

type Permission = {
  id?: string;
  permissionName: string;
  permissionDisplayName: string;
  permissionDescription: string;
  dependencies: MultiValue<DependProps>;
  resource: Resource;
  status: boolean;
};

export default function Form({
  heading,
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: Permission;
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();

  const ResourceSchema = Yup.object().shape({
    permissionName: Yup.string().required("Required"),
    permissionDisplayName: Yup.string().required("Required"),
    permissionDescription: Yup.string(),
    dependencies: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required("Required"),
        value: Yup.string().required("Required"),
      })
    ),
    resource: Yup.object().shape({
      id: Yup.string().required("Required"),
      resourceName: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ResourceSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting, setFieldValue } = formik;

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="py-8 px-4 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.permissionName
                    ? formik.values.permissionName
                    : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Get started by filling in the information below to create your
                new permission.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-2">
                    <FieldType
                      value={formik.values?.permissionDisplayName}
                      onChange={(type: { title: string; value: string }) => {
                        setFieldValue("permissionDisplayName", type.title);
                        setFieldValue(
                          "permissionName",
                          type.value +
                            "-" +
                            (Object.keys(formik.values?.resource).length === 0
                              ? ""
                              : formik.values.resource.resourceName?.toLowerCase())
                        );
                      }}
                    />
                    {formik.touched.permissionDisplayName &&
                    formik.errors.permissionDisplayName ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="permissionDisplayName-errors"
                      >
                        {formik.errors.permissionDisplayName}
                      </p>
                    ) : null}
                  </div>
                  <div className="sm:col-span-4">
                    <FieldResources
                      value={formik.values?.resource?.resourceName}
                      onChange={(value: any) => {
                        setFieldValue("resource", value);
                        setFieldValue(
                          "permissionName",
                          formik.values?.permissionDisplayName?.toLowerCase() +
                            "-" +
                            value.resourceName?.toLowerCase()
                        );
                      }}
                    />
                    {formik.touched.resource && formik.errors.resource ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="resourceName-errors"
                      >
                        {formik.errors.resource?.resourceName}
                      </p>
                    ) : null}
                  </div>

                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_permission_name")}
                      type="text"
                      name="permissionName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.permissionName}
                      touched={touched.permissionName}
                      errors={errors.permissionName}
                      readOnly
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-900">
                      {t("text_dependencies")}
                    </label>
                    <FieldDependencies
                      value={formik.values.dependencies}
                      onChange={(value: MultiValue<DependProps>) => {
                        setFieldValue("dependencies", value);
                      }}
                      className={classNames(
                        "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                        formik.touched.dependencies &&
                          formik.errors.dependencies
                          ? "border-red-600 text-red-900"
                          : ""
                      )}
                    />
                    {formik.touched.dependencies &&
                    formik.errors.dependencies ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="roles-errors"
                      >
                        {formik.errors.dependencies.toString()}
                      </p>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <FieldEditor
                      title="Description"
                      name="permissionDescription"
                      onChange={(content: any, editor: any) => {
                        setFieldValue("permissionDescription", content);
                      }}
                      value={formik.values.permissionDescription}
                      touched={formik.touched.permissionDescription}
                      errors={formik.errors.permissionDescription}
                    />
                  </div>
                </div>

                <fieldset>
                  <legend className="text-sm font-medium text-gray-900">
                    {t("text_status")}
                  </legend>
                  <Switch.Group
                    as="div"
                    className="mt-1.5 inline-flex items-center"
                  >
                    <Switch
                      checked={formik.values.status}
                      onChange={() => {
                        formik.setFieldValue("status", !formik.values.status);
                      }}
                      id="status"
                      className={classNames(
                        formik.values.status ? "bg-primary-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          formik.values.status
                            ? "translate-x-5"
                            : "translate-x-0",
                          "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <Switch.Label
                      passive
                      htmlFor="status"
                      className="ml-2 mb-0 block text-sm font-normal text-gray-700"
                    >
                      {formik.values.status
                        ? t("text_active")
                        : t("text_inactive")}
                    </Switch.Label>
                  </Switch.Group>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit">
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

const FETCH_RESOURCES = gql`
  query FetchResources($status: Boolean) {
    fetchResources(status: $status) {
      id
      resourceName
      resourceDescription
      status
    }
  }
`;

export function FieldResources({
  value = "",
  onChange,
}: {
  value?: string;
  onChange: any;
}) {
  const navigate = useNavigate();
  const [resources, setResources] = useState<Resource[]>([]);
  const [query, setQuery] = useState("");
  const [selectedResource, setSelectedResource] = useState<Resource>();

  const [fetchResources] = useLazyQuery(FETCH_RESOURCES);

  useEffect(() => {
    fetchResources({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchResources) {
          const resourcesList = data?.fetchResources as Resource[];
          setResources(resourcesList);

          resourcesList.findIndex((resource) => {
            if (resource.resourceName.toLowerCase() === value?.toLowerCase()) {
              setSelectedResource(resource);
              return true;
            }
            return false;
          });
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Resources read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResources, value]);

  const filteredResources =
    query === ""
      ? resources
      : resources.filter((resource) => {
          return resource.resourceName
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={selectedResource}
      onChange={(value: Resource) => {
        setSelectedResource(value);
        onChange(value);
      }}
    >
      <Combobox.Label className="block text-sm font-medium text-gray-900">
        Permission resource
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white px-3 py-2.5 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(resource: Resource) => resource?.resourceName}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredResources.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredResources.map((resource: Resource) => (
              <Combobox.Option
                key={resource.id}
                value={resource}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-primary-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          "inline-block h-2 w-2 flex-shrink-0 rounded-full",
                          resource.status ? "bg-green-400" : "bg-red-200"
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected ? "font-medium" : ""
                        )}
                      >
                        {resource.resourceName}
                        <span className="sr-only">
                          {" "}
                          is {resource.status ? "online" : "offline"}
                        </span>
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-primary-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

const FETCH_PERMISSIONS = gql`
  query FetchPermissions($status: Boolean) {
    fetchPermissions(status: $status) {
      id
      permissionName
      permissionDisplayName
      permissionDescription
      dependencies
      createdAt
      status
    }
  }
`;

export function FieldDependencies({
  value,
  onChange,
  className,
}: {
  value: MultiValue<DependProps>;
  onChange: (newValue: MultiValue<DependProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<MultiValue<DependProps>>(value);
  const [options, setOptions] = useState<MultiValue<DependProps>>([]);
  const [fetchPermissions] = useLazyQuery(FETCH_PERMISSIONS);

  useEffect(() => {
    fetchPermissions({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchPermissions) {
          const newOptions = data.fetchPermissions.filter(
            (p: Permission) =>
              !PROTECTED_RESOURCE_LIST.includes(p.permissionName)
          );
          const updatedOptions: DependProps[] = newOptions?.map(
            (p: Permission) => {
              return {
                label: p.permissionName,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Permissions read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPermissions]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={false}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

export function FieldType({
  value,
  onChange,
}: {
  value?: string;
  onChange: any;
}) {
  type Option = {
    title: string;
    value: string;
    color: string;
  };
  const permisionOptions: Option[] = useMemo<Option[]>(() => {
    return [
      {
        title: "Create",
        value: "create",
        color: "text-blue-600",
      },
      {
        title: "Read",
        value: "read",
        color: "text-green-600",
      },
      {
        title: "Update",
        value: "update",
        color: "text-yellow-600",
      },
      {
        title: "Destroy",
        value: "destroy",
        color: "text-red-600",
      },
    ];
  }, []);

  const [selected, setSelected] = useState<Option>();

  useEffect(() => {
    permisionOptions.findIndex((option: Option) => {
      if (option.value === value?.toLowerCase()) {
        setSelected(option);
        return true;
      }
      return false;
    });
  }, [value, permisionOptions]);

  return (
    <Listbox
      value={selected}
      onChange={(value) => {
        setSelected(value);
        onChange(value);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-900">
            Permission type
          </Listbox.Label>
          <div className="relative mt-1 inline-flex w-full">
            <div className="relative z-0 inline-flex w-full rounded-md shadow-sm">
              <div className="relative inline-flex w-full items-center rounded-l-md border border-gray-300 py-2 pl-3 pr-4">
                {selected && (
                  <CheckIcon
                    className={classNames(
                      selected?.color ? selected.color : "text-gray-700",
                      "h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                )}
                <p className="ml-2.5 text-sm">{selected?.title}</p>
              </div>
              <Listbox.Button className="relative inline-flex items-center rounded-l-none rounded-r-md border border-l-0 border-gray-300  p-2 text-sm font-medium focus:z-10">
                <span className="sr-only">Change permission type</span>
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-10 w-36 origin-top-right overflow-hidden rounded-md bg-white px-2 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {permisionOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    value={option}
                    className={({ selected }) =>
                      classNames(selected ? "bg-gray-50" : "", "rounded-md")
                    }
                  >
                    {({ selected, active }) => (
                      <span
                        className={classNames(
                          active ? "bg-primary-700/10 text-primary-700" : "",
                          "group flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-700 transition ease-in-out"
                        )}
                      >
                        {option.title}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
