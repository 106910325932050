import { lazy, Suspense, useEffect, useState } from "react";
import { Link, RouteObject, useRoutes } from "react-router-dom";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";

import { useAuth, isAuthorizedForResource } from "../../auth";

import {
  LayoutSplashScreen,
  NotFound,
  NotAuthorized,
  Head,
} from "../../../components/core";

import { classNames } from "../../../utils";

import { PricingMethodListResource } from "./pricing-methods/List";

const PricingMethodList = lazy(() => import("./pricing-methods/List"));

const FETCH_PRICINGMETHODS = gql`
  query FetchPricingMethods {
    fetchPricingMethods {
      id
      name
      createdAt
      status
    }
  }
`;

export default function Settings({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      children: [
        {
          index: true,
          element: (
            <>
              <Head
                title="Inventory"
                heading="Inventory Settings"
                breadcrumbs={[
                  ...breadcrumbs,
                  {
                    name: "Inventory",
                    href: "/inventory",
                  },
                  {
                    name: "Inventory Settings",
                    href: null,
                  },
                ]}
              />
              <Layout />
            </>
          ),
        },
        {
          path: PricingMethodListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingMethodListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingMethodList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingMethodListResource.access.join(", "))}
            />
          ),
        },
        {
          path: SettingsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SettingsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Settings breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SettingsResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}

function Layout() {
  const [navigation, setNavigation] = useState([
    {
      id: "pricing-methods",
      name: "Pricing Methods",
      path: "/inventory/settings/pricing-methods",
      icon: "bi bi-hdd-stack",
      count: 0,
    },
  ]);

  const [fetchPricingMethods] = useLazyQuery(FETCH_PRICINGMETHODS);

  useEffect(() => {
    fetchPricingMethods({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "pricing-methods") {
              return {
                ...item,
                count: data.fetchPricingMethods.length,
              };
            }

            return item;
          })
        );
      },
    });
  }, [fetchPricingMethods]);

  return (
    <>
      <div>
        <h2 className="text-sm font-medium text-gray-500">Settings</h2>
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {navigation.map((nav) => (
            <Link
              key={nav.name}
              to={nav.path}
              className="overflow-hidden rounded-lg border border-gray-300 bg-white hover:border-primary-600"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex h-10 w-10 items-center justify-center rounded-md bg-primary-300">
                      <span
                        className={classNames(
                          "h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                          nav.icon
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="ml-4 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {nav.name}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {nav.count}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <span className="font-medium text-primary-700 hover:text-primary-900">
                    View all
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}

export const SettingsResource: ResourceProps = {
  name: "Inventory Settings",
  description: "Inventory Settings",
  access: ["read-inventorysettings"],
  path: "settings/*",
};
