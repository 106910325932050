import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import {
  ExclamationTriangleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { TablePaginate } from "../../../components/table";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type User = {
  id: string;
  fullName: string;
  email: string;
  roles: {
    roleName: string;
  }[];
  createdAt: string;
  status: boolean;
};

type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: Customer[];
  createdAt: string;
  status: boolean;
};

type DeliveryRun = {
  id?: string;
  name: string;
  dock: {
    id: string;
    name: string;
  };
  createdAt: string;
  status: boolean;
};

type TradingAddresses = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
};

type Customer = {
  id: string;
  abnNo: string;
  email: string;
  createdAt: string;
  creditApplicationStatus: boolean;
  creditLimit: number;
  customerCode: string;
  customerGroups: CustomerGroup[];
  customerName: string;
  customerPlu: string;
  customerType: number;
  deliveryInstruction: string;
  deliveryRuns: DeliveryRun;
  packingInstruction: string;
  salesRep: User;
  storeContact: string;
  storeEmail: string;
  storeFax: string;
  storePhoneNumber: string;
  tradingAddresses: TradingAddresses[];
  status: number;
};

const FETCH_CUSTOMERS = gql`
  query FetchCustomers {
    fetchCustomers {
      id
      abnNo
      email
      createdAt
      creditApplicationStatus
      creditLimit
      customerCode
      customerGroups {
        id
        name
      }
      customerName
      customerPlu
      customerType
      deliveryInstruction
      deliveryRuns {
        id
        name
      }
      packingInstruction
      salesRep {
        id
        fullName
      }
      storeContact
      storeEmail
      storeFax
      storePhoneNumber
      tradingAddresses {
        address
        suburb
        state
        postcode
      }
      status
    }
  }
`;

const CustomerList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchCustomers, { loading }] = useLazyQuery(FETCH_CUSTOMERS);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [pendingCustomers, setPendingCustomers] = useState<Customer[]>([]);

  const didFetchCustomers = useRef(false);
  const fetchingCustomers = useCallback(() => {
    fetchCustomers()
      .then(({ data, error }) => {
        if (data?.fetchCustomers) {
          const updatedCustomers = data.fetchCustomers.map(
            (item: Customer) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setCustomers(updatedCustomers);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Customer read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomers]);

  useEffect(() => {
    if (!didFetchCustomers.current) {
      fetchingCustomers();
      didFetchCustomers.current = true;
    }
  }, [fetchingCustomers]);

  useEffect(() => {
    if (customers.length > 0) {
      const pendingCustomers = customers.filter(
        (customer) => customer.status === 1
      );
      setPendingCustomers(pendingCustomers);
    }
  }, [customers]);

  const DELETE_CUSTOMER = gql`
    mutation CustomerDelete($id: ID!) {
      customerDelete(input: { id: $id }) {
        customer {
          id
          customerName
        }
      }
    }
  `;
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);
  const handleDelete = async (id: string) => {
    return await deleteCustomer({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.customerDelete) {
          const deletedCustomer = data?.customerDelete?.customer;
          const newCustomers = [...customers];
          const index = newCustomers.findIndex(
            (customer) => customer.id === id
          );
          newCustomers.splice(index, 1);
          setCustomers(newCustomers);
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Customer ${deletedCustomer.customerName} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<Customer>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "customerName",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() === 3 ? (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Rejected
            </span>
          ) : info.getValue() === 2 ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Approve/Active
            </span>
          ) : info.getValue() === 1 ? (
            <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
              Pending
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-customers"]}>
              <Link
                to={`/sales/customers/${props.row.original.id}`}
                className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.customerName}
                </span>
              </Link>
            </AuthShield>
            <AuthShield access={["destroy-customers"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.customerName,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.customerName}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Customers"
        heading="Customers"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: CustomerListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Customers List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the Customers in your account including their name,
              title, description and customer.
            </p>
          </div>
        </div>
        {pendingCustomers.length ? (
          <div className="my-5 rounded-md border border-yellow-300 bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  {pendingCustomers.length}{" "}
                  {pendingCustomers.length === 1
                    ? "customer is"
                    : "customers are"}{" "}
                  waiting for approval.{" "}
                  <span className="font-medium text-yellow-700 underline hover:text-yellow-600">
                    Please review{" "}
                    {pendingCustomers.length === 1 ? "him" : "them"} for
                    approval.
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <TablePaginate
          data={customers}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <div className="flex justify-end space-x-4">
              <AuthShield access={["create-customers"]}>
                <Link to="/sales/customers/add">
                  <Button>Add customer</Button>
                </Link>
              </AuthShield>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default CustomerList;
export const CustomerListResource: ResourceProps = {
  name: "Customers List",
  description: "A list of Customers",
  access: ["read-customers"],
  path: "customers",
};
