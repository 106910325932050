import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import App from "../App";
import { MasterLayout } from "../layouts/MasterLayout";

import { AuthPage, Logout, useAuth } from "../modules/auth";
import { ErrorsPage } from "../modules/errors";

import DashboardPage from "../modules/Dashboard";
import { UserManagementPage } from "../modules/user-management";
import { InventoryPage } from "../modules/inventory";
import { PurchasePage } from "../modules/purchases";
import { FleetsPage } from "../modules/fleets";
import { SalePage } from "../modules/sales";
import { StorePage } from "../modules/store";
import { SettingsPage } from "../modules/settings";

import { Loader } from "../animations";
import { NotMatch, NetworkStatus } from "../components/core";

// import FormulaBuilder from "../pages/sample/FormulaBuilder";

const { REACT_APP_URL } = process.env;

export default function AppRoutes() {
  const { isLocked, refresh } = useAuth();

  let elements =
    !isLocked && refresh
      ? [
          {
            element: <MasterLayout />,
            children: [
              {
                path: "auth/*",
                children: [
                  {
                    path: "login",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "register",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "forgot-password",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "reset-password",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "create-password",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "challenge/*",
                    element: <Navigate to="/" />,
                  },
                ],
              },
              {
                path: "dashboard",
                element: <DashboardPage />,
              },
              {
                path: "user-management/*",
                element: <UserManagementPage />,
              },
              {
                path: "inventory/*",
                element: <InventoryPage />,
              },
              {
                path: "purchases/*",
                element: <PurchasePage />,
              },
              {
                path: "fleets/*",
                element: <FleetsPage />,
              },
              {
                path: "sales/*",
                element: <SalePage />,
              },
              {
                path: "store/*",
                element: <StorePage />,
              },
              {
                path: "settings/*",
                element: <SettingsPage />,
              },
              {
                index: true,
                element: <Navigate to="/dashboard" replace={true} />,
              },
            ],
          },
          {
            index: true,
            element: <Navigate to="/dashboard" replace={true} />,
          },
        ]
      : [
          {
            path: "auth/*",
            element: <AuthPage />,
          },
          {
            path: "*",
            element: <Navigate to="/auth/login" replace={true} />,
          },
          {
            index: true,
            element: <Navigate to="/auth/login" replace={true} />,
          },
        ];

  let router = createBrowserRouter(
    [
      {
        path: "/",
        element: <App />,
        errorElement: <NotMatch />,
        children: [
          {
            path: "login",
            element: <Navigate to="/auth/login" />,
          },
          {
            path: "register",
            element: <Navigate to="/auth/register" />,
          },
          {
            path: "logout",
            element: <Logout />,
          },
          {
            path: "error/*",
            element: <ErrorsPage />,
          },
          ...elements,
          // {
          //   path: "sample/*",
          //   children: [
          //     {
          //       path: "formula-builder",
          //       element: (
          //         <FormulaBuilder
          //           variants={["$cost", "$price", "$quantity"]}
          //           values={["$item1", "$item2", "$item3"]}
          //           onChange={(values) => {
          //             console.log(values);
          //           }}
          //         />
          //       ),
          //     },
          //   ],
          // },
        ],
      },
    ],
    {
      basename: REACT_APP_URL,
    }
  );

  return (
    <>
      <NetworkStatus />
      <RouterProvider router={router} fallbackElement={<Loader loading />} />
    </>
  );
}
