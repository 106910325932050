import { useState } from "react";
import { Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { AlertType, useAlert } from "../../../hooks/useAlert";

const Notification = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [warning, setWarning] = useState(false);

  const Message = useAlert({
    open: warning,
    title: "Under Development",
    message: "This area is under development and will be available soon.",
    type: AlertType.SUCCESS,
    delay: 3000,
    onDismiss: () => {
      setWarning(false);
    },
  });

  return (
    <>
      <Message />
      <Head
        title="Notifications"
        heading="Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Notifications",
            href: "/settings/notifications",
          },
        ]}
      />
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div className="shadow-sm sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Notification
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Provide basic informtion about the job. Be specific with the job
                title.
              </p>
            </div>

            <fieldset>
              <legend className="text-base font-medium text-gray-900">
                By Email
              </legend>
              <div className="mt-4 space-y-4">
                <div className="flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="comments"
                      name="comments"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary-700 focus:ring-primary-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Comments
                    </label>
                    <p className="text-gray-500">
                      Get notified when someones posts a comment on a posting.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="candidates"
                        name="candidates"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-primary-700 focus:ring-primary-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="candidates"
                        className="font-medium text-gray-700"
                      >
                        Candidates
                      </label>
                      <p className="text-gray-500">
                        Get notified when a candidate applies for a job.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="offers"
                        name="offers"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-primary-700 focus:ring-primary-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="offers"
                        className="font-medium text-gray-700"
                      >
                        Offers
                      </label>
                      <p className="text-gray-500">
                        Get notified when a candidate accepts or rejects an
                        offer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            {/* <fieldset className="mt-6">
            <legend className="text-base font-medium text-gray-900">
              Push Notifications
            </legend>
            <p className="text-sm text-gray-500">
              These are delivered via SMS to your mobile phone.
            </p>
            <div className="mt-4 space-y-4">
              <div className="flex items-center">
                <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-primary-700 focus:ring-primary-500"
                />
                <label htmlFor="push-everything" className="ml-3">
                  <span className="block text-sm font-medium text-gray-900">
                    Everything
                  </span>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-primary-700 focus:ring-primary-500"
                />
                <label htmlFor="push-email" className="ml-3">
                  <span className="block text-sm font-medium text-gray-900">
                    Same as email
                  </span>
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-nothing"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-primary-700 focus:ring-primary-500"
                />
                <label htmlFor="push-nothing" className="ml-3">
                  <span className="block text-sm font-medium text-gray-900">
                    No push notifications
                  </span>
                </label>
              </div>
            </div>
          </fieldset> */}
          </div>
          <div className="border-t border-gray-200 bg-white px-4 py-4 text-right md:py-6 md:px-6">
            <Button
              variant="primary"
              className="justify-center md:w-36"
              onClick={() => setWarning(true)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
export const NotificationResource: ResourceProps = {
  name: "Notification",
  description: "Notification page",
  access: [],
  path: "notification",
  icon: "bi bi-bell",
};
