import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

import { TablePaginate } from "../../../components/table";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";
import { toNestedTable } from "../../../utils";

type Location = {
  id: string;
  name: string;
  description: string;
  branch: string;
  address: string;
  parent: Location | null;
  createdAt: string;
  status: boolean;
};

const FETCH_LOCATIONS = gql`
  query FetchBinLocations {
    fetchBinLocations {
      id
      name
      branch
      address
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;

const LocationList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchBinLocations, { loading }] = useLazyQuery(FETCH_LOCATIONS);
  const [locations, setLocations] = useState<Location[]>([]);

  const reloadQuery = useCallback(() => {
    fetchBinLocations()
      .then(({ data, error }) => {
        if (data?.fetchBinLocations) {
          const fetchedBinLocations = data.fetchBinLocations as Location[];
          setLocations(toNestedTable(fetchedBinLocations));
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Location read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBinLocations]);

  useEffect(() => {
    reloadQuery();
  }, [reloadQuery]);

  const DELETE_LOCATION = gql`
    mutation BinLocationDelete($id: ID!) {
      binLocationDelete(input: { id: $id }) {
        location {
          id
          name
        }
      }
    }
  `;
  const [deleteLocation] = useMutation(DELETE_LOCATION);
  const handleDelete = async (id: string) => {
    return await deleteLocation({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.binLocationDelete) {
          const deletedLocation = data.binLocationDelete?.location;
          // const newLocations = [...locations];
          // const index = newLocations.findIndex(
          //   (location) => location.id === id
          // );
          // newLocations.splice(index, 1);
          // setLocations(newLocations);
          reloadQuery();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Location ${deletedLocation.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Location delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<
    ColumnDef<Location & { expand: null; depth: number }>[]
  >(
    () => [
      {
        accessorKey: "expand",
        header: ({ table }) => {
          return (
            <button
              {...{
                onClick: table.getToggleAllRowsExpandedHandler(),
              }}
            >
              {table.getIsAllRowsExpanded() ? (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-300 bg-gray-50 transition-all">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-gray-700"
                  />
                </span>
              ) : (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-200 transition-all">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-gray-700"
                  />
                </span>
              )}
            </button>
          );
        },
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() ? (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-100 bg-gray-50 transition-all">
                  {row.original.depth === 0 ? (
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-4 w-4 text-gray-700"
                    />
                  ) : (
                    <span
                      aria-hidden="true"
                      className="bi bi-arrow-bar-down h-4 w-4 text-gray-700"
                    />
                  )}
                </span>
              ) : (
                <span className="flex h-8 w-8 items-center justify-center rounded-md border border-gray-50 transition-all">
                  {row.original.depth === 0 ? (
                    <ChevronRightIcon
                      aria-hidden="true"
                      className="h-4 w-4 text-gray-700"
                    />
                  ) : (
                    <span
                      aria-hidden="true"
                      className="bi bi-arrow-return-right h-4 w-4 text-gray-700"
                    />
                  )}
                </span>
              )}
            </button>
          ) : row.original.depth !== 0 ? (
            ""
          ) : null;
        },
        enableSorting: false,
        meta: {
          className: "text-center md:w-8",
        },
      },
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => {
          return (
            <span className={`ml-${row.original.depth * 1}`}>
              {`${Array.from(
                Array(
                  typeof row.original.depth === "number"
                    ? row.original.depth
                    : 0
                ).keys()
              )
                .map((_) => "-")
                .join("")} ${row.original.name}`}
            </span>
          );
        },
      },
      {
        accessorKey: "branch",
        header: "Branch",
      },
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (info) =>
          DateTime.fromISO(info.getValue(), {
            locale: i18n.language,
          }).toLocaleString(DateTime.DATE_MED),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-binlocations"]}>
              <Link
                to={`/inventory/locations/${props.row.original.id}`}
                className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Link>
            </AuthShield>
            <AuthShield access={["destroy-binlocations"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Locations"
        heading="Locations"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Locations",
            href: "/inventory/locations",
          },
          {
            name: LocationListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Locations List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the locations in your account including their name,
              title, email and location.
            </p>
          </div>
        </div>
        <TablePaginate
          data={locations}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <div className="flex space-x-4">
              <AuthShield access={["read-binlocationtypes"]}>
                <Link to="/inventory/location-types">
                  <Button variant="secondary">Location types</Button>
                </Link>
              </AuthShield>
              <AuthShield access={["create-binlocations"]}>
                <Link to="/inventory/locations/add">
                  <Button>Add location</Button>
                </Link>
              </AuthShield>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default LocationList;
export const LocationListResource: ResourceProps = {
  name: "Locations List",
  description: "A list of locations",
  access: ["read-binlocations"],
  path: "locations",
};
