import React, { Dispatch, SetStateAction } from "react";

import {
  FilterFn,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  Row,
  SortingState,
  useReactTable,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { useVirtual } from "react-virtual";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

import { IndeterminateCheckbox } from ".";
import { classNames } from "../../utils";
import { Button } from "../form";

declare module "@tanstack/table-core" {
  interface FilterMeta {
    itemRank: RankingInfo;
  }
  interface ColumnMeta {
    className?: string;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type TableProps<T> = {
  initialState?: any;
  data: T[];
  columns: ColumnDef<T>[];
  className?: string;
  loading?: boolean;
  enableRowSelection?: boolean;
  rowSelection?: any;
  setRowSelection?: Dispatch<SetStateAction<T>>;
  enableSearch?: boolean;
  searchLabel?: string;
  searchPlaceholder?: string;
  searchValue?: string;
  enableHeader?: boolean;
  enableFooter?: boolean;
  selectionCancelLabel?: string;
  selectionCancelAction?: () => void;
  selectionConfirmLabel?: string;
  selectionConfirmAction?: (data: T[]) => void;
};

export function TableInfinite({
  initialState = {},
  data,
  columns,
  className,
  loading,
  enableRowSelection = false,
  rowSelection,
  setRowSelection = () => {},
  enableSearch = true,
  searchLabel = "Search",
  searchPlaceholder = "Search by keyword",
  searchValue,
  enableHeader = true,
  enableFooter = true,
  selectionCancelLabel = "Cancel",
  selectionCancelAction = () => {},
  selectionConfirmLabel = "Confirm",
  selectionConfirmAction = () => {},
}: TableProps<any>) {
  const tableData = React.useMemo(
    () => (loading ? Array(20).fill({}) : Array.from(data)),
    [loading, data]
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />,
          }))
        : columns,
    [loading, columns]
  );

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  React.useEffect(() => {
    if (searchValue) {
      setGlobalFilter(searchValue);
    }
  }, [searchValue]);

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    initialState: {
      ...initialState,
      columnVisibility: {
        id: false,
        ...initialState.columnVisibility,
      },
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
      rowSelection: enableRowSelection ? rowSelection : {},
    },
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  const [isPending, startTransition] = React.useTransition();
  const handleFilter = React.useCallback(
    (e: { target: { value: React.SetStateAction<string> } }) => {
      startTransition(() => {
        setGlobalFilter(e.target.value);
      });
    },
    []
  );

  const tableContainerRef = React.useRef<any>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div className={className}>
      <SkeletonTheme baseColor="#f7f7f7" borderRadius="0.25rem" duration={0.1}>
        <>
          {enableSearch && (
            <div>
              <label htmlFor="search" className="sr-only">
                {searchLabel}
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  className={classNames(
                    "block w-full rounded-md border-gray-200 pl-10 focus:border-primary-500 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                  )}
                  placeholder={searchPlaceholder}
                  value={globalFilter ?? ""}
                  onChange={handleFilter}
                />
              </div>
            </div>
          )}
          <SimpleBar className="mt-4 h-80 w-full md:mt-6">
            <table
              ref={tableContainerRef}
              className="block min-w-full divide-gray-300 md:table md:table-fixed md:divide-y"
            >
              {enableHeader && (
                <thead className="hidden bg-white md:table-header-group">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="block md:table-row">
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            {...{
                              key: header.id,
                              scope: "col",
                              colSpan: header.colSpan,
                              className: classNames(
                                "px-2 py-4 first-of-type:pl-0 last-of-type:pr-0 text-left text-sm font-medium relative text-gray-900 block md:table-cell",
                                header.column.columnDef.meta?.className
                                  ? header.column.columnDef.meta.className
                                  : ""
                              ),
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className:
                                    (header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "") + " group inline-flex",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: (
                                    <div className="leading ml-2 h-5 w-5 flex-none rounded bg-gray-200 text-center leading-5 text-gray-900 group-hover:bg-gray-300">
                                      <span
                                        className="bi bi-chevron-up"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  ),
                                  desc: (
                                    <div className="leading ml-2 h-5 w-5 flex-none rounded bg-gray-200 px-0.5 text-center text-gray-900 group-hover:bg-gray-300">
                                      <span
                                        className="bi bi-chevron-down"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  ),
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody className="block divide-greyish md:table-row-group md:divide-y">
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}
                {virtualRows.map((virtualRow) => {
                  const row = rows[virtualRow.index] as Row<any>;
                  return (
                    <tr
                      key={row.id}
                      className="my-5 block overflow-hidden rounded-lg border border-gray-200 bg-white md:my-0 md:table-row md:rounded-none md:border-0 md:bg-transparent"
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            {...{
                              key: cell.id,
                              className: classNames(
                                "md:px-2 md:py-2 first-of-type:pl-0 last-of-type:pr-0 text-xs md:text-sm text-gray-500 relative flex md:table-cell",
                                cell.column.columnDef.meta?.className
                                  ? cell.column.columnDef.meta.className
                                  : ""
                              ),
                            }}
                          >
                            {loading ? (
                              <Skeleton />
                            ) : (
                              flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
              </tbody>
            </table>
          </SimpleBar>
          {enableRowSelection && (
            <>
              {enableFooter && (
                <div className="flex w-full items-center pt-4 pb-2 text-xs text-gray-500 sm:text-sm">
                  <IndeterminateCheckbox
                    {...{
                      checked: table.getIsAllPageRowsSelected(),
                      indeterminate: table.getIsSomePageRowsSelected(),
                      onChange: table.getToggleAllPageRowsSelectedHandler(),
                    }}
                    className="mr-2"
                  />
                  <span>Page Rows ({table.getRowModel().rows.length})</span>
                </div>
              )}
              <div className="flex items-center justify-end space-x-4 rounded-md bg-gray-50 px-2 py-3 sm:px-4">
                <p className="mr-auto mb-0 text-sm text-gray-700">
                  {table.getSelectedRowModel().rows.length} item(s) selected.
                </p>
                <Button variant="secondary" onClick={selectionCancelAction}>
                  {selectionCancelLabel}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    selectionConfirmAction(
                      table.getSelectedRowModel().flatRows.map((row) => {
                        return {
                          index: row.index,
                          ...row.original,
                        };
                      })
                    );
                  }}
                  disabled={table.getSelectedRowModel().rows.length === 0}
                >
                  {selectionConfirmLabel}
                </Button>
              </div>
            </>
          )}
        </>
      </SkeletonTheme>
    </div>
  );
}
