import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { KeyIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import {
  Field,
  FieldPassword,
  passwordValidator,
  Response,
} from "../../../components/form";

import { AlertType, useAlert } from "../../../hooks/useAlert";

import { Spinner } from "../../../animations";
import { Head } from "../../../components/core";

import logo from "../../../assets/logo.svg";
const { REACT_APP_NAME } = process.env;

declare module "yup" {
  interface StringSchema {
    strongPassword(threshold?: number, strength?: number): this;
  }
}

Yup.addMethod(Yup.string, "strongPassword", strongPasswordMethod);

function strongPasswordMethod(this: any, threshold: number, strength: number) {
  return this.test(
    "strongPasswordTest",
    null,
    (value: string, context: any) => {

      const { path, createError } = context;
      try {
        passwordValidator(value, threshold, strength);
        return true;
      } catch (e: any) {
        return createError({
          path,
          message: e.message,
        });
      }
    }
  );
}

const ResetSchema = Yup.object().shape({
  "new-password": Yup.string().required("Required").strongPassword(7),
  "confirm-password": Yup.string()
    .required("Password confirmation is required")
    .when("new-password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new-password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const RESET_PASSWORD = gql`
  mutation ActivateAccount(
    $email: String!
    $token: String!
    $password: String!
  ) {
    activateAccount(
      input: { params: { email: $email, token: $token, password: $password } }
    ) {
      user {
        id
        fullName
      }
    }
  }
`;

const VERIFY_USER = gql`
  query VerifyUser($email: String!) {
    verifyUser(email: $email) {
      fullName
    }
  }
`;

export function Create() {
  const [response, setResponse] = useState<FormResponse | null>(null);
  const [isValidRequest, setValidRequest] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    status: false,
    title: "",
    message: "",
  });

  const ResponseAlert = useAlert({
    open: alert.status,
    title: alert.title,
    message: alert.message,
    type: AlertType.SUCCESS,
    confirmText: "Login",
    onDismiss: () => {
      setAlert({
        ...alert,
        status: false,
      });
      navigate("/auth/login");
    },
  });

  let [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const [verifyUser] = useLazyQuery(VERIFY_USER);

  useEffect(() => {
    verifyUser({
      variables: {
        email: email,
      },
    })
      .then(({ data, error }) => {
        if (!data?.verifyUser) {
          setResponse({
            type: "error",
            message: error
              ? error.message
              : "Something went wrong, please try again later",
          });
          setValidRequest(false);
        }
      })
      .catch((error) => {
        setResponse({
          type: "error",
          message: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, verifyUser]);

  const [activateAccount, { error }] = useMutation(RESET_PASSWORD);

  const formik = useFormik({
    initialValues: {
      "new-password": "",
      "confirm-password": "",
    },
    validationSchema: ResetSchema,
    onSubmit: (values, actions) => {
      setResponse(null);

      activateAccount({
        variables: {
          email: email,
          token: token,
          password: values["new-password"],
        },
      })
        .then(({ data }) => {
          if (data?.activateAccount?.user?.fullName) {
            setSearchParams({ token: "", email: "" });
            setAlert({
              status: true,
              title: "Account activated successfully",
              message: "You can now login to your account",
            });
          } else {
            setResponse({
              type: "error",
              message: "Something went wrong, please try again later",
            });
          }
          actions.setSubmitting(false);
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <>
      <Head title="Create Password" heading="Create Password" />
      <header>
        <img className="mx-auto h-9 sm:h-10 md:h-11 xl:h-12 w-auto" src={logo} alt={REACT_APP_NAME} />
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          Create your password
        </h2>
      </header>
      <div className="mt-8 mb-5">
        {isValidRequest && (
          <>
            <form className="space-y-6" onSubmit={formik.handleSubmit}>
              <div>
                <FieldPassword
                  title={t("text_password")}
                  autoComplete="new-password"
                  name="new-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["new-password"]}
                  touched={touched["new-password"]}
                  errors={errors["new-password"]}
                  label={false}
                />
              </div>

              <div>
                <Field
                  title={t("text_confirm_password")}
                  autoComplete="confirm-password"
                  type="password"
                  name="confirm-password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["confirm-password"]}
                  touched={touched["confirm-password"]}
                  errors={errors["confirm-password"]}
                  label={false}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 py-2.5 px-4 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <KeyIcon
                      className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
                      aria-hidden="true"
                    />
                  </span>

                  {isSubmitting ? (
                    <>
                      <Spinner />
                      {t("text_processing")}
                    </>
                  ) : (
                    t("text_create_password")
                  )}
                </button>
              </div>
            </form>
            <ResponseAlert />
          </>
        )}
      </div>

      <Response
        response={response}
        onDismiss={() => {
          setResponse(null);
        }}
      />

      {!isValidRequest && (
        <Link
          to="/auth/login"
          className="group relative mt-5 flex w-full justify-center rounded-md border border-transparent bg-primary-700 py-2.5 px-4 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
        >
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <KeyIcon
              className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
              aria-hidden="true"
            />
          </span>
          Back to login
        </Link>
      )}
    </>
  );
}
