import { useEffect } from "react";
import ms from "ms";
import { useTranslation } from "react-i18next";
import { useAuth } from "..";

import { Head } from "../../../components/core";

import logo from "../../../assets/logo.svg";
const { REACT_APP_NAME } = process.env;

export function Locked() {
  const { lockedExpiry } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (lockedExpiry && new Date(lockedExpiry).getTime() < Date.now()) {
        console.log("lockedExpiry", lockedExpiry);
      }
      console.log("Logs every minute");
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head title="Locked" heading="Locked" />
      <header>
        <img className="mx-auto h-9 sm:h-10 md:h-11 xl:h-12 w-auto" src={logo} alt={REACT_APP_NAME} />
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {t("heading_locked")}
        </h2>
      </header>

      <div className="mt-6 space-y-4">
        <div className="text-center">
          {lockedExpiry
            ? `You account is locked until ${ms(
                new Date(lockedExpiry).getTime() - Date.now(),
                { long: true }
              )}`
            : "Your account is locked. Please reset your password to unlock your account."}
        </div>
      </div>
    </>
  );
}
