import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Select, { MultiValue, ActionMeta } from "react-select";
import {
  classNames,
  orderOptions,
  toCleanString,
  toNestedOptions,
} from "../../../../utils";
import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldUploader,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";

type HomeOptions = {
  id?: string;
  title: string;
  caption: string;
  button: {
    title: string;
    link: string;
  }[];
  bannerImageUrl: string;
  categoryIds: number[];
};

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: HomeOptions;
  onSubmit: (
    values: {
      title: string;
      caption: string;
      button: { title: string; link: string }[];
      bannerImageUrl: string;
      categoryIds: number[];
    },
    actions: any
  ) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const HomeSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    caption: Yup.string().required("Required"),
    button: Yup.array().of(
      Yup.object().shape({
        title: Yup.string(),
        link: Yup.string(),
      })
    ),
    bannerImageUrl: Yup.string(),
    categoryIds: Yup.array(Yup.number()),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: HomeSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <Field
            title={t("text_title")}
            name="title"
            onChange={(e) => {
              const { value } = e.target;
              formik.setFieldValue("title", value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            touched={formik.touched.title}
            errors={formik.errors.title}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <Field
            title={t("text_caption")}
            name="caption"
            type="textarea"
            onChange={(e) => {
              const { value } = e.target;
              formik.setFieldValue("caption", value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.caption}
            touched={formik.touched.caption}
            errors={formik.errors.caption}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <div className="mb-1 text-sm font-medium text-gray-900">
            {t("text_button")}
          </div>
          <FieldButton
            value={formik.values.button}
            onChange={(value) => {
              formik.setFieldValue("button", value);
            }}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <FieldUploader
            title={t("text_image")}
            name="bannerImageUrl"
            directory="store/home"
            onChange={(value: any) => {
              formik.setFieldValue("bannerImageUrl", value);
            }}
            value={formik.values.bannerImageUrl}
            touched={formik.touched.bannerImageUrl}
            errors={formik.errors.bannerImageUrl}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_category")}
          </label>
          <FieldCategory
            value={formik.values.categoryIds}
            onChange={(value: MultiValue<OptionProps>) => {
              const categoryIds = value.flatMap((v) => parseInt(v.value));
              formik.setFieldValue("categoryIds", categoryIds);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.categoryIds && formik.errors.categoryIds
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.categoryIds && formik.errors.categoryIds ? (
            <p className="mt-2 text-sm text-red-600" id="categoryIds-errors">
              {formik.errors.categoryIds.toString()}
            </p>
          ) : null}
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/store" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit">
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}

const FETCH_CATEGORIES = gql`
  query FetchCategories($status: Boolean) {
    fetchCategories(status: $status) {
      id
      name
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export function FieldCategory({
  value,
  onChange,
  className,
}: {
  value: number[];
  onChange: (newValue: MultiValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<MultiValue<OptionProps>>([]);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchCategories] = useLazyQuery(FETCH_CATEGORIES);

  const didFetchCategories = useRef(false);
  const fetchingCategories = useCallback(() => {
    fetchCategories({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCategories) {
          const fetchedCategories: OptionsData[] = data.fetchCategories;
          const optionsWithDepth: Array<OptionsData & { depth?: number }> =
            toNestedOptions(fetchedCategories);
          const updatedOptions: OptionProps[] = optionsWithDepth?.map((p) => {
            return {
              label: `${Array.from(
                Array(typeof p.depth === "number" ? p.depth : 0).keys()
              )
                .map((_) => "-")
                .join("")} ${p.name}`,
              value: p.id,
            };
          });

          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Categories read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories]);

  useEffect(() => {
    if (!didFetchCategories.current) {
      fetchingCategories();
      didFetchCategories.current = true;
    }
  }, [fetchingCategories]);

  useEffect(() => {
    const activeOptionsIds = value;
    const activeOptions = options.filter((v) =>
      activeOptionsIds.includes(Number(v.value))
    );
    const updatedValues = activeOptions.map((v) => {
      return {
        label: toCleanString(v.label),
        value: v.value,
      };
    });
    setValues(updatedValues);
  }, [options, value]);

  const handleChange = (
    newValue: MultiValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(orderOptions(newValue));
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={handleChange}
        isClearable
      />
    </SelectWrapper>
  );
}

type IButtonAttr = {
  title: string;
  name: string;
  value: string;
};
type IButtonValue = { title: string; link: string };

export function FieldButton({
  value,
  onChange,
}: {
  value: IButtonValue[];
  onChange: (newValue: IButtonValue[]) => void;
}) {
  const [values, setValues] = useState<IButtonValue[]>([]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <div>
      {values.map((v, i) => (
        <ButtonProperty
          key={i}
          value={v}
          onChange={(newValue) => {
            const updatedValues = values.map((p, j) => {
              if (i === j) {
                return newValue;
              }
              return p;
            });
            setValues(updatedValues);
            onChange(updatedValues);
          }}
        />
      ))}
    </div>
  );
}

function ButtonProperty({
  value,
  onChange,
}: {
  value: IButtonValue;
  onChange: (newValue: IButtonValue) => void;
}) {
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState<IButtonAttr[]>([
    {
      title: t("text_title"),
      name: "title",
      value: value.title,
    },
    {
      title: t("text_link"),
      name: "link",
      value: value.link,
    },
  ]);

  useEffect(() => {
    const updatedValue = values.map((v) => {
      return {
        ...v,
        value: v.name === "title" ? value.title : value.link,
      };
    });
    setValues(updatedValue);
  }, [i18n.language, value]);

  const handleUpdate = (index: number, value: string) => {
    const newValues = values.map((p, j) => {
      if (index === j) {
        return {
          ...p,
          value,
        };
      }
      return p;
    });
    setValues(newValues);
    onChange({
      title: newValues[0].value,
      link: newValues[1].value,
    });
  };

  return (
    <div className="grid grid-cols-2 gap-6">
      {values.map((v, i) => {
        return (
          <div key={i}>
            <Field
              title={v.title}
              name={v.name}
              onChange={(e) => {
                const { value } = e.target;
                handleUpdate(i, value);
              }}
              value={v.value}
              label={false}
            />
          </div>
        );
      })}
    </div>
  );
}
