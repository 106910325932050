import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";
import { Button } from "../../../components/form";

type BinLocation = {
  id: string;
  name: string;
  description: string;
  branch: string;
  address: string;
  createdAt: string;
  status: boolean;
};
type Category = {
  id: string;
  name: string;
  description: string;
  parent: Category;
  createdAt: string;
  status: boolean;
};
type Department = {
  id: string;
  name: string;
  description: string;
  parent: Department;
  createdAt: string;
  status: boolean;
};
type Vendor = {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  vendorCode: string;
  webAddress: string;
  address: string;
  comments: string;
  createdAt: string;
  status: boolean;
};

type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
};

type Variant = {
  id: string;
  variantTitle: any;
  stockCode?: string;
  pricingLevelDetails?: Details[];
  basePrice?: number;
  comparePrice?: number;
  optionValues: {
    option: string;
    optionValue: string[];
  }[];
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: {
    id: string;
    name: string;
  }[];
  status: boolean;
};

type Product = {
  id: string;
  name: string;
  description: string;
  basePrice: number;
  binLocation: BinLocation;
  categories: Category[];
  autoCategories?: Category[];
  comparePrice: number;
  department: Department;
  featureImageUrl?: string;
  options: string[];
  inventory: number;
  pricingLevelName: PricingLevel[];
  hasVariants: boolean;
  variants: Variant[];
  returnableItem: boolean;
  stockCode: string;
  vendor: Vendor | null;
  galleryImages: {
    id: number;
    imageUrl: string;
  }[];
  relatedProducts: Product[];
  createdAt: string;
  status: any;
};

type VariantItem = {
  id: number;
  stockCode: string;
  basePrice: number;
  comparePrice: number;
  cost: number;
  marketCost: number;
  minimumQuantity: number;
  inventory: number;
  variantImageUrl: string;
  optionValues: {
    option: string;
    optionValue: string;
  }[];
};

const FETCH_PRODUCT = gql`
  query FetchProduct($id: ID!) {
    fetchProduct(id: $id) {
      id
      name
      description
      binLocation {
        id
        name
      }
      categories {
        id
        name
      }
      autoCategories {
        id
        name
      }
      department {
        id
        name
      }
      pricingLevelName {
        id
        name
        pricingMethod {
          id
          name
          sellPriceFormula
          pricingMethodFields {
            fieldType
            fieldKey
            fieldName
            fieldOptions
          }
          status
        }
        productType
        products {
          id
          name
        }
        status
      }
      featureImageUrl
      hasVariants
      variants {
        id
        stockCode
        basePrice
        comparePrice
        variantTitle {
          name
          option {
            name
          }
        }
        cost
        marketCost
        minimumQuantity
        inventory
        variantImageUrl
      }
      returnableItem
      vendor {
        id
        companyName
      }
      galleryImages {
        id
        imageUrl
      }
      relatedProducts {
        id
        name
      }
      status
    }
  }
`;

const ProductUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { productId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [product, setProduct] = useState<Product>();

  const [fetchProduct] = useLazyQuery(FETCH_PRODUCT);

  useEffect(() => {
    fetchProduct({
      variables: {
        id: productId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchProduct) {
          console.log(data.fetchProduct);

          setProduct(data.fetchProduct);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProduct, productId]);

  const UPDATE_PRODUCT = gql`
    mutation ProductUpdate(
      $id: ID!
      $name: String!
      $description: String
      $departmentId: Int
      $categoryIds: [Int!]
      $vendorId: Int
      $binLocationId: Int
      $featureImageUrl: String
      $options: [String!]
      $hasVariants: Boolean
      $variants: [ProductskuInput!]
      $returnableItem: Boolean
      $relatedIds: [Int!]
      $galleryImageUrls: [String!]
      $status: Int!
    ) {
      productUpdate(
        input: {
          id: $id
          params: {
            name: $name
            description: $description
            departmentId: $departmentId
            categoryIds: $categoryIds
            vendorId: $vendorId
            binLocationId: $binLocationId
            featureImageUrl: $featureImageUrl
            options: $options
            hasVariants: $hasVariants
            variants: $variants
            returnableItem: $returnableItem
            relatedIds: $relatedIds
            galleryImageUrls: $galleryImageUrls
            status: $status
          }
        }
      ) {
        product {
          id
          name
        }
      }
    }
  `;
  const [updateProduct] = useMutation(UPDATE_PRODUCT);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    console.log(259, values);
    updateProduct({
      variables: {
        id: product?.id,
        name: values.name,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        departmentId: values.department
          ? parseInt(values.department.value)
          : null,
        categoryIds: values.category
          ? values.category.map((item: any) => parseInt(item.value))
          : [],
        vendorId: values.vendor ? parseInt(values.vendor.value) : null,
        binLocationId: values.binLocation
          ? parseInt(values.binLocation.value)
          : null,
        featureImageUrl: values.featureImageUrl,
        options: values.options,
        hasVariants: values.hasVariants,
        variants: values.hasVariants
          ? values.variants.map((v: VariantItem) => {
              return {
                stockCode: v.stockCode,
                basePrice: v.basePrice,
                comparePrice: v.comparePrice,
                optionValues: v.optionValues,
                cost: v.cost,
                marketCost: v.marketCost,
                minimumQuantity: v.minimumQuantity,
                inventory: v.inventory,
                variantImageUrl: v.variantImageUrl,
              };
            })
          : [
              {
                stockCode: values.stockCode,
                basePrice: values.basePrice,
                comparePrice: values.comparePrice,
                optionValues: [],
                cost: values.cost,
                marketCost: values.marketCost,
                minimumQuantity: values.minimumQuantity,
                inventory: values.inventory,
                variantImageUrl: values.featureImageUrl,
              },
            ],
        returnableItem: values.returnableItem,
        relatedIds: values?.relatedIds ? values.relatedIds : [],
        galleryImageUrls: values?.galleryImageUrls,
        status: values.status ? parseInt(values.status.value) : null,
      },
    })
      .then(({ data, errors }) => {
        actions.setSubmitting(false);
        if (data?.productUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Product updated successfully",
          });
          return navigate(`/inventory/products`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Product update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Product update failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_update_product")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_update_product")}
          </p>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-16">
          <Link to="/inventory/products" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_update")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title={ProductUpdateResource.name}
        heading="Products"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Products",
            href: "/inventory/products",
          },
          {
            name: ProductUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-6xl py-6 sm:py-8">
        <Form
          initialValues={{
            name: product?.name ?? "",
            description: product?.description ?? "",
            basePrice: product?.variants[0]?.basePrice ?? 0,
            binLocation: product?.binLocation
              ? {
                  label: product?.binLocation.name,
                  value: product?.binLocation.id,
                }
              : null,

            category: product?.categories
              ? product.categories.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })
              : [],
            autoCategories: product?.autoCategories
              ? product.autoCategories
              : [],
            comparePrice: product?.variants[0]?.comparePrice ?? 0,
            department: product?.department
              ? {
                  label: product?.department.name,
                  value: product?.department.id,
                }
              : null,
            featureImageUrl: product?.featureImageUrl ?? "",
            pricingLevel: product?.pricingLevelName.length
              ? product?.pricingLevelName[0]
              : null,
            options: product?.options ?? [],
            cost: product?.variants[0]?.cost ?? 0,
            marketCost: product?.variants[0]?.marketCost ?? 0,
            minimumQuantity: product?.variants[0]?.minimumQuantity ?? 0,
            inventory: product?.variants[0]?.inventory ?? 0,
            hasVariants: product?.hasVariants ?? false,
            variants: product?.hasVariants
              ? product?.variants.map((v) => {
                  return {
                    id: v.id,
                    stockCode: v.stockCode,
                    basePrice: v.basePrice,
                    comparePrice: v.comparePrice,
                    optionValues: v.variantTitle.map((vt: any) => {
                      return {
                        option: vt.option.name,
                        optionValue: vt.name,
                      };
                    }),
                    cost: v.cost,
                    marketCost: v.marketCost,
                    minimumQuantity: v.minimumQuantity,
                    inventory: v.inventory,
                    variantImageUrl: v.variantImageUrl,
                  };
                })
              : [
                  {
                    id: product?.variants[0]?.id,
                    stockCode: product?.variants[0]?.stockCode,
                    basePrice: product?.variants[0]?.basePrice,
                    comparePrice: product?.variants[0]?.comparePrice,
                    optionValues: [],
                    cost: product?.variants[0]?.cost,
                    marketCost: product?.variants[0]?.marketCost,
                    minimumQuantity: product?.variants[0]?.minimumQuantity,
                    inventory: product?.variants[0]?.inventory,
                    variantImageUrl: "",
                  },
                ],
            returnableItem: product?.returnableItem ?? false,
            stockCode: product?.variants[0]?.stockCode ?? "",
            vendor: product?.vendor
              ? {
                  label: product?.vendor.companyName,
                  value: product?.vendor.id,
                }
              : null,
            relatedIds: product?.relatedProducts
              ? product.relatedProducts.map((p) =>
                  typeof p.id === "string" ? parseInt(p.id) : p.id
                )
              : [],
            galleryImageUrls: product?.galleryImages
              ? product.galleryImages.map((g) => g.imageUrl)
              : [],
            status: product?.status
              ? {
                  label:
                    product.status === 2
                      ? "Active"
                      : product.status === 0
                      ? "Inactive"
                      : "Draft",
                  value: String(product.status),
                }
              : null,
          }}
          onSubmit={handleSubmit}
          header={<FormHeader />}
          actionType="update"
        />
      </div>
    </>
  );
};

export default ProductUpdate;
export const ProductUpdateResource: ResourceProps = {
  name: "Edit Product",
  description: "Update an existing product",
  access: ["update-products"],
  path: "products/:productId",
};
