import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";

const CategoryCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CREATE_CATEGORY = gql`
    mutation CategoryCreate(
      $name: String!
      $description: String
      $parentId: Int
      $productsIds: [Int!]
      $imageUrl: String
      $categoryType: Int
      $conditionType: Int
      $conditions: [CategoryproductInput!]
      $status: Boolean!
    ) {
      categoryCreate(
        input: {
          params: {
            name: $name
            description: $description
            parentId: $parentId
            productsIds: $productsIds
            imageUrl: $imageUrl
            categoryType: $categoryType
            conditionType: $conditionType
            conditions: $conditions
            status: $status
          }
        }
      ) {
        category {
          id
          name
          description
          parent {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [createCategory] = useMutation(CREATE_CATEGORY);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCategory({
      variables: {
        name: values.name,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        productsIds: values?.productsIds ? values.productsIds : [],
        imageUrl: values.imageUrl,
        categoryType: values.categoryType,
        conditionType: values.conditionType,
        conditions: values.conditions,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.categoryCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Category created successfully",
          });
          return navigate(`/inventory/categories`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Category creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Category creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Category"
        heading="Categories"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Categories",
            href: "/inventory/categories",
          },
          {
            name: CategoryCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          {/* <div className="sm:flex sm:items-center hidden">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_category")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_category")}
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/inventory/categories/add"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
            >
              Add category
            </Link>
          </div>
          </div> */}
          <div className="flex flex-col">
            <div className="md:col-span-2">
              <Form
                initialValues={{
                  name: "",
                  description: "",
                  parent: null,
                  productsIds: [],
                  imageUrl: "",
                  categoryType: 0,
                  conditionType: 0,
                  conditions: [],
                  status: true,
                }}
                onSubmit={handleSubmit}
                actionLabel={t("text_create")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryCreate;
export const CategoryCreateResource: ResourceProps = {
  name: "Create Category",
  description: "Create a new category",
  access: ["create-categories"],
  path: "categories/add",
};
