import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { TablePaginate } from "../../../../components/table";
import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";

import { Button } from "../../../../components/form";
import { AuthShield } from "../../../auth/core";
import { Head } from "../../../../components/core";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id?: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  customerType: number;
};

type PricingCategory = {
  id: string;
  name: string;
  description: string;
  pricingStructures: PricingStructure[];
  createdAt: string;
  status: boolean;
};

const FETCH_PRICINGCATEGORIES = gql`
  query FetchPricingCategories {
    fetchPricingCategories {
      id
      name
      description
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
      }
      createdAt
      status
    }
  }
`;

const PricingCategoryList = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchPricingCategories, { loading }] = useLazyQuery(
    FETCH_PRICINGCATEGORIES
  );
  const [pricingCategories, setPricingCategories] = useState<PricingCategory[]>(
    []
  );

  const didFetchPricingCategories = useRef(false);
  const fetchingPricingCategories = useCallback(() => {
    fetchPricingCategories()
      .then(({ data, error }) => {
        if (data?.fetchPricingCategories) {
          const updatedPricingCategories = data.fetchPricingCategories.map(
            (item: PricingCategory) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setPricingCategories(updatedPricingCategories);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing category read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingCategories]);

  useEffect(() => {
    if (!didFetchPricingCategories.current) {
      fetchingPricingCategories();
      didFetchPricingCategories.current = true;
    }
  }, [fetchingPricingCategories]);

  const DELETE_PRICINGCATEGORY = gql`
    mutation PricingCategoryDelete($id: ID!) {
      pricingCategoryDelete(input: { id: $id }) {
        pricingCategory {
          id
          name
        }
      }
    }
  `;
  const [deletePricingCategory] = useMutation(DELETE_PRICINGCATEGORY);
  const handleDelete = async (id: string) => {
    return await deletePricingCategory({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.pricingCategoryDelete) {
          const deletedPricingCategory =
            data?.pricingCategoryDelete?.pricingCategory;
          const newPricingCategories = [...pricingCategories];
          const index = newPricingCategories.findIndex(
            (pricingCategory) => pricingCategory.id === id
          );
          newPricingCategories.splice(index, 1);
          setPricingCategories(newPricingCategories);
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Pricing category ${deletedPricingCategory.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing category delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing category delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<PricingCategory>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-pricingcategories"]}>
              <Link
                to={`/inventory/pricelist/pricing-categories/${props.row.original.id}`}
                className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Link>
            </AuthShield>
            <AuthShield access={["destroy-pricingcategories"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Pricing Categories"
        heading="Pricing Categories"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing Categories",
            href: "/inventory/pricelist/pricing-categories",
          },
          {
            name: PricingCategoryListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Pricing Categories List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the pricing categories in your account including
              their name, title, email and pricing category.
            </p>
          </div>
        </div>
        <TablePaginate
          data={pricingCategories}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-pricingcategories"]}>
              <Link to="/inventory/pricelist/pricing-categories/add">
                <Button>Add pricing category</Button>
              </Link>
            </AuthShield>
          )}
        />
      </div>
    </>
  );
};

export default PricingCategoryList;
export const PricingCategoryListResource: ResourceProps = {
  name: "Pricing Categories List",
  description: "A list of pricing categories",
  access: ["read-pricingcategories"],
  path: "pricing-categories",
};
