import { createContext, useReducer } from "react";

export const GenReducer = (
  state: any,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "UPDATE_PAGE":
      const { title, heading, description, breadcrumbs } = action.payload;
      return {
        ...state,
        title,
        heading,
        description,
        breadcrumbs,
      };
    case "HANDLE_MENU":
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  title: "Alpha Fresh",
  heading: "Alpha Fresh",
  description: "",
  breadcrumbs: [],
  sidebarOpen: false,
};

export const GenContext = createContext<any>({});

export default function GenContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(GenReducer, initialState);

  const updatePage = (page: any) => {
    dispatch({ type: "UPDATE_PAGE", payload: page });
  };
  const handleMenu = (sidebarOpen: boolean) => {
    dispatch({ type: "HANDLE_MENU", payload: sidebarOpen });
  };
  const contextValues = {
    updatePage,
    handleMenu,
    ...state,
  };
  return (
    <GenContext.Provider value={contextValues}>{children}</GenContext.Provider>
  );
}
