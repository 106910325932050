import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { TablePaginate } from "../../../components/table";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type Customer = {
  id: string;
  customerName: string;
};
type ContactType = {};
type ContactDetails = {
  id: string;
  name: string;
  contactType: ContactType;
  email: string;
  phoneNumber: string;
  sendSms: boolean;
};
type CustomerContact = {
  id: string;
  contactDetails: ContactDetails[];
  createdAt: string;
  customer: Customer;
  emailImportantUpdates: boolean;
  emailSpecials: boolean;
  firstName: string;
  mobileNumber: string;
  phoneNumber: string;
  postalAddress: string;
  postcode: string;
  state: string;
  suburb: string;
  surName: string;
};

const FETCH_CUSTOMERCONTACTS = gql`
  query FetchCustomerContacts {
    fetchCustomerContacts {
      id
      contactDetails {
        id
        name
        contactType {
          id
          name
        }
        phoneNumber
        email
        sendSms
      }
      createdAt
      customer {
        id
        customerName
      }
      emailImportantUpdates
      emailSpecials
      firstName
      mobileNumber
      phoneNumber
      postalAddress
      postcode
      state
      suburb
      surName
    }
  }
`;

const CustomerContactList = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchCustomerContacts, { loading }] = useLazyQuery(
    FETCH_CUSTOMERCONTACTS
  );
  const [customerContacts, setCustomerContacts] = useState<CustomerContact[]>(
    []
  );

  useEffect(() => {
    fetchCustomerContacts()
      .then(({ data, error }) => {
        if (data?.fetchCustomerContacts) {
          const updatedCustomerContacts = data.fetchCustomerContacts.map(
            (item: CustomerContact) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setCustomerContacts(updatedCustomerContacts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "CustomerContact read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerContacts]);

  const DELETE_CUSTOMERCONTACT = gql`
    mutation CustomerContactDelete($id: ID!) {
      customerContactDelete(input: { id: $id }) {
        customerContact {
          id
          firstName
        }
      }
    }
  `;
  const [deleteCustomerContact] = useMutation(DELETE_CUSTOMERCONTACT);
  const handleDelete = async (id: string) => {
    return await deleteCustomerContact({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.customerContactDelete) {
          const deletedCustomerContact =
            data?.customerContactDelete?.customerContact;
          const newCustomerContacts = [...customerContacts];
          const index = newCustomerContacts.findIndex(
            (customerContact) => customerContact.id === id
          );
          newCustomerContacts.splice(index, 1);
          setCustomerContacts(newCustomerContacts);
          addNotify({
            type: NotifyType.SUCCESS,
            title: `CustomerContact ${deletedCustomerContact.firstName} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "CustomerContact delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "CustomerContact delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<CustomerContact>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "firstName",
        header: "Name",
      },
      {
        accessorKey: "postalAddress",
        header: "Postal Address"
      },
      {
        accessorKey: "state",
        header: "State",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-customercontacts"]}>
              <Link
                to={`/sales/customer-contacts/${props.row.original.id}`}
                className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.firstName}
                </span>
              </Link>
            </AuthShield>
            <AuthShield access={["destroy-customercontacts"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.firstName,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.firstName}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Customer Contacts"
        heading="Customer Contacts"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customer Contacts",
            href: "/sales/customer-contacts",
          },
          {
            name: CustomerContactListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Customer Contacts List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the customer contacts in your account including
              their name, title, email and customer contact.
            </p>
          </div>
        </div>
        <TablePaginate
          data={customerContacts}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            
             <div className="flex space-x-4">
             <AuthShield access={["read-customercontacttypes"]}>
               <Link to="/sales/customer-contact-types">
                 <Button variant="secondary">Customer contact types</Button>
               </Link>
             </AuthShield>
             <AuthShield access={["create-customercontacts"]}>
              <Link to="/sales/customer-contacts/add">
                <Button>Add customer contact</Button>
              </Link>
            </AuthShield>
           </div>
          )}
        />
      </div>
    </>
  );
};

export default CustomerContactList;
export const CustomerContactListResource: ResourceProps = {
  name: "Customer Contacts List",
  description: "A list of customer contacts",
  access: ["read-customercontacts"],
  path: "customer-contacts",
};
