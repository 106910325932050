import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ScaleIcon } from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/solid";

import {
  useNotifyContext,
  NotifyType,
  NotifyModal,
  NotifyAction,
} from "../contexts/NotifyContext";
import HeaderLayout from "../layouts/HeaderLayout";

import { useAuth } from "./auth";
import {
  Layout as WidgetLayout,
  MultiAxisLineChart,
  LineChart,
} from "../widgets";

import { classNames } from "../utils";
import { t } from "i18next";

import { AlertModal, AlertType, useAlert } from "../hooks/useAlert";
import DashboardWidgets from "../assets/Dashboard.svg";
import { Head } from "../components/core";

const cards = [
  { name: "Account balance", href: "#", icon: ScaleIcon, amount: "$30,659.45" },
  // More items...
];
const transactions = [
  {
    id: 1,
    name: "Payment to Molly Sanders",
    href: "#",
    amount: "$20,000",
    currency: "USD",
    status: "success",
    date: "July 11, 2020",
    datetime: "2020-07-11",
  },
  // More transactions...
];

const Greeting = () => {
  const { t } = useTranslation();
  var today = new Date();
  var hours = today.getHours();
  var greet;

  if (hours < 12) greet = t("text_morning");
  else if (hours >= 12 && hours <= 17) greet = t("text_afternoon");
  else if (hours >= 17 && hours <= 24) greet = t("text_evening");

  return (
    <span>
      {t("text_good")} {greet}
    </span>
  );
};

const Dashboard = () => {
  // const { currentUser } = useAuth();
  // let navigate = useNavigate();

  // const { dispatchNotify, addNotify } = useNotifyContext();

  const [warning, setWarning] = useState(false);

  const Message = useAlert({
    open: warning,
    title: "Under Development",
    message: "This area is under development and will be available soon.",
    type: AlertType.SUCCESS,
    delay: 3000,
    onDismiss: () => {
      setWarning(false);
    },
  });

  return (
    <>
      <Head
        title={t("heading_dashboard")}
        heading={t("heading_dashboard")}
        description={t("description_dashboard")}
        breadcrumbs={[
          {
            name: "Alpha Fresh",
            href: "/",
          },
          {
            name: t("heading_dashboard"),
            href: "/dashboard",
          },
        ]}
      />
      <div className="mx-auto max-w-6xl px-4 pb-10 sm:px-6 lg:px-8">
        <HeaderLayout />

        {/* <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <WidgetLayout
              title="Overview"
              count={3}
              navigation={[
                {
                  name: t("text_view_more"),
                  href: "/",
                  icon: "bi bi-box-arrow-up-right",
                },
                {
                  name: "Remove from pinned",
                  href: "/",
                  icon: "bi bi-x-circle",
                },
              ]}
              className="mt-5 w-full"
            >
              <MultiAxisLineChart className="rounded-lg bg-white py-4" />
            </WidgetLayout>
            <WidgetLayout
              title="Line Chart"
              count={5}
              navigation={[
                {
                  name: t("text_view_more"),
                  href: "/",
                  icon: "bi bi-box-arrow-up-right",
                },
                {
                  name: "Remove from pinned",
                  href: "/",
                  icon: "bi bi-x-circle",
                },
              ]}
              className="mt-5 w-full col-span-2"
            >
              <LineChart className="rounded-lg bg-white py-4" />
            </WidgetLayout>
          </div>
        </div> */}
        <img
          src={DashboardWidgets}
          alt="Dashboard Widgets"
          className="w-full"
          draggable={false}
          onClick={() => setWarning(true)}
        />
        <Message />

        {/* <WidgetLayout className="mt-5 px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="p-4 md:flex md:items-center md:justify-between rounded-lg bg-white">
          <div className="min-w-0 flex-1">
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src={`${
                  currentUser?.avatar
                    ? currentUser.avatar
                    : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                }`}
                alt={currentUser?.firstName}
              />
              <div>
                <div className="flex items-center">
                  <img
                    className="h-16 w-16 rounded-full sm:hidden"
                    src={`${
                      currentUser?.avatar
                        ? currentUser.avatar
                        : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    }`}
                    alt={currentUser?.firstName}
                  />
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    <Greeting />, {currentUser?.firstName}{" "}
                    {currentUser?.lastName}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Role</dt>
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                    <BuildingOfficeIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {JSON.stringify(currentUser?.roles)}
                  </dd>
                  <dt className="sr-only">Account status</dt>
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                      aria-hidden="true"
                    />
                    Verified account
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              Add money
            </button>
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              Send money
            </button>
          </div>
        </div>
      </WidgetLayout>

      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <WidgetLayout
            title="Overview"
            count={3}
            navigation={[
              {
                name: t("text_view_more"),
                href: "/",
                icon: "bi bi-box-arrow-up-right",
              },{
                name: 'Remove from pinned',
                href: '/',
                icon: 'bi bi-x-circle'
              }
            ]}
            className="mt-5 w-full"
          >
            <MultiAxisLineChart className="py-4 rounded-lg bg-white" />
          </WidgetLayout>
          {cards.map((card) => (
            <WidgetLayout
              key={card.name}
              title={card.name}
              className="mt-5 w-full"
            >
              <div className="p-5 rounded-lg bg-white">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <card.icon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {card.name}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {card.amount}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <a
                    href={card.href}
                    className="font-medium text-primary-700 hover:text-primary-900"
                  >
                    View all
                  </a>
                </div>
              </div>
            </WidgetLayout>
          ))}
        </div>
      </div>

      <WidgetLayout
        title="Recent Activity"
        className="mt-5 px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8"
      >
        <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {transactions.map((transaction) => (
            <li key={transaction.id}>
              <a
                href={transaction.href}
                className="block bg-white px-4 py-4 hover:bg-gray-50"
              >
                <span className="flex items-center space-x-4">
                  <span className="flex flex-1 space-x-2 truncate">
                    <BanknotesIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="flex flex-col truncate text-sm text-gray-500">
                      <span className="truncate">{transaction.name}</span>
                      <span>
                        <span className="font-medium text-gray-900">
                          {transaction.amount}
                        </span>{" "}
                        {transaction.currency}
                      </span>
                      <time dateTime={transaction.datetime}>
                        {transaction.date}
                      </time>
                    </span>
                  </span>
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </a>
            </li>
          ))}
        </ul>

        <div className="mt-2 flex flex-col">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    scope="col"
                  >
                    Transaction
                  </th>
                  <th
                    className="bg-gray-50 px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    scope="col"
                  >
                    Amount
                  </th>
                  <th
                    className="hidden bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 md:block"
                    scope="col"
                  >
                    Status
                  </th>
                  <th
                    className="bg-gray-50 px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    scope="col"
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {transactions.map((transaction) => (
                  <tr key={transaction.id} className="bg-white">
                    <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                      <div className="flex">
                        <a
                          href={transaction.href}
                          className="group inline-flex space-x-2 truncate text-sm"
                        >
                          <BanknotesIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <p className="truncate text-gray-500 group-hover:text-gray-900">
                            {transaction.name}
                          </p>
                        </a>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                      <span className="font-medium text-gray-900">
                        {transaction.amount}{" "}
                      </span>
                      {transaction.currency}
                    </td>
                    <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                      <span
                        className={classNames(
                          "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize"
                        )}
                      >
                        {transaction.status}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                      <time dateTime={transaction.datetime}>
                        {transaction.date}
                      </time>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav
              className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">1</span> to{" "}
                  <span className="font-medium">10</span> of{" "}
                  <span className="font-medium">20</span> results
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                <a
                  href="/"
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </a>
                <a
                  href="/"
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </a>
              </div>
            </nav>
          </div>
        </div>
      </WidgetLayout> */}
      </div>
    </>
  );
};

export default Dashboard;
export const DashboardResource: ResourceProps = {
  name: "Dashboard",
  description: "A dashboard for your store.",
  path: "/dashboard",
  icon: "bi bi-speedometer2",
  access: [],
};
