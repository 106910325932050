import { useState, useEffect, createContext, useContext, useRef } from "react";
import * as authHelper from "./AuthHelper";
import { AuthContextProps, Role, UserModel } from "../@types/auth";
import { LayoutSplashScreen } from "../../../components/core";
import { getUser } from "../";

const initAuthContextPropsState = {
  access: authHelper.getAccess(),
  saveAccess: () => {},
  refresh: authHelper.getRefresh(),
  saveRefresh: () => {},
  currentIdentifier: undefined,
  setCurrentIdentifier: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentRole: undefined,
  setCurrentRole: () => {},
  isMfaEnabled: false,
  setMfaEnabled: () => {},
  isLocked: false,
  setLocked: () => {},
  lockedExpiry: undefined,
  setLockedExpiry: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);
AuthContext.displayName = "AuthenticationContext";

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [access, setAccess] = useState<string | undefined>(
    authHelper.getAccess()
  );
  const [refresh, setRefresh] = useState<string | undefined>(
    authHelper.getRefresh()
  );

  const [currentIdentifier, setCurrentIdentifier] = useState<
    string | undefined
  >();
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [currentRole, setCurrentRole] = useState<Role | undefined>();
  const [isMfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const [isLocked, setLocked] = useState<boolean>(false);
  const [lockedExpiry, setLockedExpiry] = useState<Date | undefined>();

  const saveAccess = (access: string | undefined) => {
    setAccess(access);
    if (access) {
      authHelper.setAccess(access);
    } else {
      authHelper.removeAccess();
    }
  };

  const saveRefresh = (refresh: string | undefined) => {
    setRefresh(refresh);
    if (refresh) {
      authHelper.setRefresh(refresh);
    } else {
      authHelper.removeRefresh();
    }
  };

  const logout = async () => {
    saveAccess(undefined);
    saveRefresh(undefined);
    setCurrentIdentifier(undefined);
    setCurrentUser(undefined);
    setCurrentRole(undefined);
    setMfaEnabled(false);
  };

  return (
    <AuthContext.Provider
      value={{
        access,
        saveAccess,
        refresh,
        saveRefresh,
        currentIdentifier,
        setCurrentIdentifier,
        currentUser,
        setCurrentUser,
        currentRole,
        setCurrentRole,
        isMfaEnabled,
        setMfaEnabled,
        isLocked,
        lockedExpiry,
        setLockedExpiry,
        setLocked,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = ({ children }: { children: React.ReactNode }) => {
  const {
    logout,
    setCurrentUser,
    setCurrentRole,
    setMfaEnabled,
    setLocked,
    setLockedExpiry,
  } = useAuth();

  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUser();
          if (user) {
            setCurrentUser(user);
            setCurrentRole(user.roles[0]);
            setMfaEnabled(user.enableMfa);
            setLocked(user.isLocked);
            setLockedExpiry(user.lockedExpiry);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    (async () => {
      if (authHelper.getRefresh()) {
        await requestUser();
      } else {
        logout();
        setShowSplashScreen(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
