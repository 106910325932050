import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";
import { Button } from "../../../components/form";

const LocationCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CREATE_LOCATION = gql`
    mutation binLocationCreate(
      $name: String!
      $parentId: Int
      $binLocationTypesId: Int
      $status: Boolean!
      $branch: String
      $description: String
      $address: String
      $returnAddress: String
      $imageUrl: String
      $latitude: String
      $longitude: String
      $timezone: String
    ) {
      binLocationCreate(
        input: {
          params: {
            name: $name
            parentId: $parentId
            binLocationTypesId: $binLocationTypesId
            status: $status
            branch: $branch
            description: $description
            address: $address
            returnAddress: $returnAddress
            imageUrl: $imageUrl
            latitude: $latitude
            longitude: $longitude
            timezone: $timezone
          }
        }
      ) {
        location {
          id
          name
          description
          parent {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [createLocation] = useMutation(CREATE_LOCATION);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createLocation({
      variables: {
        name: values.name,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        binLocationTypesId: values.type ? parseInt(values.type.value) : null,
        status: values.status,
        branch: values.branch,
        description: values.description,
        address: values.address,
        returnAddress: values.returnAddress,
        imageUrl: values.imageUrl,
        latitude: values.latitude,
        longitude: values.longitude,
        timezone: values.timezone ? values.timezone.value : "",
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.binLocationCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Location created successfully",
          });
          return navigate(`/inventory/locations`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Location creation failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_create_location")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_create_location")}
          </p>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-16">
          <Link to="/inventory/locations" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_create")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title={LocationCreateResource.name}
        heading="Locations"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Locations",
            href: "/inventory/locations",
          },
          {
            name: LocationCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-6xl py-6 sm:py-8">
        <Form
          initialValues={{
            name: "",
            parent: null,
            type: null,
            status: true,
            branch: "",
            description: "",
            address: "",
            returnAddress: "",
            imageUrl: "",
            latitude: "",
            longitude: "",
            timezone: null,
          }}
          onSubmit={handleSubmit}
          header={<FormHeader />}
        />
      </div>
    </>
  );
};

export default LocationCreate;
export const LocationCreateResource: ResourceProps = {
  name: "Create Location",
  description: "Create a new location",
  access: ["create-binlocations"],
  path: "locations/add",
};
