import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FormUpdate from "./components/FormUpdate";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";

import { Head } from "../../../../components/core";

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: {
    id: string;
    name: string;
  };
  productType: number;
  products: {
    id: string;
    name: string;
  }[];
  customerType: number;
  customers: {
    id: string;
    customerName: string;
  }[];
  status: boolean;
};

type PricingCategory = {
  id: string;
  name: string;
  description: string;
  products: {
    id: string;
    name: string;
  }[];
  pricingStructures: PricingStructure[];
  createdAt: string;
  status: boolean;
};

const FETCH_PRICINGCATEGORY = gql`
  query FetchPricingCategory($id: ID!) {
    fetchPricingCategory(id: $id) {
      id
      name
      description
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
      products {
        id
        name
      }
      createdAt
      status
    }
  }
`;

const PricingCategoryUpdate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  let { pricingCategoryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pricingCategory, setPricingCategory] = useState<PricingCategory>();

  const didFetchPricingCategory = useRef(false);

  const [fetchPricingCategory] = useLazyQuery(FETCH_PRICINGCATEGORY);

  const fetchedPricingCategory = useCallback(() => {
    if (!pricingCategoryId) return;

    fetchPricingCategory({
      variables: {
        id: pricingCategoryId,
        pricingCategoryId: parseInt(pricingCategoryId),
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchPricingCategory) {
          setPricingCategory(data.fetchPricingCategory);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing category read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingCategory, pricingCategoryId]);

  useEffect(() => {
    if (!didFetchPricingCategory.current) {
      fetchedPricingCategory();
      didFetchPricingCategory.current = true;
    }
  }, [fetchedPricingCategory]);

  const UPDATE_PRICINGCATEGORY = gql`
    mutation PricingCategoryUpdate(
      $id: ID!
      $name: String!
      $description: String
      $productIds: [Int!]
      $status: Boolean
    ) {
      pricingCategoryUpdate(
        input: {
          id: $id
          params: {
            name: $name
            description: $description
            productIds: $productIds
            status: $status
          }
        }
      ) {
        pricingCategory {
          id
          name
        }
      }
    }
  `;
  const [updatePricingCategory] = useMutation(UPDATE_PRICINGCATEGORY);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!pricingCategory) return;

    const oldProductIds = pricingCategory.products.flatMap((product) =>
      parseInt(product.id)
    );
    const newProductIds = values.productIds;
    const productIds = newProductIds.filter(
      (productId: number) => !oldProductIds.includes(productId)
    );

    console.log(productIds);

    updatePricingCategory({
      variables: {
        id: pricingCategory?.id,
        name: values.name,
        description: values.description,
        productIds: productIds,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingCategoryUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing category updated successfully",
          });
          return navigate(`/inventory/pricelist/pricing-categories`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing category update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing category update failed",
          message: error.message,
        });
      });
  };

  useEffect(() => {
    console.log(pricingCategory);
  }, [pricingCategory]);

  return (
    <>
      <Head
        title={PricingCategoryUpdateResource.name}
        heading="Pricing Categories"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing Categories",
            href: "/inventory/pricelist/pricing-categories",
          },
          {
            name: PricingCategoryUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_pricing_category")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_pricing_category")}
              </p>
            </div>
          </div>

          {pricingCategory ? (
            <FormUpdate
              initialValues={{
                name: pricingCategory.name ?? "",
                description: pricingCategory.description ?? "",
                productIds:
                  pricingCategory.products?.map((product) =>
                    parseInt(product.id)
                  ) ?? [],
                pricingStructures: pricingCategory.pricingStructures ?? [],
                status: pricingCategory.status ? true : false,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PricingCategoryUpdate;
export const PricingCategoryUpdateResource: ResourceProps = {
  name: "Edit Pricing Category",
  description: "Update an existing pricing category",
  access: ["update-pricingcategories"],
  path: "pricing-categories/:pricingCategoryId",
};
