import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  FieldPricingProducts,
  FieldPriceLevelCalculator,
} from "../../../../components/form";
import { Head } from "../../../../components/core";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";
import { Spinner } from "../../../../animations";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
};

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
  pricingLevelDetails?: Details[];
};

type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
};

type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: Product[];
  createdAt: string;
  status: boolean;
};

enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

type ProductPricing = {
  id: string;
  pricingId?: string;
  pricingLevelId: number;
  productId: number;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};

const FETCH_PRICINGLEVEL = gql`
  query FetchPricingLevel($id: ID!, $pricingLevelId: Int!) {
    fetchPricingLevel(id: $id) {
      id
      name
      pricingMethod {
        id
        name
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
      productType
      products {
        id
        name
        featureImageUrl
        variants {
          id
          stockCode
          productId
          pricingLevelDetails(pricingLevelId: $pricingLevelId) {
            id
            priceFields
            sellPrice
          }
        }
        status
      }
      createdAt
      status
    }
  }
`;

const CREATE_PRODUCTPRICING = gql`
  mutation ProductPricingCreate(
    $pricingLevelId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    productPricingCreate(
      input: {
        params: {
          pricingLevelId: $pricingLevelId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const UPDATE_PRODUCTPRICING = gql`
  mutation ProductPricingUpdate(
    $id: ID!
    $pricingLevelId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    productPricingUpdate(
      input: {
        id: $id
        params: {
          pricingLevelId: $pricingLevelId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const DELETE_PRODUCTPRICING = gql`
  mutation ProductPricingDelete($productId: Int!, $pricingLevelId: Int!) {
    productPricingDelete(
      input: { productId: $productId, pricingLevelId: $pricingLevelId }
    ) {
      message
    }
  }
`;

const UPDATE_PRICINGLEVEL = gql`
  mutation PricingLevelUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingLevelUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingLevel {
        id
        name
        pricingMethod {
          id
          name
        }
        productType
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

const PricingLevelPricing = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { pricingLevelId } = useParams();

  const [pricingLevel, setPricingLevel] = useState<PricingLevel>();
  const [fetchPricingLevel] = useLazyQuery(FETCH_PRICINGLEVEL);

  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);

  const [deleteProductPricing] = useMutation(DELETE_PRODUCTPRICING);

  const [updatePricingLevel] = useMutation(UPDATE_PRICINGLEVEL);

  const [createProductPricing, { loading: loadingCreate }] = useMutation(
    CREATE_PRODUCTPRICING
  );
  const [updateProductPricing, { loading: loadingUpdate }] = useMutation(
    UPDATE_PRODUCTPRICING
  );

  const didFetchPricingLevel = useRef(false);

  const fetchingPricingLevel = useCallback(() => {
    if (!pricingLevelId) return;

    fetchPricingLevel({
      variables: {
        id: pricingLevelId,
        pricingLevelId: parseInt(pricingLevelId),
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchPricingLevel) {
          console.log(data.fetchPricingLevel);
          setPricingLevel(data.fetchPricingLevel);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing level read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingLevel, pricingLevelId]);

  useEffect(() => {
    if (!didFetchPricingLevel.current) {
      fetchingPricingLevel();
      didFetchPricingLevel.current = true;
    }
  }, [fetchingPricingLevel]);

  async function handleSave() {
    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const {
        pricingId,
        pricingLevelId,
        productId,
        productSkuId,
        priceFields,
        sellPrice,
      } = product;

      if (pricingId) {
        await updateProductPricing({
          variables: {
            id: pricingId,
            pricingLevelId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.productPricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createProductPricing({
          variables: {
            pricingLevelId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.productPricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    setProductPricing(newProductPricing);
  }

  const handleCancel = () => {
    return navigate(`/inventory/pricelist/pricing-levels/${pricingLevelId}`);
  };

  const handleRemoveProduct = (productId: string) => {
    if (!pricingLevelId || !pricingLevel) return;
    const updatedLevel = {
      ...pricingLevel,
      products: pricingLevel.products.filter((item) => item.id !== productId),
    };

    deleteProductPricing({
      variables: {
        productId: parseInt(productId),
        pricingLevelId: parseInt(pricingLevelId),
      },
    })
      .then(({ data }) => {
        if (data?.productPricingDelete) {
          setPricingLevel(updatedLevel);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Product removed successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcut remove failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Prodcut remove failed",
          message: error.message,
        });
      });
  };

  const handleAddProduct = (products: Product[]) => {
    if (!pricingLevel) return;

    const updatedPricingLevel = {
      ...pricingLevel,
      products: [...pricingLevel.products, ...products],
    };

    updatePricingLevel({
      variables: {
        id: pricingLevel.id,
        name: pricingLevel.name,
        pricingMethodId: parseInt(pricingLevel.pricingMethod.id),
        productIds: products.map((item) => parseInt(item.id)),
        status: pricingLevel.status,
      },
    })
      .then(({ data }) => {
        if (data?.pricingLevelUpdate) {
          setPricingLevel(updatedPricingLevel);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Products updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcuts update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Prodcuts update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Manage Pricing"
        heading="Manage Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Manage Pricing",
            href: "/inventory/pricelist/pricing-levels",
          },
          {
            name: pricingLevel?.name || "Pricing Level",
            href: `/inventory/pricelist/pricing-levels/${pricingLevelId}`,
          },
          {
            name: PricingLevelPricingResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PricingLevelPricingResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PricingLevelPricingResource.description}
            </p>
          </div>
        </div>
        {pricingLevel ? (
          <>
            <div className="mt-4 space-y-2">
              <div className="flex justify-end">
                <FieldPricingProducts
                  onChange={handleAddProduct}
                  exceptIds={
                    pricingLevel.products.map((item) => parseInt(item.id)) ?? []
                  }
                />
              </div>
              {pricingLevel?.products.map((product) => (
                <FieldPriceLevelCalculator
                  key={product.id}
                  product={product}
                  removeProduct={handleRemoveProduct}
                  pricingMethod={pricingLevel.pricingMethod}
                  productPricing={productPricing}
                  setProductPricing={setProductPricing}
                />
              ))}
            </div>
            <div className="mt-4 flex flex-wrap justify-end space-x-4">
              <Button
                variant="secondary"
                className="min-w-[10rem] justify-center"
                onClick={handleCancel}
              >
                {t("text_back")}
              </Button>
              {productPricing.filter(
                (pp) =>
                  pp.status === ProductPricingStatus.pending ||
                  pp.status === ProductPricingStatus.error
              ).length ? (
                <Button
                  onClick={handleSave}
                  className="min-w-[16rem] justify-center"
                >
                  {loadingCreate || loadingUpdate ? (
                    <>
                      <Spinner />
                      {t("text_save")}
                    </>
                  ) : (
                    t("text_save")
                  )}
                </Button>
              ) : null}
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default PricingLevelPricing;
export const PricingLevelPricingResource: ResourceProps = {
  name: "Manage Pricing",
  description: "Manage pricing list for pricing level",
  access: ["read-pricinglevels"],
  path: "pricing-levels/:pricingLevelId/pricing",
};
