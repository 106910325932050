import { useState, useEffect, useCallback, useRef } from "react";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import {
  Button,
  Field,
  FieldCheckbox,
  FieldEditor,
} from "../../../../components/form";
import { isAuthorizedForProtectedResource, useAuth } from "../../../auth";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";
import { Spinner } from "../../../../animations";
import { Switch } from "@headlessui/react";
import { classNames } from "../../../../utils";

type Role = {
  roleName: string;
  roleDescription: string;
  permissions: number[];
  status: boolean;
};

type ModulePermission = {
  id: string | undefined;
  title: string;
  checked: boolean;
  disabled: boolean;
  readonly: boolean;
  dependencies: number[];
};
type Module = {
  id: string;
  resourceName: string;
  status: boolean;
  permissions: ModulePermission[];
};

type Resource = {
  id: string;
  resourceName: string;
  permissions: {
    id: string;
    permissionName: string;
    permissionDisplayName: string;
    dependencies: number[];
    createdAt: string;
    status: string;
  }[];
  status: boolean;
};

type Permission = {
  id: string;
  permissionName: string;
  permissionDisplayName: string;
  dependencies: number[];
  createdAt: string;
  resource: Resource;
  status: boolean;
};

type Dependency = {
  id: number;
  dependencies: number[];
};

const FETCH_PERMISSIONS = gql`
  query FetchPermissions {
    fetchPermissions {
      id
      permissionName
      permissionDisplayName
      dependencies
      status
      resource {
        id
        resourceName
        status
      }
    }
  }
`;

const FETCH_RESOURCES = gql`
  query FetchResources {
    fetchResources {
      id
      resourceName
      createdAt
      status
    }
  }
`;

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: Role;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotify } = useNotifyContext();

  const { currentRole } = useAuth();

  const [fetchPermissions] = useLazyQuery(FETCH_PERMISSIONS);
  const [fetchResources] = useLazyQuery(FETCH_RESOURCES);

  const [modules, setModules] = useState<Module[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [checkedPermissions, setCheckedPermissions] = useState<Dependency[]>(
    []
  );

  const didFetchModules = useRef(false);

  const fetchModules = useCallback(async () => {
    try {
      const permissionsList = await fetchPermissions();
      const updatedResources: Resource[] = [];
      const resourcesList = await fetchResources();
      const fetchedPermissions = permissionsList?.data?.fetchPermissions;
      setPermissions(fetchedPermissions);
      for (const resource of resourcesList.data?.fetchResources) {
        updatedResources.push({
          ...(resource as Resource),
          permissions: [
            ...fetchedPermissions.filter(
              (permission: Permission) => permission.resource.id === resource.id
            ),
          ],
        });
      }

      const updatedCheckedPermissions = fetchedPermissions.filter(
        (c: Dependency) => {
          if (initialValues?.permissions.length === 0) return false;
          return initialValues.permissions.includes(Number(c.id));
        }
      );
      setCheckedPermissions(
        updatedCheckedPermissions.map((c: Dependency) => {
          const { id, dependencies } = c;
          return { id: Number(id), dependencies };
        })
      );

      const updatedModules: Module[] = updatedResources.map(
        (resource: Resource) => {
          const _create = resource.permissions.find(
            (p) => p.permissionDisplayName?.toLowerCase() === "create"
          );
          const _read = resource.permissions.find(
            (p) => p.permissionDisplayName?.toLowerCase() === "read"
          );
          const _update = resource.permissions.find(
            (p) => p.permissionDisplayName?.toLowerCase() === "update"
          );
          const _destroy = resource.permissions.find(
            (p) => p.permissionDisplayName?.toLowerCase() === "destroy"
          );

          return {
            id: resource.id,
            resourceName: resource.resourceName,
            status: resource.status,
            permissions: [
              {
                id: _create?.id,
                title: _create?.permissionDisplayName ?? "Create",
                checked: initialValues?.permissions.find(
                  (p) => _create?.id && p === Number(_create?.id)
                )
                  ? true
                  : false,
                readonly: !isAuthorizedForProtectedResource(currentRole, [
                  _create?.permissionName ?? "protected",
                ]),
                disabled: !_create?.status,
                dependencies: _create?.dependencies ?? [],
              },
              {
                id: _read?.id,
                title: _read?.permissionDisplayName ?? "Read",
                checked: initialValues?.permissions.find(
                  (p) => _read?.id && p === Number(_read?.id)
                )
                  ? true
                  : false,
                readonly: !isAuthorizedForProtectedResource(currentRole, [
                  _read?.permissionName ?? "protected",
                ]),
                disabled: !_read?.status,
                dependencies: _read?.dependencies ?? [],
              },
              {
                id: _update?.id,
                title: _update?.permissionDisplayName ?? "Update",
                checked: initialValues?.permissions.find(
                  (p) => _update?.id && p === Number(_update?.id)
                )
                  ? true
                  : false,
                readonly: !isAuthorizedForProtectedResource(currentRole, [
                  _update?.permissionName ?? "protected",
                ]),
                disabled: !_update?.status,
                dependencies: _update?.dependencies ?? [],
              },
              {
                id: _destroy?.id,
                title: _destroy?.permissionDisplayName ?? "Destroy",
                checked: initialValues?.permissions.find(
                  (p) => _destroy?.id && p === Number(_destroy?.id)
                )
                  ? true
                  : false,
                readonly: !isAuthorizedForProtectedResource(currentRole, [
                  _destroy?.permissionName ?? "protected",
                ]),
                disabled: !_destroy?.status,
                dependencies: _destroy?.dependencies ?? [],
              },
            ],
          };
        }
      );

      setModules(updatedModules);
    } catch (error) {
      return navigate("/error/401", {
        state: {
          error,
          message:
            "Permissions and Resources read permission(s) is required to access this page.",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPermissions, fetchResources, currentRole, navigate]);

  useEffect(() => {
    if (!didFetchModules.current) {
      fetchModules();
      didFetchModules.current = true;
    }
  }, [fetchModules]);

  const RoleSchema = Yup.object().shape({
    roleName: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    roleDescription: Yup.string(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: RoleSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting, setFieldValue } = formik;

  const isIndependentDependency = useCallback(
    (parent: number) => {
      const options = checkedPermissions.filter(
        (c) =>
          c.dependencies &&
          c.dependencies.length > 0 &&
          c.dependencies.includes(parent)
      );
      return options && options.length === 0;
    },
    [checkedPermissions]
  );

  const isParentDependency = useCallback(
    (parent: number) => {
      const checkedOptions = checkedPermissions?.filter(
        (c) =>
          c.dependencies &&
          c.dependencies.length > 0 &&
          c.dependencies.includes(parent)
      );
      if (checkedOptions && checkedOptions.length !== 0) {
        const dependencyIds = checkedOptions.flatMap((c) => c.id);
        const depends: {
          name: string;
          operation: string;
        }[] = [];
        for (const m of modules) {
          for (const p of m.permissions) {
            if (dependencyIds.includes(Number(p.id))) {
              depends.push({ name: m.resourceName, operation: p.title });
            }
          }
        }
        if (depends.length > 0) {
          addNotify({
            type: NotifyType.ERROR,
            title: `It's a child dependency of ${depends
              .map((d) => d.operation + " " + d.name)
              .join(", ")}, please disable it first.`,
          });
        }
      }

      return checkedOptions.length === 0;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [checkedPermissions, modules]
  );

  const isValidDependencyOperation = useCallback(
    (permissionId: number) => {
      const updatedIds: number[] = [];
      const recurReduce = (arr: any[], parent: number) => {
        if (isIndependentDependency(parent)) {
          updatedIds.push(parent);
        }

        for (const a of arr) {
          for (const p of a.permissions) {
            if (parent === Number(p.id)) {
              if (
                !checkedPermissions.some((c) => {
                  return (
                    c.id !== Number(p.id) &&
                    c.dependencies &&
                    c.dependencies.some((d) => p.dependencies.includes(d))
                  );
                })
              ) {
                if (p.dependencies && p.dependencies.length > 0) {
                  p.dependencies.forEach((d: number) => {
                    recurReduce(arr, d);
                  });
                }
              }
            }
          }
        }
      };
      recurReduce(modules, permissionId);
      return updatedIds;
    },
    [checkedPermissions, isIndependentDependency, modules]
  );

  const handleModuleChange = (
    checked: boolean,
    permission: ModulePermission
  ) => {
    if (!isParentDependency(Number(permission.id))) return;
    if (permission.readonly) {
      addNotify({
        type: NotifyType.ERROR,
        title: "Readonly option",
      });
      return;
    }

    if (permission.disabled) {
      addNotify({
        type: NotifyType.ERROR,
        title: "Disabled option",
      });
      return;
    }

    const updatedPermissionIds: number[] = isValidDependencyOperation(
      Number(permission.id)
    );

    setCheckedPermissions((prevState) => {
      if (checked) {
        const newCheckedPermissions = updatedPermissionIds.map((id) => {
          const p = permissions.find((p) => Number(p.id) === id)!;
          return {
            id: Number(p.id),
            dependencies: p.dependencies,
          };
        });
        return [...prevState, ...newCheckedPermissions];
      } else {
        return prevState.filter((p) => !updatedPermissionIds.includes(p.id));
      }
    });

    const newModules = modules.map((m: Module) => {
      const { permissions } = m;
      const updatedPermissions = permissions.map((p: ModulePermission) => {
        let updatedPermission: ModulePermission = {
          ...p,
        };
        if (!p.id) return updatedPermission;
        if (updatedPermissionIds.includes(Number(p.id)) && !p.disabled) {
          updatedPermission = {
            ...updatedPermission,
            checked: checked,
          };
        }
        return updatedPermission;
      });

      return {
        ...m,
        permissions: updatedPermissions,
      };
    });

    setModules(newModules);
    updatedModuleChange(newModules);
  };

  const updatedModuleChange = (updatedModules: Module[]) => {
    let permissionIds: number[] = [];
    for (const permissions of updatedModules) {
      for (const permission of permissions.permissions) {
        if (permission.checked && permission.id) {
          permissionIds.push(Number(permission.id));
        }
      }
    }
    setFieldValue("permissions", permissionIds);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="overflow-hidden rounded-md shadow-sm">
        <div className="bg-white px-4 py-5 sm:p-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_role_name")}
                name="roleName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.roleName}
                touched={touched.roleName}
                errors={errors.roleName}
              />
            </div>

            <div className="col-span-12">
              <FieldEditor
                title={t("text_description")}
                name="roleDescription"
                onChange={(content: any, editor: any) => {
                  setFieldValue("roleDescription", content);
                }}
                value={formik.values.roleDescription}
                touched={formik.touched.roleDescription}
                errors={formik.errors.roleDescription}
              />
            </div>

            <div className="col-span-12">
              <div className="overflow-hidden rounded-md border border-gray-200">
                <header className="rounded-t-md shadow-md">
                  <div className="block">
                    <div className="grid grid-cols-6 gap-4">
                      <div className="col-span-2 truncate py-3.5 pl-2 pr-2 text-left text-sm font-medium text-gray-900 md:pr-3 md:pl-6">
                        Module
                      </div>
                      <div className="truncate py-3.5 pl-2 pr-2 text-left text-sm font-medium text-gray-900 md:pr-3 md:pl-6 lg:text-center">
                        <span className="hidden md:inline-flex">Create</span>
                        <span className="md:hidden">C</span>
                      </div>
                      <div className="truncate py-3.5 pl-2 pr-2 text-left text-sm font-medium text-gray-900 md:pr-3 md:pl-6 lg:text-center">
                        <span className="hidden md:inline-flex">Read</span>
                        <span className="md:hidden">R</span>
                      </div>
                      <div className="truncate py-3.5 pl-2 pr-2 text-left text-sm font-medium text-gray-900 md:pr-3 md:pl-6 lg:text-center">
                        <span className="hidden md:inline-flex">Update</span>
                        <span className="md:hidden">U</span>
                      </div>
                      <div className="truncate py-3.5 pl-2 pr-2 text-left text-sm font-medium text-gray-900 md:pr-3 md:pl-6 lg:text-center">
                        <span className="hidden md:inline-flex">Destroy</span>
                        <span className="md:hidden">D</span>
                      </div>
                    </div>
                  </div>
                </header>
                <ul className="divide-y divide-gray-100 pt-5 pb-3">
                  {modules?.map((module, index) => (
                    <li key={module.id}>
                      <div>
                        <div className="grid grid-cols-6 items-center gap-1 md:gap-4">
                          <div className="col-span-2 py-3.5 pl-2 pr-2 md:pr-3 md:pl-6">
                            <p className="truncate text-sm font-medium text-primary-700">
                              {module.resourceName}
                            </p>
                          </div>
                          {module.permissions.map((permission, i) => (
                            <div
                              key={`permissions-${module.id}-${i}`}
                              className="flex items-center justify-center py-3.5 pl-2 pr-2 md:pr-3 md:pl-6 lg:text-center"
                            >
                              <FieldCheckbox
                                id={`permissions-${module.id}-${i}`}
                                title={permission.title}
                                checked={permission.checked}
                                disabled={permission.disabled}
                                readOnly={permission.readonly}
                                onChange={(checked: boolean) => {
                                  handleModuleChange(checked, permission);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-span-12">
              <fieldset>
                <legend className="text-sm font-medium text-gray-900">
                  {t("text_status")}
                </legend>
                <Switch.Group
                  as="div"
                  className="mt-1.5 inline-flex items-center"
                >
                  <Switch
                    checked={formik.values.status}
                    onChange={() => {
                      formik.setFieldValue("status", !formik.values.status);
                    }}
                    id="status"
                    className={classNames(
                      formik.values.status ? "bg-primary-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        formik.values.status
                          ? "translate-x-5"
                          : "translate-x-0",
                        "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                  <Switch.Label
                    passive
                    htmlFor="status"
                    className="ml-2 mb-0 block text-sm font-normal text-gray-700"
                  >
                    {formik.values.status
                      ? t("text_active")
                      : t("text_inactive")}
                  </Switch.Label>
                </Switch.Group>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="flex justify-end border-t border-gray-300 bg-white px-4 py-4 text-right md:py-6 md:px-6">
          <Link to="/user-management/roles" className="mr-4 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              actionLabel
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}
