import {
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";

import { Button } from "../form";
import { Spinner } from "../../animations";
import { classNames } from "../../utils";

import { ColumnDef } from "@tanstack/react-table";
import { IndeterminateCheckbox, TableInfinite } from "../table";

import placeholder from "../../assets/placeholder.svg";

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
};

type Product = {
  id: string;
  index: number;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

const FETCH_PRODUCTS = gql`
  query FetchProducts {
    fetchProducts {
      id
      name
      vendor {
        companyName
      }
      featureImageUrl
      variants {
        id
        stockCode
        productId
      }
      createdAt
      status
    }
  }
`;

export function FieldProducts({
  title,
  value = [],
  onChange,
  exceptIds = [],
}: {
  title: string;
  value: number[];
  onChange: (newValue: Product[]) => void;
  exceptIds?: number[];
}) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [fetchProducts, { loading }] = useLazyQuery(FETCH_PRODUCTS);

  const didFetchProducts = useRef(false);
  const fetchingProducts = useCallback(() => {
    fetchProducts()
      .then(({ data, error }) => {
        if (data?.fetchProducts) {
          const fetchedProducts = data.fetchProducts.filter(
            (p: Product) => !exceptIds.includes(Number(p.id))
          );
          setProducts(fetchedProducts);
          const updatedFetchProducts = fetchedProducts.filter((p: Product) =>
            value.includes(Number(p.id))
          );
          setSelectedProducts(updatedFetchProducts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts, value]);

  useEffect(() => {
    if (!didFetchProducts.current) {
      fetchingProducts();
      didFetchProducts.current = true;
    }
  }, [fetchingProducts]);

  useEffect(() => {
    const updatedRowSelection: {
      [key: number]: boolean;
    } = {};
    products.forEach((p: Product, i: number) => {
      updatedRowSelection[i] = selectedProducts.includes(p);
    });
    setRowSelection(updatedRowSelection);
  }, [products, selectedProducts]);

  const columns = useMemo<ColumnDef<Product>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        meta: {
          className: "text-center w-8",
        },
      },
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "featureImageUrl",
        header: "Image",
        cell: ({ row }) => (
          <span className="relative inline-block h-10 w-12 overflow-hidden rounded-md border border-gray-200 bg-white">
            <img
              className="absolute h-full w-full object-cover"
              src={
                row.original?.featureImageUrl
                  ? row.original.featureImageUrl
                  : placeholder
              }
              alt={row.original.name}
            />
          </span>
        ),
        meta: {
          className: "text-center w-12",
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => (
          <Link to={`/inventory/products/${row.original.id}`} target="_blank">
            {row.original.name}
          </Link>
        ),
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <label
        htmlFor="description"
        className="mb-1 block text-sm font-medium text-gray-900"
      >
        {title}
      </label>
      <header className="grid grid-cols-1 gap-4 sm:grid-cols-4">
        <div className="relative col-span-3">
          <label htmlFor="search" className="sr-only">
            Search products
          </label>
          <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className={classNames(
              "relative block w-full appearance-none rounded-md border border-gray-300 py-2.5 pr-3 pl-10 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
              "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
            )}
            placeholder="Search products"
            value={query || ""}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <div className="col-span-1 flex">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={() => {
              setQuery("");
            }}
          >
            Browse
          </Button>
        </div>
      </header>
      <div className="mt-2">
        {loading ? (
          <div className="flex justify-center py-6 md:py-12">
            <Spinner className="h-8 w-8 text-primary-700" />
          </div>
        ) : selectedProducts.length > 0 ? (
          <div className="divide-y">
            {selectedProducts.map((product, index) => (
              <div key={product.id} className="flex items-center py-2 md:py-4">
                <span className="mr-4 text-sm text-gray-700">{index + 1}.</span>
                <div className="h-10 w-10 flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={
                      product.featureImageUrl
                        ? product.featureImageUrl
                        : placeholder
                    }
                    alt={product.name}
                  />
                </div>
                <div className="ml-4">
                  <Link
                    to={`/inventory/products/${product.id}`}
                    target="_blank"
                    className="text-sm font-medium text-gray-900"
                  >
                    {product.name}
                  </Link>
                </div>
                <div className="ml-auto">
                  {product.status === 2 ? null : product.status === 0 ? (
                    <span className="mr-4 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                      Inactive
                    </span>
                  ) : (
                    <span className="mr-4 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                      Draft
                    </span>
                  )}
                  <Button
                    variant="text"
                    onClick={() => {
                      const newSelectedProducts = selectedProducts.filter(
                        (selectedProduct) => selectedProduct.id !== product.id
                      );
                      setSelectedProducts(newSelectedProducts);
                      onChange(newSelectedProducts);
                      const newRowSelection = {
                        ...rowSelection,
                        [product.index]: false,
                      };
                      setRowSelection(newRowSelection);
                    }}
                  >
                    <span className="sr-only">Remove {product.name}</span>
                    <span
                      aria-hidden="true"
                      className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
                    />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="py-6 text-center text-sm md:py-12">
            <ExclamationCircleIcon
              type="outline"
              name="exclamation-circle"
              className="mx-auto h-6 w-6 text-gray-400"
            />
            <p className="mt-4 font-medium text-gray-900">
              No products selected
            </p>
            <p className="mt-2 text-gray-500">
              Please{" "}
              <Button
                variant="text"
                onClick={() => {
                  setQuery("");
                }}
              >
                browse
              </Button>{" "}
              products to add to the category.
            </p>
          </div>
        )}
      </div>
      <Transition.Root
        show={query === undefined ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setQuery(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <TableInfinite
                  data={products}
                  columns={columns}
                  loading={loading}
                  searchValue={query}
                  enableHeader={false}
                  enableRowSelection={true}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  selectionCancelAction={() => {
                    setQuery(undefined);
                  }}
                  selectionConfirmLabel={
                    selectedProducts.length ? "Done" : "Add"
                  }
                  selectionConfirmAction={(products) => {
                    setQuery(undefined);
                    setTimeout(() => {
                      setSelectedProducts(products);
                      onChange(products);
                    }, 300);
                  }}
                  className="p-4"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
