import { lazy, Suspense } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import { useAuth, isAuthorizedForResource } from "../auth";

import PageLayout from "../../layouts/PageLayout";
import {
  LayoutSplashScreen,
  NotFound,
  NotAuthorized,
} from "../../components/core";

import { DepartmentListResource } from "./departments/List";
import { CategoryListResource } from "./categories/List";
import { CategoryCreateResource } from "./categories/Create";
import { CategoryUpdateResource } from "./categories/Update";
import { LocationListResource } from "./locations/List";
import { LocationCreateResource } from "./locations/Create";
import { LocationUpdateResource } from "./locations/Update";
import { LocationTypeListResource } from "./location-types/List";
import { ProductListResource } from "./products/List";
import { ProductCreateResource } from "./products/Create";
import { ProductUpdateResource } from "./products/Update";
import { PricelistResource } from "./pricelist/Pricelist";
import { SettingsResource } from "./settings/Settings";

const DepartmentList = lazy(() => import("./departments/List"));
const CategoryList = lazy(() => import("./categories/List"));
const CategoryCreate = lazy(() => import("./categories/Create"));
const CategoryUpdate = lazy(() => import("./categories/Update"));
const LocationList = lazy(() => import("./locations/List"));
const LocationCreate = lazy(() => import("./locations/Create"));
const LocationUpdate = lazy(() => import("./locations/Update"));
const LocationTypesList = lazy(() => import("./location-types/List"));
const ProductList = lazy(() => import("./products/List"));
const ProductCreate = lazy(() => import("./products/Create"));
const ProductUpdate = lazy(() => import("./products/Update"));
const Pricelist = lazy(() => import("./pricelist/Pricelist"));
const Settings = lazy(() => import("./settings/Settings"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
];

export function InventoryPage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={DepartmentListResource.path} replace={true} />,
        },
        {
          path: DepartmentListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DepartmentListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DepartmentList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DepartmentListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CategoryListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CategoryListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CategoryList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CategoryListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CategoryCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CategoryCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CategoryCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CategoryCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CategoryUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CategoryUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CategoryUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CategoryUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: LocationListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            LocationListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <LocationList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(LocationListResource.access.join(", "))}
            />
          ),
        },
        {
          path: LocationCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            LocationCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <LocationCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(LocationCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: LocationUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            LocationUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <LocationUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(LocationUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: LocationTypeListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            LocationTypeListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <LocationTypesList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(LocationTypeListResource.access.join(", "))}
            />
          ),
        },
        {
          path: ProductListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            ProductListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <ProductList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(ProductListResource.access.join(", "))}
            />
          ),
        },
        {
          path: ProductCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            ProductCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <ProductCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(ProductCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: ProductUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            ProductUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <ProductUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(ProductUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricelistResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricelistResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Pricelist breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricelistResource.access.join(", "))}
            />
          ),
        },
        {
          path: SettingsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SettingsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Settings breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SettingsResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
