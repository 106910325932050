import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { classNames } from "../../utils";

interface FieldProps {
  title: string;
  name: string;
  id?: string;
  className?: string;
  placeholder?: string;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  errors?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  label?: boolean;
  onChange: (value: string) => void;
  value: string;
  readOnly?: boolean;
}

const states = [
  {
    label: "New South Wales",
    value: "NSW",
  },
  {
    label: "Victoria",
    value: "VIC",
  },
  {
    label: "Queensland",
    value: "QLD",
  },
  {
    label: "Tasmania",
    value: "TAS",
  },
  {
    label: "South Australia",
    value: "SA",
  },
  {
    label: "Western Australia",
    value: "WA",
  },
  {
    label: "Northern Territory",
    value: "NT",
  },
  {
    label: "Australian Capital Territory",
    value: "ACT",
  },
];

export function FieldState(props: FieldProps) {
  const {
    title,
    name,
    value: initialValue,
    onChange,
    readOnly = false,
    touched,
    errors,
    label = true,
    id,
    className,
    placeholder,
    ...rest
  } = props;

  const [selectedState, setSelectedState] = useState<{
    label: string;
    value: string;
  }>();
  const [query, setQuery] = useState("");

  const filteredState =
    query === ""
      ? states
      : states.filter((state) => {
          return (
            state.label.toLowerCase().includes(query.toLowerCase()) ||
            state.value.toLowerCase().includes(query.toLowerCase())
          );
        });

  const handleSelect = (state: { label: string; value: string }) => {
    setSelectedState(state);
    setQuery(state.label);
    onChange && onChange(state.value);
  };

  useEffect(() => {
    if (initialValue) {
      const state = states.find((state) => state.value === initialValue);
      if (state) {
        setSelectedState(state);
        setQuery(state.label);
      }
    }
  }, [initialValue]);

  return (
    <Combobox as="div" value={selectedState} onChange={handleSelect}>
      <Combobox.Label
        htmlFor={id ? id : name}
        className={`block text-sm font-medium text-gray-900 ${
          label ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className={classNames(
            "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
            "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
            "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
          )}
          readOnly={readOnly}
          placeholder={placeholder ? placeholder : label ? "" : title}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(value: { label: string; value: string }) =>
            value?.value ?? ""
          }
          {...rest}
        />
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredState.map((state) => (
            <Combobox.Option
              key={state.value}
              value={state}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                  active ? "bg-primary-600 text-white" : "text-gray-900"
                )
              }
            >
              {({ active, selected }) => (
                <span
                  className={classNames(
                    "ml-3 block",
                    selected ? "font-medium" : ""
                  )}
                >
                  {state.label}
                </span>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
