import {
  Routes,
  Route,
  Navigate,
  Outlet,
  RouteObject,
  useRoutes,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import {
  // Register,
  Login,
  Forgot,
  Reset,
  Create,
  Locked,
  useAuth,
  ChallengePage,
} from ".";
import { NotFound } from "../../components/core";

import { classNames } from "../../utils";

const GlobalStyle = createGlobalStyle`
html {
  background-color: #f7f7f7;
}
`;
const Box = styled.div`
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.025);
  &:hover {
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.05);
  }
`;

function Layout() {
  // useEffect(() => {
  //   document.documentElement.classList.add("h-full", "bg-gray-50");
  //   document.body.classList.add("h-full");
  //   const appRoot = document.getElementById("root-app")!;
  //   appRoot && appRoot.classList.add("h-full");
  //   return () => {
  //     document.documentElement.classList.remove("h-full", "bg-gray-50");
  //     document.body.classList.remove("h-full");
  //     appRoot && appRoot.classList.add("h-full");
  //   };
  // }, []);

  return (
    <>
      <GlobalStyle />
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <Box className={classNames("rounded-xl bg-gray-100 p-2.5")}>
            <div className="rounded-xl bg-white px-4 py-12">
              <Outlet />
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}

export function AuthPage() {
  const { isMfaEnabled, isLocked, refresh, currentUser } = useAuth();

  let elements =
    currentUser && isLocked
      ? [
          {
            index: true,
            element: <Navigate to="locked" />,
          },
          {
            path: "locked",
            element: <Locked />,
          },
          {
            path: "*",
            element: <Navigate to="locked" />,
          },
        ]
      : currentUser && isMfaEnabled && !refresh
      ? [
          {
            index: true,
            element: <Navigate to="challenge/totp" />,
          },
          {
            path: "challenge/*",
            element: <ChallengePage />,
          },
          {
            path: "*",
            element: <Navigate to="challenge/totp" />,
          },
        ]
      : [
          {
            index: true,
            element: <Navigate to="login" />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "forgot-password",
            element: <Forgot />,
          },
          {
            path: "reset-password",
            element: <Reset />,
          },
          {
            path: "create-password",
            element: <Create />,
          },
        ];

  let routes: RouteObject[] = [
    {
      element: <Layout />,
      children: [...elements],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
