import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { Head } from "../../../components/core";

import { Spinner } from "../../../animations";

import Form from "./components/Form";

const FETCH_STOREHOME = gql`
  query FetchStoreHome {
    fetchStoreHome {
      id
      bannerImageUrl
      title
      caption
      button
      categories {
        id
        name
      }
    }
  }
`;

const CREATE_STOREHOME = gql`
  mutation HomePageCreate(
    $title: String!
    $caption: String!
    $button: [JSON!]
    $bannerImageUrl: String
    $categoryIds: [Int!]
  ) {
    homePageCreate(
      input: {
        params: {
          title: $title
          caption: $caption
          button: $button
          bannerImageUrl: $bannerImageUrl
          categoryIds: $categoryIds
        }
      }
    ) {
      home {
        id
        title
      }
    }
  }
`;

const UPDATE_STOREHOME = gql`
  mutation HomePageUpdate(
    $id: ID!
    $title: String!
    $caption: String!
    $button: [JSON!]
    $bannerImageUrl: String
    $categoryIds: [Int!]
  ) {
    homePageUpdate(
      input: {
        id: $id
        params: {
          title: $title
          caption: $caption
          button: $button
          bannerImageUrl: $bannerImageUrl
          categoryIds: $categoryIds
        }
      }
    ) {
      home {
        id
        title
      }
    }
  }
`;

type HomeOptions = {
  id?: string;
  title: string;
  caption: string;
  button: {
    title: string;
    link: string;
  }[];
  bannerImageUrl: string;
  categoryIds: number[];
};
export default function HomePage({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const [homeOptions, setHomeOptions] = useState<HomeOptions>();
  const [fetchStoreHome, { loading }] = useLazyQuery(FETCH_STOREHOME);
  const [createHomePage] = useMutation(CREATE_STOREHOME);
  const [updateHomePage] = useMutation(UPDATE_STOREHOME);

  const didFetchStoreHome = useRef(false);

  const fetchingStoreHome = useCallback(() => {
    fetchStoreHome()
      .then(({ data, error }) => {
        if (data?.fetchStoreHome) {
          const newHomePage = data.fetchStoreHome;
          const updateHomePage = {
            id: newHomePage.id,
            title: newHomePage.title,
            caption: newHomePage.caption,
            button: newHomePage.button,
            bannerImageUrl: newHomePage.bannerImageUrl,
            categoryIds: newHomePage.categories.map(
              (category: { id: string }) => parseInt(category.id)
            ),
          };
          setHomeOptions(updateHomePage);
        }
        console.log(data);
        console.log(error);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchStoreHome]);

  useEffect(() => {
    if (!didFetchStoreHome.current) {
      fetchingStoreHome();
      didFetchStoreHome.current = true;
    }
  }, [didFetchStoreHome, fetchingStoreHome]);

  const handleCreate = (
    values: {
      title: string;
      caption: string;
      button: { title: string; link: string }[];
      bannerImageUrl: string;
      categoryIds: number[];
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createHomePage({
      variables: {
        title: values.title,
        caption: values.caption,
        button: values.button,
        bannerImageUrl: values.bannerImageUrl,
        categoryIds: values.categoryIds,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.homePageCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Home Settings updated",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Home Settings update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Home Settings update failed",
          message: error.message,
        });
      });
  };

  const handleUpdate = (
    values: {
      title: string;
      caption: string;
      button: { title: string; link: string }[];
      bannerImageUrl: string;
      categoryIds: number[];
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!homeOptions) return;

    updateHomePage({
      variables: {
        id: homeOptions.id,
        title: values.title,
        caption: values.caption,
        button: values.button,
        bannerImageUrl: values.bannerImageUrl,
        categoryIds: values.categoryIds,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.homePageUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Home Settings updated",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Home Settings update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Home Settings update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Home Settings"
        heading="Home Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: HomeResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          {loading ? (
            <Spinner />
          ) : homeOptions && Object.keys(homeOptions).length > 0 ? (
            <Form
              initialValues={{
                title: homeOptions?.title || "",
                caption: homeOptions?.caption || "",
                button:
                  homeOptions?.button.map(
                    (b: { title: string; link: string }) => ({
                      title: b.title,
                      link: b.link,
                    })
                  ) || [],
                bannerImageUrl: homeOptions?.bannerImageUrl || "",
                categoryIds: homeOptions?.categoryIds || [],
              }}
              onSubmit={handleUpdate}
              actionLabel={t("text_update")}
            />
          ) : (
            <Form
              initialValues={{
                title: "",
                caption: "",
                button: [
                  {
                    title: "",
                    link: "",
                  },
                ],
                bannerImageUrl: "",
                categoryIds: [],
              }}
              onSubmit={handleCreate}
              actionLabel={t("text_create")}
            />
          )}
        </div>
      </div>
    </>
  );
}

export const HomeResource: ResourceProps = {
  name: "Home Settings",
  description: "Home page settings",
  access: ["read-home"],
  path: "home",
};
