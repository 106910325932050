import React from "react";
import { Spinner } from "../../animations";
import { classNames } from "../../utils";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "tertiary" | "text" | "icon";
  type?: "button" | "submit" | "reset";
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const {
    className,
    children,
    type = "button",
    variant,
    loading,
    ...rest
  } = props;

  const customClass = className ? className : "justify-center";
  const render = loading ? <Spinner /> : children;

  switch (variant) {
    case "secondary":
      return (
        <button
          className={classNames(
            "inline-flex items-center rounded-md border border-gray-300 bg-white px-5 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40",
            customClass
          )}
          type={type}
          {...rest}
        >
          {render}
        </button>
      );
    case "tertiary":
      return (
        <button
          className={classNames(
            "inline-flex items-center rounded-md border border-gray-300 bg-white px-5 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40",
            customClass
          )}
          type={type}
          {...rest}
        >
          {render}
        </button>
      );
    case "text":
      return (
        <button
          className={classNames(
            "inline-flex items-center rounded-md border-0 bg-transparent px-0 py-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40",
            customClass
          )}
          type={type}
          {...rest}
        >
          {render}
        </button>
      );
    case "icon":
      return (
        <button
          className={classNames(
            "inline-flex items-center border-0 bg-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40",
            customClass
          )}
          type={type}
          {...rest}
        >
          {render}
        </button>
      );

    default:
      return (
        <button
          className={classNames(
            "inline-flex items-center rounded-md border border-transparent bg-primary-600 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40",
            customClass
          )}
          type={type}
          {...rest}
        >
          {render}
        </button>
      );
  }
};
