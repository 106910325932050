import React from "react";
import { classNames } from "../../utils";

export const IndeterminateCheckbox = ({
    indeterminate,
    className = "",
    ...rest
  }: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) => {
    const ref = React.useRef<HTMLInputElement>(null!);
  
    React.useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);
  
    return (
      <input
        type="checkbox"
        ref={ref}
        className={classNames(
          "relative my-1 h-4 w-4 rounded border border-gray-400 text-primary-600 shadow-sm sm:text-sm",
          "cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
          "disabled:cursor-not-allowed disabled:border-gray-200 disabled:ring-primary-50",
          "checked:border-primary-500 checked:ring-primary-500",
          className ?? ""
        )}
        {...rest}
      />
    );
  };
  