import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../components/form";
import { Head } from "../../../components/core";

import { classNames } from "../../../utils";
import placeholder from "../../../assets/placeholder.svg";

const FETCH_PRODUCTS = gql`
  query FetchProducts($customerId: Int!) {
    fetchProducts {
      id
      name
      featureImageUrl
      variants {
        id
        stockCode
        variantTitle {
          name
          option {
            name
          }
        }
        customerBasePrice(customerId: $customerId)
        customerSpecialPrice(customerId: $customerId)
      }
      status
    }
  }
`;

type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: {
    id: string;
    stockCode: string;
    variantTitle: {
      name: string;
      option: {
        name: string;
      };
    }[];
    customerBasePrice: number;
    customerSpecialPrice: number;
    status: string;
  }[];
};
const CustomerProducts = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [fetchProducts] = useLazyQuery(FETCH_PRODUCTS);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (!customerId) return;

    fetchProducts({
      variables: {
        customerId: parseInt(customerId),
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchProducts) {
          console.log(data.fetchProducts);
          setProducts(data.fetchProducts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts]);

  const handleCancel = () => {
    return navigate(`/sales/customers/${customerId}`);
  };
  return (
    <>
      <Head
        title="Customer Pricing"
        heading="Customer Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: "Customer Pricing",
            href: `/sales/customers/${customerId}`,
          },
          {
            name: CustomerProductsResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {CustomerProductsResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {CustomerProductsResource.description}
            </p>
          </div>
        </div>
        <div className="mt-4 space-y-2">
          <div className="relative overflow-hidden overflow-x-auto border-gray-200 md:mt-4 md:rounded-lg md:border md:bg-white">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th
                    className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-medium text-gray-900"
                    scope="col"
                  >
                    <span className="lg:pl-2">Product</span>
                  </th>
                  <th
                    className="whitespace-nowrap border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-medium text-gray-900"
                    scope="col"
                  >
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="whitespace-nowrap px-6" scope="col">
                            Variant Name
                          </th>
                          <th className="whitespace-nowrap px-6" scope="col">
                            Stock Code
                          </th>
                          <th className="whitespace-nowrap px-6" scope="col">
                            Sell Price
                          </th>
                          <th
                            className="whitespace-nowrap px-6 text-right"
                            scope="col"
                          >
                            Special Price
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 bg-white">
                {products.map((product) => (
                  <tr key={product.id}>
                    <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
                      <div className="flex items-center space-x-3 lg:pl-2">
                        {product.variants
                          .flatMap((variant) => variant.customerSpecialPrice)
                          .some((price) => price > 0) ? (
                          <div
                            className={classNames(
                              "h-2.5 w-2.5 flex-shrink-0 rounded-full",
                              "bg-red-700"
                            )}
                            aria-hidden="true"
                          />
                        ) : product.variants
                            .flatMap((variant) => variant.customerBasePrice)
                            .some((price) => price === 0) ? (
                          <div
                            className={classNames(
                              "h-2.5 w-2.5 flex-shrink-0 rounded-full",
                              "bg-yellow-400"
                            )}
                            aria-hidden="true"
                          />
                        ) : (
                          <div
                            className={classNames(
                              "h-2.5 w-2.5 flex-shrink-0 rounded-full",
                              "bg-transparent"
                            )}
                            aria-hidden="true"
                          />
                        )}
                        <Link
                          to={`/inventory/products/${product.id}`}
                          className="group block flex-shrink-0"
                        >
                          <div className="flex items-center">
                            <div>
                              <img
                                className="inline-block h-9 w-9 rounded-full"
                                src={
                                  product.featureImageUrl
                                    ? product.featureImageUrl
                                    : placeholder
                                }
                                alt={product.name}
                              />
                            </div>
                            <div className="ml-3">
                              <p className="truncate text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                {product.name}
                              </p>
                              <p className="text-xs font-normal text-gray-500 group-hover:text-gray-700">
                                View Product
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </td>
                    <td className="px-6 py-3 text-sm text-gray-700">
                      <table className="w-full rounded-md bg-gray-100">
                        <tbody>
                          {product.variants.map((variant) => (
                            <tr
                              key={`${product.id}-${variant.id}`}
                              className="py-1"
                            >
                              <td className="min-w-[6rem] border-b border-white px-1 py-1 text-sm">
                                <div className="w-full rounded-md bg-white px-3 py-2 text-sm lowercase">
                                  {variant.variantTitle
                                    ? variant.variantTitle.length > 0
                                      ? variant.variantTitle
                                          .map((title) => `${title.option.name} - ${title.name}`)
                                          .join(" / ")
                                      : "default"
                                    : "default"}
                                </div>
                              </td>
                              <td className="min-w-[6rem] border-b border-white px-1 py-1 text-sm">
                                <div className="w-full rounded-md bg-white px-3 py-2 uppercase">
                                  {variant.stockCode}
                                </div>
                              </td>
                              <td className="border-b border-white px-6 py-3 text-sm">
                                {variant.customerSpecialPrice > 0 ? (
                                  <s className="text-red-700">
                                    AUD ${variant.customerBasePrice}
                                  </s>
                                ) : (
                                  <span>AUD ${variant.customerBasePrice}</span>
                                )}
                              </td>
                              <td className="border-b border-white px-6 py-3 text-right text-sm font-medium">
                                {variant.customerSpecialPrice > 0 ? (
                                  <span>
                                    AUD ${variant.customerSpecialPrice}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4 flex flex-wrap justify-end space-x-4">
          <Button
            variant="secondary"
            className="min-w-[10rem] justify-center"
            onClick={handleCancel}
          >
            {t("text_back")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CustomerProducts;
export const CustomerProductsResource: ResourceProps = {
  name: "Customer Product Pricing",
  description: "View product list for customer",
  access: ["read-productscustomers"],
  path: "customers/:customerId/products",
};
