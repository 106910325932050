import { useEffect, useMemo, useState, Fragment } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../../components/table";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Form from "./components/Form";

import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";

import { Button } from "../../../../components/form";
import { AuthShield } from "../../../auth/core";
import { Head } from "../../../../components/core";

type Dock = {
  id: string;
  name: string;
};

type DeliveryRun = {
  id?: string;
  name: string;
  dock: Dock;
  createdAt: string;
  status: boolean;
};

const FETCH_DELIVERYRUNS = gql`
  query FetchDeliveryRuns {
    fetchDeliveryRuns {
      id
      name
      dock {
        id
        name
      }
      createdAt
      status
    }
  }
`;

const DeliveryRunList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchDeliveryRuns, { loading }] = useLazyQuery(FETCH_DELIVERYRUNS);
  const [deliveryRuns, setDeliveryRuns] = useState<DeliveryRun[]>([]);
  const [activeDeliveryRun, setActiveDeliveryRun] = useState<
    DeliveryRun | undefined
  >(undefined);
  const [newDeliveryRun, setNewDeliveryRun] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    fetchDeliveryRuns()
      .then(({ data, error }) => {
        if (data?.fetchDeliveryRuns) {
          const updatedDeliveryRuns = data.fetchDeliveryRuns.map(
            (item: DeliveryRun) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setDeliveryRuns(updatedDeliveryRuns);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Delivery Runs read delivery run(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeliveryRuns]);

  const UPDATE_DELIVERYRUN = gql`
    mutation DeliveryRunUpdate(
      $id: ID!
      $name: String!
      $dockId: Int!
      $status: Boolean!
    ) {
      deliveryRunUpdate(
        input: {
          id: $id
          params: { name: $name, dockId: $dockId, status: $status }
        }
      ) {
        deliveryRun {
          id
          name
          dock {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [updateDeliveryRun] = useMutation(UPDATE_DELIVERYRUN);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateDeliveryRun({
      variables: {
        id: activeDeliveryRun?.id,
        name: values.name,
        dockId: values.dock ? parseInt(values.dock.value) : null,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.deliveryRunUpdate) {
          const newDeliveryRuns = [...deliveryRuns];
          const index = newDeliveryRuns.findIndex(
            (deliveryRun) => deliveryRun.id === activeDeliveryRun?.id
          );
          newDeliveryRuns[index] = {
            ...data.deliveryRunUpdate.deliveryRun,
            createdAt: DateTime.fromISO(
              data.deliveryRunUpdate.deliveryRun.createdAt,
              {
                locale: i18n.language,
              }
            ).toLocaleString(DateTime.DATE_MED),
          };
          setDeliveryRuns(newDeliveryRuns);
          setActiveDeliveryRun(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Delivery Run update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Delivery Run update failed",
          message: error.message,
        });
      });
  };

  const CREATE_DELIVERYRUN = gql`
    mutation DeliveryRunCreate(
      $name: String!
      $dockId: Int!
      $status: Boolean!
    ) {
      deliveryRunCreate(
        input: { params: { name: $name, dockId: $dockId, status: $status } }
      ) {
        deliveryRun {
          id
          name
          dock {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [createDeliveryRun] = useMutation(CREATE_DELIVERYRUN);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createDeliveryRun({
      variables: {
        name: values.name,
        dockId: values.dock ? parseInt(values.dock.value) : null,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.deliveryRunCreate) {
          setDeliveryRuns([
            ...deliveryRuns,
            {
              ...data.deliveryRunCreate?.deliveryRun,
              createdAt: DateTime.fromISO(
                data.deliveryRunCreate.deliveryRun.createdAt,
                {
                  locale: i18n.language,
                }
              ).toLocaleString(DateTime.DATE_MED),
            },
          ]);
          setNewDeliveryRun(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Delivery Run created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Delivery Run create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Delivery Run create failed",
          message: error.message,
        });
      });
  };

  const DELETE_DELIVERYRUN = gql`
    mutation DeliveryRunDelete($id: ID!) {
      deliveryRunDelete(input: { id: $id }) {
        deliveryRun {
          id
          name
        }
      }
    }
  `;
  const [deleteDeliveryRun] = useMutation(DELETE_DELIVERYRUN);
  const handleDelete = async (id: string) => {
    deleteDeliveryRun({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.deliveryRunDelete) {
          const deletedDeliveryRun = data.deliveryRunDelete?.deliveryRun;
          const newDeliveryRuns = [...deliveryRuns];
          const index = newDeliveryRuns.findIndex(
            (deliveryRun) => deliveryRun.id === id
          );
          newDeliveryRuns.splice(index, 1);
          setDeliveryRuns(newDeliveryRuns);

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Delivery Run ${deletedDeliveryRun.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Delivery Run deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Delivery Run deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<DeliveryRun>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "dock",
        header: "Dock",
        cell: ({ row }) => {
          return row.original.dock?.name;
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-deliveryRuns"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => setActiveDeliveryRun(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-deliveryRuns"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Delivery Runs"
        heading="Delivery Runs"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: DeliveryRunListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Delivery Runs List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the delivery runs in your account including their
              name, title, email and delivery run.
            </p>
          </div>
        </div>

        <TablePaginate
          data={deliveryRuns}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-deliveryRuns"]}>
              <Button onClick={() => setNewDeliveryRun(true)}>
                Add delivery run
              </Button>
            </AuthShield>
          )}
        />
      </div>

      <Transition.Root
        show={activeDeliveryRun ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveDeliveryRun(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Delivery Run - "
                  initialValues={{
                    id: activeDeliveryRun?.id,
                    name: activeDeliveryRun?.name ?? "",
                    dock: activeDeliveryRun?.dock
                      ? {
                          label: activeDeliveryRun?.dock.name,
                          value: activeDeliveryRun?.dock.id,
                        }
                      : null,
                    status: activeDeliveryRun?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveDeliveryRun(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newDeliveryRun ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewDeliveryRun(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Delivery Run - "
                  initialValues={{
                    name: "",
                    dock: null,
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewDeliveryRun(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeliveryRunList;
export const DeliveryRunListResource: ResourceProps = {
  name: "Delivery Runs List",
  description: "A list of delivery runs",
  access: ["read-deliveryRuns"],
  path: "delivery-runs",
};
