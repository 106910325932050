import { useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import { nanoid } from "nanoid";

import { LifebuoyIcon } from "@heroicons/react/24/outline";

import { IFormula, IFieldType } from "./formulabuilder/types";

import { SortableItem } from "./formulabuilder/SortableItem";

import { classNames } from "../../utils";
import { Button } from "./";

import Integer from "./formulabuilder/Integer";

export interface IFormulaProps {
  name: string;
  type: IFieldType;
}

export function FieldFormula({
  variants,
  values,
  onChange,
}: {
  variants: string[];
  values: string[];
  onChange: (values: string[]) => void;
}) {
  const [items, setItems] = useState<IFormula[]>([]);

  const symbols: IFormula[] = [
    {
      id: nanoid(11),
      name: "+",
      type: IFieldType.symbol,
      color: "red",
      description: "Addition",
    },
    {
      id: nanoid(11),
      name: "-",
      type: IFieldType.symbol,
      color: "violet",
      description: "Subtraction",
    },
    {
      id: nanoid(11),
      name: "*",
      type: IFieldType.symbol,
      color: "green",
      description: "Multiplication",
    },
    {
      id: nanoid(11),
      name: "/",
      type: IFieldType.symbol,
      color: "blue",
      description: "Division",
    },
    {
      id: nanoid(11),
      name: "%",
      type: IFieldType.symbol,
      color: "purple",
      description: "Modulo",
    },
    {
      id: nanoid(11),
      name: "(",
      type: IFieldType.symbol,
      color: "orange",
      description: "Left parenthesis",
    },
    {
      id: nanoid(11),
      name: ")",
      type: IFieldType.symbol,
      color: "orange",
      description: "Right parenthesis",
    },
  ];
  const [variables, setVariables] = useState<IFormula[]>([]);

  const [openNum, setOpenNum] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (variants.length) {
      setVariables([
        ...symbols,
        ...variants.map((v) => {
          const itemOrg = variables.find((i) => i.name === v) || {
            type: IFieldType.variable,
            color: "light-green",
            description: "Variable",
          };
          return {
            ...itemOrg,
            id: nanoid(),
            name: v,
          };
        }),
      ]);
    } else {
      setVariables(symbols);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  useEffect(() => {
    if (values.length) {
      setItems([
        ...values.map((v) => {
          const itemOrg = variables.find((i) => i.name === v) || {
            type: IFieldType.symbol,
            color: "light-green",
            description: "Variable",
          };

          return {
            ...itemOrg,
            id: nanoid(),
            name: v,
          };
        }),
      ]);
    } else {
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, variants]);

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;

    if (active.id !== over.id) {
      let newItems: IFormula[];

      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      newItems = arrayMove(items, oldIndex, newIndex);

      setItems(newItems);
      onChange(newItems.map((i) => i.name));
    }
  }

  function handleSymbolVariable(item: IFormula) {
    let newItems: IFormula[];
    const newItem = {
      ...item,
      id: nanoid(11),
    };
    newItems = [...items, newItem];
    setItems(newItems);
    onChange(newItems.map((i) => i.name));
  }

  function deleteItem(id: UniqueIdentifier | string) {
    let newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
    onChange(newItems.map((i) => i.name));
  }

  return (
    <div className="relative">
      <div
        className={classNames(
          "relative isolate z-10 inline-flex w-full rounded-md"
        )}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <div className="relative inline-flex min-h-[3rem] w-full flex-wrap items-center space-x-0.5 rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 md:min-h-[4rem] md:py-4">
            <SortableContext
              items={items.map((item) => item.id)}
              strategy={rectSortingStrategy}
            >
              {items.map((item) => (
                <SortableItem key={item.id} {...item} deleteItem={deleteItem} />
              ))}
            </SortableContext>
          </div>
        </DndContext>
        <Popover className="relative flex">
          <Popover.Button className="relative -ml-px inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus-visible:outline-none">
            <LifebuoyIcon
              className="h-5 w-5 text-gray-700"
              aria-hidden="true"
            />
            <span className="sr-only">
              Help with <span className="font-medium">Formula</span>
            </span>
          </Popover.Button>
          <Popover.Panel className="absolute top-full right-5 z-10 mt-2 w-72 rounded-md bg-primary-300 p-2.5 text-xs font-normal text-black">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 95 95"
              enable-background="new 0 0 95 95"
              className="absolute -top-2.5 right-0 h-5 w-5 rotate-1 text-primary-300"
              aria-hidden="true"
            >
              <polygon fill="currentColor" points="95,95 0,95 95,0" />
            </svg>
            Click to add or remove a variable/symbol to the formula
          </Popover.Panel>
        </Popover>
      </div>
      <div className="mt-2 space-y-2">
        {variables.map((variable) => (
          <Button
            key={variable.id}
            variant="icon"
            className={classNames(
              "text-md rounded-md mr-2",
              variable.type === IFieldType.variable
                ? "bg-primary-100 py-1 px-2"
                : "flex h-8 w-8 items-center justify-center bg-blue-50"
            )}
            style={{ color: variable.color }}
            onClick={() => handleSymbolVariable(variable)}
          >
            <div className="scale-90">{variable.name}</div>
          </Button>
        ))}
        <Button
          variant="icon"
          className={classNames("text-md rounded-md bg-violet-100 py-1 px-2 mr-2")}
          onClick={() => {
            setOpenNum(true);
          }}
        >
          <div className="scale-90">add number</div>
        </Button>

        <Integer
          openNum={openNum}
          setOpenNum={setOpenNum}
          onChange={(value) => {
            setItems([
              ...items,
              {
                id: nanoid(),
                name: value,
                type: IFieldType.number,
                color: "#673ab7",
                description: `Number ${value}`,
              },
            ]);
          }}
        />
      </div>
    </div>
  );
}
