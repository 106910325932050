import { useEffect, useMemo, useState, Fragment } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../components/table";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Form from "./components/Form";

import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type LocationType = {
  id?: string;
  name: string;
  description: string;
  createdAt: string;
  status: boolean;
};

const FETCH_LOCATION_TYPES = gql`
  query FetchBinLocationTypes {
    fetchBinLocationTypes {
      id
      name
      createdAt
      status
    }
  }
`;

const LocationTypeList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchBinLocationTypes, { loading }] =
    useLazyQuery(FETCH_LOCATION_TYPES);
  const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);
  const [activeLocationType, setActiveLocationType] = useState<
    LocationType | undefined
  >(undefined);
  const [newLocationType, setNewLocationType] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    fetchBinLocationTypes()
      .then(({ data, error }) => {
        if (data?.fetchBinLocationTypes) {
          const updatedBinLocationTypes = data.fetchBinLocationTypes.map(
            (item: LocationType) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setLocationTypes(updatedBinLocationTypes);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "LocationTypes read locationType(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBinLocationTypes]);

  const UPDATE_TYPE = gql`
    mutation BinLocationTypeUpdate(
      $id: ID!
      $name: String!
      $description: String
      $status: Boolean!
    ) {
      binLocationTypeUpdate(
        input: {
          id: $id
          params: { name: $name, description: $description, status: $status }
        }
      ) {
        type {
          id
          name
          createdAt
          status
        }
      }
    }
  `;
  const [updateLocationType] = useMutation(UPDATE_TYPE);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateLocationType({
      variables: {
        id: activeLocationType?.id,
        name: values.name,
        description: "",
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.binLocationTypeUpdate) {
          const newLocationTypes = [...locationTypes];
          const index = newLocationTypes.findIndex(
            (locationType) => locationType.id === activeLocationType?.id
          );
          newLocationTypes[index] = {
            ...data.binLocationTypeUpdate.type,
            createdAt: DateTime.fromISO(
              data.binLocationTypeUpdate.type.createdAt,
              {
                locale: i18n.language,
              }
            ).toLocaleString(DateTime.DATE_MED),
          };
          setLocationTypes(newLocationTypes);
          setActiveLocationType(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location Type update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Location Type update failed",
          message: error.message,
        });
      });
  };

  const CREATE_TYPE = gql`
    mutation BinLocationTypeCreate(
      $name: String!
      $description: String
      $status: Boolean!
    ) {
      binLocationTypeCreate(
        input: {
          params: { name: $name, description: $description, status: $status }
        }
      ) {
        type {
          id
          name
          createdAt
          status
        }
      }
    }
  `;
  const [createLocationType] = useMutation(CREATE_TYPE);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createLocationType({
      variables: {
        name: values.name,
        description: "",
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.binLocationTypeCreate) {
          setLocationTypes([
            ...locationTypes,
            {
              ...data.binLocationTypeCreate?.type,
              createdAt: DateTime.fromISO(
                data.binLocationTypeCreate.type.createdAt,
                {
                  locale: i18n.language,
                }
              ).toLocaleString(DateTime.DATE_MED),
            },
          ]);
          setNewLocationType(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Location Type created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location Type create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Location Type create failed",
          message: error.message,
        });
      });
  };

  const DELETE_TYPE = gql`
    mutation BinLocationTypeDelete($id: ID!) {
      binLocationTypeDelete(input: { id: $id }) {
        type {
          id
          name
        }
      }
    }
  `;
  const [deleteLocationType] = useMutation(DELETE_TYPE);
  const handleDelete = async (id: string) => {
    deleteLocationType({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.binLocationTypeDelete) {
          const deletedLocationType = data.binLocationTypeDelete?.type;
          const newLocationTypes = [...locationTypes];
          const index = newLocationTypes.findIndex(
            (locationType) => locationType.id === id
          );
          newLocationTypes.splice(index, 1);
          setLocationTypes(newLocationTypes);

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Location Type ${deletedLocationType.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location Type deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Location Type deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<LocationType>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-binlocationtypes"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => setActiveLocationType(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-binlocationtypes"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Location Types"
        heading="Location Types"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: LocationTypeListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Location Types List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the locationTypes in your account including their
              name, title, email and locationType.
            </p>
          </div>
        </div>

        <TablePaginate
          data={locationTypes}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-binlocationtypes"]}>
              <Button onClick={() => setNewLocationType(true)}>
                Add locationType
              </Button>
            </AuthShield>
          )}
        />
      </div>

      <Transition.Root
        show={activeLocationType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveLocationType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Location Type - "
                  initialValues={{
                    id: activeLocationType?.id,
                    name: activeLocationType?.name ?? "",
                    status: activeLocationType?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveLocationType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newLocationType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewLocationType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Location Type - "
                  initialValues={{
                    name: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewLocationType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default LocationTypeList;
export const LocationTypeListResource: ResourceProps = {
  name: "LocationTypes List",
  description: "A list of location types",
  access: ["read-binlocationtypes"],
  path: "location-types",
};
