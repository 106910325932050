import { useEffect, useState, Fragment, useMemo, useCallback } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { FormikProps, useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select, {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
} from "react-select";
import { useTranslation } from "react-i18next";
import { Switch, RadioGroup } from "@headlessui/react";

import {
  Button,
  Field,
  FieldEditor,
  FieldProducts,
  FieldUploader,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";

import { Spinner } from "../../../../animations";
import { classNames, toNestedOptions } from "../../../../utils";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ProductList } from "../../../../components/product";
import { PlusIcon } from "@heroicons/react/24/solid";

type Category = {
  id?: number;
  name: string;
  description: string;
  parent: SingleValue<OptionProps>;
  productsIds: number[];
  imageUrl: string;
  categoryType: number;
  conditionType: number;
  conditions: Condition[];
  status: boolean;
};

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: Category;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    description: Yup.string(),
    parent: Yup.object().nullable(),
    productsIds: Yup.array().of(Yup.number()),
    imageUrl: Yup.string().nullable(),
    categoryType: Yup.number().nullable(),
    conditionType: Yup.number().nullable(),
    conditions: Yup.array().of(Yup.object()),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: CategorySchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_category_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={touched.name}
            errors={errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_category_parent")}
          </label>
          <FieldCategories
            value={formik.values.parent}
            onChange={(value: SingleValue<OptionProps>) => {
              formik.setFieldValue("parent", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.parent && formik.errors.parent
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.parent && formik.errors.parent ? (
            <p className="mt-2 text-sm text-red-600" id="roles-errors">
              {formik.errors.parent.toString()}
            </p>
          ) : null}
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <FieldEditor
            title={t("text_description")}
            name="description"
            onChange={(content: any, editor: any) => {
              formik.setFieldValue("description", content);
            }}
            value={formik.values.description}
            touched={formik.touched.description}
            errors={formik.errors.description}
          />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <FieldUploader
            title={t("text_image")}
            name="imageUrl"
            directory="inventories/categories"
            onChange={(value: any) => {
              formik.setFieldValue("imageUrl", value);
            }}
            value={formik.values.imageUrl}
            touched={touched.imageUrl}
            errors={errors.imageUrl}
          />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <FieldGroupProducts formik={formik} />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              {t("text_status")}
            </legend>
            <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
              <Switch
                checked={formik.values.status}
                onChange={() => {
                  formik.setFieldValue("status", !formik.values.status);
                }}
                id="status"
                className={classNames(
                  formik.values.status ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formik.values.status ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor="status"
                className="ml-2 mb-0 block text-sm font-normal text-gray-700"
              >
                {formik.values.status ? t("text_active") : t("text_inactive")}
              </Switch.Label>
            </Switch.Group>
          </fieldset>
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/inventory/categories" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit">
          {isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}

const FETCH_CATEGORIES = gql`
  query FetchCategories($status: Boolean) {
    fetchCategories(status: $status) {
      id
      name
      description
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export function FieldCategories({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  let { categoryId } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<
    OptionsOrGroups<OptionProps, GroupBase<OptionProps>> | undefined
  >();
  const [fetchCategories] = useLazyQuery(FETCH_CATEGORIES);

  useEffect(() => {
    fetchCategories({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCategories) {
          const fetchCategories: OptionsData[] = data.fetchCategories;
          const optionsWithDepth: Array<OptionsData & { depth?: number }> =
            toNestedOptions(fetchCategories, categoryId);
          const updatedOptions: OptionProps[] = optionsWithDepth?.map((p) => {
            return {
              label: `${Array.from(
                Array(typeof p.depth === "number" ? p.depth : 0).keys()
              )
                .map((_) => "-")
                .join("")} ${p.name}`,
              value: p.id,
            };
          });

          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Categories read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

type Condition = {
  conditionTitle: string;
  conditionSymbol: string;
  conditionValue: string;
};
function FieldGroupProducts(props: { formik: FormikProps<any> }) {
  const { formik } = props;
  let { categoryId } = useParams();

  const categoryTypes = [
    {
      label: "Manual",
      value: 0,
      description: "Add products to this collection one by one.",
    },
    {
      label: "Automated",
      value: 1,
      description:
        "Existing and future products that match the conditions you set will automatically be added to this category. ",
    },
  ];

  const conditionTypes = [
    {
      label: "All conditions",
      value: 0,
    },
    {
      label: "Any conditions",
      value: 1,
    },
  ];

  const [conditions, setConditions] = useState<Condition[]>([]);

  useEffect(() => {
    if (formik.values.conditions.length > 0) {
      setConditions(formik.values.conditions);
    } else {
      setConditions([
        {
          conditionTitle: "vendor_id",
          conditionSymbol: "=",
          conditionValue: "",
        },
      ]);
    }
  }, [formik.values.conditions]);

  const addCondition = () => {
    setConditions([
      ...conditions,
      {
        conditionTitle: "vendor_id",
        conditionSymbol: "=",
        conditionValue: "",
      },
    ]);
  };

  const removeCondition = (index: number) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
  };

  const updateCondition = (index: number, condition: Condition) => {
    const newConditions = [...conditions];
    newConditions[index] = condition;
    setConditions(newConditions);
    formik.setFieldValue("conditions", newConditions);
  };

  return (
    <>
      {typeof categoryId === "undefined" ? (
        <div>
          <RadioGroup
            value={formik.values.categoryType}
            onChange={(value: number) => {
              formik.setFieldValue("categoryType", value);
            }}
          >
            <RadioGroup.Label className="text-sm font-medium text-gray-900">
              Type
            </RadioGroup.Label>

            <div className="isolate mt-1 -space-y-px rounded-md bg-white">
              {categoryTypes.map((type, typeIdx) => (
                <RadioGroup.Option
                  key={type.label}
                  value={type.value}
                  className={({ checked }) =>
                    classNames(
                      typeIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                      typeIdx === categoryTypes.length - 1
                        ? "rounded-bl-md rounded-br-md"
                        : "",
                      checked
                        ? "z-10 border-primary-200 bg-primary-50"
                        : "border-gray-200",
                      "relative flex cursor-pointer border p-4 focus:outline-none"
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked
                            ? "border-transparent bg-primary-600"
                            : "border-gray-300 bg-white",
                          active ? "ring-2 ring-primary-500 ring-offset-2" : "",
                          "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                        )}
                        aria-hidden="true"
                      >
                        <span className="h-1.5 w-1.5 rounded-full bg-white" />
                      </span>
                      <span className="ml-3 flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            checked ? "text-primary-900" : "text-gray-900",
                            "block text-sm font-medium"
                          )}
                        >
                          {type.label}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={classNames(
                            checked ? "text-primary-700" : "text-gray-500",
                            "block text-sm"
                          )}
                        >
                          {type.description}
                        </RadioGroup.Description>
                      </span>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      ) : null}
      {formik.values.categoryType === 0 ? (
        <div className="mt-4">
          <FieldProducts
            title="Products"
            value={formik.values.productsIds}
            onChange={(products) => {
              formik.setFieldValue(
                "productsIds",
                products.map((product) => parseInt(product.id))
              );
            }}
          />
        </div>
      ) : (
        <div className="mt-4">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              Conditions
            </legend>
            <div className="mt-1 space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
              <span className="text-sm">Products must match:</span>
              {conditionTypes.map((type) => (
                <div key={type.label} className="flex items-center">
                  <input
                    id={`ct-${type.value}`}
                    name="notification-method"
                    type="radio"
                    defaultChecked={type.value === formik.values.conditionType}
                    className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                    onChange={() => {
                      formik.setFieldValue("conditionType", type.value);
                    }}
                  />
                  <label
                    htmlFor={`ct-${type.value}`}
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {type.label}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
          {conditions.map((condition, conditionIdx) => (
            <fieldset
              key={conditionIdx}
              className="mt-2 flex items-center justify-between space-x-4"
            >
              <div className="w-full">
                <FieldConditionTitle
                  value={condition.conditionTitle}
                  onChange={(v) => {
                    updateCondition(conditionIdx, {
                      ...condition,
                      conditionTitle: v?.value ?? "vendor_id",
                    });
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                  )}
                />
              </div>
              <div className="w-full">
                <FieldConditionSymbol
                  value={condition.conditionSymbol}
                  onChange={(v) => {
                    updateCondition(conditionIdx, {
                      ...condition,
                      conditionSymbol: v?.value ?? "=",
                    });
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                  )}
                />
              </div>
              <div className="w-full">
                <FieldConditionValue
                  value={condition.conditionValue}
                  type={condition.conditionTitle}
                  onChange={(v) => {
                    updateCondition(conditionIdx, {
                      ...condition,
                      conditionValue: v?.value ?? "",
                    });
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                  )}
                />
              </div>
              {conditions.length > 1 ? (
                <Button
                  variant="text"
                  onClick={() => removeCondition(conditionIdx)}
                >
                  <TrashIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-gray-700"
                  />
                </Button>
              ) : null}
            </fieldset>
          ))}

          <div className="mt-2 flex justify-end">
            <Button
              variant="secondary"
              onClick={() => {
                addCondition();
              }}
            >
              <span className="sr-only">Add condition</span>
              <PlusIcon aria-hidden="true" className="h-4 w-4 text-gray-700" />
            </Button>
          </div>

          {typeof categoryId !== "undefined" ? (
            <div className="mt-4">
              <ProductList
                title="Products"
                productIds={formik.values.productsIds}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

type ConditionTitle = {
  label: string;
  value: "vendor_id" | "department_id";
};

export function FieldConditionTitle({
  value,
  onChange,
  className,
}: {
  value: string;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className?: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const options: ConditionTitle[] = useMemo(
    () => [
      { label: "Vendor", value: "vendor_id" },
      { label: "Department", value: "department_id" },
    ],
    []
  );

  useEffect(() => {
    const updatedValue = options.find((o) => o.value === value) || null;
    setValues(updatedValue);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

type ConditionSymbol = {
  label: string;
  value: "=" | "!=" | ">" | ">=" | "<" | "<=";
};

export function FieldConditionSymbol({
  value,
  onChange,
  className,
}: {
  value: string;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className?: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const options: ConditionSymbol[] = useMemo(
    () => [{ label: "is equal to", value: "=" }],
    []
  );

  useEffect(() => {
    const updatedValue = options.find((o) => o.value === value) || null;
    setValues(updatedValue);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_VENDORS = gql`
  query FetchVendors($status: Boolean) {
    fetchVendors(status: $status) {
      id
      companyName
      status
    }
  }
`;

const FETCH_DEPARTMENTS = gql`
  query FetchDepartments($status: Boolean) {
    fetchDepartments(status: $status) {
      id
      name
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export function FieldConditionValue({
  value,
  type,
  onChange,
  className,
}: {
  value: string;
  type: string;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const [options, setOptions] = useState<MultiValue<OptionProps>>();

  const [fetchVendors] = useLazyQuery(FETCH_VENDORS);
  const fetchedVendors = useCallback(() => {
    fetchVendors({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchVendors) {
          const updatedOptions: OptionProps[] = data.fetchVendors?.map(
            (p: { id: string; companyName: string }) => {
              return {
                label: p.companyName,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Vendors read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  }, [fetchVendors, navigate]);

  const [fetchDepartments] = useLazyQuery(FETCH_DEPARTMENTS);
  const fetchedDepartments = useCallback(() => {
    fetchDepartments({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchDepartments) {
          const fetchedDepartments: OptionsData[] = data.fetchDepartments;
          const optionsWithDepth: Array<OptionsData & { depth?: number }> =
            toNestedOptions(fetchedDepartments);
          const updatedOptions: OptionProps[] = optionsWithDepth?.map((p) => {
            return {
              label: `${Array.from(
                Array(typeof p.depth === "number" ? p.depth : 0).keys()
              )
                .map((_) => "-")
                .join("")} ${p.name}`,
              value: p.id,
            };
          });

          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Departments read department(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  }, [fetchDepartments, navigate]);

  useEffect(() => {
    switch (type) {
      case "vendor_id":
        fetchedVendors();
        break;
      case "department_id":
        fetchedDepartments();
        break;
      default:
        break;
    }
  }, [fetchedDepartments, fetchedVendors, type]);

  useEffect(() => {
    if (!options?.length) return setValues(null);
    const updatedValue = options?.find((o) => o.value === value) || null;
    setValues(updatedValue);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}
