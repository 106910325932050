import { SettingsResource } from "./settings";

export * from "./Layout";

export const FleetsResource = {
  name: "Fleets",
  description: "Fleets management module",
  path: "/fleets",
  icon: "bi bi-ui-checks",
  access: ["read-fleets"],
  children: [SettingsResource],
};
