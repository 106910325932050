import { useState, useEffect, useMemo, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { classNames } from "../../utils";
import { Button } from "./Button";

import placeholder from "../../assets/placeholder.svg";
import { Spinner } from "../../animations";

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
};

type Product = {
  id: string;
  index: number;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

export function FieldStructureProducts({
  loading,
  products: initialProducts = [],
  occupiedIds = [],
  values: productIds,
  onChange,
}: {
  loading?: boolean;
  products: Product[];
  occupiedIds: number[];
  values: number[];
  onChange: (productIds: number[]) => void;
}) {
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const [query, setQuery] = useState<string | undefined>(undefined);

  const isDirty = useMemo(() => {
    const selectedProductIds = selectedProducts.flatMap((c) => parseInt(c.id));
    const isArray =
      Array.isArray(productIds) && Array.isArray(selectedProductIds);
    const isLength = selectedProductIds.length !== productIds.length;
    return (
      (isArray && isLength) ||
      (isArray && selectedProductIds.some((id) => !productIds.includes(id)))
    );
  }, [selectedProducts, productIds]);

  const products = useMemo(() => {
    return initialProducts;
  }, [initialProducts]);

  useEffect(() => {
    if (Array.isArray(productIds) && productIds.length > 0) {
      const updateSelectedProducts = products.filter((product) =>
        productIds.includes(parseInt(product.id))
      );
      setSelectedProducts(updateSelectedProducts);
    }
  }, [productIds, initialProducts]);

  return (
    <>
      <label
        htmlFor="description"
        className="mb-1 block text-sm font-medium text-gray-900"
      >
        Products
      </label>
      <header className="grid grid-cols-1 gap-4 sm:grid-cols-4">
        <div className="relative col-span-3">
          <label htmlFor="search" className="sr-only">
            Search products
          </label>
          <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className={classNames(
              "relative block w-full appearance-none rounded-md border border-gray-300 py-2.5 pr-3 pl-10 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
              "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
            )}
            placeholder="Search products"
            value={query || ""}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <div className="col-span-1 flex">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={() => {
              setQuery("");
            }}
          >
            Browse
          </Button>
        </div>
      </header>
      <div className="mt-2">
        {loading ? (
          <div className="flex justify-center py-6 md:py-12">
            <Spinner className="h-8 w-8 text-primary-700" />
          </div>
        ) : selectedProducts.length > 0 ? (
          <div className="divide-y">
            {selectedProducts.map((product, index) => (
              <div key={product.id} className="flex items-center py-2 md:py-4">
                <span className="mr-4 text-sm text-gray-700">{index + 1}.</span>
                <div className="h-10 w-10 flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={
                      product.featureImageUrl
                        ? product.featureImageUrl
                        : placeholder
                    }
                    alt={product.name}
                  />
                </div>
                <div className="ml-4">
                  <Link
                    to={`/inventory/products/${product.id}`}
                    target="_blank"
                    className="text-sm font-medium text-gray-900"
                  >
                    {product.name}
                  </Link>
                </div>
                <div className="ml-auto">
                  {product.status === 2 ? null : product.status === 0 ? (
                    <span className="mr-4 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                      Inactive
                    </span>
                  ) : (
                    <span className="mr-4 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                      Draft
                    </span>
                  )}
                  <Button
                    variant="text"
                    onClick={() => {
                      const newSelectedProducts = selectedProducts.filter(
                        (selectedProduct) => selectedProduct.id !== product.id
                      );
                      setSelectedProducts(newSelectedProducts);
                      onChange(newSelectedProducts.map((c) => parseInt(c.id)));
                    }}
                  >
                    <span className="sr-only">Remove {product.name}</span>
                    <span
                      aria-hidden="true"
                      className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
                    />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="py-6 text-center text-sm md:py-12">
            <ExclamationCircleIcon
              type="outline"
              name="exclamation-circle"
              className="mx-auto h-6 w-6 text-gray-400"
            />
            <p className="mt-4 font-medium text-gray-900">
              No products selected
            </p>
            <p className="mt-2 text-gray-500">
              Please{" "}
              <Button
                variant="text"
                onClick={() => {
                  setQuery("");
                }}
              >
                browse
              </Button>{" "}
              products to add to the category.
            </p>
          </div>
        )}
      </div>

      <Transition.Root
        show={query === undefined ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setQuery(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="py-8 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          Select products
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setQuery(undefined);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new department.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="relative mb-6 overflow-hidden border border-gray-200 md:rounded-lg">
                          <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="relative w-12 px-6 sm:w-16 sm:px-8"
                                ></th>
                                <th
                                  scope="col"
                                  className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-medium text-gray-900"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                                >
                                  Pricing Structure
                                </th>
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                >
                                  <span className="sr-only">Edit</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {products.map((product) => {
                                const isDisabled = occupiedIds.includes(
                                  parseInt(product.id)
                                )
                                  ? true
                                  : false;

                                return (
                                  <tr
                                    key={product.id}
                                    className={
                                      selectedProducts.includes(product)
                                        ? "bg-gray-50"
                                        : undefined
                                    }
                                  >
                                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                      {selectedProducts.includes(product) && (
                                        <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-600" />
                                      )}
                                      <input
                                        type="checkbox"
                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                                        value={product.id}
                                        checked={selectedProducts.includes(
                                          product
                                        )}
                                        onChange={(e) =>
                                          setSelectedProducts(
                                            e.target.checked
                                              ? [...selectedProducts, product]
                                              : selectedProducts.filter(
                                                  (p) => p !== product
                                                )
                                          )
                                        }
                                        disabled={isDisabled}
                                      />
                                    </td>
                                    <td
                                      className={classNames(
                                        "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                        selectedProducts.includes(product)
                                          ? "text-primary-600"
                                          : "text-gray-900"
                                      )}
                                    >
                                      <div className="flex items-center">
                                        <div className="h-10 w-10 flex-shrink-0">
                                          <img
                                            className="h-10 w-10 rounded-full"
                                            src={
                                              product.featureImageUrl
                                                ? product.featureImageUrl
                                                : placeholder
                                            }
                                            alt={product.name}
                                          />
                                        </div>
                                        <div className="ml-4">
                                          <Link
                                            to={`/inventory/products/${product.id}`}
                                            target="_blank"
                                            className="text-sm font-medium text-gray-900"
                                          >
                                            {product.name}
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {occupiedIds.includes(
                                        parseInt(product.id)
                                      )
                                        ? "Assigned to current customers"
                                        : "--"}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                      {!isDisabled &&
                                      !selectedProducts.includes(product) ? (
                                        <Button
                                          variant="text"
                                          className="text-primary-600 hover:text-primary-900"
                                          onClick={() => {
                                            onChange([parseInt(product.id)]);
                                            setQuery(undefined);
                                          }}
                                        >
                                          Insert
                                          <span className="sr-only">
                                            , {product.id}
                                          </span>
                                        </Button>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setQuery(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!isDirty}
                      onClick={() => {
                        onChange(selectedProducts.map((c) => parseInt(c.id)));
                        setQuery(undefined);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
