import { Fragment, useContext } from "react";
import { Link, useLocation, useResolvedPath } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { XMarkIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useTrail, animated as a } from "@react-spring/web";
import { isAuthorizedForResource, useAuth } from "../modules/auth";
import { classNames } from "../utils";

import { Avatar } from "../components/presentation";
import { pages } from "../modules";

import { GenContext } from "../contexts/GenContext";
import LogoLight from "../assets/logo-light.svg";

interface NavigationProps {
  name: string;
  path: string;
  icon?: any;
  access?: string[] | undefined;
  children?: {
    name: string;
    path: string;
    access?: string[] | undefined;
  }[];
}

const navigation: NavigationProps[] = pages;

const secondaryNavigation: NavigationProps[] = [
  { name: "Settings", path: "/settings/security", icon: "bi bi-gear" },
  { name: "Store", path: "/store", icon: "bi bi-sliders" },
  { name: "Logout", path: "/logout", icon: "bi bi-box-arrow-left" },
];

interface NavigationLinkProps extends LinkProps {
  name: string;
  icon: string;
  access?: string[] | undefined;
  child?: { name: string; path: string; access?: string[] | undefined }[];
}

interface SubLinkProps {
  open: boolean;
  name: string;
  icon: string;
  access?: string[] | undefined;
  child: { name: string; path: string; access?: string[] | undefined }[];
  active: boolean;
}

export default function NavLayout() {
  const { currentUser, currentRole } = useAuth();
  const { sidebarOpen, handleMenu } = useContext(GenContext);
  const location = useLocation();

  function MenuItem({
    children,
    to,
    access,
    name,
    icon,
    child,
    ...props
  }: NavigationLinkProps) {
    let resolved = useResolvedPath(to);
    let match = location.pathname.match(resolved.pathname);
    let active = match ? true : false;
    if (!isAuthorizedForResource(currentRole, access)) return null;

    return !child ? (
      <div>
        <Link
          to={to}
          className={classNames(
            active
              ? " bg-primary-700/20 text-primary-700"
              : "text-primary-50 hover:text-primary-700",
            "group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 transition-colors"
          )}
          aria-current={active ? "page" : undefined}
          {...props}
        >
          <span
            className={classNames(
              active
                ? " text-primary-700"
                : "text-primary-50 group-hover:text-primary-700",
              "mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 transition-colors",
              icon
            )}
            aria-hidden="true"
          />
          <span className="truncate">{name}</span>
        </Link>
      </div>
    ) : (
      <>
        <Disclosure as="div" defaultOpen={active} className="space-y-1">
          {(props) => (
            <MenuSubItem
              {...props}
              name={name}
              icon={icon}
              access={access}
              child={child}
              active={active}
            />
          )}
        </Disclosure>
      </>
    );
  }

  function MenuSubItem({
    open,
    name,
    icon,
    access,
    child,
    active,
  }: SubLinkProps) {
    const trailConfig = {
      mass: 3,
      tension: active ? 0 : 1000,
      friction: 100,
    };

    const childTrail = useTrail(child ? child.length : 0, {
      config: trailConfig,
      opacity: open ? 1 : 0,
      x: open ? 0 : 10,
      height: open ? 36 : 0,
      from: { opacity: 0, x: 10, height: 0 },
    });

    if (!isAuthorizedForResource(currentRole, access)) return null;

    return (
      <>
        <Disclosure.Button
          className={classNames(
            active
              ? " bg-primary-700/20 text-primary-700"
              : "text-primary-50 hover:text-primary-700",
            "group flex w-full items-center rounded-md px-2 py-2 text-left text-sm font-medium leading-6 transition-colors"
          )}
        >
          <span
            className={classNames(
              active
                ? " text-primary-700"
                : "text-primary-50 group-hover:text-primary-700",
              "mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 transition-colors",
              icon
            )}
            aria-hidden="true"
          />
          <span className="flex-1 truncate">{name}</span>
          <ChevronUpIcon
            className={classNames(
              open ? "text-gray-400" : "rotate-90 text-gray-300",
              "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
            )}
            aria-hidden="true"
          />
        </Disclosure.Button>
        <Disclosure.Panel className="space-y-1">
          {childTrail.map(({ x, height, ...rest }, index) => {
            let match = location.pathname.match(child[index].path);
            let active = match ? true : false;

            if (!isAuthorizedForResource(currentRole, child[index].access))
              return null;

            return (
              <a.div
                key={child[index].name}
                style={{
                  ...rest,
                  transform: x.to((x) => `translate3d(0,${x}px,0)`),
                }}
              >
                <a.div style={{ height }}>
                  <Link
                    to={child[index].path}
                    className={classNames(
                      active
                        ? "text-primary-300 hover:text-primary-400"
                        : "text-gray-300 hover:text-primary-700",
                      "group flex w-full items-center rounded-md py-2 pl-12 pr-2 text-sm font-normal transition-colors"
                    )}
                  >
                    <span className="truncate">{child[index].name}</span>
                  </Link>
                </a.div>
              </a.div>
            );
          })}
        </Disclosure.Panel>
      </>
    );
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={handleMenu}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-[18rem] flex-1 flex-col bg-black pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none"
                      onClick={() => handleMenu(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4 py-6">
                  <Link to="/" className="w-3/4">
                    <span className="sr-only">
                      {process.env.REACT_APP_NAME}
                    </span>
                    <img src={LogoLight} alt={process.env.REACT_APP_NAME} />
                  </Link>
                </div>
                <nav
                  className="h-full flex-shrink-0 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="flex flex-shrink-0 p-6">
                    <Link
                      to="/settings/account"
                      className="group block w-full flex-shrink-0"
                    >
                      <div className="flex items-center">
                        <div>
                          <Avatar className="h-9 w-9 bg-primary-700 text-black" />
                        </div>
                        <div className="ml-3">
                          <p className="truncate text-sm font-medium text-white">
                            {currentUser?.fullName}
                          </p>
                          <p className="text-xs font-medium text-primary-300 group-hover:text-gray-200">
                            {currentRole?.roleName}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="space-y-1 px-4 pb-6">
                    <div className="space-y-1">
                      <button
                        className="group flex w-full items-center rounded-md px-2 py-2 text-left text-sm font-medium leading-6 text-gray-100  transition-colors hover:text-primary-700"
                        id="headlessui-disclosure-button-:r2:"
                        type="button"
                        aria-expanded="false"
                      >
                        <span
                          className="bi bi-map mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-primary-50 transition-colors group-hover:text-primary-700"
                          aria-hidden="true"
                        />
                        <span className="flex-1 truncate">Track Shipment</span>
                        <span className="ml-3 hidden rounded-full border border-solid border-primary-700 bg-primary-700 py-0.5 px-2.5 text-xs font-medium text-black transition-colors duration-150 ease-in-out group-hover:border-primary-600 group-hover:bg-primary-600 md:inline-block">
                          5
                        </span>
                      </button>
                    </div>
                    <div>
                      <button
                        className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-primary-50 transition-colors hover:text-primary-700"
                        type="button"
                        aria-expanded="false"
                      >
                        <span
                          className="bi bi-kanban mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-primary-50 transition-colors group-hover:text-primary-700"
                          aria-hidden="true"
                        />
                        <span className="truncate">Fleets</span>
                      </button>
                    </div>
                  </div>

                  <div className="space-y-1 border-t border-gray-700/50 px-4 py-6">
                    {navigation.map((item) => (
                      <MenuItem
                        key={item.name}
                        name={item.name}
                        icon={item.icon}
                        to={item.path}
                        access={item.access}
                        child={item?.children}
                      />
                    ))}
                  </div>
                  <div className="mt-6 border-0 pt-6 md:mt-auto">
                    <div className="space-y-1 px-4">
                      {secondaryNavigation.map((item) => (
                        <MenuItem
                          key={item.name}
                          name={item.name}
                          icon={item.icon}
                          to={item.path}
                          access={item.access}
                          child={item?.children}
                        />
                      ))}
                    </div>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center px-6 py-6">
            <Link to="/" className="w-3/4">
              <span className="sr-only">{process.env.REACT_APP_NAME}</span>
              <img src={LogoLight} alt={process.env.REACT_APP_NAME} />
            </Link>
          </div>
          <nav
            className="flex flex-1 flex-col overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="flex flex-shrink-0 p-6">
              <Link
                to="/settings/account"
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center">
                  <div>
                    <Avatar className="h-9 w-9 bg-primary-700 text-black" />
                  </div>
                  <div className="ml-3">
                    <p className="truncate text-sm font-medium text-white">
                      {currentUser?.fullName}
                    </p>
                    <p className="text-xs font-medium text-primary-300 group-hover:text-gray-200">
                      {currentRole?.roleName}
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="space-y-1 px-4 pb-6">
              <div className="space-y-1">
                <button
                  className="group flex w-full items-center rounded-md px-2 py-2 text-left text-sm font-medium leading-6 text-gray-100  transition-colors hover:text-primary-700"
                  id="headlessui-disclosure-button-:r2:"
                  type="button"
                  aria-expanded="false"
                >
                  <span
                    className="bi bi-map mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-primary-50 transition-colors group-hover:text-primary-700"
                    aria-hidden="true"
                  />
                  <span className="flex-1 truncate">Track Shipment</span>
                  <span className="ml-3 hidden rounded-full border border-solid border-primary-700 bg-primary-700 py-0.5 px-2.5 text-xs font-medium text-black transition-colors duration-150 ease-in-out group-hover:border-primary-600 group-hover:bg-primary-600 md:inline-block">
                    5
                  </span>
                </button>
              </div>
              <div>
                <button
                  className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-primary-50 transition-colors hover:text-primary-700"
                  type="button"
                  aria-expanded="false"
                >
                  <span
                    className="bi bi-kanban mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-primary-50 transition-colors group-hover:text-primary-700"
                    aria-hidden="true"
                  />
                  <span className="truncate">Fleets</span>
                </button>
              </div>
            </div>

            <div className="space-y-1 border-t border-gray-700/50 px-4 py-6">
              {navigation.map((item) => (
                <MenuItem
                  key={item.name}
                  name={item.name}
                  icon={item.icon}
                  to={item.path}
                  access={item.access}
                  child={item?.children}
                />
              ))}
            </div>
            <div className="mt-6 border-0 pt-6 md:mt-auto">
              <div className="space-y-1 px-4">
                {secondaryNavigation.map((item) => (
                  <MenuItem
                    key={item.name}
                    name={item.name}
                    icon={item.icon}
                    to={item.path}
                    access={item.access}
                    child={item?.children}
                  />
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
