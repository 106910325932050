import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { FormikProps, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import Select, { MultiValue, SingleValue } from "react-select";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TrashIcon } from "@heroicons/react/24/outline";

import {
  Button,
  Field,
  FieldAddress,
  FieldState,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";

import { arrayMove, classNames } from "../../../../utils";

type Customer = {
  id: string;
  customerName: string;
};

type ContactDetails = {
  contactType: SingleValue<OptionProps>;
  name: string;
  email: string;
  phoneNumber: string;
  sendSms: boolean;
};
type CustomerContact = {
  id?: number;
  contactDetails: ContactDetails[];
  customer: SingleValue<OptionProps>;
  emailImportantUpdates: boolean;
  emailSpecials: boolean;
  firstName: string;
  mobileNumber: string;
  phoneNumber: string;
  postalAddress: string;
  postcode: string;
  state: string;
  suburb: string;
  surName: string;
};

type ContactDetailOption = ContactDetails & {
  id: string;
};

type ContactTypeOptions = {
  label: string;
  value: string;
  disabled: boolean;
};

type CustomerContactType = {
  id?: string;
  name: string;
  createdAt: string;
  status: boolean;
};

const FETCH_CUSTOMERCONTACTTYPES = gql`
  query FetchCustomerContactTypes {
    fetchCustomerContactTypes {
      id
      name
      createdAt
      status
    }
  }
`;

export default function Form({
  initialValues,
  onSubmit,
  header,
}: {
  initialValues: CustomerContact;
  onSubmit: (values: any, actions: any) => void;
  header?: JSX.Element;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [contactDetailOptions, setContactDetailOptions] = useState<
    ContactDetailOption[]
  >([]);

  const [contactTypeOptions, setContactTypeOptions] = useState<
    ContactTypeOptions[]
  >([]);

  const [fetchCustomerContactTypes] = useLazyQuery(FETCH_CUSTOMERCONTACTTYPES);

  useEffect(() => {
    if (initialValues.contactDetails.length > 0) {
      setContactDetailOptions(
        initialValues.contactDetails.map((address, index) => ({
          ...address,
          id: index.toString(),
        }))
      );

      fetchCustomerContactTypes()
        .then(({ data, error }) => {
          if (data?.fetchCustomerContactTypes) {
            const customerContactDetailIds =
              initialValues.contactDetails.flatMap((v) => v.contactType?.value);
            const updatedCustomerContactTypes =
              data.fetchCustomerContactTypes.map(
                (item: CustomerContactType) => ({
                  label: item.name,
                  value: item.id,
                  disabled: customerContactDetailIds.includes(item.id),
                })
              );
            setContactTypeOptions(updatedCustomerContactTypes);
          } else {
            return navigate("/error/401", {
              state: {
                message: error
                  ? error.message
                  : "Customer contact types read is required to access this page.",
              },
            });
          }
        })
        .catch((error) => {
          return navigate("/error/500", {
            state: {
              error,
              message: error.message,
            },
          });
        });
    } else {
      fetchCustomerContactTypes()
        .then(({ data, error }) => {
          if (data?.fetchCustomerContactTypes) {
            const updatedCustomerContactTypes =
              data.fetchCustomerContactTypes.map(
                (item: CustomerContactType) => ({
                  label: item.name,
                  value: item.id,
                  disabled: false,
                })
              );
            setContactTypeOptions(updatedCustomerContactTypes);
          } else {
            return navigate("/error/401", {
              state: {
                message: error
                  ? error.message
                  : "Customer contact types read is required to access this page.",
              },
            });
          }
        })
        .catch((error) => {
          return navigate("/error/500", {
            state: {
              error,
              message: error.message,
            },
          });
        });

      setContactDetailOptions([
        {
          id: "0",
          contactType: {
            label: "",
            value: "",
          },
          name: "",
          email: "",
          phoneNumber: "",
          sendSms: false,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.contactDetails, fetchCustomerContactTypes]);

  const CustomerContactSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    surName: Yup.string().nullable(),
    customer: Yup.object().nullable(),
    phoneNumber: Yup.string().nullable(),
    mobileNumber: Yup.string().nullable(),
    postalAddress: Yup.string().nullable(),
    suburb: Yup.string().nullable(),
    state: Yup.string().nullable(),
    postcode: Yup.string().nullable(),
    contactDetails: Yup.object().nullable(),
    emailSpecials: Yup.boolean().required("Required"),
    emailImportantUpdates: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: CustomerContactSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      {header}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
        <div className="space-y-4 md:col-span-8">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_firstname")}
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  touched={touched.firstName}
                  errors={errors.firstName}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_surname")}
                  name="surName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.surName}
                  touched={touched.surName}
                  errors={errors.surName}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_customer")}
                </label>
                <FieldCustomer
                  value={formik.values.customer}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("customer", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.customer && formik.errors.customer
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.customer && formik.errors.customer ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.customer.toString()}
                  </p>
                ) : null}
              </div>

              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_phone")}
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  touched={touched.phoneNumber}
                  errors={errors.phoneNumber}
                />
              </div>

              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_mobile")}
                  name="mobileNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  touched={touched.mobileNumber}
                  errors={errors.mobileNumber}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <FieldAddress
                  title={t("text_postal_address")}
                  name="postalAddress"
                  type="street"
                  onChange={(value) => {
                    const { street, state, suburb, postcode } = value;

                    formik.setValues({
                      ...formik.values,
                      postalAddress: street,
                      suburb: suburb,
                      state: state,
                      postcode: postcode,
                    });
                  }}
                  value={formik.values.postalAddress}
                  touched={touched.postalAddress}
                  errors={errors.postalAddress}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_suburb")}
                  name="suburb"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.suburb}
                  touched={touched.suburb}
                  errors={errors.suburb}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <FieldState
                  title={t("text_state")}
                  name="state"
                  onChange={(value) => {
                    formik.setFieldValue("state", value);
                  }}
                  value={formik.values.state}
                  touched={touched.state}
                  errors={errors.state}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <FieldAddress
                  title={t("text_postcode")}
                  name="postcode"
                  type="postcode"
                  onChange={(value) => {
                    const { street, state, suburb, postcode } = value;

                    formik.setValues({
                      ...formik.values,
                      postalAddress: street,
                      suburb: suburb,
                      state: state,
                      postcode: postcode,
                    });
                  }}
                  value={formik.values.postcode}
                  touched={touched.postcode}
                  errors={errors.postcode}
                />
              </div>
            </div>
          </div>

          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <ContactDetailOptions
              formik={formik}
              contactTypeOptions={contactTypeOptions}
              setContactTypeOptions={setContactTypeOptions}
              contactDetailOptions={contactDetailOptions}
              setContactDetailOptions={setContactDetailOptions}
            />
          </div>
        </div>
        <div className="col-span-4 space-y-4">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <div className="block text-sm font-medium text-gray-900">
              {t("text_communication_settings")}
            </div>

            <fieldset className="mt-4">
              <Switch.Group as="div" className="inline-flex items-center">
                <Switch
                  checked={formik.values.emailSpecials}
                  onChange={() => {
                    formik.setFieldValue(
                      "emailSpecials",
                      !formik.values.emailSpecials
                    );
                  }}
                  id="emailSpecials"
                  className={classNames(
                    formik.values.emailSpecials
                      ? "bg-primary-600"
                      : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      formik.values.emailSpecials
                        ? "translate-x-5"
                        : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label
                  passive
                  htmlFor="emailSpecials"
                  className="ml-2 mb-0 block text-sm font-normal text-gray-900"
                >
                  {t("text_email_specials")}
                </Switch.Label>
              </Switch.Group>
            </fieldset>

            <fieldset className="mt-4">
              <Switch.Group as="div" className="inline-flex items-center">
                <Switch
                  checked={formik.values.emailImportantUpdates}
                  onChange={() => {
                    formik.setFieldValue(
                      "emailImportantUpdates",
                      !formik.values.emailImportantUpdates
                    );
                  }}
                  id="emailImportantUpdates"
                  className={classNames(
                    formik.values.emailImportantUpdates
                      ? "bg-primary-600"
                      : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      formik.values.emailImportantUpdates
                        ? "translate-x-5"
                        : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label
                  passive
                  htmlFor="emailImportantUpdates"
                  className="ml-2 mb-0 block text-sm font-normal text-gray-900"
                >
                  {t("text_email_important_updates")}
                </Switch.Label>
              </Switch.Group>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  );
}

function ContactDetailOptions(props: {
  formik: FormikProps<any>;
  contactTypeOptions: ContactTypeOptions[];
  setContactTypeOptions: Dispatch<SetStateAction<ContactTypeOptions[]>>;
  contactDetailOptions: ContactDetailOption[];
  setContactDetailOptions: Dispatch<SetStateAction<ContactDetailOption[]>>;
}) {
  const { t } = useTranslation();
  const {
    formik,
    contactTypeOptions,
    setContactTypeOptions,
    contactDetailOptions,
    setContactDetailOptions,
  } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addContactDetail = () => {
    const newContactDetails = [
      ...contactDetailOptions,
      {
        id: String(contactDetailOptions.length + 1),
        contactType: {
          label: "",
          value: "",
        },
        name: "",
        email: "",
        phoneNumber: "",
        sendSms: false,
      },
    ];
    formik.setFieldValue(
      "contactDetails",
      newContactDetails.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setContactDetailOptions(newContactDetails);
  };

  const removeContactDetail = (id: string) => {
    const deletedContactDetails = contactDetailOptions.filter(
      (v: ContactDetailOption) => v.id !== id
    );
    formik.setFieldValue(
      "contactDetails",
      deletedContactDetails.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setContactDetailOptions(deletedContactDetails);
  };

  const updateContactDetail = (
    id: string,
    contactDetail: ContactDetailOption
  ) => {
    const updatedContactDetails = [...contactDetailOptions];
    const index = updatedContactDetails.findIndex((v) => v.id === id);
    updatedContactDetails[index] = contactDetail;
    formik.setFieldValue(
      "contactDetails",
      updatedContactDetails.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setContactDetailOptions(updatedContactDetails);

    const contactDetailOptionValues = updatedContactDetails.flatMap(
      (v: ContactDetailOption) => v.contactType?.value
    );
    const newContactTypeOptions = contactTypeOptions.map((v) => ({
      ...v,
      disabled: contactDetailOptionValues.includes(v.value) && v.value !== "",
    }));
    setContactTypeOptions(newContactTypeOptions);
  };

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = contactDetailOptions.findIndex(
        (v) => v.id === active.id
      );
      const newIndex = contactDetailOptions.findIndex((v) => v.id === over.id);
      const updatedArray = arrayMove(contactDetailOptions, oldIndex, newIndex);
      formik.setFieldValue(
        "contactDetails",
        updatedArray.map((t) => {
          const { id, ...rest } = t;
          return rest;
        })
      );
      setContactDetailOptions(updatedArray);
    }
  }

  return (
    <fieldset>
      <legend className="text-sm font-medium text-gray-900">
        {t("text_contact_details")}
      </legend>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={contactDetailOptions.map((v) => v.id!)}
            strategy={verticalListSortingStrategy}
          >
            {contactDetailOptions.map((v) => (
              <FieldsetContactDetail
                key={v.id}
                id={v.id!}
                contactDetail={v}
                removeContactDetail={removeContactDetail}
                updateContactDetail={updateContactDetail}
                contactDetailOptions={contactDetailOptions}
                contactTypeOptions={contactTypeOptions}
                setContactTypeOptions={setContactTypeOptions}
              />
            ))}
          </SortableContext>
        </DndContext>
        {contactDetailOptions.length > 0 &&
          contactDetailOptions.length !== contactTypeOptions.length && (
            <div className="mt-2 flex justify-end">
              <Button variant="secondary" onClick={addContactDetail}>
                {t("text_add_contact_details")}
              </Button>
            </div>
          )}
      </div>
    </fieldset>
  );
}

function FieldsetContactDetail(props: {
  id: string;
  contactDetail: ContactDetailOption;
  updateContactDetail?: any;
  removeContactDetail?: any;
  contactDetailOptions: ContactDetailOption[];
  contactTypeOptions: ContactTypeOptions[];
  setContactTypeOptions: Dispatch<SetStateAction<ContactTypeOptions[]>>;
}) {
  const { t } = useTranslation();
  const {
    contactDetail,
    updateContactDetail,
    removeContactDetail,
    contactDetailOptions,
    contactTypeOptions,
    setContactTypeOptions,
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  function handleContactTypeChange(value: SingleValue<OptionProps>) {
    const newContactDetail = { ...contactDetail };
    newContactDetail.contactType = value;
    updateContactDetail(contactDetail.id, newContactDetail);
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {props.id === "0" ? null : <hr className="my-4" />}
      <div className="flex w-full items-start">
        <div className="mr-1 mt-3.5 space-y-5">
          <Button
            variant="text"
            style={{
              display: "flex",
            }}
            className="transition-all hover:bg-white"
            {...listeners}
          >
            <span
              aria-hidden="true"
              className="bi bi-grip-vertical px-2 text-lg"
            ></span>
          </Button>
          {contactDetailOptions.length > 1 ? (
            <Button
              variant="text"
              style={{
                display: "flex",
              }}
              onClick={() => {
                const newContactTypeOptions = [...contactTypeOptions];
                const index = newContactTypeOptions.findIndex(
                  (v) => v.value === contactDetail.contactType?.value
                );
                newContactTypeOptions[index].disabled = false;
                setContactTypeOptions(newContactTypeOptions);
                removeContactDetail(contactDetail.id);
              }}
              className="transition-all hover:bg-white"
            >
              <TrashIcon
                aria-hidden="true"
                className="mx-2 h-4 w-4 text-gray-700"
              />
            </Button>
          ) : null}
        </div>

        <div className="grid w-full grid-cols-2 gap-4 py-4">
          <div>
            <SelectWrapper
              className={classNames(
                "rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
              )}
            >
              <Select
                closeMenuOnSelect={true}
                styles={selectStyles}
                value={contactDetail.contactType}
                options={contactTypeOptions.filter((v) => !v.disabled)}
                isOptionDisabled={(option) => option.disabled || false}
                onChange={handleContactTypeChange}
                isClearable={false}
                isSearchable={false}
              />
            </SelectWrapper>
          </div>
          <div>
            <Field
              title={t("text_name")}
              name={`contact-name-${contactDetail.id}`}
              onChange={(e) => {
                const newContactDetail = { ...contactDetail };
                newContactDetail.name = e.target.value;
                updateContactDetail(contactDetail.id, newContactDetail);
              }}
              value={contactDetail.name}
              placeholder={t("text_name")}
              label={false}
            />
          </div>
          <div>
            <Field
              title={t("text_email")}
              name={`contact-email-${contactDetail.id}`}
              onChange={(e) => {
                const newContactDetail = { ...contactDetail };
                newContactDetail.email = e.target.value;
                updateContactDetail(contactDetail.id, newContactDetail);
              }}
              value={contactDetail.email}
              placeholder={t("text_email")}
              label={false}
            />
          </div>
          <div>
            <Field
              title={t("text_phone")}
              name={`contact-phoneNumber-${contactDetail.id}`}
              onChange={(e) => {
                const newContactDetail = { ...contactDetail };
                newContactDetail.phoneNumber = e.target.value;
                updateContactDetail(contactDetail.id, newContactDetail);
              }}
              value={contactDetail.phoneNumber}
              placeholder={t("text_phone")}
              label={false}
            />
          </div>
          <div>
            <Switch.Group as="div" className="inline-flex items-center">
              <Switch
                checked={contactDetail.sendSms}
                onChange={() => {
                  const newContactDetail = { ...contactDetail };
                  newContactDetail.sendSms = !newContactDetail.sendSms;
                  updateContactDetail(contactDetail.id, newContactDetail);
                }}
                id={`contact-sendSms-${contactDetail.id}`}
                className={classNames(
                  contactDetail.sendSms ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    contactDetail.sendSms ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor={`contact-sendSms-${contactDetail.id}`}
                className="ml-2 mb-0 block text-sm font-normal text-gray-900"
              >
                {t("text_send_sms")}
              </Switch.Label>
            </Switch.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

const FETCH_CUSTOMERS = gql`
  query FetchCustomers($status: Int) {
    fetchCustomers(status: $status) {
      id
      customerName
      createdAt
      status
    }
  }
`;

export function FieldCustomer({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchCustomers] = useLazyQuery(FETCH_CUSTOMERS);

  useEffect(() => {
    fetchCustomers({
      variables: {
        status: 2,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCustomers) {
          const fetchCustomers = data.fetchCustomers.map((p: Customer) => ({
            value: p.id,
            label: p.customerName,
          }));

          setOptions(fetchCustomers);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Customers read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomers]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}
