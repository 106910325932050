import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  gql,
  Observable,
  FetchResult,
} from "@apollo/client";
// import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";
import * as authHelper from "./AuthHelper";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
});

// const retryLink = new RetryLink({
//   delay: {
//     initial: 300,
//     max: Infinity,
//     jitter: true,
//   },
//   attempts: {
//     max: 5,
//     retryIf: (error, _operation) => {
//       console.log("RetryLink", error);
//       console.log(error.message);
//       if (error.graphQLErrors.length) {
//         const graphQLError = error.graphQLErrors[0];
//         if (graphQLError.extensions.code === "UNAUTHENTICATED") {
//           return true;
//         }
//       }
//       return false;
//     },
//   },
// });

const authLink = new ApolloLink((operation, forward) => {
  const oldHeaders = operation.getContext().headers;
  // console.log(operation.operationName);
  if (operation.operationName === "GenerateAccessToken") {
    operation.setContext({
      headers: {
        ...oldHeaders,
        platform: "web",
        // authorization: _refresh ? `Bearer ${_refresh}` : "",
      },
    });
  } else {
    const _access = authHelper.getAccess();
    operation.setContext({
      headers: {
        ...oldHeaders,
        platform: "web",
        authorization: _access ? `Bearer ${_access}` : "",
      },
    });
  }

  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // console.log(operation.operationName, 678);
    // console.log("GraphQL error", graphQLErrors);

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.error(err?.extensions?.code, 678);
        console.log(err, 678);

        switch (err?.extensions?.code) {
          case "UNAUTHENTICATED":
            // console.log(operation.operationName);
            if (operation.operationName === "GenerateAccessToken") return;

            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    const accessToken = await getToken();
                    // console.log("Access token", accessToken);
                    if (!accessToken) {
                      throw new GraphQLError("Access token not found");
                    }

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              }
            );
            return observable;

          case "FORBIDDEN":
            // console.log("this should redirect to 403 page");
            // window.location.href = "/error/403";
            break;
          case "INTERNAL_SERVER_ERROR":
            // console.log("this should redirect to 500 page");
            // window.location.href = "/error/500";
            break;
          case "GRAPHQL_VALIDATION_FAILED":
            // console.log("this should redirect to 400 page");
            // window.location.href = "/error/400";
            break;
        }
      }
    }

    // console.log(`[Network error]: ${networkError}`);

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError && networkError.name === "ServerError") {
      // remove cached token on 401 from the server
      authHelper.removeAccess();
    }
  }
);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          fetchRoles: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
          fetchPermissions: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
          fetchDepartments: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
          fetchCategories: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
          fetchBinLocations: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
          fetchDocks: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
          fetchPricingCategories: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      User: {
        fields: {
          loginHistories: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      Role: {
        fields: {
          permissions: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      Product: {
        fields: {
          categories: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      CustomerGroup: {
        fields: {
          customers: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      PricingMethod: {
        fields: {
          pricingMethodFields: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      PricingStructure: {
        fields: {
          customers: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
          products: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      SpecialPrice: {
        fields: {
          products: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      PricingLevel: {
        fields: {
          products: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
      ProductSku: {
        fields: {
          pricingLevelDetails: {
            merge(existing = [], incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([
    errorLink,
    authLink,
    // retryLink,
    httpLink,
  ]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export const getToken = async (): Promise<string | any> => {
  const _refresh = authHelper.getRefresh();
  // const { data } = await client.mutate({
  //   mutation: gql`
  //     mutation TokenCreate($refresh: String!) {
  //       tokenCreate(refresh: $refresh) {
  //         access
  //       }
  //     }
  //   `,
  //   variables: {
  //     refresh: _refresh,
  //   },
  // });

  const { data } = await client.mutate({
    mutation: gql`
      mutation GenerateAccessToken($refresh: String!) {
        generateAccessToken(input: { params: { token: $refresh } }) {
          accessToken
        }
      }
    `,
    variables: {
      refresh: _refresh,
    },
    fetchPolicy: "network-only",
  });

  authHelper.setAccess(data.generateAccessToken.accessToken);
  return data.generateAccessToken.accessToken;
};

export const getUser = async (): Promise<any> => {
  // const { data } = await client.query({
  //   query: gql`
  //     query UserProfile {
  //       userProfile {
  //         firstName
  //         lastName
  //         fullName
  //         email
  //         username
  //         avatar
  //         mfaEnabled
  //         isLocked
  //         lockedExpiry
  //         role {
  //           title
  //         }
  //       }
  //     }
  //   `,
  //   fetchPolicy: "network-only",
  // });
  // return data.userProfile;

  const { data } = await client.query({
    query: gql`
      query Me {
        me {
          id
          fullName
          firstName
          lastName
          userName
          email
          enableMfa
          phoneNumber
          roles {
            id
            permissions {
              id
              permissionDescription
              permissionDisplayName
              permissionName
              resource {
                id
                permission {
                  id
                  permissionDescription
                  permissionDisplayName
                  permissionName
                  status
                  updatedAt
                }
                resourceDescription
                resourceName
                status
                updatedAt
              }
              status
              updatedAt
            }
            roleDescription
            roleName
            status
            updatedAt
          }
          status
          updatedAt
        }
      }
    `,
    fetchPolicy: "cache-first",
  });
  return data?.me;
};
