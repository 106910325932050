import { lazy, Suspense } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import { useAuth, isAuthorizedForResource } from "../auth";

import PageLayout from "../../layouts/PageLayout";
import {
  LayoutSplashScreen,
  NotFound,
  NotAuthorized,
} from "../../components/core";

import { CustomerGroupListResource } from "./customer-groups/List";
import { CustomerGroupCreateResource } from "./customer-groups/Create";
import { CustomerGroupUpdateResource } from "./customer-groups/Update";
import { CustomerListResource } from "./customers/List";
import { CustomerCreateResource } from "./customers/Create";
import { CustomerUpdateResource } from "./customers/Update";
import { CustomerPricingResource } from "./customers/Pricing";
import { CustomerProductsResource } from "./customers/Products";
import { CustomerContactTypeListResource } from "./customer-contact-types/List";
import { CustomerContactListResource } from "./customer-contacts/List";
import { CustomerContactCreateResource } from "./customer-contacts/Create";
import { CustomerContactUpdateResource } from "./customer-contacts/Update";

const CustomerGroupList = lazy(() => import("./customer-groups/List"));
const CustomerGroupCreate = lazy(() => import("./customer-groups/Create"));
const CustomerGroupUpdate = lazy(() => import("./customer-groups/Update"));
const CustomerList = lazy(() => import("./customers/List"));
const CustomerCreate = lazy(() => import("./customers/Create"));
const CustomerUpdate = lazy(() => import("./customers/Update"));
const CustomerPricing = lazy(() => import("./customers/Pricing"));
const CustomerProducts = lazy(() => import("./customers/Products"));
const CustomerContactTypeList = lazy(
  () => import("./customer-contact-types/List")
);
const CustomerContactList = lazy(() => import("./customer-contacts/List"));
const CustomerContactCreate = lazy(() => import("./customer-contacts/Create"));
const CustomerContactUpdate = lazy(() => import("./customer-contacts/Update"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
];

export function SalePage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: (
            <Navigate to={CustomerGroupListResource.path} replace={true} />
          ),
        },
        {
          path: CustomerGroupListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerGroupListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerGroupList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerGroupListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerGroupCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerGroupCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerGroupCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerGroupCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerGroupUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerGroupUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerGroupUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerGroupUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerPricingResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerPricingResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerPricing breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerPricingResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerProductsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerProductsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerProducts breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerProductsResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerContactTypeListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactTypeListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactTypeList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={
                new Error(CustomerContactTypeListResource.access.join(", "))
              }
            />
          ),
        },
        {
          path: CustomerContactListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerContactListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerContactCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerContactCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerContactUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerContactUpdateResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
