import { CustomerGroupsResource } from "./customer-groups";
import { CustomersResource } from "./customers";
import { CustomerContactsResource } from "./customer-contacts";

export * from "./Layout";

export const SalesResource = {
  name: "Sales",
  description: "Sales management module",
  path: "/sales",
  icon: "bi bi-receipt",
  access: ["read-customergroups", "read-customers", "read-customercontacts"],
  children: [
    CustomerGroupsResource,
    CustomersResource,
    CustomerContactsResource,
  ],
};
