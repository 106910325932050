import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";
import { Button } from "../../../components/form";

const CustomerCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CREATE_CUSTOMER = gql`
    mutation CustomerCreate(
      $customerCode: String!
      $customerName: String!
      $customerType: Int!
      $email: String!
      $storeContact: String
      $storeEmail: String!
      $storePhoneNumber: String
      $storeFax: String
      $abnNo: String!
      $customerPlu: String!
      $packingInstruction: String
      $deliveryInstruction: String
      $salesRepId: Int
      $deliveryRunId: Int
      $creditApplicationStatus: Boolean
      $creditLimit: Float
      $customerGroupIds: [Int!]
      $tradingAddresses: [CustomertradingaddressInput!]
      $status: Int!
    ) {
      customerCreate(
        input: {
          params: {
            customerCode: $customerCode
            customerName: $customerName
            customerType: $customerType
            email: $email
            storeContact: $storeContact
            storeEmail: $storeEmail
            storePhoneNumber: $storePhoneNumber
            storeFax: $storeFax
            abnNo: $abnNo
            customerPlu: $customerPlu
            packingInstruction: $packingInstruction
            deliveryInstruction: $deliveryInstruction
            salesRepId: $salesRepId
            deliveryRunId: $deliveryRunId
            creditApplicationStatus: $creditApplicationStatus
            creditLimit: $creditLimit
            customerGroupIds: $customerGroupIds
            tradingAddresses: $tradingAddresses
            status: $status
          }
        }
      ) {
        customer {
          id
          abnNo
          email
          createdAt
          creditApplicationStatus
          creditLimit
          customerCode
          customerGroups {
            id
            name
          }
          customerName
          customerPlu
          customerType
          deliveryInstruction
          deliveryRuns {
            id
            name
          }
          packingInstruction
          salesRep {
            id
            fullName
          }
          storeContact
          storeEmail
          storeFax
          storePhoneNumber
          tradingAddresses {
            address
            suburb
            state
            postcode
          }
          status
        }
      }
    }
  `;
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCustomer({
      variables: {
        customerCode: values.customerCode,
        customerName: values.customerName,
        customerType: values.customerType
          ? parseInt(values.customerType.value)
          : null,
        email: values.email,
        storeContact: values.storeContact,
        storeEmail: values.storeEmail,
        storePhoneNumber: values.storePhoneNumber,
        storeFax: values.storeFax,
        abnNo: values.abnNo,
        customerPlu: values.customerPlu,
        packingInstruction: values.packingInstruction,
        deliveryInstruction: values.deliveryInstruction,
        salesRepId: values.salesRep ? parseInt(values.salesRep.value) : null,
        deliveryRunId: values.deliveryRun
          ? parseInt(values.deliveryRun.value)
          : null,
        creditApplicationStatus: values.creditApplicationStatus,
        creditLimit: values.creditLimit,
        customerGroupIds: values.customerGroups
          ? values.customerGroups.map((item: any) => parseInt(item.value))
          : [],
        tradingAddresses: values.tradingAddresses ?? [],
        status: values.status ? parseInt(values.status.value) : null,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer created successfully",
          });
          return navigate(`/sales/customers`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer creation failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_create_customer")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_create_customer")}
          </p>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-16">
          <Link to="/inventory/products" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_create")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title="Create Customer"
        heading="Customers"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: CustomerCreateResource.name,
            href: null,
          },
        ]}
      />
      <Form
        initialValues={{
          customerCode: "",
          customerName: "",
          customerType: null,
          email: "",
          storeContact: "",
          storeEmail: "",
          storePhoneNumber: "",
          storeFax: "",
          abnNo: "",
          customerPlu: "",
          packingInstruction: "",
          deliveryInstruction: "",
          salesRep: null,
          deliveryRun: null,
          creditApplicationStatus: false,
          creditLimit: 0,
          customerGroups: [],
          tradingAddresses: [],
          status: null,
        }}
        pricingStructures={[]}
        onSubmit={handleSubmit}
        header={<FormHeader />}
      />
    </>
  );
};

export default CustomerCreate;
export const CustomerCreateResource: ResourceProps = {
  name: "Create Customer",
  description: "Create a new customer",
  access: ["create-customers"],
  path: "customers/add",
};
