import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";

const VendorCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CREATE_VENDOR = gql`
    mutation VendorCreate(
      $companyName: String
      $email: String!
      $phoneNumber: String
      $vendorCode: String!
      $webAddress: String
      $vendorType: Int!
      $address: String
      $comments: String
      $status: Boolean!
    ) {
      vendorCreate(
        input: {
          params: {
            companyName: $companyName
            email: $email
            phoneNumber: $phoneNumber
            vendorCode: $vendorCode
            webAddress: $webAddress
            vendorType: $vendorType
            address: $address
            comments: $comments
            status: $status
          }
        }
      ) {
        vendor {
          id
          companyName
          email
          phoneNumber
          vendorCode
          webAddress
          vendorType
          address
          comments
          status
        }
      }
    }
  `;
  const [createVendor] = useMutation(CREATE_VENDOR);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createVendor({
      variables: {
        companyName: values.companyName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        vendorCode: values.vendorCode,
        webAddress: values.webAddress,
        vendorType: values.vendorType
          ? parseInt(values.vendorType.value)
          : null,
        address: values.address,
        comments: values.comments,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.vendorCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vendor created successfully",
          });
          return navigate(`/purchases/vendors`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vendor creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Vendor creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Vendor"
        heading="Vendors"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Vendors",
            href: "/purchases/vendors",
          },
          {
            name: VendorCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-6 sm:flex sm:items-center md:mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_create_vendor")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_create_vendor")}
            </p>
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link
                  to="/purchases/vendors/add"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add vendor
                </Link>
              </div> */}
        </div>

        <Form
          initialValues={{
            companyName: "",
            email: "",
            phoneNumber: "",
            vendorCode: "",
            webAddress: "",
            vendorType: null,
            address: "",
            comments: "",
            status: true,
          }}
          onSubmit={handleSubmit}
          actionLabel={t("text_create")}
        />
      </div>
    </>
  );
};

export default VendorCreate;
export const VendorCreateResource: ResourceProps = {
  name: "Create Vendor",
  description: "Create a new vendor",
  access: ["create-vendors"],
  path: "vendors/add",
};
