import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";
import { Button } from "../../../components/form";

const FETCH_LOCATION = gql`
  query FetchBinLocation($id: ID!) {
    fetchBinLocation(id: $id) {
      id
      name
      parent {
        id
        name
      }
      type {
        id
        name
      }
      status
      branch
      description
      address
      returnAddress
      imageUrl
      latitude
      longitude
      timezone
    }
  }
`;

const LocationUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { locationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type TypeProps = {
    id: string;
    name: string;
  };
  type Location = {
    id: string;
    name: string;
    parent: Location;
    type: TypeProps;
    status: boolean;
    branch: string;
    description: string;
    address: string;
    returnAddress: string;
    imageUrl: string;
    latitude: string;
    longitude: string;
    timezone: string;
  };

  const [location, setLocation] = useState<Location>();

  const [fetchLocation] = useLazyQuery(FETCH_LOCATION);

  useEffect(() => {
    fetchLocation({
      variables: {
        id: locationId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchBinLocation) {
          setLocation(data.fetchBinLocation);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Location read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLocation, locationId]);

  const UPDATE_LOCATION = gql`
    mutation BinLocationUpdate(
      $id: ID!
      $name: String!
      $parentId: Int
      $binLocationTypesId: Int
      $status: Boolean!
      $branch: String
      $description: String
      $address: String
      $returnAddress: String
      $imageUrl: String
      $latitude: String
      $longitude: String
      $timezone: String
    ) {
      binLocationUpdate(
        input: {
          id: $id
          params: {
            name: $name
            parentId: $parentId
            binLocationTypesId: $binLocationTypesId
            status: $status
            branch: $branch
            description: $description
            address: $address
            returnAddress: $returnAddress
            imageUrl: $imageUrl
            latitude: $latitude
            longitude: $longitude
            timezone: $timezone
          }
        }
      ) {
        location {
          id
          name
          description
          parent {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [updateLocation] = useMutation(UPDATE_LOCATION);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateLocation({
      variables: {
        id: location?.id,
        name: values.name,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        binLocationTypesId: values.type ? parseInt(values.type.value) : null,
        status: values.status,
        branch: values.branch,
        description: values.description,
        address: values.address,
        returnAddress: values.returnAddress,
        imageUrl: values.imageUrl,
        latitude: values.latitude,
        longitude: values.longitude,
        timezone: values.timezone ? values.timezone.value : "",
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.binLocationUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Location updated successfully",
          });
          return navigate(`/inventory/locations`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Location update failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_update_location")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_update_location")}
          </p>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-16">
          <Link to="/inventory/locations" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_update")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title={LocationUpdateResource.name}
        heading="Locations"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Locations",
            href: "/inventory/locations",
          },
          {
            name: LocationUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-6xl py-6 sm:py-8">
        <Form
          initialValues={{
            name: location?.name ?? "",
            parent: location?.parent
              ? {
                  label: location?.parent.name,
                  value: location?.parent.id,
                }
              : null,
            type: location?.type
              ? {
                  label: location?.type.name,
                  value: location?.type.id,
                }
              : null,
            status: location?.status ? true : false,
            branch: location?.branch ?? "",
            description: location?.description ?? "",
            address: location?.address ?? "",
            returnAddress: location?.returnAddress ?? "",
            imageUrl: location?.imageUrl ?? "",
            latitude: location?.latitude ?? "",
            longitude: location?.longitude ?? "",
            timezone: location?.timezone
              ? {
                  label: location?.timezone,
                  value: location?.timezone,
                }
              : null,
          }}
          onSubmit={handleSubmit}
          header={<FormHeader />}
        />
      </div>
    </>
  );
};

export default LocationUpdate;
export const LocationUpdateResource: ResourceProps = {
  name: "Edit Location",
  description: "Update an existing location",
  access: ["update-binlocations"],
  path: "locations/:locationId",
};
