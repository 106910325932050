import { useLocation } from "react-router-dom";
import { Head } from "../../../components/core";

export const Error404 = () => {
  const location: any = useLocation();

  return (
    <>
      <Head title="Error 404" heading="Error 404" />
      <div className="sm:flex">
        <p className="text-4xl font-extrabold text-primary-600 sm:text-5xl">
          404
        </p>
        <div className="sm:ml-6">
          <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 className="mb-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              {`${
                location.state?.title ? location.state.title : "Page not found"
              }`}
            </h1>

            <p className="mt-1 text-base text-gray-500 capitalize-first">
              {/* {`${
                location.state?.message
                  ? location.state.message
                  : "Please check the URL in the address bar and try again."
              }`} */}
              We couldn't find the page you were looking for.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
