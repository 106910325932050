import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { Spinner } from "../../../animations";

type User = {
  id: string;
  fullName: string;
  email: string;
  roles: {
    roleName: string;
  }[];
  createdAt: string;
  status: boolean;
};

type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: Customer[];
  createdAt: string;
  status: boolean;
};

type DeliveryRun = {
  id: string;
  name: string;
  dock: {
    id: string;
    name: string;
  };
  createdAt: string;
  status: boolean;
};

type TradingAddresses = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  structureProducts: {
    id: string;
    name: string;
  }[];
  customers: {
    id: string;
    customerName: string;
  }[];
  status: boolean;
};

type Customer = {
  id: string;
  abnNo: string;
  email: string;
  createdAt: string;
  creditApplicationStatus: boolean;
  creditLimit: number;
  customerCode: string;
  customerGroups: CustomerGroup[];
  customerName: string;
  customerPlu: string;
  customerType: number;
  deliveryInstruction: string;
  deliveryRuns: DeliveryRun;
  packingInstruction: string;
  pricingStructures: PricingStructure[];
  salesRep: User;
  storeContact: string;
  storeEmail: string;
  storeFax: string;
  storePhoneNumber: string;
  tradingAddresses: TradingAddresses[];
  status: number;
};

const FETCH_CUSTOMER = gql`
  query FetchCustomer($id: ID!) {
    fetchCustomer(id: $id) {
      id
      abnNo
      email
      createdAt
      creditApplicationStatus
      creditLimit
      customerCode
      customerGroups {
        id
        name
      }
      customerName
      customerPlu
      customerType
      deliveryInstruction
      deliveryRuns {
        id
        name
      }
      packingInstruction
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
        structureProducts {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
      salesRep {
        id
        fullName
      }
      storeContact
      storeEmail
      storeFax
      storePhoneNumber
      tradingAddresses {
        address
        suburb
        state
        postcode
      }
      status
    }
  }
`;

const UPDATE_CUSTOMER = gql`
  mutation CustomerUpdate(
    $id: ID!
    $customerCode: String!
    $customerName: String!
    $customerType: Int!
    $email: String!
    $storeContact: String
    $storeEmail: String!
    $storePhoneNumber: String
    $storeFax: String
    $abnNo: String!
    $customerPlu: String!
    $packingInstruction: String
    $deliveryInstruction: String
    $salesRepId: Int
    $deliveryRunId: Int
    $creditApplicationStatus: Boolean
    $creditLimit: Float
    $customerGroupIds: [Int!]
    $tradingAddresses: [CustomertradingaddressInput!]
    $status: Int!
  ) {
    customerUpdate(
      input: {
        id: $id
        params: {
          customerCode: $customerCode
          customerName: $customerName
          customerType: $customerType
          email: $email
          storeContact: $storeContact
          storeEmail: $storeEmail
          storePhoneNumber: $storePhoneNumber
          storeFax: $storeFax
          abnNo: $abnNo
          customerPlu: $customerPlu
          packingInstruction: $packingInstruction
          deliveryInstruction: $deliveryInstruction
          salesRepId: $salesRepId
          deliveryRunId: $deliveryRunId
          creditApplicationStatus: $creditApplicationStatus
          creditLimit: $creditLimit
          customerGroupIds: $customerGroupIds
          tradingAddresses: $tradingAddresses
          status: $status
        }
      }
    ) {
      customer {
        id
        abnNo
        email
        createdAt
        creditApplicationStatus
        creditLimit
        customerCode
        customerGroups {
          id
          name
        }
        customerName
        customerPlu
        customerType
        deliveryInstruction
        deliveryRuns {
          id
          name
        }
        packingInstruction
        salesRep {
          id
          fullName
        }
        storeContact
        storeEmail
        storeFax
        storePhoneNumber
        tradingAddresses {
          address
          suburb
          state
          postcode
        }
        status
      }
    }
  }
`;

const CustomerUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { customerId } = useParams<{ customerId: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState<Customer>();

  const [fetchCustomer] = useLazyQuery(FETCH_CUSTOMER);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const didFetchCustomers = useRef(false);
  const fetchedCustomers = useCallback(() => {
    if (!customerId) return;

    fetchCustomer({
      variables: {
        id: customerId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCustomer) {
          console.log(data.fetchCustomer);
          setCustomer(data.fetchCustomer);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Customer read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomer, customerId]);

  useEffect(() => {
    if (!didFetchCustomers.current) {
      fetchedCustomers();
      didFetchCustomers.current = true;
    }
  }, [fetchedCustomers]);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {

    updateCustomer({
      variables: {
        id: customer?.id,
        customerCode: values.customerCode,
        customerName: values.customerName,
        customerType: values.customerType
          ? parseInt(values.customerType.value)
          : null,
        email: values.email,
        storeContact: values.storeContact,
        storeEmail: values.storeEmail,
        storePhoneNumber: values.storePhoneNumber,
        storeFax: values.storeFax,
        abnNo: values.abnNo,
        customerPlu: values.customerPlu,
        packingInstruction: values.packingInstruction,
        deliveryInstruction: values.deliveryInstruction,
        salesRepId: values.salesRep ? parseInt(values.salesRep.value) : null,
        deliveryRunId: values.deliveryRun
          ? parseInt(values.deliveryRun.value)
          : null,
        creditApplicationStatus: values.creditApplicationStatus,
        creditLimit: values.creditLimit,
        customerGroupIds: values.customerGroups
          ? values.customerGroups.map((item: any) => parseInt(item.value))
          : [],
        tradingAddresses: values.tradingAddresses ?? [],
        status: values.status ? parseInt(values.status.value) : null,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer updated successfully",
          });
          return navigate(`/sales/customers`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer update failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_update_customer")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_update_customer")}
          </p>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-16">
          <Link to="/inventory/products" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit" className="mr-2">
            {t("text_update")}
          </Button>
          <Button
            variant="secondary"
            className="whitespace-nowrap border-gray-900 bg-gray-900 text-white hover:bg-gray-800 hover:text-white"
            onClick={() => {
              navigate(`/sales/customers/${customerId}/products`);
            }}
          >
            View Product Pricing
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title={CustomerUpdateResource.name}
        heading="Customers"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: CustomerUpdateResource.name,
            href: null,
          },
        ]}
      />
      {customer ? (
        <Form
          initialValues={{
            customerCode: customer.customerCode ?? "",
            customerName: customer.customerName ?? "",
            customerType: String(customer.customerType)
              ? {
                  value: String(customer.customerType),
                  label: customer.customerType === 1 ? "Company" : "Individual",
                }
              : null,
            email: customer.email ?? "",
            storeContact: customer.storeContact ?? "",
            storeEmail: customer.storeEmail ?? "",
            storePhoneNumber: customer.storePhoneNumber ?? "",
            storeFax: customer.storeFax ?? "",
            abnNo: customer.abnNo ?? "",
            customerPlu: customer.customerPlu ?? "",
            packingInstruction: customer.packingInstruction ?? "",
            deliveryInstruction: customer.deliveryInstruction ?? "",
            salesRep: customer.salesRep
              ? {
                  label: customer.salesRep?.fullName,
                  value: customer.salesRep?.id,
                }
              : null,
            deliveryRun: customer.deliveryRuns
              ? {
                  label: customer.deliveryRuns.name,
                  value: customer.deliveryRuns.id,
                }
              : null,
            creditApplicationStatus: customer.creditApplicationStatus ?? false,
            creditLimit: customer.creditLimit ?? 0,
            customerGroups: customer.customerGroups
              ? customer.customerGroups.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })
              : [],
            tradingAddresses: customer.tradingAddresses
              ? customer.tradingAddresses.map((item: any) => {
                  return {
                    address: item.address ?? "",
                    suburb: item.suburb ?? "",
                    state: item.state ?? "",
                    postcode: item.postcode ?? "",
                  };
                })
              : [],
            status: String(customer.status)
              ? {
                  label:
                    customer.status === 3
                      ? "Rejected"
                      : customer.status === 2
                      ? "Approve/Active"
                      : customer.status === 1
                      ? "Pending"
                      : "Inactive",
                  value: String(customer.status),
                }
              : null,
          }}
          pricingStructures={customer.pricingStructures.map(
            (pricingStructure: PricingStructure) => ({
              id: pricingStructure.id,
              name: pricingStructure.name,
              pricingMethod: pricingStructure.pricingMethod,
              productIds: pricingStructure.structureProducts.map((product) =>
                parseInt(product.id)
              ),
              customerIds: pricingStructure.customers.map((customer) =>
                parseInt(customer.id)
              ),
              status: pricingStructure.status,
            })
          )}
          onSubmit={handleSubmit}
          header={<FormHeader />}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default CustomerUpdate;
export const CustomerUpdateResource: ResourceProps = {
  name: "Edit Customer",
  description: "Update an existing customer",
  access: ["update-customers"],
  path: "customers/:customerId",
};
