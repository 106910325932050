import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Form from "./components/FormUpdate";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";

import { Head } from "../../../../components/core";
import { Spinner } from "../../../../animations";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type Product = {
  id: string;
  name: string;
};

type SpecialPrice = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  products: Product[];
  customerType: number;
  customers: {
    id: string;
    customerName: string;
  }[];
  startDate: string;
  endDate: string;
  createdAt: string;
  status: boolean;
};

const FETCH_SPECIALPRICE = gql`
  query FetchSpecialPrice($id: ID!) {
    fetchSpecialPrice(id: $id) {
      id
      name
      pricingMethod {
        id
        name
      }
      products {
        id
        name
      }
      customerType
      customers {
        id
        customerName
      }
      startDate
      endDate
      createdAt
      status
    }
  }
`;

const SpecialPriceUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { specialPriceId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [specialPrice, setSpecialPrice] = useState<SpecialPrice>();

  const [fetchSpecialPrice] = useLazyQuery(FETCH_SPECIALPRICE);

  const didFetchSpecialPrice = useRef(false);
  const fetchingSpecialPrice = useCallback(() => {
    fetchSpecialPrice({
      variables: {
        id: specialPriceId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchSpecialPrice) {
          setSpecialPrice(data.fetchSpecialPrice);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Special price read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSpecialPrice, specialPriceId]);

  useEffect(() => {
    if (!didFetchSpecialPrice.current) {
      fetchingSpecialPrice();
      didFetchSpecialPrice.current = true;
    }
  }, [fetchingSpecialPrice]);

  const UPDATE_SPECIALPRICE = gql`
    mutation SpecialPriceUpdate(
      $id: ID!
      $name: String!
      $pricingMethodId: Int!
      $customerType: Int
      $customerIds: [Int!]
      $startDate: ISO8601DateTime
      $endDate: ISO8601DateTime
      $status: Boolean
    ) {
      specialPriceUpdate(
        input: {
          id: $id
          params: {
            name: $name
            pricingMethodId: $pricingMethodId
            customerType: $customerType
            customerIds: $customerIds
            startDate: $startDate
            endDate: $endDate
            status: $status
          }
        }
      ) {
        specialPrice {
          id
          name
          pricingMethod {
            id
            name
          }
          products {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [updateSpecialPrice] = useMutation(UPDATE_SPECIALPRICE);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateSpecialPrice({
      variables: {
        id: specialPrice?.id,
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        customerType: values.customerType,
        customerIds: values.customerIds,
        startDate: values.startDate
          ? new Date(values.startDate).toISOString()
          : null,
        endDate: values.endDate ? new Date(values.endDate).toISOString() : null,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.specialPriceUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Special price updated successfully",
          });
          return navigate(`/inventory/pricelist/special-prices`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Special price update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Special price update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={SpecialPriceUpdateResource.name}
        heading="Special Prices"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Special Prices",
            href: "/inventory/pricelist/special-prices",
          },
          {
            name: SpecialPriceUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_special_price")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_special_price")}
              </p>
            </div>
          </div>

          {specialPrice ? (
            <Form
              initialValues={{
                name: specialPrice.name ?? "",
                pricingMethod: specialPrice.pricingMethod
                  ? {
                      label: specialPrice.pricingMethod.name,
                      value: specialPrice.pricingMethod.id,
                    }
                  : null,
                customerType: specialPrice.customerType ?? 0,
                customerIds: specialPrice.customers
                  ? specialPrice.customers.map((c) => parseInt(c.id))
                  : [],
                startDate: specialPrice.startDate ?? "",
                endDate: specialPrice.endDate ?? "",
                status: specialPrice.status ? true : false,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};

export default SpecialPriceUpdate;
export const SpecialPriceUpdateResource: ResourceProps = {
  name: "Edit Special Price",
  description: "Update an existing special price",
  access: ["update-specialprices"],
  path: "special-prices/:specialPriceId",
};
