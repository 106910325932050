import { useContext, useEffect } from "react";
import { GenContext } from "../../contexts/GenContext";
import { useTitle } from "../../hooks/useTitle";

export function Head({
  title,
  heading,
  description,
  breadcrumbs = [],
}: {
  title: string;
  heading: string;
  description?: string;
  breadcrumbs?: {
    name: string;
    href: string | null;
  }[];
}) {
  const { updatePage } = useContext(GenContext);
  useTitle(title);
  useEffect(() => {
    updatePage({ title, heading, description, breadcrumbs });
  }, []);
  return <></>;
}
