import { Outlet } from "react-router-dom";
import HeaderLayout from "./HeaderLayout";

export default function PageLayout() {
  return (
    <>
    <div className="lg:mx-auto xl:max-w-6xl xl:px-8 pb-6 xl:pb-8">
      <HeaderLayout />
      <Outlet />
    </div>
    </>
  );
}
