import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";
import { Spinner } from "../../../animations";

const FETCH_ROLES = gql`
  query FetchRole($id: ID!) {
    fetchRole(id: $id) {
      id
      roleName
      roleDescription
      permissions {
        id
        permissionName
        permissionDisplayName
        permissionDescription
        createdAt
        status
      }
      createdAt
      status
    }
  }
`;

const RoleUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { roleId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type Permissions = {
    id: string;
    permissionName: string;
    permissionDisplayName: string;
    permissionDescription: string;
    createdAt: string;
    status: string;
  };

  type Role = {
    id: string;
    roleName: string;
    roleDescription: string;
    permissions: Permissions[];
    createdAt: string;
    status: boolean;
  };

  const [role, setRole] = useState<Role>();

  const [fetchRole] = useLazyQuery(FETCH_ROLES);

  const didFetchRole = useRef(false);
  const fetchingRole = useCallback(() => {
    fetchRole({
      variables: {
        id: roleId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchRole) {
          setRole(data?.fetchRole as Role);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Role read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRole, roleId]);

  useEffect(() => {
    if (!didFetchRole.current) {
      fetchingRole();
      didFetchRole.current = true;
    }
  }, [fetchingRole]);

  useEffect(() => {
    console.log(123);
  }, []);

  const UPDATE_ROLE = gql`
    mutation RoleUpdate(
      $id: ID!
      $roleName: String!
      $roleDescription: String!
      $permissions: [Int!]!
      $status: Boolean!
    ) {
      roleUpdate(
        input: {
          id: $id
          params: {
            roleName: $roleName
            roleDescription: $roleDescription
            permissions: $permissions
            status: $status
          }
        }
      ) {
        role {
          id
          roleName
        }
      }
    }
  `;
  const [updateRole] = useMutation(UPDATE_ROLE);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateRole({
      variables: {
        id: role?.id,
        roleName: values.roleName,
        roleDescription: values.roleDescription,
        permissions: values.permissions,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.roleUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            message: t("Role updated successfully"),
          });
          return navigate("/user-management/roles");
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: t("Role update failed"),
            message: t("Something went wrong, please try again later"),
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: t("Role update failed"),
          message: t(error.message),
        });
      });
  };

  return (
    <>
      <Head
        title="Roles"
        heading="Roles"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Roles",
            href: "/user-management/roles",
          },
          {
            name: RoleUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_update_role")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_update_role")}
            </p>
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/user-management/users/add"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
            >
              Add user
            </Link>
          </div> */}
        </div>
        {role ? (
          <div className="mt-6 flex flex-col md:mt-8">
            <div className="md:col-span-2">
              <Form
                initialValues={{
                  roleName: role.roleName ?? "",
                  roleDescription: role.roleDescription ?? "",
                  permissions:
                    role.permissions.map((p) => parseInt(p.id)) ?? [],
                  status: role.status ?? true,
                }}
                onSubmit={handleSubmit}
                actionLabel={t("text_update")}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default RoleUpdate;
export const RoleUpdateResource: ResourceProps = {
  name: "Edit Role",
  description: "Update an existing role",
  access: ["update-roles"],
  path: "roles/:roleId",
};
