import { useEffect, useMemo, useState, Fragment, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../components/table";
import { MultiValue } from "react-select";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Form from "./components/Form";

import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type Resource = {
  id: string;
  resourceName: string;
  resourceDescription: string;
  status: string;
};

type Dependency = {
  label: string;
  value: string;
};

type Permission = {
  id?: string;
  permissionName: string;
  permissionDisplayName: string;
  permissionDescription: string;
  dependencies?: MultiValue<Dependency>;
  createdAt: string;
  resource: Resource;
  status: boolean;
};

const FETCH_PERMISSIONS = gql`
  query FetchPermissions {
    fetchPermissions {
      id
      permissionName
      permissionDisplayName
      permissionDescription
      dependencies
      createdAt
      status
      resource {
        id
        resourceName
        resourceDescription
        status
      }
    }
  }
`;

const PermissionList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchPermissions, { loading }] = useLazyQuery(FETCH_PERMISSIONS);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [activePermission, setActivePermission] = useState<
    Permission | undefined
  >(undefined);
  const [newPermission, setNewPermission] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    fetchPermissions()
      .then(({ data, error }) => {
        if (data?.fetchPermissions) {
          const updatedPermissions = data.fetchPermissions.map(
            (item: Permission) => ({
              ...item,
              createdAt: DateTime.fromISO(item.createdAt, {
                locale: i18n.language,
              }).toLocaleString(DateTime.DATE_MED),
            })
          );
          setPermissions(updatedPermissions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Permissions read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPermissions]);

  const UPDATE_PERMISSION = gql`
    mutation PermissionUpdate(
      $id: ID!
      $permissionName: String!
      $permissionDisplayName: String!
      $permissionDescription: String!
      $dependencies: [Int!]
      $resourceId: Int!
      $status: Boolean!
    ) {
      permissionUpdate(
        input: {
          id: $id
          params: {
            permissionName: $permissionName
            permissionDisplayName: $permissionDisplayName
            permissionDescription: $permissionDescription
            dependencies: $dependencies
            resourceId: $resourceId
            status: $status
          }
        }
      ) {
        permission {
          id
          permissionName
          permissionDisplayName
          permissionDescription
          dependencies
          createdAt
          status
          resource {
            id
            resourceName
            resourceDescription
            status
          }
        }
      }
    }
  `;
  const [updatePermission] = useMutation(UPDATE_PERMISSION);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updatePermission({
      variables: {
        id: activePermission?.id,
        permissionName: values.permissionName,
        permissionDisplayName: values.permissionDisplayName,
        permissionDescription: values.permissionDescription,
        dependencies:
          values?.dependencies.map((d: Dependency) => {
            return parseInt(d.value);
          }) ?? [],
        resourceId: parseInt(values.resource.id),
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.permissionUpdate) {
          const newPermissions = [...permissions];
          const index = newPermissions.findIndex(
            (permission) => permission.id === activePermission?.id
          );
          newPermissions[index] = {
            ...data.permissionUpdate?.permission,
            createdAt: DateTime.fromISO(
              data.permissionUpdate?.permission.createdAt,
              {
                locale: i18n.language,
              }
            ).toLocaleString(DateTime.DATE_MED),
          };
          setPermissions(newPermissions);
          setActivePermission(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Permission update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Permission update failed",
          message: error.message,
        });
      });
  };

  const CREATE_PERMISSION = gql`
    mutation PermissionCreate(
      $permissionName: String!
      $permissionDisplayName: String!
      $permissionDescription: String!
      $dependencies: [Int!]
      $resourceId: Int!
      $status: Boolean!
    ) {
      permissionCreate(
        input: {
          params: {
            permissionName: $permissionName
            permissionDescription: $permissionDescription
            permissionDisplayName: $permissionDisplayName
            dependencies: $dependencies
            resourceId: $resourceId
            status: $status
          }
        }
      ) {
        permission {
          id
          permissionName
          permissionDisplayName
          permissionDescription
          dependencies
          createdAt
          status
          resource {
            id
            resourceName
            resourceDescription
            status
          }
        }
      }
    }
  `;
  const [createPermission] = useMutation(CREATE_PERMISSION);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createPermission({
      variables: {
        permissionName: values.permissionName,
        permissionDisplayName: values.permissionDisplayName,
        permissionDescription: values.permissionDescription,
        dependencies: values?.dependencies.map((d: Dependency) => {
          return parseInt(d.value);
        }),
        resourceId: parseInt(values.resource.id),
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.permissionCreate) {
          setPermissions([
            ...permissions,
            {
              ...data.permissionCreate.permission,
              createdAt: DateTime.fromISO(
                data.permissionCreate.permission.createdAt,
                {
                  locale: i18n.language,
                }
              ).toLocaleString(DateTime.DATE_MED),
            },
          ]);
          setNewPermission(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Permission created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Permission create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Permission create failed",
          message: error.message,
        });
      });
  };

  const DELETE_PERMISSION = gql`
    mutation PermissionDelete($id: ID!) {
      permissionDelete(input: { id: $id }) {
        permission {
          id
          permissionName
        }
      }
    }
  `;
  const [deletePermission] = useMutation(DELETE_PERMISSION);
  const handleDelete = async (id: string) => {
    deletePermission({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.permissionDelete) {
          const deletedPermission = data?.permissionDelete?.permission;
          const newPermissions = [...permissions];
          const index = newPermissions.findIndex(
            (permission) => permission.id === id
          );
          newPermissions.splice(index, 1);
          setPermissions(newPermissions);

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Permission ${deletedPermission.permissionName} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Permission deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Permission deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const updateHandler = useCallback(
    (
      dependencies: MultiValue<Dependency> | undefined,
      permission: Permission
    ) => {
      const filterDependencies = permissions.filter((p: Permission) => {
        return dependencies?.includes(parseInt(p.id!) as any);
      });
      const filteredDependencies = filterDependencies?.map((p: any) => {
        return {
          value: p.id,
          label: p.permissionName,
        };
      });
      const formattedPermission = {
        ...permission,
        dependencies: filteredDependencies,
      };
      setActivePermission(formattedPermission);
    },
    [permissions]
  );

  const columns = useMemo<ColumnDef<Permission>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "permissionDisplayName",
        header: "Name",
      },
      {
        accessorKey: "permissionDescription",
        header: "Description",
        cell: (info: any) => (
          <div dangerouslySetInnerHTML={{ __html: info.getValue() }} />
        ),
      },
      {
        accessorKey: "resource",
        header: "Resource",
        cell: (info: any) => info.getValue().resourceName,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-permissions"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => {
                  updateHandler(
                    props.row.original?.dependencies,
                    props.row.original
                  );
                }}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.permissionName}
                </span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-permissions"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.permissionName,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.permissionName}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language, permissions, updateHandler]
  );

  return (
    <>
      <Head
        title="Permissions"
        heading="Permissions"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: PermissionListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Permissions List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the permissions in your account including their
              name, title, email and permission.
            </p>
          </div>
        </div>

        <TablePaginate
          data={permissions}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-permissions"]}>
              <Button onClick={() => setNewPermission(true)}>
                Add permission
              </Button>
            </AuthShield>
          )}
        />
      </div>

      <Transition.Root
        show={activePermission ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePermission(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Permission - "
                  initialValues={{
                    id: activePermission?.id,
                    permissionName: activePermission?.permissionName ?? "",
                    permissionDisplayName:
                      activePermission?.permissionDisplayName ?? "",
                    permissionDescription:
                      activePermission?.permissionDescription ?? "",
                    dependencies: activePermission?.dependencies ?? [],
                    resource: activePermission?.resource ?? ({} as Resource),
                    status: activePermission?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActivePermission(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newPermission ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPermission(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Permission - "
                  initialValues={{
                    permissionName: "",
                    permissionDisplayName: "",
                    permissionDescription: "",
                    dependencies: [],
                    resource: {} as Resource,
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewPermission(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* <Transition.Root show={activePermission ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePermission(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="Edit Permission - "
                      initialValues={{
                        id: activePermission?.id,
                        permissionName: activePermission?.permissionName ?? "",
                        permissionDisplayName:
                          activePermission?.permissionDisplayName ?? "",
                        permissionDescription:
                          activePermission?.permissionDescription ?? "",
                        dependencies: activePermission?.dependencies ?? [],
                        resource:
                          activePermission?.resource ?? ({} as Resource),
                        status: activePermission?.status ?? true,
                      }}
                      onSubmit={handleUpdate}
                      submitLabel={t("text_update")}
                      onCancel={() => setActivePermission(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}

      {/* <Transition.Root show={newPermission ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPermission(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="New Permission - "
                      initialValues={{
                        permissionName: "",
                        permissionDisplayName: "",
                        permissionDescription: "",
                        dependencies: [],
                        resource: {} as Resource,
                        status: true,
                      }}
                      onSubmit={handleCreate}
                      submitLabel={t("text_create")}
                      onCancel={() => setNewPermission(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
};

export default PermissionList;
export const PermissionListResource: ResourceProps = {
  name: "Permissions List",
  description: "A list of permissions",
  access: ["create-permissions", "read-permissions"],
  path: "permissions",
};
