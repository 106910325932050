import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";
import {
  Button,
  Field,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";

import { classNames } from "../../../../utils";
import { isAdmin } from "../../../auth";
import { Role } from "../../../auth/@types/auth";
import { Spinner } from "../../../../animations";
import { Switch } from "@headlessui/react";

type RoleProps = {
  readonly label: string;
  readonly value: string;
};

type User = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  phoneNumber: string;
  roles: RoleProps[];
  status: boolean;
};

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: User;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const UserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string().required("Required"),
    userName: Yup.string().required("Required"),
    roles: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required("Required"),
          value: Yup.string().required("Required"),
        })
      )
      .required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="overflow-hidden rounded-md shadow-sm">
        <div className="bg-white px-4 py-5 sm:p-6">
          <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_firstname")}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                touched={touched.firstName}
                errors={errors.firstName}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_lastname")}
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                touched={touched.lastName}
                errors={errors.lastName}
              />
            </div>

            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_email")}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                touched={touched.email}
                errors={errors.email}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_phone")}
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                touched={touched.phoneNumber}
                errors={errors.phoneNumber}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_username")}
                name="userName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                touched={touched.userName}
                errors={errors.userName}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_roles")}
              </label>
              <FieldRoles
                value={formik.values.roles}
                onChange={(value: MultiValue<RoleProps>) => {
                  formik.setFieldValue("roles", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.roles && formik.errors.roles
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.roles && formik.errors.roles ? (
                <p className="mt-2 text-sm text-red-600" id="roles-errors">
                  {formik.errors.roles.toString()}
                </p>
              ) : null}
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <fieldset>
                <legend className="text-sm font-medium text-gray-900">
                  {t("text_status")}
                </legend>
                <Switch.Group
                  as="div"
                  className="mt-1.5 inline-flex items-center"
                >
                  <Switch
                    checked={formik.values.status}
                    onChange={() => {
                      formik.setFieldValue("status", !formik.values.status);
                    }}
                    id="status"
                    className={classNames(
                      formik.values.status ? "bg-primary-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        formik.values.status
                          ? "translate-x-5"
                          : "translate-x-0",
                        "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                  <Switch.Label
                    passive
                    htmlFor="status"
                    className="ml-2 mb-0 block text-sm font-normal text-gray-700"
                  >
                    {formik.values.status
                      ? t("text_active")
                      : t("text_inactive")}
                  </Switch.Label>
                </Switch.Group>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="flex justify-end border-t border-gray-300 bg-white px-4 py-4 text-right md:py-6 md:px-6">
          <Link to="/user-management/users" className="mr-4 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              actionLabel
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

const FETCH_ROLES = gql`
  query FetchRoles($status: Boolean) {
    fetchRoles(status: $status) {
      id
      roleName
    }
  }
`;

export function FieldRoles({
  value,
  onChange,
  className,
}: {
  value: MultiValue<RoleProps>;
  onChange: (newValue: MultiValue<RoleProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<MultiValue<RoleProps>>(value);
  const [options, setOptions] = useState<MultiValue<RoleProps>>([]);
  const [fetchRoles] = useLazyQuery(FETCH_ROLES);

  useEffect(() => {
    fetchRoles({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchRoles) {
          const newOptions = data.fetchRoles.filter((r: Role) => {
            if (isAdmin(r)) return false;
            return r;
          });
          const updatedOptions: RoleProps[] = newOptions?.map((r: Role) => {
            return {
              label: r.roleName,
              value: r.id,
            };
          });
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Role read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoles]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={(newValue: MultiValue<RoleProps>) => {
          if (newValue.length > 1) {
            onChange([newValue[newValue.length - 1]]);
          } else {
            onChange(newValue);
          }
        }}
        isClearable
      />
    </SelectWrapper>
  );
}
