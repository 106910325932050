import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Dialog, Switch } from "@headlessui/react";
import Select, { MultiValue, SingleValue } from "react-select";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Field,
  FieldStructureProducts,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { classNames } from "../../../../utils";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";
import { Spinner } from "../../../../animations";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[] | null;
  status: boolean;
};

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productIds: number[];
  customerIds: number[];
  status: boolean;
};

type Structure = {
  id: string;
  name: string;
  pricingMethod: SingleValue<OptionProps>;
  productIds: number[];
  status: boolean;
};

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
};

type Product = {
  id: string;
  index: number;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

const DELETE_PRODUCTPRICING = gql`
  mutation PricingStructurePricingDelete(
    $productId: Int!
    $pricingStructureId: Int!
  ) {
    pricingStructurePricingDelete(
      input: { productId: $productId, pricingStructureId: $pricingStructureId }
    ) {
      message
    }
  }
`;

export default function Form({
  heading,
  initialValues,
  products,
  occupiedProductIds: initialOccupiedProductIds,
  pricingStructures,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
  actionType,
}: {
  heading: string;
  initialValues: Structure;
  products: Product[];
  occupiedProductIds: number[];
  pricingStructures: PricingStructure[];
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
  actionType: string;
}) {
  const { t } = useTranslation();
  const { pricingStructureId } = useParams();
  const { addNotify } = useNotifyContext();

  const [deleteProductPricing, { loading: deletingProducts }] = useMutation(
    DELETE_PRODUCTPRICING
  );

  const StructureSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    pricingMethod: Yup.object().required("Required"),
    productIds: Yup.array()
      .min(1)
      .of(Yup.string().trim().required("Required"))
      .required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: StructureSchema,
    onSubmit: onSubmit,
  });

  const occupiedProductIds: number[] = useMemo(() => {
    if (initialValues.id && parseInt(initialValues.id) > 0) {
      const pricingStructureProductIds = pricingStructures.reduce(
        (acc: number[], structure) => {
          if (structure.id === initialValues.id) {
            return acc;
          }
          return [...acc, ...structure.productIds];
        },
        []
      );
      console.log(
        "edit pricingStructureProductIds",
        pricingStructureProductIds
      );
      return [...initialOccupiedProductIds, ...pricingStructureProductIds];
    } else {
      const pricingStructureProductIds = pricingStructures.reduce(
        (acc: number[], structure) => [...acc, ...structure.productIds],
        []
      );
      console.log(
        "create pricingStructureProductIds",
        pricingStructureProductIds
      );
      return [...initialOccupiedProductIds, ...pricingStructureProductIds];
    }
  }, [initialOccupiedProductIds, initialValues.id, pricingStructures]);

  const handleRemoveProducts = useCallback(
    async (productIds: number[], pricingStructureId: number) => {
      console.log(pricingStructureId, "handleRemoveProducts", productIds);
      if (!pricingStructureId) return;

      for (const productId of productIds) {
        await deleteProductPricing({
          variables: {
            productId: productId,
            pricingStructureId: pricingStructureId,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructurePricingDelete) {
              addNotify({
                type: NotifyType.SUCCESS,
                title: "Product removed successfully",
              });
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Prodcut remove failed",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            addNotify({
              type: NotifyType.ERROR,
              title: "Prodcut remove failed",
              message: error.message,
            });
          });
      }
    },
    [deleteProductPricing, pricingStructureId]
  );

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="py-8 px-4 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.name ? formik.values.name : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Get started by filling in the information below to create your
                new department.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_name")}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    {initialValues.id && parseInt(initialValues.id) > 0 ? (
                      <Field
                        title={t("text_pricing_method")}
                        name="name"
                        value={
                          formik.values.pricingMethod
                            ? formik.values.pricingMethod.label
                            : "--"
                        }
                        disabled
                        readOnly
                      />
                    ) : (
                      <>
                        <label className="block text-sm font-medium text-gray-900">
                          {t("text_pricing_method")}
                        </label>
                        <FieldPricingMethod
                          value={formik.values.pricingMethod}
                          onChange={(value: SingleValue<OptionProps>) => {
                            formik.setFieldValue("pricingMethod", value);
                          }}
                          className={classNames(
                            "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                            formik.touched.pricingMethod &&
                              formik.errors.pricingMethod
                              ? "border-red-600 text-red-900"
                              : ""
                          )}
                        />
                        {formik.touched.pricingMethod &&
                        formik.errors.pricingMethod ? (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="pricingMethod-errors"
                          >
                            {formik.errors.pricingMethod.toString()}
                          </p>
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className="sm:col-span-6">
                    <FieldStructureProducts
                      loading={deletingProducts}
                      products={products}
                      occupiedIds={occupiedProductIds}
                      values={formik.values.productIds}
                      onChange={(values) => {
                        console.log("values", formik.values.productIds, values);
                        // const existingProductIds = values.filter((v) =>
                        //   formik.values.productIds.includes(v)
                        // );
                        const addedProductIds = values.filter(
                          (v) => !formik.values.productIds.includes(v)
                        );
                        const removedProductIds =
                          formik.values.productIds.filter(
                            (v) => !values.includes(v)
                          );
                        // console.log("existingProductIds", existingProductIds);
                        // console.log("addedProductIds", addedProductIds);
                        // console.log("removedProductIds", removedProductIds);

                        if (
                          removedProductIds.length > 0 &&
                          initialValues.id &&
                          parseInt(initialValues.id) > 0
                        ) {
                          console.log("removedProductIds", removedProductIds);
                          handleRemoveProducts(
                            removedProductIds,
                            parseInt(initialValues.id)
                          );
                        }
                        formik.setFieldValue("productIds", addedProductIds);
                      }}
                    />
                    {formik.touched.productIds && formik.errors.productIds ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="productIds-errors"
                      >
                        {formik.errors.productIds.toString()}
                      </p>
                    ) : null}
                  </div>
                  <div className="sm:col-span-3">
                    <fieldset>
                      <legend className="text-sm font-medium text-gray-900">
                        {t("text_status")}
                      </legend>
                      <Switch.Group
                        as="div"
                        className="mt-1.5 inline-flex items-center"
                      >
                        <Switch
                          checked={formik.values.status}
                          onChange={() => {
                            formik.setFieldValue(
                              "status",
                              !formik.values.status
                            );
                          }}
                          id="status"
                          className={classNames(
                            formik.values.status
                              ? "bg-primary-600"
                              : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              formik.values.status
                                ? "translate-x-5"
                                : "translate-x-0",
                              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                        <Switch.Label
                          passive
                          htmlFor="status"
                          className="ml-2 mb-0 block text-sm font-normal text-gray-700"
                        >
                          {formik.values.status
                            ? t("text_active")
                            : t("text_inactive")}
                        </Switch.Label>
                      </Switch.Group>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit">
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

const FETCH_PRICINGMETHODS = gql`
  query FetchPricingMethods($status: Boolean) {
    fetchPricingMethods(status: $status) {
      id
      name
      sellPriceFormula
      pricingMethodFields {
        fieldType
        fieldKey
        fieldName
        fieldOptions
      }
      createdAt
      status
    }
  }
`;

export function FieldPricingMethod({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchPricingMethods] = useLazyQuery(FETCH_PRICINGMETHODS);

  useEffect(() => {
    fetchPricingMethods({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchPricingMethods) {
          const updatedOptions: OptionProps[] = data.fetchPricingMethods?.map(
            (p: PricingMethod) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing methods read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingMethods]);

  useEffect(() => {
    let updatedValues = null;
    if (value) {
      const activeOption = options.find((o) => o.value === value.value);
      if (activeOption) {
        updatedValues = {
          label: activeOption.label,
          value: activeOption.value,
        };
      }
    }
    setValues(updatedValues);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}
