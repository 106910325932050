import { useAuth } from "../../modules/auth";
import { classNames } from "../../utils";
export function Avatar({ className }: { className?: string }) {
  const { currentUser } = useAuth();
  const userLetter = `${currentUser?.firstName?.charAt(
    0
  )}${currentUser?.lastName?.charAt(0)}`;
  return (
    <span
      className={classNames(
        "inline-flex h-10 w-10 items-center justify-center rounded-full",
        className ? className : "bg-primary-700"
      )}
    >
      <span className="font-medium leading-none text-current">{userLetter}</span>
    </span>
  );
}
