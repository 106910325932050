import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Switch, Menu, Transition } from "@headlessui/react";
import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  ChevronDownIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";

import Select, { MultiValue, SingleValue } from "react-select";

import {
  Button,
  Field,
  FieldDatepicker,
  FieldProducts,
  selectStyles,
  SelectWrapper,
} from "../../../../../components/form";

import { AlertType, useAlert } from "../../../../../hooks/useAlert";

import { Spinner } from "../../../../../animations";
import { classNames } from "../../../../../utils";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id?: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type SpecialPrice = {
  id?: number;
  name: string;
  pricingMethod: SingleValue<OptionProps>;
  productIds: number[];
  startDate: string;
  endDate: string;
  status: boolean;
};

const FETCH_PRICINGMETHODS = gql`
  query FetchPricingMethods($status: Boolean) {
    fetchPricingMethods(status: $status) {
      id
      name
      sellPriceFormula
      pricingMethodFields {
        fieldType
        fieldKey
        fieldName
        fieldOptions
      }
      createdAt
      status
    }
  }
`;

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: SpecialPrice;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const [fetchPricingMethods] = useLazyQuery(FETCH_PRICINGMETHODS);

  const SpecialPriceSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    pricingMethod: Yup.object().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: SpecialPriceSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={touched.name}
            errors={errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_pricing_method")}
          </label>
          <FieldPricingMethod
            fetchPricingMethods={fetchPricingMethods}
            value={formik.values.pricingMethod}
            onChange={(value: SingleValue<OptionProps>) => {
              formik.setFieldValue("pricingMethod", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.pricingMethod && formik.errors.pricingMethod
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.pricingMethod && formik.errors.pricingMethod ? (
            <p className="mt-2 text-sm text-red-600" id="pricingMethod-errors">
              {formik.errors.pricingMethod.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <FieldProducts
            title="Products"
            value={formik.values.productIds}
            onChange={(products) => {
              formik.setFieldValue(
                "productIds",
                products.map((product) => parseInt(product.id))
              );
            }}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <FieldDatepicker
            title={t("text_start_date")}
            name="startDate"
            onChange={(value) => {
              if (!Array.isArray(value)) {
                formik.setFieldValue(
                  "startDate",
                  value ? new Date(value).toISOString() : ""
                );
              }
            }}
            selected={
              formik.values.startDate ? new Date(formik.values.startDate) : null
            }
            showTimeSelect
            timeFormat="p"
            timeIntervals={5}
            dateFormat="Pp"
            touched={touched.startDate}
            errors={errors.startDate}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <FieldDatepicker
            title={t("text_end_date")}
            name="endDate"
            onChange={(value) => {
              if (!Array.isArray(value)) {
                formik.setFieldValue(
                  "endDate",
                  value ? new Date(value).toISOString() : ""
                );
              }
            }}
            selected={
              formik.values.endDate ? new Date(formik.values.endDate) : null
            }
            showTimeSelect
            timeFormat="p"
            timeIntervals={5}
            dateFormat="Pp"
            touched={touched.endDate}
            errors={errors.endDate}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              {t("text_status")}
            </legend>
            <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
              <Switch
                checked={formik.values.status}
                onChange={() => {
                  formik.setFieldValue("status", !formik.values.status);
                }}
                id="status"
                className={classNames(
                  formik.values.status ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formik.values.status ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor="status"
                className="ml-2 mb-0 block text-sm font-normal text-gray-700"
              >
                {formik.values.status ? t("text_active") : t("text_inactive")}
              </Switch.Label>
            </Switch.Group>
          </fieldset>
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/inventory/pricelist/special-prices" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit">
          {isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}

export function FieldPricingMethod({
  value,
  onChange,
  className,
  fetchPricingMethods,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
  fetchPricingMethods: (variables: object) => Promise<any>;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>();

  const didFetchPricingMethods = useRef(false);

  const fetchingPricingMethods = useCallback(() => {
    fetchPricingMethods({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchPricingMethods) {
          const updatedOptions: OptionProps[] = data.fetchPricingMethods?.map(
            (p: PricingMethod) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing methods read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingMethods]);

  useEffect(() => {
    if (!didFetchPricingMethods.current) {
      fetchingPricingMethods();
      didFetchPricingMethods.current = true;
    }
  }, [fetchingPricingMethods]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

export function ManagePricing() {
  const { t } = useTranslation();

  const [warning, setWarning] = useState(false);

  const Message = useAlert({
    open: warning,
    title: "Under Development",
    message: "This area is under development and will be available soon.",
    type: AlertType.SUCCESS,
    delay: 3000,
    onDismiss: () => {
      setWarning(false);
    },
  });

  return (
    <>
      <Message />
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-200 bg-gray-800 px-5 py-2.5 text-sm font-normal text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-primary-100">
            <>{t("text_adjust_prices")}</>
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="pricing"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <TicketIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Set prices manually
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => setWarning(true)}
                  >
                    <ArrowUpOnSquareIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Import from CSV
                  </span>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => setWarning(true)}
                  >
                    <ArrowDownOnSquareIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Export to CSV
                  </span>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
