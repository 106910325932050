import { useEffect, useState, Fragment } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import { Spinner } from "../../animations";

import placeholder from "../../assets/placeholder.svg";
import { Button } from "../form";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

type Product = {
  id: string;
  name: string;
  category: {
    name: string;
  }[];
  department: {
    name: string;
  };
  vendor: {
    companyName: string;
  };
  featureImageUrl: string;
  status: number;
};

const FETCH_PRODUCTS = gql`
  query FetchProducts {
    fetchProducts {
      id
      name
      categories {
        name
      }
      department {
        name
      }
      vendor {
        companyName
      }
      featureImageUrl
      status
    }
  }
`;
export function ProductList({
  title,
  productIds,
}: {
  title: string;
  productIds: number[];
}) {
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);
  const [fetchProducts, { loading }] = useLazyQuery(FETCH_PRODUCTS);
  useEffect(() => {
    fetchProducts()
      .then(({ data, error }) => {
        if (data?.fetchProducts) {
          const fetchedProducts = data.fetchProducts.filter((p: Product) =>
            productIds.includes(Number(p.id))
          );
          setProducts(fetchedProducts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts, productIds]);

  return (
    <div>
      <div className="mb-2 block text-sm font-medium text-gray-900">
        {title}
      </div>
      {loading ? (
        <div className="flex justify-center py-6 md:py-12">
          <Spinner className="h-8 w-8 text-primary-700" />
        </div>
      ) : products.length > 0 ? (
        <div className="overflow-hidden rounded-md bg-white">
          <div className="divide-y py-2 px-4">
            {products.map((product, index) => (
              <div key={product.id} className="flex items-center py-1 md:py-2">
                <span className="mr-4 text-sm text-gray-700">{index + 1}.</span>
                <div className="h-10 w-10 flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={
                      product.featureImageUrl
                        ? product.featureImageUrl
                        : placeholder
                    }
                    alt={product.name}
                  />
                </div>
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">
                    {product.name}
                  </div>
                </div>
                <div className="ml-auto">
                  {product.status === 2 ? null : product.status === 0 ? (
                    <span className="mr-4 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                      Inactive
                    </span>
                  ) : (
                    <span className="mr-4 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                      Draft
                    </span>
                  )}
                  <Link to={`/inventory/products/${product.id}`}>
                    <Button variant="text">
                      <span className="sr-only">Edit {product.name}</span>
                      <PencilSquareIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-500"
                      />
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="py-6 text-center text-sm md:py-12">
          <ExclamationCircleIcon
            type="outline"
            name="exclamation-circle"
            className="mx-auto h-6 w-6 text-gray-400"
          />
          <p className="mt-4 font-medium text-gray-900">
            No products listed in this category
          </p>
        </div>
      )}
    </div>
  );
}
