import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { TablePaginate } from "../../../components/table";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";
import { TreeItem } from "./components/types";

type IStoreMenu = {
  id: string;
  name: string;
  handle: string;
  menuItems: TreeItem[];
  status: boolean;
};

const FETCH_STOREMENUS = gql`
  query FetchStoreNavigations {
    fetchStoreNavigations {
      id
      name
      handle
      menuItems {
        id
        linkType
        linkValue {
          id
          name
          handle
        }
        menuName
        subMenu {
          id
          linkType
          linkValue {
            id
            name
            handle
          }
          menuName
        }
      }
      createdAt
      status
    }
  }
`;

const MenuList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchStoreNavigations, { loading }] = useLazyQuery(FETCH_STOREMENUS);
  const [storeMenu, setStoreMenu] = useState<IStoreMenu[]>([]);

  const reloadQuery = useCallback(() => {
    fetchStoreNavigations()
      .then(({ data, error }) => {
        console.log(data);
        console.log(error);
        if (data?.fetchStoreNavigations) {
          console.log(data.fetchStoreNavigations);
          setStoreMenu(data.fetchStoreNavigations);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Store Menu read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStoreNavigations]);

  useEffect(() => {
    reloadQuery();
  }, [reloadQuery]);

  const DELETE_STOREMENU = gql`
    mutation StoreMenuDelete($id: ID!) {
      storeMenuDelete(input: { id: $id }) {
        menu {
          id
          name
        }
      }
    }
  `;
  const [deleteStoreMenu] = useMutation(DELETE_STOREMENU);
  const handleDelete = async (id: string) => {
    return await deleteStoreMenu({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.storeMenuDelete) {
          const deletedStoreMenu = data.storeMenuDelete?.menu;
          // const newStoreMenu = [...storeMenu];
          // const index = newStoreMenu.findIndex(
          //   (menu) => menu.id === id
          // );
          // newStoreMenu.splice(index, 1);
          // setStoreMenu(newStoreMenu);
          reloadQuery();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Menu ${deletedStoreMenu.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Menu delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Menu delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<IStoreMenu>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "handle",
        header: "Handle",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (info) =>
          DateTime.fromISO(info.getValue(), {
            locale: i18n.language,
          }).toLocaleString(DateTime.DATE_MED),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div>
            <AuthShield access={["update-menus"]}>
              <Link
                to={`/store/menu/${props.row.original.id}`}
                className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Link>
            </AuthShield>
            <AuthShield access={["destroy-menus"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Store Menu"
        heading="Store Menu"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Store Menu",
            href: "/store/menu",
          },
          {
            name: MenuListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Store Menu List
            </h1>
            <p className="mt-2 text-sm text-gray-700">A list of store menu</p>
          </div>
        </div>
        <TablePaginate
          data={storeMenu}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <div className="flex space-x-4">
              <AuthShield access={["create-menus"]}>
                <Link to="/store/menu/add">
                  <Button>Add menu</Button>
                </Link>
              </AuthShield>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default MenuList;
export const MenuListResource: ResourceProps = {
  name: "Store Menu List",
  description: "A list of store menus",
  access: ["read-menus"],
  path: "menu",
};
