import { PricingCategoriesResource } from "./pricing-categories";

export const PricelistResource = {
  name: "Price List",
  description: "Price list management module",
  path: "inventory/pricelist",
  icon: "bi bi-bag",
  access: ["read-pricingcategories", "read-pricelevels"],
  children: [PricingCategoriesResource],
};
