import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";

import { Head } from "../../../components/core";

const FETCH_VENDORS = gql`
  query FetchVendor($id: ID!) {
    fetchVendor(id: $id) {
      id
      companyName
      email
      phoneNumber
      vendorCode
      webAddress
      vendorType
      address
      comments
      status
    }
  }
`;

const VendorUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { vendorId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type Vendor = {
    id: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    vendorCode: string;
    webAddress: string;
    vendorType: any;
    address: string;
    comments: string;
    status: boolean;
  };

  const [vendor, setVendor] = useState<Vendor>();

  const [fetchVendor] = useLazyQuery(FETCH_VENDORS);

  useEffect(() => {
    fetchVendor({
      variables: {
        id: vendorId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchVendor) {
          setVendor(data.fetchVendor);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Vendor read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVendor, vendorId]);

  const UPDATE_VENDOR = gql`
    mutation VendorUpdate(
      $id: ID!
      $companyName: String
      $email: String!
      $phoneNumber: String
      $vendorCode: String!
      $webAddress: String
      $vendorType: Int!
      $address: String
      $comments: String
      $status: Boolean!
    ) {
      vendorUpdate(
        input: {
          id: $id
          params: {
            companyName: $companyName
            email: $email
            phoneNumber: $phoneNumber
            vendorCode: $vendorCode
            webAddress: $webAddress
            vendorType: $vendorType
            address: $address
            comments: $comments
            status: $status
          }
        }
      ) {
        vendor {
          id
          companyName
          email
          phoneNumber
          vendorCode
          webAddress
          vendorType
          address
          comments
          status
        }
      }
    }
  `;
  const [updateVendor] = useMutation(UPDATE_VENDOR);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateVendor({
      variables: {
        id: vendor?.id,
        companyName: values.companyName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        vendorCode: values.vendorCode,
        webAddress: values.webAddress,
        vendorType: values.vendorType ? parseInt(values.vendorType.value) : null,
        address: values.address,
        comments: values.comments,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.vendorUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vendor updated successfully",
          });
          return navigate(`/purchases/vendors`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vendor update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Vendor update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={VendorUpdateResource.name}
        heading="Vendors"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Vendors",
            href: "/purchases/vendors",
          },
          {
            name: VendorUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-6 sm:flex sm:items-center md:mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_update_vendor")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_update_vendor")}
            </p>
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link
                  to="/purchases/vendors/add"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add vendor
                </Link>
              </div> */}
        </div>

        <Form
          initialValues={{
            companyName: vendor?.companyName ?? "",
            email: vendor?.email ?? "",
            phoneNumber: vendor?.phoneNumber ?? "",
            vendorCode: vendor?.vendorCode ?? "",
            webAddress: vendor?.webAddress ?? "",
            vendorType: vendor?.vendorType
              ? {
                  value: vendor?.vendorType,
                  label: vendor?.vendorType === 1 ? "Company" : "Individual",
                }
              : null,
            address: vendor?.address ?? "",
            comments: vendor?.comments ?? "",
            status: vendor?.status ? true : false,
          }}
          onSubmit={handleSubmit}
          actionLabel={t("text_update")}
        />
      </div>
    </>
  );
};

export default VendorUpdate;
export const VendorUpdateResource: ResourceProps = {
  name: "Edit Vendor",
  description: "Update an existing vendor",
  access: ["update-vendors"],
  path: "vendors/:vendorId",
};
