import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Button, FieldPriceCategoryCalculator } from "../../../components/form";
import { Head } from "../../../components/core";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { Spinner } from "../../../animations";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type StructureDetails = {
  id: string;
  priceFields: any[];
  sellPrice: number;
};
type Variant = {
  id: string;
  stockCode: string;
  productId: number;
  structureDetails?: StructureDetails[];
};

type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  structureProducts: Product[];
  customers: {
    id: string;
    customerName: string;
  }[];
  createdAt: string;
  status: boolean;
};

enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

type ProductPricing = {
  id: string;
  pricingId?: string;
  pricingStructureId: number;
  productId: number;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};

const FETCH_PRICINGSTRUCTURE = gql`
  query FetchPricingStructure($id: ID!, $structureId: Int!) {
    fetchPricingStructure(id: $id) {
      id
      name
      pricingMethod {
        id
        name
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
      structureProducts {
        id
        name
        featureImageUrl
        hasVariants
        variants {
          id
          stockCode
          productId
          structureDetails(structureId: $structureId) {
            id
            priceFields
            sellPrice
          }
        }
        status
      }
      customers {
        id
        customerName
      }
      createdAt
      status
    }
  }
`;

const CREATE_PRICINGSTRUCTUREPRICING = gql`
  mutation PricingStructurePricingCreate(
    $pricingStructureId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    pricingStructurePricingCreate(
      input: {
        params: {
          pricingStructureId: $pricingStructureId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const UPDATE_PRICINGSTRUCTUREPRICING = gql`
  mutation PricingStructurePricingUpdate(
    $id: ID!
    $pricingStructureId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    pricingStructurePricingUpdate(
      input: {
        id: $id
        params: {
          pricingStructureId: $pricingStructureId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const CustomerPricing = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { customerId, pricingStructureId } = useParams();

  const [pricingStructure, setPricingStructure] = useState<PricingStructure>();
  const [fetchPricingStructure] = useLazyQuery(FETCH_PRICINGSTRUCTURE);

  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);

  const [createProductPricing, { loading: loadingCreate }] = useMutation(
    CREATE_PRICINGSTRUCTUREPRICING
  );
  const [updateProductPricing, { loading: loadingUpdate }] = useMutation(
    UPDATE_PRICINGSTRUCTUREPRICING
  );

  const didFetchPricingStructure = useRef(false);
  const fetchingPricingStructure = useCallback(() => {
    if (!pricingStructureId) return;

    fetchPricingStructure({
      variables: {
        id: pricingStructureId,
        structureId: parseInt(pricingStructureId),
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchPricingStructure) {
          console.log(data.fetchPricingStructure);
          setPricingStructure(data.fetchPricingStructure);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing structure read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingStructure, pricingStructureId]);

  useEffect(() => {
    if (!didFetchPricingStructure.current) {
      fetchingPricingStructure();
      didFetchPricingStructure.current = true;
    }
  }, [fetchingPricingStructure]);

  async function handleSave() {
    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const {
        pricingId,
        pricingStructureId,
        productId,
        productSkuId,
        priceFields,
        sellPrice,
      } = product;

      if (pricingId) {
        await updateProductPricing({
          variables: {
            id: pricingId,
            pricingStructureId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructurePricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createProductPricing({
          variables: {
            pricingStructureId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructurePricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    setProductPricing(newProductPricing);
  }

  const handleCancel = () => {
    return navigate(`/sales/customers/${customerId}`);
  };

  return (
    <>
      <Head
        title="Customer Pricing"
        heading="Customer Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: "Customer Pricing",
            href: `/sales/customers/${customerId}`,
          },
          {
            name: CustomerPricingResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {CustomerPricingResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {CustomerPricingResource.description}
            </p>
          </div>
        </div>
        {pricingStructure ? (
          <>
            <div className="mt-4 space-y-2">
              {pricingStructure.structureProducts.map((product) => (
                <FieldPriceCategoryCalculator
                  key={product.id}
                  product={product}
                  pricingMethod={pricingStructure.pricingMethod}
                  productPricing={productPricing}
                  setProductPricing={setProductPricing}
                />
              ))}
            </div>
            <div className="mt-4 flex flex-wrap justify-end space-x-4">
              <Button
                variant="secondary"
                className="min-w-[10rem] justify-center"
                onClick={handleCancel}
              >
                {t("text_back")}
              </Button>
              {productPricing.filter(
                (pp) =>
                  pp.status === ProductPricingStatus.pending ||
                  pp.status === ProductPricingStatus.error
              ).length ? (
                <Button
                  onClick={handleSave}
                  className="min-w-[10rem] justify-center"
                >
                  {loadingCreate || loadingUpdate ? (
                    <>
                      <Spinner />
                      {t("text_save")}
                    </>
                  ) : (
                    t("text_save")
                  )}
                </Button>
              ) : null}
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default CustomerPricing;
export const CustomerPricingResource: ResourceProps = {
  name: "Customer Pricing",
  description: "Manage pricing list for customer",
  access: ["read-pricingcustomers"],
  path: "customers/:customerId/structure/:pricingStructureId/pricing",
};
