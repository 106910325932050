import {
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";

import { Button } from ".";
import { Spinner } from "../../animations";

import { ColumnDef } from "@tanstack/react-table";
import { IndeterminateCheckbox, TableInfinite } from "../table";

import placeholder from "../../assets/placeholder.svg";

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
};

type Product = {
  id: string;
  index: number;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

type ProductR = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

const FETCH_PRODUCTS = gql`
  query FetchProducts {
    fetchProducts {
      id
      name
      featureImageUrl
      variants {
        id
        stockCode
        productId
      }
      status
    }
  }
`;

export function FieldPricingProducts({
  onChange,
  exceptIds = [],
}: {
  onChange: (newValue: ProductR[]) => void;
  exceptIds?: number[];
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [products, setProducts] = useState<Product[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [fetchProducts, { loading }] = useLazyQuery(FETCH_PRODUCTS);

  const didFetchProducts = useRef(false);
  const fetchingProducts = useCallback(() => {
    fetchProducts()
      .then(({ data, error }) => {
        if (data?.fetchProducts) {
          const fetchedProducts = data.fetchProducts.filter(
            (p: Product) => !exceptIds.includes(Number(p.id))
          );
          setProducts(fetchedProducts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts]);

  useEffect(() => {
    if (!didFetchProducts.current) {
      fetchingProducts();
      didFetchProducts.current = true;
    }
  }, [fetchingProducts]);

  const columns = useMemo<ColumnDef<Product>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        meta: {
          className: "text-center w-8",
        },
      },
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "featureImageUrl",
        header: "Image",
        cell: ({ row }) => (
          <span className="relative inline-block h-10 w-12 overflow-hidden rounded-md border border-gray-200 bg-white">
            <img
              className="absolute h-full w-full object-cover"
              src={
                row.original?.featureImageUrl
                  ? row.original.featureImageUrl
                  : placeholder
              }
              alt={row.original.name}
            />
          </span>
        ),
        meta: {
          className: "text-center w-12",
        },
      },
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => (
          <Link to={`/inventory/products/${row.original.id}`} target="_blank">
            {row.original.name}
          </Link>
        ),
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          setQuery("");
        }}
        disabled={loading}
      >
        {loading ? (
          <>
            <Spinner />
            {t("text_add_product")}
          </>
        ) : (
          t("text_add_product")
        )}
      </Button>
      <Transition.Root
        show={query === undefined ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setQuery(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <TableInfinite
                  data={products}
                  columns={columns}
                  loading={loading}
                  searchValue={query}
                  enableHeader={false}
                  enableRowSelection={true}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  selectionCancelAction={() => {
                    setQuery(undefined);
                  }}
                  selectionConfirmLabel={
                    Object.keys(rowSelection).length
                      ? "Add Product"
                      : "Select Product"
                  }
                  selectionConfirmAction={(products) => {
                    setQuery(undefined);
                    console.log(products);
                    setTimeout(() => {
                      onChange(
                        products.map((p) => ({
                          id: p.id,
                          name: p.name,
                          featureImageUrl: p.featureImageUrl,
                          variants: p.variants,
                          status: p.status,
                        }))
                      );
                    }, 300);
                  }}
                  className="p-4"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
