import { useState } from "react";
import zxcvbn from "zxcvbn";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import {
  InformationCircleIcon,
  BoltIcon,
} from "@heroicons/react/24/solid";
import { Popover } from "@headlessui/react";

import i18next from "../../i18n";
import { classNames } from "../../utils";

export const passwordValidator = (
  value: string,
  threshold: number,
  strength: number = 1
) => {
  const minStrength = Math.max(Math.min(strength, 4), 0);
  const thresholdLength = Math.max(threshold, 7);

  const isContainsUpperCase = /[A-Z]/.test(value);
  const isContainsLowerCase = /[a-z]/.test(value);
  const isContainsNumber = /[0-9]/.test(value);
  const isContainsSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
    value
  );
  const isContainsSpace = /\s/.test(value);
  const isContainsMinStrength = zxcvbn(value).score >= minStrength;
  const isContainsMaxStrength = zxcvbn(value).score <= 4;
  const isContainsMinThreshold = value.length >= thresholdLength;
  const isContainsMaxThreshold = value.length <= 16;

  if (!isContainsUpperCase) {
    throw new Error(i18next.t("isContainsUpperCase", { ns: "error" }));
    // throw new Error("Password must contain at least one uppercase letter");
  }
  if (!isContainsLowerCase) {
    throw new Error(i18next.t("isContainsLowerCase", { ns: "error" }));
    // throw new Error("Password must contain at least one lowercase letter");
  }
  if (!isContainsNumber) {
    throw new Error(i18next.t("isContainsNumber", { ns: "error" }));
    // throw new Error("Password must contain at least one number");
  }
  if (!isContainsSpecialCharacter) {
    throw new Error(i18next.t("isContainsSpecialCharacter", { ns: "error" }));
    // throw new Error("Password must contain at least one special character");
  }
  if (isContainsSpace) {
    throw new Error(i18next.t("isContainsSpace", { ns: "error" }));
    // throw new Error("Password must not contain spaces");
  }
  if (!isContainsMinStrength) {
    throw new Error(
      i18next.t("isContainsMinStrength", {
        ns: "error",
        count: minStrength,
      })
    );
    // throw new Error("Password must be at least " + strength + " strength");
  }
  if (!isContainsMaxStrength) {
    throw new Error(
      i18next.t("isContainsMaxStrength", { ns: "error", count: 4 })
    );
    // throw new Error("Password must be at most 4 strength");
  }
  if (!isContainsMinThreshold) {
    throw new Error(
      i18next.t("isContainsMinThreshold", {
        ns: "error",
        count: thresholdLength,
      })
    );
    // throw new Error(
    //   "Password must be at least " + threshold + " characters long"
    // );
  }
  if (!isContainsMaxThreshold) {
    throw new Error(
      i18next.t("isContainsMaxThreshold", { ns: "error", count: 16 })
    );
    // throw new Error("Password must be at most 16 characters long");
  }
};

interface FieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  touched: boolean | undefined;
  errors: string | undefined;
  label?: boolean;
}
export function FieldPassword(props: FieldProps) {
  const [passwordVisibile, setPasswordVisibile] = useState(false);
  const {
    title,
    name,
    value,
    onChange,
    onBlur,
    readOnly = false,
    touched,
    errors,
    label = true,
    id,
    className,
    placeholder,
    ...rest
  } = props;

  const [strength, setStrength] = useState(0);
  const [warning, setWarning] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const [password, setPassword] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setPassword(newValue);
    const { feedback, score } = zxcvbn(newValue);
    setStrength(score);
    setWarning(feedback.warning);
    setSuggestions(feedback.suggestions);

    typeof onChange === "function" && onChange(e);
  };

  return (
    <div>
      <label
        htmlFor={id ? id : name}
        className={`block text-sm font-medium text-gray-900 ${
          label ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </label>
      <div className={`relative ${label ? "" : "mt-1"}`}>
        <input
          name={name}
          type={passwordVisibile ? "text" : "password"}
          id={id ? id : name}
          className={classNames(
            "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
            "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
            "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
            touched && errors ? "border-red-600 text-red-900" : "",
            className ? className : ""
          )}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          readOnly={readOnly}
          placeholder={placeholder ? placeholder : label ? "" : title}
          {...rest}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 z-10 flex items-center pl-3 pr-3 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
          title={passwordVisibile ? "Hide Password" : "Show Password"}
          onClick={() => {
            setPasswordVisibile(!passwordVisibile);
          }}
        >
          {passwordVisibile ? (
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          ) : (
            <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </button>
      </div>
      {password.length > 0 && (
        <div className="mt-1 flex items-center justify-between">
          <div className="text-sm font-medium">
            <>{i18next.t("text_password_strength")}</>
            <span className="ml-1"></span>
            <>
              {strength === 0 ? (
                <span className="text-red-600">
                  <>{i18next.t("text_password_strength_weak")}</>
                </span>
              ) : strength === 1 ? (
                <span className="text-orange-600">
                  <>{i18next.t("text_password_strength_good")}</>
                </span>
              ) : strength === 2 ? (
                <span className="text-yellow-600">
                  <>{i18next.t("text_password_strength_strong")}</>
                </span>
              ) : strength === 3 ? (
                <span className="text-green-400">
                  <>{i18next.t("text_password_strength_very_strong")}</>
                </span>
              ) : strength === 4 ? (
                <span className="text-green-600">
                  <>{i18next.t("text_password_strength_excellent")}</>
                </span>
              ) : (
                <span className="text-red-600">
                  <>{i18next.t("text_password_strength_invalid")}</>
                </span>
              )}
            </>
          </div>
          <div className="flex items-center">
            {warning && (
              <Popover className="relative">
                <Popover.Button>
                  <InformationCircleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  <span className="sr-only">
                    <>{i18next.t("text_warning")}</>
                  </span>
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-10 mt-2 w-72 rounded-md bg-yellow-400 p-2 text-xs font-normal text-black shadow-md">
                  <BoltIcon
                    className="absolute -top-2.5 right-0.5 h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                  {warning}
                </Popover.Panel>
              </Popover>
            )}
          </div>
        </div>
      )}
      {suggestions.length > 0 && (
        <div className="mt-2 rounded-md border border-solid border-yellow-200 bg-yellow-50 p-2 text-xs font-light">
          <div className="text-xs font-medium text-yellow-700">
            <>{i18next.t("text_tips")}</>
          </div>
          <ul className="ml-3.5 list-decimal text-yellow-600">
            {suggestions.map((suggestion, index) => (
              <li key={suggestion}>{suggestion}</li>
            ))}
          </ul>
        </div>
      )}

      {touched && errors ? (
        <p
          className="mt-2 text-sm text-red-600"
          id={`${id ? id : name}-errors`}
        >
          {errors}
        </p>
      ) : null}
    </div>
  );
}
