import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import Form from "./components/Form";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";

import { Head } from "../../../../components/core";
import { Spinner } from "../../../../animations";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type Product = {
  id: string;
  name: string;
};

type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: Product[];
  createdAt: string;
  status: boolean;
};

const FETCH_PRICINGLEVEL = gql`
  query FetchPricingLevel($id: ID!) {
    fetchPricingLevel(id: $id) {
      id
      name
      pricingMethod {
        id
        name
      }
      productType
      products {
        id
        name
      }
      createdAt
      status
    }
  }
`;

const PricingLevelUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { pricingLevelId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pricingLevel, setPricingLevel] = useState<PricingLevel>();

  const [fetchPricingLevel] = useLazyQuery(FETCH_PRICINGLEVEL);

  const didFetchPricingLevel = useRef(false);

  const fetchingPricingLevel = useCallback(() => {
    fetchPricingLevel({
      variables: {
        id: pricingLevelId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchPricingLevel) {
          console.log(data.fetchPricingLevel);
          setPricingLevel(data.fetchPricingLevel);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Pricing level read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPricingLevel, pricingLevelId]);

  useEffect(() => {
    if (!didFetchPricingLevel.current) {
      fetchingPricingLevel();
      didFetchPricingLevel.current = true;
    }
  }, [fetchingPricingLevel]);

  const UPDATE_PRICINGLEVEL = gql`
    mutation PricingLevelUpdate(
      $id: ID!
      $name: String!
      $pricingMethodId: Int!
      $productType: Int
      $productIds: [Int!]
      $status: Boolean
    ) {
      pricingLevelUpdate(
        input: {
          id: $id
          params: {
            name: $name
            pricingMethodId: $pricingMethodId
            productType: $productType
            productIds: $productIds
            status: $status
          }
        }
      ) {
        pricingLevel {
          id
          name
          pricingMethod {
            id
            name
          }
          productType
          products {
            id
            name
          }
          createdAt
          status
        }
      }
    }
  `;
  const [updatePricingLevel] = useMutation(UPDATE_PRICINGLEVEL);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    console.log(values);

    updatePricingLevel({
      variables: {
        id: pricingLevel?.id,
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        productType: values.productType,
        productIds: values.productIds,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingLevelUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing level updated successfully",
          });
          return navigate(`/inventory/pricelist/pricing-levels`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing level update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing level update failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={PricingLevelUpdateResource.name}
        heading="Pricing Levels"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing Levels",
            href: "/inventory/pricelist/pricing-levels",
          },
          {
            name: PricingLevelUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_pricing_level")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_pricing_level")}
              </p>
            </div>
          </div>

          {pricingLevel ? (
            <Form
              initialValues={{
                name: pricingLevel.name ?? "",
                pricingMethod: pricingLevel.pricingMethod
                  ? {
                      label: pricingLevel.pricingMethod.name,
                      value: pricingLevel.pricingMethod.id,
                    }
                  : null,
                productType: pricingLevel.productType ?? 0,
                productIds: pricingLevel.products
                  ? pricingLevel.products.map((p) => parseInt(p.id))
                  : [],
                status: pricingLevel.status ? true : false,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};

export default PricingLevelUpdate;
export const PricingLevelUpdateResource: ResourceProps = {
  name: "Edit Pricing Level",
  description: "Update an existing pricing level",
  access: ["update-pricinglevels"],
  path: "pricing-levels/:pricingLevelId",
};
