import { DashboardResource } from "./Dashboard";
import { UserManagementResource } from "./user-management";
import { InventoryResource } from "./inventory";
import { PurchaseResource } from "./purchases";
import { FleetsResource } from "./fleets";
import { SalesResource } from "./sales"

export const pages: ResourceProps[] = [
  DashboardResource,
  UserManagementResource,
  // {
  //   name: "Inventory",
  //   description: "Inventory management",
  //   path: "/inventory-management",
  //   icon: "bi bi-shop-window",
  //   access: ["read-inventories"],
  // },
  InventoryResource,
  // {
  //   name: "Sales",
  //   description: "Sales management",
  //   path: "/sales",
  //   icon: "bi bi-receipt",
  //   access: ["read-sales"],
  // },
  PurchaseResource,
  // {
  //   name: "Purchases",
  //   description: "Purchases management",
  //   path: "/purchases",
  //   icon: "bi bi-bag",
  //   access: ["read-purchases"],
  // },
  FleetsResource,
  // {
  //   name: "Fleets",
  //   description: "Fleets management",
  //   path: "/fleet-management",
  //   icon: "bi bi-ui-checks",
  //   children: [
  //     {
  //       name: "Track Fleets",
  //       path: "/fleet-management/fleets",
  //       access: ["read-fleets"],
  //     },
  //     {
  //       name: "Track Shipment",
  //       path: "/fleet-management/shipment",
  //       access: ["read-shipments"],
  //     },
  //   ],
  //   access: ["read-fleets", "read-shipments"],
  // },
  SalesResource,
  // {
  //   name: "Reports",
  //   description: "Reports management",
  //   path: "/reports",
  //   icon: "bi bi-journal-check",
  //   access: ["read-reports"],
  // },
];
