import {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  Fragment,
  useRef,
} from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { FormikProps, useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Switch, Menu, Transition, Dialog } from "@headlessui/react";
import Select, {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  ActionMeta,
} from "react-select";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  PencilIcon,
  TicketIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import {
  Button,
  Field,
  FieldAddress,
  FieldState,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";

import {
  useNotifyContext,
  NotifyType,
} from "../../../../contexts/NotifyContext";
import { AlertType, useAlert } from "../../../../hooks/useAlert";

import { arrayMove, classNames, orderOptions } from "../../../../utils";
import { isAdmin } from "../../../auth";

import FormStructure from "./FormStructure";

type User = {
  id: string;
  fullName: string;
  email: string;
  roles: {
    roleName: string;
  }[];
  createdAt: string;
  status: boolean;
};

type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: Customer[];
  createdAt: string;
  status: boolean;
};

type DeliveryRun = {
  id?: string;
  name: string;
  dock: {
    id: string;
    name: string;
  };
  createdAt: string;
  status: boolean;
};

type TradingAddresses = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productIds: number[];
  customerIds: number[];
  status: boolean;
};

type Customer = {
  id?: string;
  customerCode: string;
  customerName: string;
  customerType: SingleValue<OptionProps>;
  email: string;
  storeContact: string;
  storeEmail: string;
  storePhoneNumber: string;
  storeFax: string;
  abnNo: string;
  customerPlu: string;
  packingInstruction: string;
  deliveryInstruction: string;
  salesRep: SingleValue<OptionProps>;
  deliveryRun: SingleValue<OptionProps>;
  creditApplicationStatus: boolean;
  creditLimit: number;
  customerGroups: MultiValue<OptionProps>;
  tradingAddresses: TradingAddresses[];
  status: SingleValue<OptionProps>;
};

type AddressOption = TradingAddresses & {
  id: string;
};

const CREATE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureCreate(
    $name: String!
    $pricingMethodId: Int!
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureCreate(
      input: {
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        structureProducts {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

const UPDATE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        structureProducts {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;
const DELETE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureDelete($id: ID!) {
    pricingStructureDelete(input: { id: $id }) {
      pricingStructure {
        id
        name
      }
    }
  }
`;

const FETCH_PRODUCTS = gql`
  query FetchProducts {
    fetchProducts {
      id
      name
      vendor {
        companyName
      }
      featureImageUrl
      variants {
        id
        stockCode
        productId
      }
      createdAt
      status
    }
  }
`;

const CHECK_STRUCTURERELATION_CUSTOMERS = gql`
  query CheckStructureRelationCustomers(
    $productIds: [Int!]
    $customerIds: [Int!]
    $pricingStructureIds: [Int!]
  ) {
    checkStructureRelation(
      productIds: $productIds
      customerIds: $customerIds
      pricingStructureIds: $pricingStructureIds
    ) {
      customerId
      customerName
      productId
      productName
    }
  }
`;

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
};

type Product = {
  id: string;
  index: number;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

type Occupied = {
  customerId: number;
  customerName: string;
  productId: number;
  productName: string;
};

export default function Form({
  initialValues,
  pricingStructures: initialPricingStructures,
  onSubmit,
  header,
}: {
  initialValues: Customer;
  pricingStructures: PricingStructure[];
  onSubmit: (values: any, actions: any) => void;
  header?: JSX.Element;
}) {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { addNotify } = useNotifyContext();
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);

  const [pricingStructures, setPricingStructures] = useState<
    PricingStructure[]
  >([]);
  const [activePricingStructure, setActivePricingStructure] = useState<
    PricingStructure | undefined
  >(undefined);
  const [newPricingStructure, setNewPricingStructure] = useState<
    boolean | undefined
  >(undefined);

  const [products, setProducts] = useState<Product[]>([]);
  const [occupiedProductIds, setOccupiedProductIds] = useState<number[]>([]);
  const [fetchProducts] = useLazyQuery(FETCH_PRODUCTS);
  const [checkStructureRelationCustomers] = useLazyQuery(
    CHECK_STRUCTURERELATION_CUSTOMERS
  );
  const didFetchProducts = useRef(false);
  const didFetchStructures = useRef(false);

  const [createPricingStructure] = useMutation(CREATE_PRICINGSTRUCTURE);
  const [deletePricingStructure] = useMutation(DELETE_PRICINGSTRUCTURE);
  const [updatePricingStructure] = useMutation(UPDATE_PRICINGSTRUCTURE);

  const didFetchTradingAddresses = useRef(false);
  const fetchingTradingAddresses = useCallback(() => {
    if (initialValues.tradingAddresses.length > 0) {
      setAddressOptions(
        initialValues.tradingAddresses.map((address, index) => ({
          ...address,
          id: index.toString(),
        }))
      );
    } else {
      setAddressOptions([
        {
          id: "0",
          address: "",
          suburb: "",
          state: "",
          postcode: "",
        },
      ]);
    }
  }, [initialValues.tradingAddresses]);

  useEffect(() => {
    if (!didFetchTradingAddresses.current) {
      fetchingTradingAddresses();
      didFetchTradingAddresses.current = true;
    }
  }, [fetchingTradingAddresses]);

  const fetchStructures = useCallback(async () => {
    if (!customerId || initialPricingStructures.length === 0) {
      return;
    }

    const pricingStructureIds = initialPricingStructures.map((structure) =>
      parseInt(structure.id)
    );
    setPricingStructures(initialPricingStructures);
    await checkStructureRelationCustomers({
      variables: {
        productIds: [],
        customerIds: [parseInt(customerId)],
        pricingStructureIds,
      },
      onCompleted(data) {
        console.log(data.checkStructureRelation);
        const updatedOccupiedProductIds = data.checkStructureRelation.flatMap(
          (csr: Occupied) => csr.productId
        );
        setOccupiedProductIds(updatedOccupiedProductIds);
      },
    });
  }, [checkStructureRelationCustomers, customerId, initialPricingStructures]);

  useEffect(() => {
    if (!didFetchStructures.current) {
      fetchStructures();
      didFetchStructures.current = true;
    }
  }, [fetchStructures]);

  const fetchedProducts = useCallback(() => {
    fetchProducts()
      .then(({ data, error }) => {
        if (data?.fetchProducts) {
          setProducts(data.fetchProducts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  }, [fetchProducts, navigate]);

  useEffect(() => {
    if (!didFetchProducts.current) {
      fetchedProducts();
      didFetchProducts.current = true;
    }
  }, [fetchedProducts]);

  const customerSchema = Yup.object().shape({
    customerCode: Yup.string().required("Required"),
    customerName: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    customerType: Yup.object().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    storeContact: Yup.string(),
    storeEmail: Yup.string().email("Invalid email"),
    storePhoneNumber: Yup.string(),
    storeFax: Yup.string(),
    abnNo: Yup.string().required("Required"),
    customerPlu: Yup.string().required("Required"),
    packingInstruction: Yup.string().nullable(),
    deliveryInstruction: Yup.string().nullable(),
    salesRep: Yup.object().required("Required"),
    deliveryRun: Yup.object().required("Required"),
    creditApplicationStatus: Yup.boolean().required("Required"),
    creditLimit: Yup.number().required("Required"),
    customerGroups: Yup.array().required("Required"),
    tradingAddresses: Yup.array(
      Yup.object().shape({
        address: Yup.string().required("Required"),
        suburb: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        postcode: Yup.string().required("Required"),
      })
    ).notRequired(),
    status: Yup.object().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: customerSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched } = formik;

  const handleCreateStructure = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!customerId) return;

    createPricingStructure({
      variables: {
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        productIds: values.productIds,
        customerIds: [parseInt(customerId)],
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingStructureCreate) {
          const { pricingStructure } = data.pricingStructureCreate;
          const newStructure = {
            id: pricingStructure.id,
            name: pricingStructure.name,
            pricingMethod: pricingStructure.pricingMethod,
            productIds: pricingStructure.structureProducts.map(
              (p: { id: string }) => parseInt(p.id)
            ),
            customerIds: pricingStructure.customers.map((c: { id: string }) =>
              parseInt(c.id)
            ),
            status: pricingStructure.status,
          };
          const newStructures = [...pricingStructures, newStructure];
          setPricingStructures(newStructures);
          setNewPricingStructure(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Structure created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure create failed",
          message: error.message,
        });
      });
  };

  const handleUpdateStructure = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!customerId || !activePricingStructure) return;

    const oldProductIds = activePricingStructure.productIds;
    const newProductIds = values.productIds;
    const productIds = newProductIds.filter(
      (productId: number) => !oldProductIds.includes(productId)
    );

    console.log(productIds);

    updatePricingStructure({
      variables: {
        id: activePricingStructure.id,
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        productIds: productIds,
        customerIds: [parseInt(customerId)],
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingStructureUpdate) {
          const { pricingStructure } = data.pricingStructureUpdate;
          const newStructures = [...pricingStructures];
          const index = newStructures.findIndex(
            (structure) => structure.id === activePricingStructure.id
          );
          const newStructure = {
            id: pricingStructure.id,
            name: pricingStructure.name,
            pricingMethod: pricingStructure.pricingMethod,
            productIds: pricingStructure.structureProducts.map(
              (p: { id: string }) => parseInt(p.id)
            ),
            customerIds: pricingStructure.customers.map((c: { id: string }) =>
              parseInt(c.id)
            ),
            status: pricingStructure.status,
          };

          newStructures[index] = newStructure;
          setPricingStructures(newStructures);
          setActivePricingStructure(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Structure updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure update failed",
          message: error.message,
        });
      });
  };

  const handleDeleteStructure = async (pricingStructureId: string) => {
    deletePricingStructure({
      variables: {
        id: pricingStructureId,
      },
    })
      .then(({ data }) => {
        if (data?.pricingStructureDelete) {
          const { pricingStructure } = data.pricingStructureDelete;
          const newStructures = [...pricingStructures];
          const index = newStructures.findIndex(
            (structure) => structure.id === pricingStructureId
          );
          newStructures.splice(index, 1);
          setPricingStructures(newStructures);
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Structure ${pricingStructure.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure deletion failed",
          message: error.message,
        });
      });
  };

  const [warning, setWarning] = useState(false);

  const Message = useAlert({
    open: warning,
    title: "Under Development",
    message: "This area is under development and will be available soon.",
    type: AlertType.SUCCESS,
    delay: 3000,
    onDismiss: () => {
      setWarning(false);
    },
  });

  return (
    <>
      <Message />
      <form onSubmit={formik.handleSubmit}>
        {header}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
          <div className="space-y-4 md:col-span-8">
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_customer_name")}
                    name="customerName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerName}
                    touched={touched.customerName}
                    errors={errors.customerName}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_customer_code")}
                    name="customerCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerCode}
                    touched={touched.customerCode}
                    errors={errors.customerCode}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_email")}
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    touched={touched.email}
                    errors={errors.email}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_contact")}
                    name="storeContact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeContact}
                    touched={touched.storeContact}
                    errors={errors.storeContact}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_email")}
                    name="storeEmail"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeEmail}
                    touched={touched.storeEmail}
                    errors={errors.storeEmail}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_phone_number")}
                    name="storePhoneNumber"
                    type="tel"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storePhoneNumber}
                    touched={touched.storePhoneNumber}
                    errors={errors.storePhoneNumber}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_fax")}
                    name="storeFax"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeFax}
                    touched={touched.storeFax}
                    errors={errors.storeFax}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_abn_no")}
                    name="abnNo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.abnNo}
                    touched={touched.abnNo}
                    errors={errors.abnNo}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_customer_plu")}
                    name="customerPlu"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerPlu}
                    touched={touched.customerPlu}
                    errors={errors.customerPlu}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_credit_limit")}
                    name="creditLimit"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.creditLimit}
                    touched={touched.creditLimit}
                    errors={errors.creditLimit}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Switch.Group as="div" className="inline-flex items-center">
                    <Switch
                      checked={formik.values.creditApplicationStatus}
                      onChange={() => {
                        formik.setFieldValue(
                          "creditApplicationStatus",
                          !formik.values.creditApplicationStatus
                        );
                      }}
                      id="creditApplicationStatus"
                      className={classNames(
                        formik.values.creditApplicationStatus
                          ? "bg-primary-600"
                          : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          formik.values.creditApplicationStatus
                            ? "translate-x-5"
                            : "translate-x-0",
                          "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <Switch.Label
                      passive
                      htmlFor="creditApplicationStatus"
                      className="ml-2 mb-0 block text-sm font-normal text-gray-900"
                    >
                      {t("text_credit_application_status")}
                    </Switch.Label>
                  </Switch.Group>
                </div>
                <div className="col-span-12 sm:col-span-12">
                  <Field
                    title={t("text_packing_instruction")}
                    name="packingInstruction"
                    type="textarea"
                    className="h-24"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.packingInstruction}
                    touched={touched.packingInstruction}
                    errors={errors.packingInstruction}
                  />
                </div>
                <div className="col-span-12 sm:col-span-12">
                  <Field
                    title={t("text_delivery_instruction")}
                    name="deliveryInstruction"
                    type="textarea"
                    className="h-24"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deliveryInstruction}
                    touched={touched.deliveryInstruction}
                    errors={errors.deliveryInstruction}
                  />
                </div>
              </div>
            </div>
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              <TradingAddressOptions
                formik={formik}
                addressOptions={addressOptions}
                setAddressOptions={setAddressOptions}
              />
            </div>
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              <div className="mb-1 text-sm font-medium text-gray-900">
                {t("text_pricing_structures")}
              </div>
              <p className="mb-4 text-sm text-gray-500">
                This will override the base price and pricing level for this
                customer.
              </p>

              {pricingStructures.length > 0 ? (
                <>
                  <div className="flex flex-col space-y-4">
                    {pricingStructures.map((structure) => (
                      <div
                        key={structure.id}
                        className="space-y-4 rounded-md bg-white p-5"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <div className="text-sm">{structure.name}</div>
                          </div>
                          {customerId &&
                          Array.from(new Set(structure.customerIds)).length ===
                            1 &&
                          structure.customerIds.includes(
                            parseInt(customerId)
                          ) ? (
                            <div className="flex items-center space-x-4">
                              <Button
                                variant="icon"
                                onClick={() => {
                                  setActivePricingStructure(structure);
                                }}
                              >
                                <span className="sr-only">
                                  Edit {structure.name}
                                </span>
                                <PencilIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-700"
                                />
                              </Button>
                              <Button
                                variant="icon"
                                onClick={() => {
                                  handleDeleteStructure(structure.id);
                                }}
                              >
                                <span className="sr-only">
                                  Delete {structure.name}
                                </span>
                                <TrashIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-700"
                                />
                              </Button>
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="inline-flex items-center whitespace-nowrap rounded-md border border-gray-700 bg-white px-3 py-2.5 text-sm font-normal text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                                    <>{t("text_adjust_prices")}</>
                                    <ChevronDownIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Link
                                            to={`structure/${structure.id}/pricing`}
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "group flex items-center px-4 py-2 text-sm"
                                            )}
                                          >
                                            <TicketIcon
                                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                              aria-hidden="true"
                                            />
                                            Set prices manually
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    </div>
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <span
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "group flex items-center px-4 py-2 text-sm"
                                            )}
                                            onClick={() => setWarning(true)}
                                          >
                                            <ArrowUpOnSquareIcon
                                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                              aria-hidden="true"
                                            />
                                            Import from CSV
                                          </span>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <span
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "group flex items-center px-4 py-2 text-sm"
                                            )}
                                            onClick={() => setWarning(true)}
                                          >
                                            <ArrowDownOnSquareIcon
                                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                              aria-hidden="true"
                                            />
                                            Export to CSV
                                          </span>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          ) : (
                            <Button
                              variant="secondary"
                              className="text-sm text-gray-500"
                              disabled
                            >
                              Multiple customers included
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="text-center">
                      <Button
                        className="w-full justify-center bg-gray-800 hover:bg-gray-900"
                        onClick={() => setNewPricingStructure(true)}
                      >
                        Create Pricing Structure
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="py-6 text-center text-sm md:py-12">
                  <ExclamationCircleIcon
                    type="outline"
                    name="exclamation-circle"
                    className="mx-auto h-6 w-6 text-gray-400"
                  />
                  <p className="mt-4 font-medium text-gray-900">
                    No pricing pricing structures found
                  </p>
                  <p className="mt-2 mb-2 text-gray-500">
                    Please <Button variant="text">create</Button> a pricing
                    pricing structure to continue.
                  </p>
                  <Button
                    variant="secondary"
                    onClick={() => setNewPricingStructure(true)}
                  >
                    Add Pricing Structure
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-4 space-y-4">
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              <fieldset>
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_status")}
                </label>
                <FieldStatus
                  value={formik.values.status}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("status", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.status && formik.errors.status
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.status && formik.errors.status ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.status.toString()}
                  </p>
                ) : null}
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_customer_type")}
                </label>
                <FieldCustomerType
                  value={formik.values.customerType}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("customerType", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.customerType && formik.errors.customerType
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.customerType && formik.errors.customerType ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.customerType.toString()}
                  </p>
                ) : null}
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_sales_rep")}
                </label>
                <FieldSalesRep
                  value={formik.values.salesRep}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("salesRep", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.salesRep && formik.errors.salesRep
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.salesRep && formik.errors.salesRep ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.salesRep.toString()}
                  </p>
                ) : null}
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_delivery_run")}
                </label>
                <FieldDeliveryRuns
                  value={formik.values.deliveryRun}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("deliveryRun", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.deliveryRun && formik.errors.deliveryRun
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.deliveryRun && formik.errors.deliveryRun ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.deliveryRun.toString()}
                  </p>
                ) : null}
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_customer_groups")}
                </label>
                <FieldCustomerGroups
                  value={formik.values.customerGroups}
                  // fixedValue={formik.values.autoCategories?.flatMap(
                  //   (ac) => ac.id
                  // )}
                  onChange={(value: MultiValue<OptionProps>) => {
                    formik.setFieldValue("customerGroups", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.customerGroups &&
                      formik.errors.customerGroups
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {/* {formik.values.autoCategories?.length ? (
                <p className="mt-2 space-x-4 text-sm text-gray-500">
                  Default:{" "}
                  {formik.values.autoCategories
                    ?.flatMap((ac) => ac.name)
                    .map((name) => (
                      <span className="rounded-md border border-gray-300 bg-white py-0.5 text-xs px-1">
                        {name}
                      </span>
                    ))}
                </p>
              ) : null} */}
                {formik.touched.customerGroups &&
                formik.errors.customerGroups ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="customerGroups-errors"
                  >
                    {formik.errors.customerGroups.toString()}
                  </p>
                ) : null}
              </fieldset>
            </div>
          </div>
        </div>
      </form>

      <Transition.Root
        show={activePricingStructure ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePricingStructure(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                {activePricingStructure && activePricingStructure.id ? (
                  <FormStructure
                    heading="Edit Pricing Structure - "
                    initialValues={{
                      id: activePricingStructure.id,
                      name: activePricingStructure.name,
                      pricingMethod: activePricingStructure.pricingMethod
                        ? {
                            label: activePricingStructure.pricingMethod.name,
                            value: activePricingStructure.pricingMethod.id,
                          }
                        : null,
                      productIds: activePricingStructure.productIds,
                      status: activePricingStructure.status,
                    }}
                    products={products}
                    occupiedProductIds={occupiedProductIds}
                    pricingStructures={pricingStructures}
                    onSubmit={handleUpdateStructure}
                    submitLabel={t("text_update")}
                    onCancel={() => setActivePricingStructure(undefined)}
                    cancelLabel={t("text_cancel")}
                    actionType="update"
                  />
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newPricingStructure ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPricingStructure(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <FormStructure
                  heading="New Pricing Structure - "
                  initialValues={{
                    id: "",
                    name: "",
                    pricingMethod: null,
                    productIds: [],
                    status: true,
                  }}
                  products={products}
                  occupiedProductIds={occupiedProductIds}
                  pricingStructures={pricingStructures}
                  onSubmit={handleCreateStructure}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewPricingStructure(undefined)}
                  cancelLabel={t("text_cancel")}
                  actionType="create"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const FETCH_CUSTOMERS = gql`
  query FetchCustomers($status: Boolean) {
    fetchCustomers(status: $status) {
      id
      customerName
      createdAt
      status
    }
  }
`;

export function FieldCustomers({
  value,
  onChange,
  className,
}: {
  value: MultiValue<OptionProps>;
  onChange: (newValue: MultiValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<MultiValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchCustomers] = useLazyQuery(FETCH_CUSTOMERS);

  useEffect(() => {
    fetchCustomers({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCustomers) {
          const fetchCustomers = data.fetchCustomers.map((p: Customer) => ({
            value: p.id,
            label: p.customerName,
          }));

          setOptions(fetchCustomers);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Categories read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomers]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

export function FieldStatus({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const options = useMemo(
    () => [
      { label: "Rejected", value: "3" },
      { label: "Approve/Active", value: "2" },
      { label: "Pending", value: "1" },
      { label: "Inactive", value: "0" },
    ],
    []
  );

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

export function FieldCustomerType({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const options = useMemo(
    () => [
      { label: "Company", value: "1" },
      { label: "Individual", value: "0" },
    ],
    []
  );

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_USERS = gql`
  query FetchUsers {
    fetchUsers {
      id
      fullName
      email
      roles {
        roleName
      }
      createdAt
      status
    }
  }
`;

export function FieldSalesRep({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<
    OptionsOrGroups<OptionProps, GroupBase<OptionProps>> | undefined
  >();
  const [fetchUsers] = useLazyQuery(FETCH_USERS);

  useEffect(() => {
    fetchUsers()
      .then(({ data, error }) => {
        if (data?.fetchUsers) {
          const filteredOptions = data.fetchUsers.filter((u: User) =>
            u.roles.some((r) => !isAdmin(r))
          );
          const updatedOptions: OptionProps[] = filteredOptions?.map(
            (p: User) => {
              return {
                label: p.fullName,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Users read is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUsers]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_DELIVERYRUNS = gql`
  query FetchDeliveryRuns {
    fetchDeliveryRuns {
      id
      name
      dock {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export function FieldDeliveryRuns({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>();
  const [fetchDeliveryRuns] = useLazyQuery(FETCH_DELIVERYRUNS);

  useEffect(() => {
    fetchDeliveryRuns()
      .then(({ data, error }) => {
        if (data?.fetchDeliveryRuns) {
          const updatedOptions: OptionProps[] = data.fetchDeliveryRuns?.map(
            (p: DeliveryRun) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "DeliveryRun read is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeliveryRuns]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_CUSTOMERGROUPS = gql`
  query FetchCustomerGroups {
    fetchCustomerGroups {
      id
      name
      description
      customers {
        id
        customerName
      }
      createdAt
      status
    }
  }
`;

export function FieldCustomerGroups({
  value,
  fixedValue,
  onChange,
  className,
}: {
  value: MultiValue<OptionProps>;
  fixedValue?: string[];
  onChange: (newValue: MultiValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<MultiValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchCustomerGroups] = useLazyQuery(FETCH_CUSTOMERGROUPS);

  useEffect(() => {
    fetchCustomerGroups({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCustomerGroups) {
          const filteredOptions = data.fetchCustomerGroups?.filter(
            (p: CustomerGroup) => !fixedValue?.includes(p.id)
          );
          const updatedOptions: OptionProps[] = filteredOptions?.map(
            (p: CustomerGroup) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Customer Group read customer group(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerGroups, fixedValue]);

  useEffect(() => {
    const activeOptionsIds: string[] = value.flatMap((v) => v.value);
    const activeOptions = value.filter((v) =>
      activeOptionsIds.includes(v.value)
    );
    const updatedValues = activeOptions.map((v) => {
      return {
        label: v.label,
        value: v.value,
      };
    });
    setValues(updatedValues);
  }, [value]);

  const handleChange = (
    newValue: MultiValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(orderOptions(newValue));
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={handleChange}
        isClearable
      />
    </SelectWrapper>
  );
}

function TradingAddressOptions(props: {
  formik: FormikProps<any>;
  addressOptions: AddressOption[];
  setAddressOptions: Dispatch<SetStateAction<AddressOption[]>>;
}) {
  const { t } = useTranslation();
  const { formik, addressOptions, setAddressOptions } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addAddress = () => {
    const newAddresses = [
      ...addressOptions,
      {
        id: String(addressOptions.length + 1),
        address: "",
        suburb: "",
        state: "",
        postcode: "",
      },
    ];
    formik.setFieldValue(
      "tradingAddresses",
      newAddresses.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setAddressOptions(newAddresses);
  };

  const removeAddress = (id: string) => {
    const deletedAddresses = addressOptions.filter(
      (v: AddressOption) => v.id !== id
    );
    formik.setFieldValue(
      "tradingAddresses",
      deletedAddresses.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setAddressOptions(deletedAddresses);
  };

  const updateAddress = (id: string, address: AddressOption) => {
    const updatedAddresses = [...addressOptions];
    const index = updatedAddresses.findIndex((v) => v.id === id);
    updatedAddresses[index] = address;
    formik.setFieldValue(
      "tradingAddresses",
      updatedAddresses.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setAddressOptions(updatedAddresses);
  };

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = addressOptions.findIndex((v) => v.id === active.id);
      const newIndex = addressOptions.findIndex((v) => v.id === over.id);
      const updatedArray = arrayMove(addressOptions, oldIndex, newIndex);
      formik.setFieldValue(
        "tradingAddresses",
        updatedArray.map((t) => {
          const { id, ...rest } = t;
          return rest;
        })
      );
      setAddressOptions(updatedArray);
    }
  }

  return (
    <fieldset>
      <legend className="text-sm font-medium text-gray-900">
        {t("text_trading_addresses")}
      </legend>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={addressOptions.map((v) => v.id!)}
            strategy={verticalListSortingStrategy}
          >
            {addressOptions.map((v) => (
              <FieldsetAddress
                key={`address-${v.id}`}
                id={v.id!}
                address={v}
                removeAddress={removeAddress}
                updateAddress={updateAddress}
                addressOptions={addressOptions}
              />
            ))}
          </SortableContext>
        </DndContext>
        <div className="mt-2 flex justify-end">
          <Button variant="tertiary" onClick={addAddress}>
            {t("text_add_address")}
          </Button>
        </div>
      </div>
    </fieldset>
  );
}

function FieldsetAddress(props: {
  id: string;
  address: AddressOption;
  updateAddress?: any;
  removeAddress?: any;
  addressOptions: AddressOption[];
}) {
  const { t } = useTranslation();
  const { address, updateAddress, removeAddress, addressOptions } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {props.id === "0" ? null : <hr className="my-4" />}
      <div className="flex w-full items-start">
        <div className="mr-1 mt-3.5 space-y-5">
          <Button
            variant="text"
            style={{
              display: "flex",
            }}
            className="transition-all hover:bg-white"
            {...listeners}
          >
            <span
              aria-hidden="true"
              className="bi bi-grip-vertical px-2 text-lg"
            ></span>
          </Button>
          {addressOptions.length > 1 ? (
            <Button
              variant="text"
              style={{
                display: "flex",
              }}
              onClick={() => {
                removeAddress(address.id);
              }}
              className="transition-all hover:bg-white"
            >
              <TrashIcon
                aria-hidden="true"
                className="mx-2 h-4 w-4 text-gray-700"
              />
            </Button>
          ) : null}
        </div>

        <div className="grid w-full grid-cols-2 gap-4 py-4">
          <div>
            <FieldAddress
              title={t("text_address")}
              name={`trading-address-${address.id}`}
              type="street"
              onChange={(value) => {
                const { street, state, suburb, postcode } = value;

                const newAddress = { ...address };
                newAddress.address = street;
                newAddress.suburb = suburb;
                newAddress.state = state;
                newAddress.postcode = postcode;
                updateAddress(address.id, newAddress);
              }}
              value={address.address}
              placeholder={t("text_address")}
              label={false}
            />
          </div>
          <div>
            <Field
              title={t("text_suburb")}
              name={`trading-suburb-${address.id}`}
              onChange={(e) => {
                const newAddress = { ...address };
                newAddress.suburb = e.target.value;
                updateAddress(address.id, newAddress);
              }}
              value={address.suburb}
              placeholder={t("text_suburb")}
              label={false}
            />
          </div>
          <div>
            <FieldState
              title={t("text_state")}
              name={`trading-state-${address.id}`}
              onChange={(value) => {
                const newAddress = { ...address };
                newAddress.state = value;
                updateAddress(address.id, newAddress);
              }}
              value={address.state}
              placeholder={t("text_state")}
              label={false}
            />
          </div>
          <div>
            <FieldAddress
              title={t("text_postcode")}
              name={`trading-postcode-${address.id}`}
              type="postcode"
              onChange={(value) => {
                const { street, state, suburb, postcode } = value;

                const newAddress = { ...address };
                newAddress.address = street;
                newAddress.suburb = suburb;
                newAddress.state = state;
                newAddress.postcode = postcode;
                updateAddress(address.id, newAddress);
              }}
              value={address.postcode}
              placeholder={t("text_postcode")}
              label={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
