import { useState } from "react";
import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Form from "./components/Form";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertType, useAlert } from "../../../hooks/useAlert";

import { Head } from "../../../components/core";

type RoleProps = {
  readonly label: string;
  readonly value: string;
};

const UserCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type InfoProps = {
    name: string;
  };
  const [alert, setAlert] = useState<InfoProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Invitation sent successfully",
    message: (
      <p className="text-sm text-gray-500">
        An invitation has been sent to{" "}
        <b className="text-gray-900">{alert?.name}</b>. Please confirm with them
        to complete the sign up process.
      </p>
    ),
    type: AlertType.SUCCESS,
    delay: 10000,
    onDismiss: () => {
      setAlert(null);
      return navigate("/user-management/users");
    },
  });

  const CREATE_ROLE = gql`
    mutation UserCreate(
      $firstName: String!
      $lastName: String!
      $email: String!
      $phoneNumber: String!
      $userName: String!
      $roles: [Int!]!
      $status: Boolean!
    ) {
      userCreate(
        input: {
          params: {
            firstName: $firstName
            lastName: $lastName
            email: $email
            phoneNumber: $phoneNumber
            userName: $userName
            roles: $roles
            status: $status
          }
        }
      ) {
        user {
          id
          userName
        }
      }
    }
  `;
  const [createUser] = useMutation(CREATE_ROLE);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createUser({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        userName: values.userName,
        roles: values.roles.map((role: RoleProps) => parseInt(role.value)),
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.userCreate) {
          setAlert({
            name: values.email,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "User creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "User creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create User"
        heading="Users"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Users",
            href: "/user-management/users",
          },
          {
            name: UserCreateResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-3">
          {/* <div className="sm:flex sm:items-center hidden">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_user")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_user")}
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="/user-management/users/add"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
            >
              Add user
            </Link>
          </div>
          </div> */}
          <div className="flex flex-col">
            <div className="md:col-span-2">
              <Form
                initialValues={{
                  firstName: "",
                  lastName: "",
                  userName: "",
                  email: "",
                  phoneNumber: "",
                  roles: [],
                  status: true,
                }}
                onSubmit={handleSubmit}
                actionLabel={t("text_create")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCreate;
export const UserCreateResource: ResourceProps = {
  name: "Create User",
  description: "Create a new user",
  access: ["create-users"],
  path: "users/add",
};
