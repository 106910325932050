import {
  Outlet,
  Link,
  useLocation,
  RouteObject,
  useRoutes,
} from "react-router-dom";

import { Error400, Error401, Error403, Error404, Error500 } from ".";
import { ErrorDebug, NotFound } from "../../components/core";

import { isDeveloper, useAuth } from "../auth";

const { REACT_APP_DEBUG } = process.env;

const ErrorsLayout = () => {
  const { currentRole } = useAuth();
  const location: any = useLocation();

  return (
    <>
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main>
            <Outlet />

            <div className="mt-10 flex justify-center space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                to="/"
                className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                Go back home
              </Link>
            </div>
          </main>
        </div>
        {location.state?.error &&
          (isDeveloper(currentRole) || REACT_APP_DEBUG === "true") && (
            <ErrorDebug
              error={location.state?.error}
              className="mx-auto w-full max-w-3xl"
            />
          )}
      </div>
    </>
  );
};
export function ErrorsPage() {
  let routes: RouteObject[] = [
    {
      element: <ErrorsLayout />,
      children: [
        { path: "400", element: <Error400 /> },
        { path: "401", element: <Error401 /> },
        { path: "403", element: <Error403 /> },
        { path: "404", element: <Error404 /> },
        { path: "500", element: <Error500 /> },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
