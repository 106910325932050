import React, {
  Dispatch,
  SetStateAction,
  Fragment,
  useMemo,
  useState,
} from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  flexRender,
  ColumnDef,
  ExpandedState,
  getExpandedRowModel,
  Column,
  Table,
} from "@tanstack/react-table";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import {
  ExclamationCircleIcon,
  InboxIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { IndeterminateCheckbox } from ".";
import { classNames, toCapitalize } from "../../utils";

declare module "@tanstack/table-core" {
  interface FilterMeta {
    itemRank: RankingInfo;
  }
  interface ColumnMeta {
    className?: string;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type TableProps<T> = {
  initialState?: any;
  data: T[];
  columns: ColumnDef<T>[];
  className?: string;
  loading?: boolean;
  renderSubComponent?: any;
  enableRowSelection?: boolean;
  rowSelection?: any;
  setRowSelection?: Dispatch<SetStateAction<T>>;
  onSelect?: Dispatch<SetStateAction<{}>>;
  enableSearch?: boolean;
  searchLabel?: string;
  searchPlaceholder?: string;
  searchValue?: string;
  enableHeader?: boolean;
  enableFooter?: boolean;
};

export function TablePaginate({
  initialState = {},
  data,
  columns,
  className,
  loading,
  renderSubComponent = () => <></>,
  enableRowSelection = false,
  rowSelection,
  setRowSelection = () => {},
  onSelect = () => {},
  enableSearch = true,
  searchLabel = "Search",
  searchPlaceholder = "Search by keyword",
  searchValue,
  enableHeader = true,
  enableFooter = true,
  ...rest
}: TableProps<any>) {
  const tableData = React.useMemo(
    () => (loading ? Array(20).fill({}) : Array.from(data)),
    [loading, data]
  );
  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            enableColumnFilter: false,
            ...column,
            cell: () => <Skeleton />,
          }))
        : columns.map((column) => ({
            enableColumnFilter: false,
            ...column,
          })),
    [loading, columns]
  );

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  React.useEffect(() => {
    if (searchValue) {
      setGlobalFilter(searchValue);
    }
  }, [searchValue]);

  React.useEffect(() => {
    if (enableRowSelection) {
      onSelect(rowSelection);
    }
  }, [enableRowSelection, onSelect, rowSelection]);

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    initialState: {
      ...initialState,
      columnVisibility: {
        id: false,
        ...initialState.columnVisibility,
      },
    },
    state: {
      columnFilters,
      globalFilter,
      rowSelection: enableRowSelection ? rowSelection : {},
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    // enableColumnResizing: true,
    // columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    ...rest,
  });

  const [isPending, startTransition] = React.useTransition();
  const handleFilter = React.useCallback(
    (e: { target: { value: React.SetStateAction<string> } }) => {
      startTransition(() => {
        setGlobalFilter(e.target.value);
      });
    },
    []
  );

  return (
    <div className={className}>
      <SkeletonTheme baseColor="#f7f7f7" borderRadius="0.25rem" duration={0.1}>
        <>
          <div className="relative mt-2 pt-1 overflow-hidden overflow-x-auto border-gray-200 md:mt-4 md:rounded-lg md:border md:bg-white">
            <div className="flex-wrap justify-between border-b border-gray-200 py-4 flex md:px-4">
              {enableSearch && (
                <div className={classNames("relative w-6/12 md:w-72")}>
                  <label
                    htmlFor="search"
                    className="absolute -top-2 left-2 z-20 -mt-px inline-block bg-greyish px-1 text-xs font-medium text-gray-900 md:bg-white"
                  >
                    {searchLabel}
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="search"
                      name="search"
                      id="search"
                      className={classNames(
                        "relative block w-full appearance-none rounded-md border border-gray-400 bg-greyish px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm md:bg-white",
                        "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                        "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
                      )}
                      placeholder={searchPlaceholder}
                      value={globalFilter ?? ""}
                      onChange={handleFilter}
                    />
                  </div>
                </div>
              )}
              {renderSubComponent()}
            </div>

            {table
              .getHeaderGroups()
              .some((headerGroup) =>
                headerGroup.headers.some((header) =>
                  header.column.getCanFilter()
                )
              ) && (
              <header className="mt-4 grid grid-cols-1 flex-wrap gap-4 pt-1 pb-4 sm:grid-cols-2 md:mt-0 md:flex md:space-y-4 md:px-4">
                {table
                  .getHeaderGroups()
                  .map((headerGroup, index) =>
                    headerGroup.headers.map((header, i) =>
                      header.column.getCanFilter() ? (
                        <Filter
                          key={headerGroup.id + "-" + header.id}
                          column={header.column}
                          table={table}
                          className={classNames(i === 1 ? "md:mt-4" : "")}
                        />
                      ) : null
                    )
                  )}

                <button
                  onClick={() => {
                    table.resetColumnFilters(true);
                    table.resetGlobalFilter(true);
                  }}
                  className="rounded-md bg-gray-900 px-3 py-2.5 text-sm font-normal text-white hover:bg-gray-800"
                >
                  Clear Filters
                </button>
              </header>
            )}

            <table className="block min-w-full divide-gray-300 md:table md:table-auto md:divide-y">
              {enableHeader && (
                <thead className="hidden bg-gray-50 md:table-header-group">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="block md:table-row">
                      {headerGroup.headers.map((header) => (
                        <th
                          {...{
                            key: header.id,
                            scope: "col",
                            colSpan: header.colSpan,
                            className: classNames(
                              "px-3 py-2 text-left text-sm font-medium relative text-gray-900 block md:table-cell",
                              header.column.columnDef.meta?.className
                                ? header.column.columnDef.meta.className
                                : ""
                            ),
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className:
                                    (header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "") + " group inline-flex",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                                {{
                                  asc: (
                                    <div className="leading ml-2 h-5 w-5 flex-none rounded bg-gray-200 text-center leading-5 text-gray-900 group-hover:bg-gray-300">
                                      <span
                                        className="bi bi-chevron-up"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  ),
                                  desc: (
                                    <div className="leading ml-2 h-5 w-5 flex-none rounded bg-gray-200 px-0.5 text-center text-gray-900 group-hover:bg-gray-300">
                                      <span
                                        className="bi bi-chevron-down"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  ),
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            </>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody className="block divide-greyish md:table-row-group md:divide-y">
                {table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className="my-5 block overflow-hidden rounded-lg border border-gray-200 bg-white md:my-0 md:table-row md:rounded-none md:border-0 md:bg-transparent"
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          {...{
                            key: cell.id,
                            className: classNames(
                              "md:px-3 md:py-3 text-xs md:text-sm text-gray-500 relative flex md:table-cell",
                              cell.column.columnDef.meta?.className
                                ? cell.column.columnDef.meta.className
                                : ""
                            ),
                          }}
                        >
                          <div className="flex w-1/3 items-center bg-gray-50 px-3 py-2 font-normal text-black md:hidden md:px-0 md:py-0">
                            {typeof cell.column.columnDef.header === "string"
                              ? cell.column.columnDef.header
                              : ""}
                          </div>
                          <div className="w-2/3 truncate px-3 py-2 text-left md:w-auto md:max-w-[8rem] md:px-0 md:py-0 xl:w-auto xl:max-w-[12rem]">
                            {loading ? (
                              <Skeleton />
                            ) : (
                              flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={table.getVisibleFlatColumns().length}>
                      <div className="py-14 px-6 text-center text-sm sm:px-14">
                        {globalFilter ? (
                          <>
                            <ExclamationCircleIcon
                              type="outline"
                              name="exclamation-circle"
                              className="mx-auto h-6 w-6 text-gray-400"
                            />
                            <p className="mt-4 font-medium text-gray-900">
                              No results found
                            </p>
                            <p className="mt-2 text-gray-500">
                              No results found for this search term. Please try
                              different keywords.
                            </p>
                          </>
                        ) : (
                          <>
                            <InboxIcon
                              type="outline"
                              name="inbox"
                              className="mx-auto h-6 w-6 text-gray-400"
                            />
                            <p className="mt-4 font-medium text-gray-900">
                              No data available
                            </p>
                            <p className="mt-2 text-gray-500">
                              No data available for this table. Please try
                              again.
                            </p>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {enableFooter && (
              <nav
                className="flex flex-wrap items-center rounded-lg border-t border-r border-b border-l border-gray-200 bg-white px-4 py-3 sm:px-2.5 md:justify-between md:rounded-none md:border-r-0 md:border-b-0 md:border-l-0"
                aria-label="Pagination"
              >
                <div className="hidden flex-wrap items-center space-x-4 sm:flex">
                  {enableRowSelection && (
                    <div className="inline-flex items-center px-1.5 py-2 text-sm text-gray-700">
                      <IndeterminateCheckbox
                        {...{
                          checked: table.getIsAllPageRowsSelected(),
                          indeterminate: table.getIsSomePageRowsSelected(),
                          onChange: table.getToggleAllPageRowsSelectedHandler(),
                        }}
                      />
                      <span className="ml-4">
                        Page Rows ({table.getRowModel().rows.length})
                      </span>
                    </div>
                  )}
                  <div className="hidden items-center md:inline-flex">
                    <button
                      className="text-md relative inline-flex appearance-none items-center rounded-md border-0 bg-white px-1.5 py-2 font-medium text-gray-700 hover:bg-gray-50  focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-transparent disabled:text-gray-300"
                      onClick={() => table.setPageIndex(0)}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <span className="sr-only">Go to start</span>
                      <span className="bi bi-chevron-bar-left"></span>
                    </button>
                    <button
                      className="text-md relative ml-1 inline-flex appearance-none items-center rounded-md border-0 bg-white px-1.5 py-2 font-medium text-gray-700 hover:bg-gray-50  focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-transparent disabled:text-gray-300"
                      onClick={() =>
                        table.setPageIndex(table.getPageCount() - 1)
                      }
                      disabled={!table.getCanNextPage()}
                    >
                      <span className="sr-only"> Go to end</span>
                      <span className="bi bi-chevron-bar-right"></span>
                    </button>
                  </div>

                  <p className="hidden text-sm text-gray-700 md:inline-block">
                    <span className="mr-1">Showing</span>
                    <span className="mr-1 font-medium">
                      {table.getState().pagination.pageIndex + 1}
                    </span>
                    <span className="mr-1">of</span>
                    <span className="font-medium">
                      {table.getPageCount()}
                    </span>{" "}
                    pages
                  </p>

                  <span className="inline-flex items-center overflow-hidden rounded-md border border-gray-400 pl-2 pr-1">
                    <label htmlFor="goto" className="text-sm text-gray-700">
                      Go to page:
                    </label>
                    <input
                      id="goto"
                      type="number"
                      min={1}
                      max={table.getPageCount()}
                      defaultValue={table.getState().pagination.pageIndex + 1}
                      onChange={(e) => {
                        e.preventDefault();
                        const value = e.target.value;
                        if (!value) return;
                        if (Number(value) < 1) return;
                        if (Number(value) > table.getPageCount()) return;
                        const page = value ? Number(value) - 1 : 0;
                        table.setPageIndex(page);
                      }}
                      className="w-12 border-0 px-1 text-center text-sm focus:border-none focus:outline-none focus:ring-0"
                    />
                  </span>
                </div>
                <div className="flex flex-1 flex-wrap justify-between sm:justify-end md:flex-nowrap">
                  <select
                    className="relative mr-4 inline-flex appearance-none rounded-md border-0 bg-gray-100 pl-4 pr-7 text-sm leading-5 text-gray-800  focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[2, 10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>

                  <nav aria-label="Pagination">
                    <span className="isolate mr-1.5 inline-flex rounded-md">
                      <button
                        className={classNames(
                          "relative inline-flex items-center rounded-l-md border border-gray-400 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10",
                          "focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2",
                          "hover:bg-gray-50",
                          "disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-transparent disabled:text-gray-300"
                        )}
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        <span className="sr-only">Previous</span>
                        <span
                          className="bi-chevron-double-left"
                          aria-hidden="true"
                        ></span>
                      </button>
                      <button
                        className={classNames(
                          "relative -ml-px inline-flex items-center rounded-r-md border border-gray-400 bg-white px-2 py-2 text-sm font-medium text-gray-500 focus:z-10",
                          "focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2",
                          "hover:bg-gray-50",
                          "disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-transparent disabled:text-gray-300"
                        )}
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        <span className="sr-only">Next</span>
                        <span
                          className="bi-chevron-double-right"
                          aria-hidden="true"
                        ></span>
                      </button>
                    </span>
                  </nav>
                </div>
              </nav>
            )}
          </div>
        </>
      </SkeletonTheme>
    </div>
  );
}

function Filter({
  column,
  table,
  className,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
  className?: string;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return Array.isArray(firstValue) ? (
    <DebouncedAutocomplete
      values={sortedUniqueValues.slice(0, 5000).flat()}
      value={(columnFilterValue ?? "") as string}
      onChange={(value) => column.setFilterValue(value)}
      placeholder={column.id}
      className={className}
    />
  ) : typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <DebouncedNumber
        type="number"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(value) =>
          column.setFilterValue((old: [number, number]) => [value, old?.[1]])
        }
        placeholder={`Min ${
          column.getFacetedMinMaxValues()?.[0]
            ? `(${column.getFacetedMinMaxValues()?.[0]})`
            : ""
        }`}
        className={classNames(
          "relative block w-24 appearance-none rounded-md border border-gray-400 bg-white px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
          "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
          "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
          className ? className : ""
        )}
      />
      <DebouncedNumber
        type="number"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(value) =>
          column.setFilterValue((old: [number, number]) => [old?.[0], value])
        }
        placeholder={`Max ${
          column.getFacetedMinMaxValues()?.[1]
            ? `(${column.getFacetedMinMaxValues()?.[1]})`
            : ""
        }`}
        className={classNames(
          "relative block w-24 appearance-none rounded-md border border-gray-400 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
          "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
          "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
          className ? className : ""
        )}
      />
    </div>
  ) : (
    <DebouncedAutocomplete
      values={sortedUniqueValues.slice(0, 5000)}
      value={(columnFilterValue ?? "") as string}
      onChange={(value) => column.setFilterValue(value)}
      placeholder={column.id}
      className={className}
    />
  );
}

// A debounced input react component
function DebouncedNumber({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div>
      <input
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

export function DebouncedAutocomplete({
  values,
  value: initialValue,
  onChange,
  placeholder,
  debounce = 500,
  className,
  ...props
}: {
  values: string[];
  value: string | number;
  onChange: (value: string | number) => void;
  placeholder: string;
  debounce?: number;
  className?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const options = useMemo(() => {
    return values;
  }, [values]);

  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <Combobox
      as="div"
      className={classNames(
        "relative z-10 col-span-1 sm:w-auto md:w-44",
        className ? className : ""
      )}
      value={value}
      onChange={setValue}
      nullable
    >
      <Combobox.Label className="absolute -top-2 left-2 z-20 -mt-px inline-block bg-greyish px-1 text-xs font-medium text-gray-900 md:bg-white">
        {toCapitalize(placeholder)}
      </Combobox.Label>
      <Combobox.Input
        className={classNames(
          "relative block w-full appearance-none rounded-md border border-gray-400 bg-greyish px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm md:bg-white",
          "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
          "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
        )}
        {...props}
        displayValue={(option: string) => option}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={`Filter by ${placeholder}`}
      />
      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Combobox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setQuery("")}
      >
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white px-2 py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredOptions.length === 0 && query !== "" ? (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
              Nothing found.
            </div>
          ) : (
            filteredOptions.map((option, index) => (
              <Combobox.Option
                key={index}
                className={({ active }) =>
                  classNames(active ? "bg-gray-50" : "", "rounded-md")
                }
                value={option}
              >
                {({ selected, active }) => (
                  <p
                    className={classNames(
                      active ? "bg-primary-700/10 text-primary-700" : "",
                      "group relative flex w-full items-center rounded-md px-3 py-2 pl-8 text-sm text-gray-700 transition ease-in-out"
                    )}
                  >
                    {selected ? (
                      <span
                        className={`absolute top-2 left-1.5 transition-all ${
                          active ? "text-primary-700" : "text-primary-600"
                        }`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                    {option}
                  </p>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </Transition>
    </Combobox>
  );
}
