import { lazy, Suspense, useMemo, useState } from "react";
import { Link, RouteObject, useRoutes } from "react-router-dom";

import { useAuth, isAuthorizedForResource } from "../auth";

import PageLayout from "../../layouts/PageLayout";
import {
  LayoutSplashScreen,
  NotFound,
  NotAuthorized,
  Head,
} from "../../components/core";
import { classNames } from "../../utils";

import { MenuListResource } from "./menu/List";
import { MenuCreateResource } from "./menu/Create";
import { MenuUpdateResource } from "./menu/Update";
import { HomeResource } from "./home/Layout";

const MenuList = lazy(() => import("./menu/List"));
const MenuCreate = lazy(() => import("./menu/Create"));
const MenuUpdate = lazy(() => import("./menu/Update"));
const HomePage = lazy(() => import("./home/Layout"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
  {
    name: "Store Settings",
    href: "/store",
  },
];

export function StorePage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: (
            <>
              <Head
                title="Store Settings"
                heading="Store Settings"
                breadcrumbs={breadcrumbs}
              />
              <Layout />
            </>
          ),
        },
        {
          path: MenuListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            MenuListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <MenuList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(MenuListResource.access.join(", "))}
            />
          ),
        },
        {
          path: MenuCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            MenuCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <MenuCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(MenuCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: MenuUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            MenuUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <MenuUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(MenuUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: HomeResource.path,
          element: isAuthorizedForResource(
            currentRole,
            HomeResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <HomePage breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(HomeResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}

function Layout() {
  const navigation = useMemo(
    () => [
      {
        id: "menu",
        name: "Menu Settings",
        path: "/store/menu",
        icon: "bi bi-menu-app",
      },
      {
        id: "home",
        name: "Home Settings",
        path: "/store/home",
        icon: "bi bi-house-door",
      },
      {
        id: "pages",
        name: "Pages",
        path: "/store/pages",
        icon: "bi bi-layout-text-window-reverse",
      },
    ],
    []
  );

  return (
    <>
      <div>
        <h2 className="text-sm font-medium text-gray-500">Store</h2>
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {navigation.map((nav) => (
            <Link
              key={nav.name}
              to={nav.path}
              className="overflow-hidden rounded-lg border border-gray-300 bg-white hover:border-primary-600"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex h-10 w-10 items-center justify-center rounded-md bg-primary-300">
                      <span
                        className={classNames(
                          "h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                          nav.icon
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="ml-4 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {nav.name}
                      </dt>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <span className="font-medium text-primary-700 hover:text-primary-900">
                    View all
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}

export const StoreResource: ResourceProps = {
  name: "Store Settings",
  description: "Store",
  access: ["read-store"],
  path: "store/*",
};
