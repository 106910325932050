import { VendorsResource } from "./vendors";

export * from "./Layout";

export const PurchaseResource = {
  name: "Purchases",
  description: "Purchase management module",
  path: "/purchases",
  icon: "bi bi-bag",
  access: ["read-vendors"],
  children: [VendorsResource],
};
