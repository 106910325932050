import { useEffect, useState } from "react";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import au from "date-fns/locale/en-AU";
import "react-datepicker/dist/react-datepicker.css";
import { classNames } from "../../utils";

registerLocale("en-AU", au);

type CustomDatePickerProps = ReactDatePickerProps<any, any>;
interface FieldProps extends CustomDatePickerProps {
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  errors?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  label?: boolean;
}

export function FieldDatepicker(props: FieldProps) {
  const {
    title,
    name,
    selected: initialValue,
    onChange,
    onBlur,
    touched,
    errors,
    label = true,
    id,
    className,
    ...rest
  } = props;

  const [value, setValue] = useState<Date | null | undefined>(undefined);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleInput = (
    date: Date | [Date | null, Date | null] | null,
    e: React.SyntheticEvent<any> | undefined
  ) => {
    setValue(value);
    onChange && onChange(date, e);
  };

  return (
    <>
      <label
        htmlFor={id ? id : name}
        className={`block text-sm font-medium text-gray-900 ${
          label ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </label>
      <DatePicker
        id={id ? id : name}
        selected={value}
        onChange={handleInput}
        locale="en-AU"
        className={classNames(
          "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
          "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
          "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
          touched && errors ? "border-red-600 text-red-900" : "",
          className ? className : ""
        )}
        {...rest}
      />
    </>
  );
}
