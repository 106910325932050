import { lazy, Suspense } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import { useAuth, isAuthorizedForResource } from "../auth";

import PageLayout from "../../layouts/PageLayout";
import {
  LayoutSplashScreen,
  NotFound,
  NotAuthorized,
} from "../../components/core";

import { VendorListResource } from "./vendors/List";
import { VendorCreateResource } from "./vendors/Create";
import { VendorUpdateResource } from "./vendors/Update";

const VendorList = lazy(() => import("./vendors/List"));
const VendorCreate = lazy(() => import("./vendors/Create"));
const VendorUpdate = lazy(() => import("./vendors/Update"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
];

export function PurchasePage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={VendorListResource.path} replace={true} />,
        },
        {
          path: VendorListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            VendorListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <VendorList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(VendorListResource.access.join(", "))}
            />
          ),
        },
        {
          path: VendorCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            VendorCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <VendorCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(VendorCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: VendorUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            VendorUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <VendorUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(VendorUpdateResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
