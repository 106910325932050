import { UsersResource } from "./users";
import { RolesResource } from "./roles";
import { PermissionsResource } from "./permissions";
import { ResourcesResource } from "./resources";

export * from "./Layout";

export const UserManagementResource = {
  name: "User Management",
  description: "Manage users and roles",
  path: "/user-management",
  icon: "bi bi-people",
  access: ["read-users", "read-roles", "read-permissions", "read-resources"],
  children: [
    UsersResource,
    RolesResource,
    PermissionsResource,
    ResourcesResource,
  ],
};
