import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import { ColumnDef } from "@tanstack/react-table";
import { TablePaginate } from "../../../components/table";

import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import { classNames } from "../../../utils";

import { isAdmin } from "../../auth";
import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type Permissions = {
  id: string;
  permissionName: string;
  permissionDisplayName: string;
  permissionDescription: string;
  createdAt: string;
  status: string;
};

type Role = {
  id: string;
  roleName: string;
  roleDescription: string;
  permissions: Permissions;
  createdAt: string;
  status: boolean;
};

const FETCH_ROLES = gql`
  query FetchRoles {
    fetchRoles {
      id
      roleName
      roleDescription
      createdAt
      status
      permissions {
        id
        permissionName
        permissionDisplayName
        permissionDescription
        createdAt
        status
      }
    }
  }
`;

const RoleList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchRoles, { loading }] = useLazyQuery(FETCH_ROLES);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    fetchRoles()
      .then(({ data, error }) => {
        if (data?.fetchRoles) {
          const updatedRoles = data.fetchRoles.map((item: Role) => ({
            ...item,
            createdAt: DateTime.fromISO(item.createdAt, {
              locale: i18n.language,
            }).toLocaleString(DateTime.DATE_MED),
          }));
          setRoles(updatedRoles);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Role read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoles]);

  const DELETE_ROLE = gql`
    mutation RoleDelete($id: ID!) {
      roleDelete(input: { id: $id }) {
        role {
          id
          roleName
        }
      }
    }
  `;
  const [deleteRole] = useMutation(DELETE_ROLE);
  const handleDelete = async (id: string) => {
    return await deleteRole({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.roleDelete) {
          const deletedRole = data?.roleDelete?.role;
          const newRoles = [...roles];
          const index = newRoles.findIndex((role) => role.id === id);
          newRoles.splice(index, 1);
          setRoles(newRoles);

          addNotify({
            type: NotifyType.SUCCESS,
            title: `Role ${deletedRole.roleName} is deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Role delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Role delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<Role>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "roleName",
        header: "Name",
        meta: {
          className: "w-auto md:w-44",
        },
      },
      {
        accessorKey: "permissions",
        header: "Permissions",
        cell: (info: any) =>
          info.getValue().map((p: Permissions, i: number) => (
            <span
              key={i}
              className={classNames(
                p.permissionDisplayName === "Create"
                  ? "bg-blue-100 text-blue-600"
                  : p.permissionDisplayName === "Read"
                  ? "bg-green-100 text-green-600"
                  : p.permissionDisplayName === "Update"
                  ? "bg-yellow-100 text-yellow-600"
                  : p.permissionDisplayName === "Delete"
                  ? "bg-red-100 text-red-600"
                  : "bg-gray-100 text-gray-800",
                "m-0.5 inline-flex items-center  rounded px-2 py-0.5 text-xs font-medium"
              )}
            >
              {p.permissionName}
            </span>
          )),
        enableSorting: false,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) =>
          !isAdmin(props.row.original) ? (
            <div>
              <AuthShield access={["update-roles"]}>
                <Link
                  to={`/user-management/roles/${props.row.original.id}`}
                  className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
                >
                  <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                  <span className="sr-only">
                    Edit, {props.row.original.roleName}
                  </span>
                </Link>
              </AuthShield>
              <AuthShield access={["destroy-roles"]}>
                <button
                  type="button"
                  className="appearance-none py-2 text-red-700 hover:text-red-900"
                  onClick={() => {
                    setAlert({
                      id: props.row.original.id,
                      name: props.row.original.roleName,
                    });
                  }}
                >
                  <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                  <span className="sr-only">
                    Delete, {props.row.original.roleName}
                  </span>
                </button>
              </AuthShield>
            </div>
          ) : null,
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Roles"
        heading="Roles"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Roles",
            href: "/user-management/roles",
          },
          {
            name: RoleListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">Roles List</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the roles in your account including their name,
              title, email and role.
            </p>
          </div>
        </div>
        <TablePaginate
          data={roles}
          columns={columns}
          loading={loading}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-roles"]}>
              <Link to="/user-management/roles/add">
                <Button>Add role</Button>
              </Link>
            </AuthShield>
          )}
        />
      </div>
    </>
  );
};

export default RoleList;
export const RoleListResource: ResourceProps = {
  name: "Roles List",
  description: "A list of roles",
  access: ["read-roles"],
  path: "roles",
};
