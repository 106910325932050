import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utils";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: any;
}

export function FieldCheckbox(
  this: any,
  { id, title, checked, readOnly, disabled, onChange, ...props }: CheckboxProps
) {
  return (
    <>
      <div className="relative inline-flex">
        <div className="flex h-5 items-center">
          <input
            id={id}
            aria-describedby={`${id}-description`}
            type="checkbox"
            className="sr-only h-4 w-4 cursor-pointer rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
          />
          <label
            htmlFor={id}
            className={classNames(
              "flex h-8 w-8 items-center justify-center rounded-full border",
              disabled && !readOnly
                ? "cursor-not-allowed border-gray-100 bg-gray-100 text-gray-300"
                : "cursor-pointer",
              !disabled && readOnly
                ? "cursor-not-allowed border-blue-50 bg-blue-50 text-blue-300"
                : "cursor-pointer",
              !disabled && !readOnly && !checked
                ? "border-primary-100 bg-primary-100 text-primary-500"
                : "",
              !disabled && !readOnly && checked
                ? "border-primary-500 bg-primary-500 text-white"
                : ""
            )}
          >
            {checked ? (
              <CheckIcon className="h-4 w-4" aria-hidden="true" />
            ) : (
              <XMarkIcon className="h-4 w-4" aria-hidden="true" />
            )}
          </label>
        </div>
        <div className="sr-only ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {title}
          </label>
        </div>
      </div>
    </>
  );
}
