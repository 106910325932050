import React, { useEffect } from "react";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import usePlacesAutocomplete from "use-places-autocomplete";
import { Combobox } from "@headlessui/react";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../utils";

interface FieldProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  value: string;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  errors?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  label?: boolean;
}

// let autoComplete: {
//   setFields: (arg0: string[]) => void;
//   addListener: (arg0: string, arg1: () => Promise<void>) => void;
//   getPlace: () => any;
// };

// function handleScriptLoad(
//   updateQuery: React.Dispatch<React.SetStateAction<any>>,
//   autoCompleteRef: any
// ) {
//   autoComplete = new window.google.maps.places.Autocomplete(
//     autoCompleteRef.current,
//     {
//       componentRestrictions: { country: "au" },
//     }
//   );
//   autoComplete.setFields(["address_components", "formatted_address"]);
//   autoComplete.addListener("place_changed", () => {
//     console.log("place_changed");
//     console.log(autoComplete.getPlace());
//     return handlePlaceSelect(updateQuery);
//   });
// }

// async function handlePlaceSelect(updateQuery: (arg0: any) => void) {
//   const addressObject = autoComplete.getPlace();
//   const query = addressObject.formatted_address;
//   updateQuery(query);
// }

// export function FieldPlaces(props: FieldProps) {
//   const {
//     title,
//     name,
//     type,
//     value,
//     onChange,
//     onBlur,
//     readOnly = false,
//     touched,
//     errors,
//     label = true,
//     id,
//     className,
//     placeholder,
//     ...rest
//   } = props;

//   const [query, setQuery] = useState(value);
//   const autoCompleteRef = useRef(null);
//   const loaded = React.useRef(false);

//   useEffect(() => {
//     if (typeof window !== "undefined" && !loaded.current) {
//       if (!document.querySelector("#google-maps")) {
//         loadScript(
//           `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places`,
//           document.querySelector("head"),
//           "google-maps",
//           () => handleScriptLoad(setQuery, autoCompleteRef)
//         );
//       }

//       loaded.current = true;
//     }
//   }, []);

//   return (
//     <>
//       <label
//         htmlFor={id ? id : name}
//         className={`block text-sm font-medium text-gray-900 ${
//           label ? "mb-1" : "sr-only"
//         }`}
//       >
//         {title}
//       </label>
//       <div className="relative">
//         <input
//           ref={autoCompleteRef}
//           name={name}
//           id={id ? id : name}
//           className={classNames(
//             "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
//             "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
//             "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
//             touched && errors ? "border-red-600 text-red-900" : "",
//             className ? className : ""
//           )}
//           onChange={(event) => {
//             setQuery(event.target.value);
//             onChange && onChange(event);
//           }}
//           onBlur={onBlur}
//           readOnly={readOnly}
//           placeholder={placeholder ? placeholder : label ? "" : title}
//           value={query}
//           {...rest}
//         />
//         {touched && errors ? (
//           <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
//             <ExclamationCircleIcon
//               className="h-5 w-5 text-red-500"
//               aria-hidden="true"
//             />
//           </div>
//         ) : null}
//       </div>
//       {touched && errors ? (
//         <p
//           className="mt-2 text-sm text-red-600"
//           id={`${id ? id : name}-errors`}
//         >
//           {errors.toString()}
//         </p>
//       ) : null}
//     </>
//   );
// }

export function FieldPlaces(props: FieldProps) {
  const {
    title,
    name,
    value: initialValue,
    onChange,
    onBlur,
    readOnly = false,
    disabled = false,
    touched,
    errors,
    label = true,
    id,
    className,
    placeholder,
    ...rest
  } = props;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    defaultValue: initialValue,
    requestOptions: {
      componentRestrictions: { country: "au" },
    },
  });

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setValue(value);
    onChange && onChange(e);
  };

  const handleSelect = (val: string) => {
    setValue(val, false);
  };

  return (
    <>
      <Combobox as="div" value={value} onChange={handleSelect}>
        <Combobox.Label
          htmlFor={id ? id : name}
          className={`block text-sm font-medium text-gray-900 ${
            label ? "mb-1" : "sr-only"
          }`}
        >
          {title}
        </Combobox.Label>
        <div className="relative">
          <Combobox.Input
            className={classNames(
              "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
              "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
            )}
            readOnly={readOnly}
            placeholder={placeholder ? placeholder : label ? "" : title}
            onChange={handleInput}
            disabled={disabled || !ready}
            {...rest}
          />
          <Combobox.Button className="absolute inset-y-1 right-1 flex items-center rounded-r-md bg-white px-2 focus:outline-none">
            <GlobeAsiaAustraliaIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
          {data.length > 0 ? (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {status === "OK"
                ? data.map(({ place_id, description }) => (
                    <Combobox.Option
                      key={place_id}
                      value={description}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                          active ? "bg-primary-600 text-white" : "text-gray-900"
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <span
                          className={classNames(
                            "ml-3 block",
                            selected ? "font-medium" : ""
                          )}
                        >
                          {description}
                        </span>
                      )}
                    </Combobox.Option>
                  ))
                : null}
            </Combobox.Options>
          ) : null}
        </div>
      </Combobox>
    </>
  );
}
