import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Button, Field } from "../";
import { toVariableName } from "../../../utils";

import { IField } from "./types";

export default function Item({
  element,
  id,
  updateItem,
  deleteItem,
}: {
  element: IField;
  id: UniqueIdentifier | string;
  updateItem: (id: UniqueIdentifier | string, item: IField) => void;
  deleteItem: (id: UniqueIdentifier | string) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  function renderer() {
    switch (element.fieldType) {
      case "text":
      case "number":
      case "percentage":
        return (
          <div>
            <Field
              title={t(`type.${element.fieldType}`)}
              name={element.id.toString()}
              onChange={(e) => {
                updateItem(id, {
                  ...element,
                  fieldName: e.target.value,
                  fieldKey: toVariableName(e.target.value),
                });
              }}
              value={element.fieldName}
              disabled={element.disabled}
            />
            <p className="col-span-2 mt-1 text-xs text-gray-700">
              {element.description}
            </p>
          </div>
        );
      case "date":
        return (
          <div>
            <Field
              title={t(`type.${element.fieldType}`)}
              name={element.id.toString()}
              onChange={(e) => {
                updateItem(id, {
                  ...element,
                  fieldName: e.target.value,
                  fieldKey: toVariableName(e.target.value),
                });
              }}
              value={element.fieldName}
              disabled={element.disabled}
            />
            <p className="col-span-2 mt-1 text-xs text-gray-700">
              {element.description}
            </p>
          </div>
        );
      case "select":
        return (
          <div>
            <Field
              title={t(`type.${element.fieldType}`)}
              name={element.id.toString()}
              onChange={(e) => {
                updateItem(id, {
                  ...element,
                  fieldName: e.target.value,
                  fieldKey: toVariableName(e.target.value),
                });
              }}
              value={element.fieldName}
              disabled={element.disabled}
            />
            <p className="col-span-2 mt-1 text-xs text-gray-700">
              {element.description}
            </p>

            <div className="mt-4 grid grid-cols-2 gap-4">
              {element.fieldOptions.map((option, index) => (
                <div key={index}>
                  <Field
                    title={t("Option")}
                    name={`${element.id.toString()}-option-${index}`}
                    onChange={(e) => {
                      let newOptions = [...element.fieldOptions];
                      newOptions[index] = e.target.value;
                      updateItem(id, {
                        ...element,
                        fieldOptions: newOptions,
                      });
                    }}
                    value={option}
                  />
                  <Button
                    variant="text"
                    style={{
                      display: "flex",
                    }}
                    onClick={() => {
                      let newOptions = [...element.fieldOptions];
                      newOptions.splice(index, 1);
                      updateItem(id, {
                        ...element,
                        fieldOptions: newOptions,
                      });
                    }}
                    className="transition-all hover:bg-gray-100"
                  >
                    <TrashIcon
                      aria-hidden="true"
                      className="mx-2 h-4 w-4 text-gray-700"
                    />
                  </Button>
                </div>
              ))}
              <div className="col-span-2 flex justify-end">
                <Button
                  variant="secondary"
                  onClick={() => {
                    let newOptions = [...element.fieldOptions];
                    newOptions.push("");
                    updateItem(id, {
                      ...element,
                      fieldOptions: newOptions,
                    });
                  }}
                >
                  {t("text_add_option")}
                </Button>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <Field
              title={t(`type.${element.fieldType}`)}
              name={element.id.toString()}
              onChange={(e) => {
                updateItem(id, {
                  ...element,
                  fieldName: e.target.value,
                  fieldKey: toVariableName(e.target.value),
                });
              }}
              value={element.fieldName}
              disabled={element.disabled}
            />
            <p className="col-span-2 mt-1 text-xs text-gray-700">
              {element.description}
            </p>
          </div>
        );
    }
  }

  return (
    <div
      className="my-4 flex w-full cursor-default items-start rounded-md bg-white px-1.5 py-1"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="mr-1 mt-1.5">
        <Button
          variant="icon"
          className="rounded-md border-0 bg-transparent px-0 py-2 text-inherit transition-all hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
          {...listeners}
        >
          <span
            aria-hidden="true"
            className="bi bi-grip-vertical px-2 text-lg"
          ></span>
        </Button>
        <Button
          variant="icon"
          onClick={() => {
            deleteItem(id);
          }}
          className="rounded-md border-0 bg-transparent px-0 py-2 text-inherit transition-all hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
        >
          <TrashIcon
            aria-hidden="true"
            className="mx-2 h-4 w-4 text-gray-700"
          />
        </Button>
      </div>
      <div className="grid w-full py-4 pr-4">{renderer()}</div>
    </div>
  );
}
