import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

import {
  IndeterminateCheckbox,
  TablePaginate,
} from "../../../components/table";
import { useNotifyContext, NotifyType } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";

import { Button } from "../../../components/form";
import { AuthShield } from "../../auth/core";
import { Head } from "../../../components/core";

type BinLocation = {
  id: string;
  name: string;
  description: string;
  branch: string;
  address: string;
  createdAt: string;
  status: boolean;
};
type Category = {
  id: string;
  name: string;
  description: string;
  parent: Category;
  createdAt: string;
  status: boolean;
};
type Department = {
  id: string;
  name: string;
  description: string;
  parent: Department;
  createdAt: string;
  status: boolean;
};
type Vendor = {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  vendorCode: string;
  webAddress: string;
  type: number;
  address: string;
  comments: string;
  createdAt: string;
  status: boolean;
};

type Product = {
  id: string;
  name: string;
  description: string;
  basePrice: number;
  binLocation: BinLocation;
  categories: Category[];
  comparePrice: number;
  department: Department;
  featureImageUrl?: string;
  inventory: number;
  returnableItem: boolean;
  vendor: Vendor | null;
  createdAt: string;
  status: number;
};

const FETCH_PRODUCTS = gql`
  query FetchProducts {
    fetchProducts {
      id
      name
      vendor {
        companyName
      }
      department {
        name
      }
      categories {
        name
      }
      featureImageUrl
      createdAt
      status
    }
  }
`;

const ProductList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fetchProducts, { loading }] = useLazyQuery(FETCH_PRODUCTS);
  const [products, setProducts] = useState<Product[]>([]);

  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    fetchProducts()
      .then(({ data, error }) => {
        if (data?.fetchProducts) {
          const updatedProducts = data.fetchProducts.map((item: Product) => ({
            ...item,
            vendor: item?.vendor?.companyName,
            department: item.department.name,
            categories: item.categories
              ? item.categories.map((c: Category) => c.name)
              : [],
            createdAt: DateTime.fromISO(item.createdAt, {
              locale: i18n.language,
            }).toLocaleString(DateTime.DATE_MED),
          }));
          setProducts(updatedProducts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts, i18n.language]);

  const DELETE_PRODUCT = gql`
    mutation ProductDelete($id: ID!) {
      productDelete(input: { id: $id }) {
        product {
          id
          name
        }
      }
    }
  `;
  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  const handleDelete = async (id: string) => {
    return await deleteProduct({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.productDelete) {
          const deletedProduct = data?.productDelete?.product;
          const newProducts = [...products];
          const index = newProducts.findIndex((product) => product.id === id);
          newProducts.splice(index, 1);
          setProducts(newProducts);
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Product ${deletedProduct.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Product delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Product delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<Product>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        meta: {
          className: "text-center md:w-8",
        },
      },
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
        enableColumnFilter: true,
      },
      {
        accessorKey: "vendor",
        header: "Vendor ",
        enableColumnFilter: true,
      },
      {
        accessorKey: "department",
        header: "Department",
        enableColumnFilter: true,
      },
      {
        accessorKey: "categories",
        header: "Categories",
        enableColumnFilter: true,
        filterFn: (rows, columnId, filterValue) =>
          filterValue.length === 0 ||
          rows.original.categories.includes(filterValue),
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() === 2 ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : info.getValue() === 0 ? (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
              Draft
            </span>
          ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        cell: (props) => (
          <div>
            <AuthShield access={["update-products"]}>
              <Link
                to={`/inventory/products/${props.row.original.id}`}
                className="mr-4 inline-flex appearance-none py-2 text-primary-700 hover:text-primary-900"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Link>
            </AuthShield>
            <AuthShield access={["destroy-products"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
        meta: {
          className: "w-auto md:w-20",
        },
      },
    ],
    [i18n.language]
  );

  return (
    <>
      <Head
        title="Products"
        heading="Products"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Products",
            href: "/inventory/products",
          },
          {
            name: ProductListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              Products List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the products in your account including their name,
              title, email and product.
            </p>
          </div>
        </div>
        {/* {JSON.stringify(
          Object.keys(rowSelection).map((key) => products[Number(key)])
        )} */}
        <TablePaginate
          data={products}
          columns={columns}
          loading={loading}
          enableRowSelection={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          className="mt-4"
          renderSubComponent={() => (
            <AuthShield access={["create-products"]}>
              <Link to="/inventory/products/add">
                <Button>Add product</Button>
              </Link>
            </AuthShield>
          )}
        />
      </div>
    </>
  );
};

export default ProductList;
export const ProductListResource: ResourceProps = {
  name: "Products List",
  description: "A list of products",
  access: ["read-products"],
  path: "products",
};
