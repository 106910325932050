import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Switch, Menu, Transition, Dialog } from "@headlessui/react";
import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  TicketIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import {
  Button,
  Field,
  FieldEditor,
  FieldStructureCustomers,
  FieldStructureProducts,
} from "../../../../../components/form";

import FormStructure from "./FormStructure";

import { AlertModal, AlertType, useAlert } from "../../../../../hooks/useAlert";
import {
  useNotifyContext,
  NotifyType,
} from "../../../../../contexts/NotifyContext";

import { Spinner } from "../../../../../animations";
import { classNames } from "../../../../../utils";

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: {
    id: string;
    name: string;
  };
  customers: {
    id: string;
    customerName: string;
  }[];
  status: boolean;
};

type PricingCategoryUpdate = {
  name: string;
  description: string;
  productIds: number[];
  pricingStructures: PricingStructure[];
  status: boolean;
};

type Structure = {
  id?: string;
  name: string;
  pricingMethod: {
    id: string;
    name: string;
  };
  customerIds: number[];
  status: boolean;
};

type AlertProps = {
  id: string;
  name: string;
};

type Customer = {
  id: string;
  customerName: string;
  pricingStructure: {
    id: string;
    name: string;
  } | null;
  status: number;
};

type Variant = {
  id: string;
  stockCode: string;
  productId: number;
};

type Product = {
  id: string;
  index: number;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

type Occupied = {
  customerId: number;
  customerName: string;
  productId: number;
  productName: string;
};

const UPDATE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $pricingCategoryId: Int!
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          pricingCategoryId: $pricingCategoryId
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

const FETCH_CUSTOMERS = gql`
  query FetchCustomers {
    fetchCustomers {
      id
      customerName
      status
    }
  }
`;

const FETCH_PRODUCTS = gql`
  query FetchProducts {
    fetchProducts {
      id
      name
      vendor {
        companyName
      }
      featureImageUrl
      variants {
        id
        stockCode
        productId
      }
      createdAt
      status
    }
  }
`;

const CHECK_STRUCTURERELATION = gql`
  query CheckStructureRelation(
    $productIds: [Int!]
    $customerIds: [Int!]
    $pricingStructureIds: [Int!]
  ) {
    checkStructureRelation(
      productIds: $productIds
      customerIds: $customerIds
      pricingStructureIds: $pricingStructureIds
    ) {
      customerId
      customerName
      productId
      productName
    }
  }
`;

const CHECK_STRUCTURERELATION_CUSTOMERS = gql`
  query CheckStructureRelationCustomers(
    $productIds: [Int!]
    $customerIds: [Int!]
    $pricingStructureIds: [Int!]
  ) {
    checkStructureRelation(
      productIds: $productIds
      customerIds: $customerIds
      pricingStructureIds: $pricingStructureIds
    ) {
      customerId
      customerName
      productId
      productName
    }
  }
`;

const CREATE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureCreate(
    $name: String!
    $pricingMethodId: Int!
    $pricingCategoryId: Int!
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureCreate(
      input: {
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          pricingCategoryId: $pricingCategoryId
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

const DELETE_PRICINGSTRUCTURECUSTOMER = gql`
  mutation PricingStructureCustomerDelete(
    $customerId: Int!
    $pricingStructureId: Int!
  ) {
    pricingStructureCustomerDelete(
      input: {
        customerId: $customerId
        pricingStructureId: $pricingStructureId
      }
    ) {
      message
    }
  }
`;

const DELETE_PRICINGCATEGORYPRODUCT = gql`
  mutation PricingCategoryProductDelete(
    $productId: Int!
    $pricingCategoryId: Int!
  ) {
    pricingCategoryProductDelete(
      input: { productId: $productId, pricingCategoryId: $pricingCategoryId }
    ) {
      message
    }
  }
`;

export default function FormUpdate({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: PricingCategoryUpdate;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotify } = useNotifyContext();
  const { pricingCategoryId } = useParams<{ pricingCategoryId: string }>();

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [occupiedCustomerIds, setOccupiedCustomerIds] = useState<number[]>([]);
  const [fetchCustomers, { loading: loadingCustomers }] =
    useLazyQuery(FETCH_CUSTOMERS);

  const [products, setProducts] = useState<Product[]>([]);
  const [occupiedProductIds, setOccupiedProductIds] = useState<number[]>([]);
  const [fetchProducts, { loading: loadingProducts }] =
    useLazyQuery(FETCH_PRODUCTS);

  const [structures, setStructures] = useState<Structure[]>([]);
  const [activeStructure, setActiveStructure] = useState<Structure | undefined>(
    undefined
  );
  const [newStructure, setNewStructure] = useState<boolean | undefined>(
    undefined
  );

  const [pricingStructureCustomerDelete] = useMutation(
    DELETE_PRICINGSTRUCTURECUSTOMER
  );
  const [pricingCategoryProductDelete, { loading: deletingProducts }] =
    useMutation(DELETE_PRICINGCATEGORYPRODUCT);

  const [checkStructureRelation] = useLazyQuery(CHECK_STRUCTURERELATION);
  const [checkStructureRelationCustomers] = useLazyQuery(
    CHECK_STRUCTURERELATION_CUSTOMERS
  );

  const didFetchCustomers = useRef(false);
  const didFetchProducts = useRef(false);
  const didFetchStructures = useRef(false);

  const fetchedCustomers = useCallback(() => {
    fetchCustomers()
      .then(({ data, error }) => {
        if (data?.fetchCustomers) {
          const newCustomers = [
            ...data.fetchCustomers.map((customer: Customer) => ({
              id: customer.id,
              customerName: customer.customerName,
              pricingStructure: null,
              status: customer.status,
            })),
          ];

          for (const pricingStructure of initialValues.pricingStructures) {
            for (const customer of pricingStructure.customers) {
              const index = newCustomers.findIndex(
                (newCustomer) =>
                  parseInt(newCustomer.id) === parseInt(customer.id)
              );
              if (index === -1) continue;
              newCustomers[index] = {
                ...newCustomers[index],
                pricingStructure: {
                  id: pricingStructure.id,
                  name: pricingStructure.name,
                },
              };
            }
          }

          setCustomers(newCustomers);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Customer read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  }, [fetchCustomers, navigate, initialValues.pricingStructures]);

  const fetchedProducts = useCallback(() => {
    fetchProducts()
      .then(({ data, error }) => {
        if (data?.fetchProducts) {
          setProducts(data.fetchProducts);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Product read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  }, [fetchProducts, navigate]);

  const fetchStructures = useCallback(() => {
    const structures = initialValues.pricingStructures.map(
      (structure: PricingStructure) => {
        return {
          id: structure.id,
          name: structure.name,
          pricingMethod: structure.pricingMethod,
          customerIds: structure.customers.map((customer) =>
            parseInt(customer.id)
          ),
          status: structure.status,
        };
      }
    );
    setStructures(structures);
  }, [initialValues.pricingStructures]);

  useEffect(() => {
    if (!didFetchCustomers.current) {
      fetchedCustomers();
      didFetchCustomers.current = true;
    }
  }, [fetchedCustomers]);

  useEffect(() => {
    if (!didFetchProducts.current) {
      fetchedProducts();
      didFetchProducts.current = true;
    }
  }, [fetchedProducts]);

  useEffect(() => {
    if (!didFetchStructures.current) {
      fetchStructures();
      didFetchStructures.current = true;
    }
  }, [fetchStructures]);

  const didOccupiedProductIds = useRef(false);
  const didOccupiedCustomerIds = useRef(false);

  const fetchedOccupiedProductIds = useCallback(() => {
    if (initialValues.pricingStructures.length === 0) {
      return;
    }

    const pricingStructureIds = initialValues.pricingStructures.map(
      (structure) => parseInt(structure.id)
    );

    checkStructureRelation({
      variables: {
        productIds: initialValues.productIds,
        customerIds: [],
        pricingStructureIds,
      },
      onCompleted(data) {
        console.log("onCompleted 1 passed products");
        console.log(data.checkStructureRelation);
        const updateOccupiedCustomerIds = data.checkStructureRelation.flatMap(
          (csr: Occupied) => csr.customerId
        );
        setOccupiedCustomerIds(updateOccupiedCustomerIds);
      },
    });
  }, [
    checkStructureRelation,
    initialValues.productIds,
    initialValues.pricingStructures,
  ]);

  const fetchedOccupiedCustomerIds = useCallback(() => {
    if (initialValues.pricingStructures.length === 0) {
      return;
    }

    const customerIds = initialValues.pricingStructures.reduce(
      (acc: number[], structure) => {
        return [
          ...acc,
          ...structure.customers.map((customer) => parseInt(customer.id)),
        ];
      },
      []
    );
    const pricingStructureIds = initialValues.pricingStructures.map(
      (structure) => parseInt(structure.id)
    );
    checkStructureRelationCustomers({
      variables: {
        productIds: [],
        customerIds,
        pricingStructureIds,
      },
      onCompleted(data) {
        console.log("onCompleted 2 passed customers");
        console.log(data.checkStructureRelation);
        const updatedOccupiedProductIds = data.checkStructureRelation.flatMap(
          (csr: Occupied) => csr.productId
        );
        setOccupiedProductIds(updatedOccupiedProductIds);
      },
    });
  }, [checkStructureRelationCustomers, initialValues.pricingStructures]);

  useEffect(() => {
    if (!didOccupiedProductIds.current) {
      fetchedOccupiedProductIds();
      didOccupiedProductIds.current = true;
    }
  }, [fetchedOccupiedProductIds]);

  useEffect(() => {
    if (!didOccupiedCustomerIds.current) {
      fetchedOccupiedCustomerIds();
      didOccupiedCustomerIds.current = true;
    }
  }, [fetchedOccupiedCustomerIds]);

  useEffect(() => {
    console.log("initialValues", initialValues);
  }, [initialValues]);

  const PricingCategoryUpdateSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    description: Yup.string().nullable(),
    productIds: Yup.array().of(Yup.number()),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: PricingCategoryUpdateSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  const [updatePricingStructure] = useMutation(UPDATE_PRICINGSTRUCTURE);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!pricingCategoryId || !activeStructure) return;

    updatePricingStructure({
      variables: {
        id: activeStructure.id,
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        pricingCategoryId: parseInt(pricingCategoryId),
        productIds: formik.values.productIds,
        customerIds: [],
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingStructureUpdate) {
          const { pricingStructure } = data.pricingStructureUpdate;
          const newStructures = [...structures];
          const index = newStructures.findIndex(
            (structure) => structure.id === activeStructure.id
          );
          newStructures[index] = pricingStructure;
          setStructures(newStructures);
          setActiveStructure(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Structure updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure update failed",
          message: error.message,
        });
      });
  };

  const [createPricingStructure] = useMutation(CREATE_PRICINGSTRUCTURE);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!pricingCategoryId) return;

    createPricingStructure({
      variables: {
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        pricingCategoryId: parseInt(pricingCategoryId),
        productIds: formik.values.productIds,
        customerIds: [],
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingStructureCreate) {
          const { pricingStructure } = data.pricingStructureCreate;
          const newStructures = [...structures, pricingStructure];
          setStructures(newStructures);
          setNewStructure(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Structure created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure create failed",
          message: error.message,
        });
      });
  };

  const DELETE_PRICINGSTRUCTURE = gql`
    mutation PricingStructureDelete($id: ID!) {
      pricingStructureDelete(input: { id: $id }) {
        pricingStructure {
          id
          name
        }
      }
    }
  `;
  const [pricingStructureDelete] = useMutation(DELETE_PRICINGSTRUCTURE);
  const handleDelete = async (id: string) => {
    pricingStructureDelete({
      variables: {
        id: Number(id),
      },
    })
      .then(({ data }) => {
        if (data?.pricingStructureDelete) {
          const { pricingStructure } = data.pricingStructureDelete;
          const newStructures = [...structures];
          const index = newStructures.findIndex(
            (structure) => structure.id === id
          );
          newStructures.splice(index, 1);
          setStructures(newStructures);
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Structure ${pricingStructure.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure deletion failed",
          message: error.message,
        });
      });
  };

  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      if (!alert) return;
      await handleDelete(alert.id);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const handleUpdateCustomers = useCallback(
    async (
      pricingStructure: Structure,
      existingCustomerIds: number[],
      removedCustomerIds: number[],
      addedCustomerIds: number[]
    ) => {
      if (!pricingCategoryId || !pricingStructure.id) return;
      const pricingStructureId = parseInt(pricingStructure.id);
      const newCustomers = [...customers];
      const newStructures = [...structures];

      for (const customerId of removedCustomerIds) {
        await pricingStructureCustomerDelete({
          variables: {
            customerId,
            pricingStructureId,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructureCustomerDelete) {
              const index = newCustomers.findIndex(
                (customer) => customer.id === String(customerId)
              );
              newCustomers[index].pricingStructure = null;

              const structureIndex = newStructures.findIndex(
                (structure) => structure.id === pricingStructure.id
              );
              const structure = {
                ...newStructures[structureIndex],
                customerIds: newStructures[structureIndex].customerIds.filter(
                  (c) => c !== customerId
                ),
              };
              newStructures[structureIndex] = structure;

              addNotify({
                type: NotifyType.SUCCESS,
                title: `Customer removed from structure`,
              });
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Failed to remove customer from structure",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            addNotify({
              type: NotifyType.ERROR,
              title: "Failed to remove customer from structure",
              message: error.message,
            });
          });
      }

      if (addedCustomerIds.length > 0) {
        await updatePricingStructure({
          variables: {
            id: pricingStructure.id,
            name: pricingStructure.name,
            pricingMethodId: pricingStructure.pricingMethod
              ? parseInt(pricingStructure.pricingMethod.id)
              : null,
            pricingCategoryId: parseInt(pricingCategoryId),
            productIds: formik.values.productIds,
            customerIds: addedCustomerIds,
            status: pricingStructure.status,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructureUpdate) {
              const { pricingStructure } = data.pricingStructureUpdate;

              for (const customerId of addedCustomerIds) {
                const index = newCustomers.findIndex(
                  (customer) => customer.id === String(customerId)
                );
                newCustomers[index] = {
                  ...newCustomers[index],
                  pricingStructure: {
                    id: pricingStructure.id,
                    name: pricingStructure.name,
                  },
                };
              }

              const structureIndex = newStructures.findIndex(
                (structure) => structure.id === pricingStructure.id
              );

              newStructures[structureIndex] = {
                ...newStructures[structureIndex],
                customerIds: [...existingCustomerIds, ...addedCustomerIds],
              };
              setActiveStructure(undefined);
              addNotify({
                type: NotifyType.SUCCESS,
                title: "Structure updated successfully",
              });
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Structure update failed",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            addNotify({
              type: NotifyType.ERROR,
              title: "Structure update failed",
              message: error.message,
            });
          });
      }
      setCustomers(newCustomers);
      setStructures(newStructures);
    },
    [pricingCategoryId, formik.values.productIds, customers]
  );

  const handleRemoveProducts = useCallback(
    async (productIds: number[]) => {
      if (!pricingCategoryId) return;

      for (const productId of productIds) {
        await pricingCategoryProductDelete({
          variables: {
            productId: productId,
            pricingCategoryId: parseInt(pricingCategoryId),
          },
        })
          .then(({ data }) => {
            if (data?.pricingCategoryProductDelete) {
              addNotify({
                type: NotifyType.SUCCESS,
                title: "Product removed successfully",
              });
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Prodcut remove failed",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            addNotify({
              type: NotifyType.ERROR,
              title: "Prodcut remove failed",
              message: error.message,
            });
          });
      }
    },
    [pricingCategoryId]
  );

  const [warning, setWarning] = useState(false);

  const Message = useAlert({
    open: warning,
    title: "Under Development",
    message: "This area is under development and will be available soon.",
    type: AlertType.SUCCESS,
    delay: 3000,
    onDismiss: () => {
      setWarning(false);
    },
  });

  return (
    <>
      <ResponseAlert />
      <Message />
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <Field
              title={t("text_name")}
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              touched={touched.name}
              errors={errors.name}
            />
          </div>

          <div className="col-span-12 sm:col-span-12 md:col-span-12">
            <FieldEditor
              title={t("text_description")}
              name="description"
              onChange={(content: any, editor: any) => {
                formik.setFieldValue("description", content);
              }}
              value={formik.values.description}
              touched={formik.touched.description}
              errors={formik.errors.description}
            />
          </div>

          <div className="col-span-12 sm:col-span-12 md:col-span-12">
            <FieldStructureProducts
              loading={loadingProducts || deletingProducts}
              products={products}
              occupiedIds={occupiedProductIds}
              values={formik.values.productIds}
              onChange={(values) => {
                console.log("values", formik.values.productIds, values);
                // const existingCustomerIds = values.filter((v) =>
                //   formik.values.productIds.includes(v)
                // );
                const addedCustomerIds = values.filter(
                  (v) => !formik.values.productIds.includes(v)
                );
                const removedCustomerIds = formik.values.productIds.filter(
                  (v) => !values.includes(v)
                );
                // console.log("existingCustomerIds", existingCustomerIds);
                // console.log("addedCustomerIds", addedCustomerIds);
                // console.log("removedCustomerIds", removedCustomerIds);

                if (removedCustomerIds.length > 0) {
                  console.log("removedCustomerIds", removedCustomerIds);
                  handleRemoveProducts(removedCustomerIds);
                }
                formik.setFieldValue("productIds", addedCustomerIds);
              }}
            />
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12">
            <hr />
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12">
            <div className="mb-1 text-sm font-medium text-gray-900">
              {t("text_pricing_structures")}
            </div>
            {structures && structures.length > 0 ? (
              <div className="flex flex-col space-y-4">
                {structures.map((structure) => (
                  <div
                    key={structure.id}
                    className="space-y-4 rounded-md bg-white p-5"
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="text-sm">{structure.name}</div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <Button
                          variant="icon"
                          onClick={() => {
                            setActiveStructure(structure);
                          }}
                        >
                          <span className="sr-only">Edit {structure.name}</span>
                          <PencilIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-700"
                          />
                        </Button>
                        <Button
                          variant="icon"
                          onClick={() => {
                            setAlert({
                              id: structure.id!,
                              name: structure.name,
                            });
                          }}
                        >
                          <span className="sr-only">
                            Delete {structure.name}
                          </span>
                          <TrashIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-700"
                          />
                        </Button>
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex items-center whitespace-nowrap rounded-md border border-gray-700 bg-white px-3 py-2.5 text-sm font-normal text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                              <>{t("text_adjust_prices")}</>
                              <ChevronDownIcon
                                className="-mr-1 ml-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`structure/${structure.id}/pricing`}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                      )}
                                    >
                                      <TicketIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Set prices manually
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                      )}
                                      onClick={() => setWarning(true)}
                                    >
                                      <ArrowUpOnSquareIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Import from CSV
                                    </span>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm"
                                      )}
                                      onClick={() => setWarning(true)}
                                    >
                                      <ArrowDownOnSquareIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Export to CSV
                                    </span>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                        {structure && structure.id ? (
                          <FieldStructureCustomers
                            loading={loadingCustomers}
                            customers={customers}
                            occupiedIds={occupiedCustomerIds}
                            pricingStructure={structure}
                            values={structure.customerIds}
                            onChange={(values) => {
                              const existingCustomerIds = values.filter((v) =>
                                structure.customerIds.includes(v)
                              );
                              const addedCustomerIds = values.filter(
                                (v) => !structure.customerIds.includes(v)
                              );
                              const removedCustomerIds =
                                structure.customerIds.filter(
                                  (v) => !values.includes(v)
                                );
                              handleUpdateCustomers(
                                structure,
                                existingCustomerIds,
                                removedCustomerIds,
                                addedCustomerIds
                              );
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="text-center">
                  <Button
                    className="w-full justify-center bg-gray-800 hover:bg-gray-900"
                    onClick={() => setNewStructure(true)}
                  >
                    Create Pricing Structure
                  </Button>
                </div>
              </div>
            ) : (
              <div className="py-6 text-center text-sm md:py-12">
                <ExclamationCircleIcon
                  type="outline"
                  name="exclamation-circle"
                  className="mx-auto h-6 w-6 text-gray-400"
                />
                <p className="mt-4 font-medium text-gray-900">
                  No pricing structures found
                </p>
                <p className="mt-2 mb-2 text-gray-500">
                  Please <Button variant="text">create</Button> a pricing
                  structure to continue.
                </p>
                <Button
                  variant="secondary"
                  onClick={() => setNewStructure(true)}
                >
                  Create Pricing Structure
                </Button>
              </div>
            )}
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <fieldset>
              <legend className="text-sm font-medium text-gray-900">
                {t("text_status")}
              </legend>
              <Switch.Group
                as="div"
                className="mt-1.5 inline-flex items-center"
              >
                <Switch
                  checked={formik.values.status}
                  onChange={() => {
                    formik.setFieldValue("status", !formik.values.status);
                  }}
                  id="status"
                  className={classNames(
                    formik.values.status ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      formik.values.status ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label
                  passive
                  htmlFor="status"
                  className="ml-2 mb-0 block text-sm font-normal text-gray-700"
                >
                  {formik.values.status ? t("text_active") : t("text_inactive")}
                </Switch.Label>
              </Switch.Group>
            </fieldset>
          </div>
        </div>

        <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
          <Link
            to="/inventory/pricelist/pricing-categories"
            className="flex w-full"
          >
            <Button variant="secondary" className="w-full justify-center">
              {t("text_cancel")}
            </Button>
          </Link>
          <Button type="submit">
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              actionLabel
            )}
          </Button>
        </div>
      </form>

      <Transition.Root
        show={activeStructure ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveStructure(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                {!activeStructure || !activeStructure.id ? null : (
                  <FormStructure
                    heading="Edit Structure - "
                    initialValues={{
                      id: Number(activeStructure.id),
                      name: activeStructure.name ?? "",
                      pricingMethod: activeStructure.pricingMethod
                        ? {
                            label: activeStructure.pricingMethod.name,
                            value: activeStructure.pricingMethod.id,
                          }
                        : null,
                      status: activeStructure.status,
                    }}
                    onSubmit={handleUpdate}
                    submitLabel={t("text_update")}
                    onCancel={() => setActiveStructure(undefined)}
                    cancelLabel={t("text_cancel")}
                    actionType="update"
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={newStructure ? true : false} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewStructure(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <FormStructure
                  heading="New Structure - "
                  initialValues={{
                    name: "",
                    pricingMethod: null,
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewStructure(undefined)}
                  cancelLabel={t("text_cancel")}
                  actionType="create"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
