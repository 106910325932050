import {
  Fragment,
  useEffect,
  useMemo,
  useState,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { FormikProps, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Switch } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import Select, {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  components,
  MultiValueProps,
  MultiValueGenericProps,
  ActionMeta,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useTranslation } from "react-i18next";

import {
  Button,
  Field,
  FieldEditor,
  FieldPricing,
  FieldProducts,
  FieldUploader,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";

import {
  arrayMove,
  classNames,
  orderOptions,
  toCleanString,
  toNestedOptions,
} from "../../../../utils";

import placeholder from "../../../../assets/placeholder.svg";
import Variants from "./Variants";
import { PlusIcon } from "@heroicons/react/24/solid";

type Vendor = {
  id?: number;
  companyName: string;
  email: string;
  phoneNumber: string;
  vendorCode: string;
  webAddress: string;
  address: string;
  comments: string;
  status: boolean;
};

type Variant = {
  id?: string;
  stockCode?: string;
  basePrice?: number;
  comparePrice?: number;
  optionValues: {
    option: string;
    optionValue: string;
  }[];
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: {
    id: string;
    name: string;
  }[];
  status: boolean;
};

type Product = {
  id?: number;
  name: string;
  description: string;
  basePrice: number;
  binLocation: SingleValue<OptionProps>;
  category: MultiValue<OptionProps>;
  autoCategories?: {
    id: string;
    name: string;
  }[];
  comparePrice: number;
  department: SingleValue<OptionProps>;
  featureImageUrl?: string;
  options: string[];
  cost: number;
  marketCost: number;
  minimumQuantity: number;
  inventory: number;
  pricingLevel: PricingLevel | null;
  hasVariants: boolean;
  variants: Variant[];
  returnableItem: boolean;
  stockCode: string;
  vendor: SingleValue<OptionProps>;
  relatedIds: number[];
  galleryImageUrls: string[];
  status: SingleValue<OptionProps>;
};

type VariantItem = {
  id?: string;
  stockCode?: string;
  basePrice?: number;
  comparePrice?: number;
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
  optionValues?: {
    option: string;
    optionValue: string;
  }[];
};

type VariantOption = {
  id: string;
  type: string;
  value: string[];
};

type VariantProps = {
  index: number;
  stockCode?: string;
  basePrice?: number;
  comparePrice?: number;
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

export default function Form({
  initialValues,
  onSubmit,
  header,
  actionType,
}: {
  initialValues: Product;
  onSubmit: (values: any, actions: any) => void;
  header?: JSX.Element;
  actionType: string;
}) {
  const { t } = useTranslation();
  let { productId } = useParams();

  const [hasVariants, setHasVariants] = useState<boolean>(false);
  const [variantOptions, setVariantOptions] = useState<VariantOption[]>([]);
  const [variantItems, setVariantItems] = useState<VariantItem[]>([]);
  const [variantTypes, setVariantTypes] = useState<string[]>([]);

  const [pricingLevel, setPricingLevel] = useState<PricingLevel | null>(null);

  const reloadVariants = useCallback(
    (vOptions: VariantOption[], vItems: VariantItem[]) => {
      if (vOptions.length === 0) {
        setVariantItems([]);
        setVariantTypes([]);
        formik.setFieldValue("options", []);
        formik.setFieldValue("variants", []);
        return;
      }

      let attributes: {
        [key: string]: string[];
      } = {};
      vOptions.forEach((option) => {
        attributes[option.type] = option.value;
      });

      let attrs = [];

      for (const [attr, values] of Object.entries(attributes))
        attrs.push(values.map((v) => ({ [attr]: v })));

      attrs = attrs.reduce((a, b) =>
        a.flatMap((d) => b.map((e) => ({ ...d, ...e })))
      );

      const newVariantItems = attrs.map((attr, index) => {
        const optionValues: any = Object.keys(attr).map((key) => ({
          option: key,
          optionValue: attr[key],
        }));
        const variantItem = vItems.find((v) =>
          v.optionValues?.every((o) => {
            return optionValues.find(
              (v: { option: string; optionValue: string }) =>
                v.option === o.option && o.optionValue.includes(v.optionValue)
            );
          })
        );

        if (variantItem) {
          return {
            id: variantItem.id,
            optionValues,
            stockCode: variantItem.stockCode,
            basePrice: variantItem.basePrice,
            comparePrice: variantItem.comparePrice,
            cost: variantItem.cost,
            marketCost: variantItem.marketCost,
            minimumQuantity: variantItem.minimumQuantity,
            inventory: variantItem.inventory,
            variantImageUrl: variantItem.variantImageUrl,
          };
        }

        return {
          id: String(index + 1),
          stockCode: formik.values.stockCode + (index + 1),
          basePrice: formik.values.basePrice,
          comparePrice: formik.values.comparePrice,
          cost: 0,
          marketCost: 0,
          minimumQuantity: 0,
          inventory: formik.values.inventory,
          variantImageUrl: "",
          optionValues: Object.entries(attr).map(([option, optionValue]) => ({
            option,
            optionValue,
          })),
        };
      });

      setVariantItems(newVariantItems);
      formik.setFieldValue("variants", newVariantItems);
    },
    [variantOptions]
  );

  useEffect(() => {
    if (initialValues.hasVariants) {
      setHasVariants(true);
    }
  }, [initialValues.hasVariants]);

  useEffect(() => {
    if (initialValues.pricingLevel) {
      setPricingLevel(initialValues.pricingLevel);
    }
  }, [initialValues.pricingLevel]);

  useEffect(() => {
    reloadVariants(variantOptions, variantItems);
  }, [variantOptions, initialValues.variants]);

  useEffect(() => {
    if (initialValues.variants.length > 0) {
      const updatedVariantOptions: VariantOption[] = [];
      const updatedVariantItems = initialValues.variants.map((variant) => {
        const options = variant.optionValues.flatMap((ov) => ov.option);
        const values = variant.optionValues.flatMap((ov) => ov.optionValue);

        options.forEach((option, index) => {
          const optionIndex = updatedVariantOptions.findIndex(
            (v) => v.type === option
          );
          if (optionIndex === -1) {
            updatedVariantOptions.push({
              id: String(updatedVariantOptions.length + 1),
              type: option,
              value: [values[index]],
            });
          } else {
            if (
              !updatedVariantOptions[optionIndex].value.includes(values[index])
            ) {
              updatedVariantOptions[optionIndex].value.push(values[index]);
            }
          }
        });

        return {
          id: variant.id,
          optionValues: variant.optionValues,
          stockCode: variant.stockCode,
          basePrice: variant.basePrice,
          comparePrice: variant.comparePrice,
          cost: variant.cost,
          marketCost: variant.marketCost,
          minimumQuantity: variant.minimumQuantity,
          inventory: variant.inventory,
          variantImageUrl: variant.variantImageUrl,
        };
      });
      setVariantOptions(updatedVariantOptions);
      formik.setFieldValue(
        "options",
        updatedVariantOptions.map((v) => v.type)
      );
      setVariantTypes(updatedVariantOptions.map((v) => v.type));
      setVariantItems(updatedVariantItems);
      // formik.setFieldValue("variants", updatedVariantItems);
      reloadVariants(updatedVariantOptions, updatedVariantItems);
    }
  }, [initialValues.variants]);

  const ProductSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    description: Yup.string(),
    basePrice: Yup.number().nullable(),
    binLocation: Yup.object().required("Required"),
    category: Yup.array().of(Yup.object()).required("Required"),
    comparePrice: Yup.number().nullable(),
    department: Yup.object().required("Required"),
    featureImageUrl: Yup.string().nullable(),
    galleryImageUrls: Yup.array().of(Yup.string()),
    options: Yup.array().of(Yup.string()).nullable(),
    inventory: Yup.number().nullable(),
    hasVariants: Yup.boolean(),
    returnableItem: Yup.boolean(),
    stockCode: Yup.string().nullable(),
    vendor: Yup.object().nullable(),
    status: Yup.object().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ProductSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched } = formik;

  useEffect(() => {
    setHasVariants(initialValues.hasVariants);
  }, [initialValues]);

  const handleCreateLevel = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {};
  const handleUpdateLevel = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {};

  return (
    <form onSubmit={formik.handleSubmit}>
      {header}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
        <div className="space-y-4 md:col-span-8">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_product_name")}
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  touched={touched.name}
                  errors={errors.name}
                />
              </div>
              {typeof productId === "undefined" ||
              !formik.values.hasVariants ? (
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_stock_code")}
                    name="stockCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.stockCode}
                    touched={touched.stockCode}
                    errors={errors.stockCode}
                  />
                </div>
              ) : null}
              <div className="col-span-12 sm:col-span-12">
                <FieldEditor
                  title={t("text_description")}
                  name="description"
                  onChange={(content: any, editor: any) => {
                    formik.setFieldValue("description", content);
                  }}
                  value={formik.values.description}
                  touched={formik.touched.description}
                  errors={formik.errors.description}
                />
              </div>
              {typeof productId === "undefined" ||
              !formik.values.hasVariants ? (
                <>
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_base_price")}
                      name="basePrice"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.basePrice}
                      touched={touched.basePrice}
                      errors={errors.basePrice}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_compare_price")}
                      name="comparePrice"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.comparePrice}
                      touched={touched.comparePrice}
                      errors={errors.comparePrice}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_cost")}
                      name="cost"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cost}
                      touched={touched.cost}
                      errors={errors.cost}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_market_cost")}
                      name="marketCost"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.marketCost}
                      touched={touched.marketCost}
                      errors={errors.marketCost}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_minimum_quantity")}
                      name="minimumQuantity"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.minimumQuantity}
                      touched={touched.minimumQuantity}
                      errors={errors.minimumQuantity}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_inventory")}
                      name="inventory"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.inventory}
                      touched={touched.inventory}
                      errors={errors.inventory}
                    />
                  </div>
                </>
              ) : null}
              <div className="col-span-12 sm:col-span-6">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_bin_location")}
                </label>
                <FieldBinLocation
                  value={formik.values.binLocation}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("binLocation", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.binLocation && formik.errors.binLocation
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.binLocation && formik.errors.binLocation ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="department-errors"
                  >
                    {formik.errors.binLocation.toString()}
                  </p>
                ) : null}
              </div>

              <div className="col-span-12 sm:col-span-6">
                <fieldset>
                  <legend className="text-sm font-medium text-gray-900">
                    {t("text_returnable_item")}
                  </legend>
                  <Switch.Group
                    as="div"
                    className="mt-1.5 inline-flex items-center"
                  >
                    <Switch
                      checked={formik.values.returnableItem}
                      onChange={() => {
                        formik.setFieldValue(
                          "returnableItem",
                          !formik.values.returnableItem
                        );
                      }}
                      id="returnableItem"
                      className={classNames(
                        formik.values.returnableItem
                          ? "bg-primary-600"
                          : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          formik.values.returnableItem
                            ? "translate-x-5"
                            : "translate-x-0",
                          "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <Switch.Label
                      passive
                      htmlFor="returnableItem"
                      className="ml-2 mb-0 block text-sm font-normal text-gray-700"
                    >
                      {formik.values.returnableItem
                        ? t("text_yes")
                        : t("text_no")}
                    </Switch.Label>
                  </Switch.Group>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <ProductVariantOptions
              formik={formik}
              hasVariants={hasVariants}
              setHasVariants={setHasVariants}
              variantOptions={variantOptions}
              setVariantOptions={setVariantOptions}
              setVariantTypes={setVariantTypes}
            />
          </div>
          {hasVariants && variantItems.length > 0 ? (
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              <ProductVariantItems
                formik={formik}
                variantItems={variantItems}
                setVariantItems={setVariantItems}
                variantTypes={variantTypes}
              />
            </div>
          ) : null}
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <FieldUploader
              title={t("text_gallery")}
              name="galleryImageUrls"
              directory="inventories/products"
              maxFiles={5}
              onChange={(value: any) => {
                formik.setFieldValue("galleryImageUrls", value);
              }}
              value={formik.values.galleryImageUrls}
              touched={touched.galleryImageUrls}
              errors={errors.galleryImageUrls?.toString()}
            />
          </div>
          {typeof productId === "undefined" ? null : (
            <FieldPricing pricingLevel={pricingLevel} />
          )}
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <FieldProducts
              title="Related Products"
              value={formik.values.relatedIds}
              onChange={(products) => {
                formik.setFieldValue(
                  "relatedIds",
                  products.map((product) => parseInt(product.id))
                );
              }}
              exceptIds={formik.values?.id ? [formik.values.id] : []}
            />
          </div>
        </div>
        <div className="col-span-4 space-y-4">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <fieldset>
              <label className="block text-sm font-medium text-gray-900">
                {t("text_status")}
              </label>
              <FieldStatus
                value={formik.values.status}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("status", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.status && formik.errors.status
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.status && formik.errors.status ? (
                <p className="mt-2 text-sm text-red-600" id="roles-errors">
                  {formik.errors.status.toString()}
                </p>
              ) : null}
            </fieldset>
          </div>
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <fieldset>
              <label className="block text-sm font-medium text-gray-900">
                {t("text_category")}
              </label>
              <FieldCategory
                value={formik.values.category}
                fixedValue={formik.values.autoCategories?.flatMap(
                  (ac) => ac.id
                )}
                onChange={(value: MultiValue<OptionProps>) => {
                  formik.setFieldValue("category", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.category && formik.errors.category
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.values.autoCategories?.length ? (
                <p className="mt-2 space-x-4 text-sm text-gray-500">
                  Default:{" "}
                  {formik.values.autoCategories
                    ?.flatMap((ac) => ac.name)
                    .map((name) => (
                      <span className="rounded-md border border-gray-300 bg-white py-0.5 px-1 text-xs">
                        {name}
                      </span>
                    ))}
                </p>
              ) : null}
              {formik.touched.category && formik.errors.category ? (
                <p className="mt-2 text-sm text-red-600" id="category-errors">
                  {formik.errors.category.toString()}
                </p>
              ) : null}
            </fieldset>

            <fieldset className="mt-4">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_department")}
              </label>
              <FieldDepartment
                value={formik.values.department}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("department", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.department && formik.errors.department
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.department && formik.errors.department ? (
                <p className="mt-2 text-sm text-red-600" id="department-errors">
                  {formik.errors.department.toString()}
                </p>
              ) : null}
            </fieldset>

            <fieldset className="mt-4">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_vendor")}
              </label>
              <FieldVendor
                value={formik.values.vendor}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("vendor", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.vendor && formik.errors.vendor
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.vendor && formik.errors.vendor ? (
                <p className="mt-2 text-sm text-red-600" id="vendor-errors">
                  {formik.errors.vendor.toString()}
                </p>
              ) : null}
            </fieldset>
          </div>

          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <FieldUploader
              title={t("text_image")}
              name="featureImageUrl"
              directory="inventories/products"
              onChange={(value: any) => {
                formik.setFieldValue("featureImageUrl", value);
              }}
              value={formik.values.featureImageUrl}
              touched={touched.featureImageUrl}
              errors={errors.featureImageUrl}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

const FETCH_CATEGORIES = gql`
  query FetchCategories($status: Boolean) {
    fetchCategories(status: $status) {
      id
      name
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export function FieldCategory({
  value,
  fixedValue,
  onChange,
  className,
}: {
  value: MultiValue<OptionProps>;
  fixedValue?: string[];
  onChange: (newValue: MultiValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<MultiValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchCategories] = useLazyQuery(FETCH_CATEGORIES);

  useEffect(() => {
    fetchCategories({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchCategories) {
          const fetchedCategories: OptionsData[] = data.fetchCategories;
          const optionsWithDepth: Array<OptionsData & { depth?: number }> =
            toNestedOptions(fetchedCategories);
          const filteredOptions = optionsWithDepth?.filter(
            (p) => !fixedValue?.includes(p.id)
          );
          const updatedOptions: OptionProps[] = filteredOptions?.map((p) => {
            return {
              label: `${Array.from(
                Array(typeof p.depth === "number" ? p.depth : 0).keys()
              )
                .map((_) => "-")
                .join("")} ${p.name}`,
              value: p.id,
            };
          });

          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Categories read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories, fixedValue]);

  useEffect(() => {
    const activeOptionsIds: string[] = value.flatMap((v) => v.value);
    const activeOptions = value.filter((v) =>
      activeOptionsIds.includes(v.value)
    );
    const updatedValues = activeOptions.map((v) => {
      return {
        label: toCleanString(v.label),
        value: v.value,
      };
    });
    setValues(updatedValues);
  }, [value]);

  const handleChange = (
    newValue: MultiValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(orderOptions(newValue));
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={handleChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_DEPARTMENTS = gql`
  query FetchDepartments($status: Boolean) {
    fetchDepartments(status: $status) {
      id
      name
      description
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export function FieldDepartment({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchDepartments] = useLazyQuery(FETCH_DEPARTMENTS);

  useEffect(() => {
    fetchDepartments({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchDepartments) {
          const fetchedDepartments: OptionsData[] = data.fetchDepartments;
          const optionsWithDepth: Array<OptionsData & { depth?: number }> =
            toNestedOptions(fetchedDepartments);
          const updatedOptions: OptionProps[] = optionsWithDepth?.map((p) => {
            return {
              label: `${Array.from(
                Array(typeof p.depth === "number" ? p.depth : 0).keys()
              )
                .map((_) => "-")
                .join("")} ${p.name}`,
              value: p.id,
            };
          });

          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Departments read department(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDepartments]);

  useEffect(() => {
    let updatedValues = value;
    if (value) {
      const activeOption = options.find((o) => o.value === value.value);
      if (activeOption) {
        updatedValues = {
          label: toCleanString(activeOption.label),
          value: activeOption.value,
        };
      }
    }
    setValues(updatedValues);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_LOCATIONS = gql`
  query FetchBinLocations($status: Boolean) {
    fetchBinLocations(status: $status) {
      id
      name
      parent {
        id
        name
      }
      status
    }
  }
`;

export function FieldBinLocation({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchBinLocations] = useLazyQuery(FETCH_LOCATIONS);

  useEffect(() => {
    fetchBinLocations({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchBinLocations) {
          const fetchedBinLocations: OptionsData[] = data.fetchBinLocations;
          const optionsWithDepth: Array<OptionsData & { depth?: number }> =
            toNestedOptions(fetchedBinLocations);
          const updatedOptions: OptionProps[] = optionsWithDepth?.map((p) => {
            return {
              label: `${Array.from(
                Array(typeof p.depth === "number" ? p.depth : 0).keys()
              )
                .map((_) => "-")
                .join("")} ${p.name}`,
              value: p.id,
            };
          });
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Locations read location(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBinLocations]);

  useEffect(() => {
    let updatedValues = value;
    if (value) {
      const activeOption = options.find((o) => o.value === value.value);
      if (activeOption) {
        updatedValues = {
          label: toCleanString(activeOption.label),
          value: activeOption.value,
        };
      }
    }
    setValues(updatedValues);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_VENDORS = gql`
  query FetchVendors($status: Boolean) {
    fetchVendors(status: $status) {
      id
      companyName
      email
      phoneNumber
      vendorCode
      webAddress
      address
      comments
      createdAt
      status
    }
  }
`;

export function FieldVendor({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const [options, setOptions] = useState<
    OptionsOrGroups<OptionProps, GroupBase<OptionProps>> | undefined
  >();
  const [fetchVendors] = useLazyQuery(FETCH_VENDORS);

  useEffect(() => {
    fetchVendors({
      variables: {
        status: true,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchVendors) {
          const updatedOptions: OptionProps[] = data.fetchVendors?.map(
            (p: Vendor) => {
              return {
                label: p.companyName,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "Vendors read category(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVendors]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

export function FieldStatus({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const options = useMemo(
    () => [
      { label: "Active", value: "2" },
      { label: "Draft", value: "1" },
      { label: "Inactive", value: "0" },
    ],
    []
  );

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

function ProductVariantOptions(props: {
  formik: FormikProps<any>;
  hasVariants: boolean;
  setHasVariants: Dispatch<SetStateAction<boolean>>;
  variantOptions: VariantOption[];
  setVariantOptions: Dispatch<SetStateAction<VariantOption[]>>;
  setVariantTypes: Dispatch<SetStateAction<string[]>>;
}) {
  const {
    hasVariants,
    setHasVariants,
    formik,
    variantOptions,
    setVariantOptions,
    setVariantTypes,
  } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addVariant = () => {
    const newVariants = [
      ...variantOptions,
      {
        id: String(variantOptions.length + 1),
        type: "",
        value: [],
      },
    ];
    setVariantTypes(newVariants.map((nv) => nv.type));
    setVariantOptions(newVariants);
    formik.setFieldValue("options", newVariants);
  };

  const removeVariant = (id: string) => {
    const deletedVariants = variantOptions.filter(
      (v: VariantOption) => v.id !== id
    );
    setVariantOptions(deletedVariants);
    formik.setFieldValue(
      "options",
      deletedVariants.map((v) => v.type)
    );
    setVariantTypes(deletedVariants.map((v) => v.type));
  };

  const updateVariant = (id: string, variant: VariantOption) => {
    const updatedVariants = [...variantOptions];
    const index = updatedVariants.findIndex((v) => v.id === id);
    updatedVariants[index] = variant;
    setVariantOptions(updatedVariants);
    formik.setFieldValue(
      "options",
      updatedVariants.map((v) => v.type)
    );
    setVariantTypes(updatedVariants.map((v) => v.type));
  };

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = variantOptions.findIndex((v) => v.id === active.id);
      const newIndex = variantOptions.findIndex((v) => v.id === over.id);
      const updatedArray = arrayMove(variantOptions, oldIndex, newIndex);
      setVariantOptions(updatedArray);
      formik.setFieldValue(
        "options",
        updatedArray.map((v) => v.type)
      );
      setVariantTypes(updatedArray.map((v) => v.type));
    }
  }

  return (
    <div className="space-y-5">
      <fieldset>
        <legend className="text-sm font-medium text-gray-900">Options</legend>
        <div className="relative mt-1 flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="variantOptions"
              name="variantOptions"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
              checked={hasVariants}
              onChange={(e) => {
                setHasVariants(e.target.checked);
                if (e.target.checked && variantOptions.length === 0) {
                  setVariantOptions([
                    {
                      id: "1",
                      type: "",
                      value: [],
                    },
                  ]);
                }
                formik.setFieldValue("hasVariants", e.target.checked);
              }}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="variantOptions" className="text-gray-500">
              Enable options for creating variants
            </label>
          </div>
        </div>
      </fieldset>

      {hasVariants && (
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={variantOptions.map((v) => v.id!)}
              strategy={verticalListSortingStrategy}
            >
              {variantOptions.map((v) => (
                <FieldsetVariant
                  key={v.id}
                  id={v.id!}
                  variant={v}
                  removeVariant={removeVariant}
                  updateVariant={updateVariant}
                  variantOptions={variantOptions}
                />
              ))}
            </SortableContext>
          </DndContext>
          <div className="mt-2 flex justify-end">
            <Button variant="secondary" onClick={addVariant}>
              <span className="sr-only">Add option</span>
              <PlusIcon aria-hidden="true" className="h-4 w-4 text-gray-700" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function FieldsetVariant(props: {
  id: string;
  variant: VariantOption;
  updateVariant?: any;
  removeVariant?: any;
  variantOptions: VariantOption[];
}) {
  const { variant, updateVariant, removeVariant, variantOptions } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="mt-4 grid grid-cols-3 gap-4"
    >
      <div>
        <div className="flex w-full items-center">
          <Button variant="text" className="mr-4" {...listeners}>
            <span
              aria-hidden="true"
              className="bi bi-grip-vertical text-lg"
            ></span>
          </Button>
          <label
            htmlFor={`variant-type-${variant.id}`}
            className={`sr-only mb-1 block text-sm font-medium text-gray-900
`}
          >
            Variant type
          </label>
          <FieldVariantType
            value={variant.type}
            onChange={(value) => {
              updateVariant(variant.id, {
                ...variant,
                type: value,
              });
            }}
            id={`variant-type-${variant.id}`}
            className={classNames(
              "w-full rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
            )}
          />
        </div>
      </div>
      <div className="col-span-2">
        <div className="flex w-full items-center">
          <label
            htmlFor={`variant-value-${variant.id}`}
            className={`sr-only mb-1 block text-sm font-medium text-gray-900`}
          >
            Variant value
          </label>
          <FieldVariantValues
            value={variant.value}
            onChange={(value) => {
              updateVariant(variant.id, {
                ...variant,
                value,
              });
            }}
            id={`variant-value-${variant.id}`}
            className={classNames(
              "w-full rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
            )}
          />

          {variantOptions.length > 1 ? (
            <Button
              variant="text"
              onClick={() => {
                removeVariant(variant.id);
              }}
              className="ml-4"
            >
              <TrashIcon aria-hidden="true" className="h-4 w-4 text-gray-700" />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function FieldVariantType({
  value,
  onChange,
  className,
  id,
}: {
  value: string;
  onChange: (newValue: string) => void;
  id?: string;
  className?: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const options = useMemo(
    () => [
      { label: "size", value: "size" },
      { label: "color", value: "color" },
    ],
    []
  );

  useEffect(() => {
    setValues({
      label: value,
      value,
    });
  }, [value]);

  const handleCreate = (inputValue: string) => {
    const newOption = {
      label: inputValue.toLowerCase(),
      value: inputValue.toLowerCase(),
    };
    setValues(newOption);
    onChange(inputValue.toLowerCase());
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        onChange={(newValue) => {
          onChange(newValue?.value.toLowerCase() ?? "");
        }}
        onCreateOption={handleCreate}
        options={options}
        value={values}
      />
    </SelectWrapper>
  );
}

const SortableMultiValueLabel = (
  props: MultiValueGenericProps<OptionProps>
) => {
  const { attributes, listeners } = useSortable({ id: props.data.value });
  return (
    <div {...attributes} {...listeners}>
      <components.MultiValueLabel {...props} />
    </div>
  );
};

const SortableMultiValue = (props: MultiValueProps<OptionProps>) => {
  const { setNodeRef, transform, transition } = useSortable({
    id: props.data.value,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return (
    <div ref={setNodeRef} style={style}>
      <components.MultiValue {...props} innerProps={innerProps} />
    </div>
  );
};

export function FieldVariantValues(props: {
  value: string[];
  onChange: (newValue: string[]) => void;
  id: string;
  className: string;
}) {
  const { value, onChange, id, className } = props;

  const [options, setOptions] = useState<OptionProps[]>([]);
  const [values, setValues] = useState<readonly OptionProps[]>([]);

  useEffect(() => {
    setValues(
      value.map((value) => ({
        label: value,
        value,
      }))
    );
  }, [value]);

  const handleChange = (selectedOptions: MultiValue<OptionProps>) => {
    setValues(selectedOptions);
    onChange(selectedOptions.map((o) => o.value));
  };

  const handleCreate = (inputValue: string) => {
    const newOption = {
      label: inputValue,
      value: inputValue,
    };
    setOptions([...options, newOption]);
    setValues([...values, newOption]);
    onChange([...value, inputValue]);
  };

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = values.findIndex((i) => i.value === active.id);
      const newIndex = values.findIndex((i) => i.value === over.id);
      const updateValues = arrayMove(values, oldIndex, newIndex);
      setValues(updateValues);
      onChange(updateValues.map((o) => o.value));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <SelectWrapper id={id} className={className}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={values.map((s) => s.value)}>
          <CreatableSelect
            isMulti
            styles={selectStyles}
            options={options}
            value={values}
            onChange={handleChange}
            onCreateOption={handleCreate}
            closeMenuOnSelect={false}
            components={{
              // @ts-ignore
              MultiValue: SortableMultiValue,
              MultiValueLabel: SortableMultiValueLabel,
            }}
          />
        </SortableContext>
      </DndContext>
    </SelectWrapper>
  );
}

function ProductVariantItems(props: {
  formik: FormikProps<any>;
  variantItems: VariantItem[];
  setVariantItems: Dispatch<SetStateAction<VariantItem[]>>;
  variantTypes: string[];
}) {
  const { t } = useTranslation();
  const { formik, variantItems, setVariantItems, variantTypes } = props;
  const [activeVariant, setActiveVariant] = useState<VariantProps | undefined>(
    undefined
  );

  const updateVariantItem = (index: number, value: any) => {
    const newVariantItems = [...variantItems];
    newVariantItems[index] = {
      ...newVariantItems[index],
      ...value,
    };
    setVariantItems(newVariantItems);
    formik.setFieldValue("variants", newVariantItems);
  };

  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (activeVariant) {
      const newVariantItems = [...variantItems];
      newVariantItems[activeVariant?.index] = {
        ...newVariantItems[activeVariant?.index],
        ...values,
      };
      setVariantItems(newVariantItems);
      formik.setFieldValue("variants", newVariantItems);
    }

    actions.setSubmitting(false);
    setActiveVariant(undefined);
  };

  return (
    <>
      <div className="mb-4 text-sm font-medium text-gray-900">Variants</div>
      <div className="overflow-hidden rounded-md border border-gray-200">
        <table className="block min-w-full divide-gray-300 md:table md:table-fixed md:divide-y">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6"
              >
                Image
              </th>
              {variantTypes.map((o: string, i: number) => (
                <th
                  key={`vth-${i}`}
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-medium capitalize text-gray-900"
                >
                  {o}
                </th>
              ))}
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
              >
                Stock Code
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
              >
                Inventory
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
              >
                Base Price
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {variantItems.map((v, i) => (
              <tr key={i}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          v.variantImageUrl ? v.variantImageUrl : placeholder
                        }
                        alt="placeholder"
                      />
                    </div>
                  </div>
                </td>
                {v.optionValues?.map((ov, i) => (
                  <td
                    key={`vtb-${i}`}
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  >
                    <div className="text-gray-900">{ov.optionValue}</div>
                  </td>
                ))}
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="text-gray-900">
                    <input
                      type="text"
                      value={v.stockCode}
                      onChange={(e) =>
                        updateVariantItem(i, {
                          stockCode: e.target.value,
                        })
                      }
                      className={classNames(
                        "relative block w-24 appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                        "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                        "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
                      )}
                    />
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="text-gray-900">
                    <input
                      type="number"
                      value={v.inventory}
                      onChange={(e) =>
                        updateVariantItem(i, {
                          inventory: Number(e.target.value),
                        })
                      }
                      className={classNames(
                        "relative block w-20 appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                        "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                        "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
                      )}
                    />
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="text-gray-900">{v.basePrice}</div>
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium text-primary-600 hover:text-primary-900 sm:pr-6">
                  <Button
                    variant="text"
                    onClick={() =>
                      setActiveVariant({
                        ...v,
                        index: i,
                      })
                    }
                  >
                    Edit<span className="sr-only">, {v.stockCode}</span>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Transition.Root
        show={activeVariant ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveVariant(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Variants
                  heading="Edit Variant - "
                  initialValues={{
                    stockCode: activeVariant?.stockCode ?? "",
                    basePrice: activeVariant?.basePrice ?? 0,
                    comparePrice: activeVariant?.comparePrice ?? 0,
                    cost: activeVariant?.cost ?? 0,
                    marketCost: activeVariant?.marketCost ?? 0,
                    minimumQuantity: activeVariant?.minimumQuantity ?? 0,
                    inventory: activeVariant?.inventory ?? 0,
                    variantImageUrl: activeVariant?.variantImageUrl ?? "",
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveVariant(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
