import { lazy, Suspense, useEffect, useState } from "react";
import { Link, RouteObject, useRoutes } from "react-router-dom";
import { useLazyQuery } from "@apollo/client/react";
import { gql } from "@apollo/client";

import { useAuth, isAuthorizedForResource } from "../../auth";

import {
  LayoutSplashScreen,
  NotFound,
  NotAuthorized,
  Head,
} from "../../../components/core";

import { classNames } from "../../../utils";

import { PricingLevelListResource } from "./pricing-levels/List";
import { PricingLevelCreateResource } from "./pricing-levels/Create";
import { PricingLevelUpdateResource } from "./pricing-levels/Update";
import { PricingLevelPricingResource } from "./pricing-levels/Pricing";

import { PricingCategoryListResource } from "./pricing-categories/List";
import { PricingCategoryCreateResource } from "./pricing-categories/Create";
import { PricingCategoryUpdateResource } from "./pricing-categories/Update";
import { PricingStructurePricingResource } from "./pricing-categories/Pricing";

import { SpecialPriceListResource } from "./special-prices/List";
import { SpecialPriceCreateResource } from "./special-prices/Create";
import { SpecialPriceUpdateResource } from "./special-prices/Update";
import { SpecialPricePricingResource } from "./special-prices/Pricing";

const PricingLevelList = lazy(() => import("./pricing-levels/List"));
const PricingLevelCreate = lazy(() => import("./pricing-levels/Create"));
const PricingLevelUpdate = lazy(() => import("./pricing-levels/Update"));
const PricingLevelPricing = lazy(() => import("./pricing-levels/Pricing"));

const PricingCategoryList = lazy(() => import("./pricing-categories/List"));
const PricingCategoryCreate = lazy(() => import("./pricing-categories/Create"));
const PricingCategoryUpdate = lazy(() => import("./pricing-categories/Update"));
const PricingStructurePricing = lazy(
  () => import("./pricing-categories/Pricing")
);

const SpecialPriceList = lazy(() => import("./special-prices/List"));
const SpecialPriceCreate = lazy(() => import("./special-prices/Create"));
const SpecialPriceUpdate = lazy(() => import("./special-prices/Update"));
const SpecialPricePricing = lazy(() => import("./special-prices/Pricing"));

const FETCH_PRICINGLEVELS = gql`
  query FetchPricingLevels {
    fetchPricingLevels {
      id
      name
      pricingMethod {
        id
        name
      }
      productType
      products {
        id
        name
      }
      createdAt
      status
    }
  }
`;

const FETCH_PRICINGCATEGORIES = gql`
  query FetchPricingCategories {
    fetchPricingCategories {
      id
      name
      description
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
      }
      createdAt
      status
    }
  }
`;

const FETCH_SPECIALPRICES = gql`
  query FetchSpecialPrices {
    fetchSpecialPrices {
      id
      name
      pricingMethod {
        id
        name
      }
      customerType
      products {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export default function Pricelist({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      children: [
        {
          index: true,
          element: (
            <>
              <Head
                title="Inventory"
                heading="Price List"
                breadcrumbs={[
                  ...breadcrumbs,
                  {
                    name: "Inventory",
                    href: "/inventory",
                  },
                  {
                    name: "Price List",
                    href: null,
                  },
                ]}
              />
              <Layout />
            </>
          ),
        },
        {
          path: PricelistResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricelistResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Pricelist breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricelistResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingLevelListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingLevelListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingLevelList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingLevelListResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingLevelCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingLevelCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingLevelCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingLevelCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingLevelUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingLevelUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingLevelUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingLevelUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingLevelPricingResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingLevelPricingResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingLevelPricing breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingLevelPricingResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingCategoryListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingCategoryListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingCategoryList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingCategoryListResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingCategoryCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingCategoryCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingCategoryCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingCategoryCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingCategoryUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingCategoryUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingCategoryUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PricingCategoryUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: PricingStructurePricingResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PricingStructurePricingResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PricingStructurePricing breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={
                new Error(PricingStructurePricingResource.access.join(", "))
              }
            />
          ),
        },
        {
          path: SpecialPriceListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SpecialPriceListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <SpecialPriceList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SpecialPriceListResource.access.join(", "))}
            />
          ),
        },
        {
          path: SpecialPriceCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SpecialPriceCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <SpecialPriceCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SpecialPriceCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: SpecialPriceUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SpecialPriceUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <SpecialPriceUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SpecialPriceUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: SpecialPricePricingResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SpecialPricePricingResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <SpecialPricePricing breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SpecialPricePricingResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}

function Layout() {
  const [navigation, setNavigation] = useState([
    {
      id: "pricing-levels",
      name: "Pricing Levels",
      caption: "First level of pricing. This will override base price of item.",
      path: "/inventory/pricelist/pricing-levels",
      icon: "bi bi-hdd-stack",
      count: 0,
    },
    {
      id: "pricing-categories",
      name: "Pricing Categories",
      caption: "Second level of pricing. This will override pricing level.",
      path: "/inventory/pricelist/pricing-categories",
      icon: "bi bi-hdd-stack",
      count: 0,
    },
    {
      id: "special-prices",
      name: "Special Prices",
      caption:
        "Third level of pricing. This will override all other prices if criteria matches.",
      path: "/inventory/pricelist/special-prices",
      icon: "bi bi-hdd-stack",
      count: 0,
    },
  ]);

  const [fetchPricingLevels] = useLazyQuery(FETCH_PRICINGLEVELS);
  const [fetchPricingCategories] = useLazyQuery(FETCH_PRICINGCATEGORIES);
  const [fetchSpecialPrices] = useLazyQuery(FETCH_SPECIALPRICES);

  useEffect(() => {
    fetchPricingLevels({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "pricing-levels") {
              return {
                ...item,
                count: data.fetchPricingLevels.length,
              };
            }

            return item;
          })
        );
      },
    });

    fetchPricingCategories({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "pricing-categories") {
              return {
                ...item,
                count: data.fetchPricingCategories.length,
              };
            }

            return item;
          })
        );
      },
    });

    fetchSpecialPrices({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "special-prices") {
              return {
                ...item,
                count: data.fetchSpecialPrices.length,
              };
            }

            return item;
          })
        );
      },
    });
  }, [fetchPricingCategories, fetchPricingLevels, fetchSpecialPrices]);

  return (
    <>
      <div>
        <h2 className="text-sm font-medium text-gray-500">Price List</h2>
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {navigation.map((nav) => (
            <Link
              key={nav.name}
              to={nav.path}
              className="overflow-hidden rounded-lg border border-gray-300 bg-white hover:border-primary-600"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex h-10 w-10 items-center justify-center rounded-md bg-primary-300">
                      <span
                        className={classNames(
                          "h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                          nav.icon
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="ml-4 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {nav.name}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {nav.count}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
                <p className="mt-2 text-sm font-light text-gray-500">
                  {nav.caption}
                </p>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-xs">
                  <span className="font-medium text-primary-700 hover:text-primary-900">
                    View all
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}

export const PricelistResource: ResourceProps = {
  name: "Price List",
  description: "Price List",
  access: ["read-pricelist"],
  path: "pricelist/*",
};
