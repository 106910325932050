import { Dispatch, Fragment, SetStateAction, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../";
import { classNames } from "../../../utils";

export default function Integer({
  openNum,
  setOpenNum,
  onChange,
}: {
  openNum: boolean;
  setOpenNum: Dispatch<SetStateAction<boolean>>;
  onChange: (value: string) => void;
}) {
  const [value, setValue] = useState("");
  const fields = ["7", "8", "9", "4", "5", "6", "1", "2", "3", "AC", "0", "."];
  const cancelButtonRef = useRef(null);

  const handleFieldClick = (field: string) => {
    switch (field) {
      case "AC":
        setValue("");
        break;
      case ".":
        if (value.indexOf(".") === -1) {
          if (value === "") {
            setValue("0.");
          } else {
            setValue(value + ".");
          }
        }
        break;
      default:
        setValue(value + field);
    }
  };

  const handleInsert = () => {
    if (value === "") return;
    if (isNaN(parseInt(value))) return;
    onChange(value);
    setValue("");
    setOpenNum(false);
  };

  return (
    <Transition.Root show={openNum} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpenNum}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-2 pt-3 pb-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-4">
                <div>
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-lg font-medium leading-6 text-gray-900"
                    >
                      Integer or Decimal
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="rounded-lg border bg-greyish p-3">
                        <div className="rounded-md border border-gray-200 bg-white p-5 text-right text-4xl">
                          <span className="text-primary-500">{value || 0}</span>
                        </div>
                        <div className="mt-4 grid grid-cols-3 gap-4">
                          {fields.map((field) => (
                            <div
                              key={`num-${field}`}
                              className="flex flex-1 items-center justify-center p-1 text-2xl font-medium"
                            >
                              <Button
                                variant="icon"
                                className={classNames(
                                  "pb-100 relative flex w-full items-center justify-center rounded-full uppercase shadow-lg transition-all focus:outline-none",
                                  field === "AC"
                                    ? "bg-yellow-400 text-black hover:bg-yellow-500"
                                    : "bg-gray-800 text-white hover:bg-gray-900"
                                )}
                                name={field}
                                onClick={() => handleFieldClick(field)}
                              >
                                <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
                                  <span>{field}</span>
                                </div>
                              </Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={handleInsert}
                  >
                    Insert
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpenNum(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
