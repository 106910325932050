import { useEffect, useState, Fragment, useRef, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import platform from "platform";

import { Dialog, Transition } from "@headlessui/react";

import { Switch } from "@headlessui/react";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  Button,
  Field,
  FieldPassword,
  passwordValidator,
  Response,
} from "../../../components/form";
import { classNames } from "../../../utils";
import { getRefresh, useAuth } from "../../auth";

import { Spinner } from "../../../animations";
import { Head } from "../../../components/core";

declare module "yup" {
  interface StringSchema {
    strongPassword(threshold?: number, strength?: number): this;
  }
}

Yup.addMethod(Yup.string, "strongPassword", strongPasswordMethod);

function strongPasswordMethod(this: any, threshold: number, strength: number) {
  return this.test(
    "strongPasswordTest",
    null,
    (value: string, context: any) => {
      const { path, createError } = context;
      try {
        passwordValidator(value, threshold, strength);
        return true;
      } catch (e: any) {
        return createError({
          path,
          message: e.message,
        });
      }
    }
  );
}

const ChangePasswordSchema = Yup.object().shape({
  "current-password": Yup.string().required("Required"),
  "new-password": Yup.string().required("Required").strongPassword(7),
  "confirm-password": Yup.string()
    .required("Password confirmation is required")
    .when("new-password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new-password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

const UPDATE_PASSWORD = gql`
  mutation PasswordUpdate($password: String!, $newPassword: String!) {
    passwordUpdate(
      input: { params: { password: $password, newPassword: $newPassword } }
    ) {
      user {
        id
        userName
      }
    }
  }
`;

const Security = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  return (
    <>
      <Head
        title="Security"
        heading="Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Security",
            href: "/settings/security",
          },
        ]}
      />
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div>
          <ManagePassword />
        </div>

        <div>
          <ManageAuthentication />
        </div>

        <div>
          <ManageSession />
        </div>
      </div>
    </>
  );
};

const ManagePassword = () => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<FormResponse | null>(null);

  const [passwordUpdate, { loading }] = useMutation(UPDATE_PASSWORD);

  const formik = useFormik({
    initialValues: {
      "current-password": "",
      "new-password": "",
      "confirm-password": "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, actions) => {
      setResponse(null);

      passwordUpdate({
        variables: {
          password: values["current-password"],
          newPassword: values["new-password"],
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.passwordUpdate) {
            formik.resetForm();
            setResponse({
              type: "success",
              message: "Password updated successfully",
            });
          } else {
            setResponse({
              type: "error",
              message: "Password update failed",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="overflow-hidden rounded-md shadow-sm">
        <div className="space-y-6 bg-white px-4 py-5 md:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("heading_change_password")}
            </h3>
            <p className="text-sm text-gray-500">
              {t("description_change_password")}
            </p>
          </div>
          <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_current_password")}
                autoComplete="current-password"
                type="password"
                name="current-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["current-password"]}
                touched={touched["current-password"]}
                errors={errors["current-password"]}
              />
            </div>
            <div className="col-span-6 hidden sm:col-span-3 sm:block"></div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <FieldPassword
                title={t("text_new_password")}
                autoComplete="new-password"
                name="new-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["new-password"]}
                touched={touched["new-password"]}
                errors={errors["new-password"]}
              />
            </div>

            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_confirm_password")}
                autoComplete="confirm-password"
                type="password"
                name="confirm-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["confirm-password"]}
                touched={touched["confirm-password"]}
                errors={errors["confirm-password"]}
              />
            </div>
          </div>

          <Response
            response={response}
            onDismiss={() => {
              setResponse(null);
            }}
          />
        </div>

        <div className="border-t border-gray-200 bg-white px-4 py-4 text-right md:py-6 md:px-6">
          <Button variant="primary" type="submit" loading={loading}>
            {isSubmitting ? t("text_processing") : t("text_update_password")}
          </Button>
        </div>
      </div>
    </form>
  );
};

const ME = gql`
  query Me {
    me {
      id
      lastActive
      lastActiveIp
      lastActiveLocation
      lastActiveUserAgent
      lastUpdateAt
      loginHistories {
        id
        ip
        platform
        refreshToken
        rememberMe
        createdAt
        userAgent
        userId
        userLocation
        expired
      }
    }
  }
`;

const SESSION_UNSET = gql`
  mutation Logout($token: String!) {
    logout(input: { params: { token: $token } }) {
      user {
        id
        lastActive
        lastActiveIp
        lastActiveLocation
        lastActiveUserAgent
        lastUpdateAt
        loginHistories {
          id
          ip
          platform
          refreshToken
          rememberMe
          createdAt
          userAgent
          userId
          userLocation
          expired
        }
      }
    }
  }
`;

type Session = {
  id: string;
  ip: string;
  platform: string;
  refreshToken: string;
  createdAt: string;
  userAgent: string;
  userLocation: string;
  expired: boolean;
};
export const ManageSession = () => {
  const { i18n } = useTranslation();
  const [me, { loading }] = useLazyQuery(ME);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionUnset] = useMutation(SESSION_UNSET);
  const [response, setResponse] = useState<FormResponse | null>(null);

  useEffect(() => {
    me()
      .then(({ data }) => {
        if (data?.me) {
          let sessionsList = [...data.me.loginHistories];
          sessionsList = [
            ...sessionsList.filter((session: Session) => !session.expired),
          ];

          sessionsList.sort((a: any, b: any) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
          setSessions(sessionsList.slice(0, 5));
        } else {
          setResponse({
            type: "error",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        setResponse({
          type: "error",
          message: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const sessionsData = useMemo(
    () => (loading ? Array(5).fill({}) : sessions),
    [loading, sessions]
  );

  const handleDelete = (token: string) => {
    setResponse(null);
    sessionUnset({
      variables: {
        token: token,
      },
    })
      .then(({ data }) => {
        if (data?.logout?.user) {
          const updatedSessions = sessions.filter(
            (session) => session.refreshToken !== token
          );
          setSessions(updatedSessions);
        } else {
          setResponse({
            type: "error",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        setResponse({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <div className="overflow-hidden rounded-md shadow-sm">
      <div className="space-y-6 bg-white px-4 py-5 md:p-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Active sessions
          </h3>
          <p className="text-sm text-gray-500">
            Manage and log out your active sessions on other browsers or
            devices.
          </p>
        </div>
        <div className="mt-10 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg md:ring-1">
          <SkeletonTheme
            baseColor="#f7f7f7"
            highlightColor="#dbfce5"
            borderRadius="0.25rem"
            duration={0.1}
          >
            <table className="block min-w-full divide-gray-300 md:table md:table-auto md:divide-y">
              <thead className="hidden md:table-header-group">
                <tr className="block md:table-row">
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-900 sm:pl-6 md:table-cell"
                  >
                    Device
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-900 md:table-cell"
                  >
                    IP Address
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-900 md:table-cell"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-900 md:table-cell"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="relative hidden px-3 py-3.5 pl-3 pr-4 text-left  text-sm font-medium text-gray-900 sm:pr-6 md:table-cell"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="block md:table-row-group">
                {sessionsData.map((session, sessionIdx) => (
                  <tr
                    key={sessionIdx}
                    className="my-5 block overflow-hidden rounded-lg border border-gray-200 bg-white md:my-0 md:table-row md:rounded-none md:border-0 md:bg-transparent"
                  >
                    <td
                      className={classNames(
                        sessionIdx === 0
                          ? ""
                          : "border-transparent md:border-t",
                        "relative flex text-sm md:table-cell md:py-4 md:pr-3 md:pl-6"
                      )}
                    >
                      <div className="flex w-1/3 items-center bg-gray-50 px-3 py-2 font-normal text-black md:hidden md:px-0 md:py-0">
                        Device
                      </div>
                      <div className="w-2/3 px-3 py-2 md:w-24 md:px-0 md:py-0 xl:w-32">
                        <div className="font-medium text-gray-900">
                          {loading ? (
                            <Skeleton />
                          ) : (
                            platform.parse(session.userAgent).description
                          )}
                          <br />
                          {loading ? (
                            <Skeleton />
                          ) : session.refreshToken === getRefresh() ? (
                            <span className="text-primary-600">
                              (Current session)
                            </span>
                          ) : null}
                        </div>
                        {sessionIdx !== 0 ? (
                          <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                        ) : null}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        sessionIdx === 0 ? "" : "border-gray-200 md:border-t",
                        "flex text-sm text-gray-500 md:table-cell md:px-3 md:py-3.5"
                      )}
                    >
                      <div className="flex w-1/3 items-center bg-gray-50 px-3 py-2 font-normal text-black md:hidden md:px-0 md:py-0">
                        IP Address
                      </div>
                      <div className="w-2/3 px-3 py-2 md:w-24 md:px-0 md:py-0 xl:w-32">
                        {loading ? <Skeleton /> : session.ip}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        sessionIdx === 0 ? "" : "border-gray-200 md:border-t",
                        "flex text-sm text-gray-500 md:table-cell md:px-3 md:py-3.5"
                      )}
                    >
                      <div className="flex w-1/3 items-center bg-gray-50 px-3 py-2 font-normal text-black md:hidden md:px-0 md:py-0">
                        Location
                      </div>
                      <div className="w-2/3 px-3 py-2 md:w-24 md:px-0 md:py-0 xl:w-32">
                        {loading ? <Skeleton /> : session.userLocation}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        sessionIdx === 0 ? "" : "border-gray-200 md:border-t",
                        "flex text-sm text-gray-500 md:table-cell md:px-3 md:py-3.5"
                      )}
                    >
                      <div className="flex w-1/3 items-center bg-gray-50 px-3 py-2 font-normal text-black md:hidden md:px-0 md:py-0">
                        Date
                      </div>
                      <div className="w-2/3 px-3 py-2 md:w-24 md:px-0 md:py-0 xl:w-32">
                        {loading ? (
                          <Skeleton />
                        ) : session?.createdAt ? (
                          DateTime.fromISO(session?.createdAt, {
                            locale: i18n.language,
                          })?.toLocaleString(DateTime.DATETIME_FULL)
                        ) : (
                          session?.createdAt
                        )}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        sessionIdx === 0
                          ? ""
                          : "border-transparent md:border-t",
                        "relative flex text-right text-sm font-medium md:table-cell md:py-3.5 md:pl-3 md:pr-6"
                      )}
                    >
                      <div className="flex w-1/3 items-center bg-gray-50 px-3 py-2 font-normal text-black md:hidden md:px-0 md:py-0">
                        Actions
                      </div>
                      <div className="w-2/3 px-3 py-2 md:w-auto md:px-0 md:py-0">
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <button
                            type="button"
                            className={classNames(
                              "appearance-none text-red-600 hover:text-red-900",
                              "text-red-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:text-red-600"
                            )}
                            disabled={session.refreshToken === getRefresh()}
                            onClick={() => {
                              handleDelete(session.refreshToken);
                            }}
                          >
                            <span
                              className="bi bi-box-arrow-left h-6 w-6 flex-shrink-0 text-center text-xl leading-6 transition-colors"
                              aria-hidden="true"
                            />
                            <span className="sr-only">Logout</span>
                          </button>
                        )}
                        {sessionIdx !== 0 ? (
                          <div className="absolute right-6 left-0 -top-px hidden h-px bg-gray-200 md:block" />
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </SkeletonTheme>
        </div>

        <Response
          response={response}
          onDismiss={() => {
            setResponse(null);
          }}
          className="mt-5"
        />
      </div>
    </div>
  );
};

const MfaDisableSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
});

const MFA_DISABLE = gql`
  mutation disableMfa($password: String!) {
    disableMfa(input: { params: { password: $password } }) {
      user {
        id
        userName
      }
    }
  }
`;

export const ManageAuthentication = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(currentUser?.enableMfa!);
  const navigate = useNavigate();

  const [response, setResponse] = useState<FormResponse | null>(null);

  const [disable, setDisable] = useState(false);
  const cancelButtonRef = useRef(null);

  const [disabledMfa] = useMutation(MFA_DISABLE);

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: MfaDisableSchema,
    onSubmit: (values, actions) => {
      setResponse(null);

      disabledMfa({
        variables: {
          password: values.password,
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.disableMfa) {
            setEnabled(!enabled);
            setResponse({
              type: "success",
              message: "MFA disabled successfully",
            });
            window.location.reload();
          } else {
            setResponse({
              type: "error",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <>
      <div className="rounded-md bg-white shadow-sm">
        <Switch.Group as="div" className="px-4 py-5 md:p-6">
          <Switch.Label
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
            passive
          >
            Multi-Factor Authentication
          </Switch.Label>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <Switch.Description>
                When enabled, you will be prompted for a verification code each
                time you sign in to your account.
              </Switch.Description>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
              <Switch
                checked={currentUser?.enableMfa ?? enabled}
                onChange={() => {
                  if (!currentUser?.enableMfa) {
                    return navigate("/settings/multi-factor-authentication");
                  }
                  setDisable(true);
                }}
                className={classNames(
                  enabled ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          </div>
        </Switch.Group>
      </div>

      <Transition.Root show={disable} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setDisable}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Deactivate Multi-Factor Authentication
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to deactivate Multi-Factor
                              Authentication?
                            </p>
                          </div>
                          <div className="mt-2">
                            <Field
                              title={t("text_password")}
                              name="password"
                              type="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                              touched={touched.password}
                              errors={errors.password}
                            />
                          </div>
                          <Response
                            response={response}
                            onDismiss={() => {
                              setResponse(null);
                            }}
                            className="mt-5"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        type="submit"
                      >
                        {isSubmitting ? (
                          <>
                            <Spinner />
                            {t("text_processing")}
                          </>
                        ) : (
                          t("text_deactivate")
                        )}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setDisable(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Security;
export const SecurityResource: ResourceProps = {
  name: "Security",
  description: "Security page",
  access: [],
  path: "security",
  icon: "bi bi-key",
};
