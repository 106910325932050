import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";
import {
  Announcements,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  Modifier,
  defaultDropAnimation,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeItem,
  addItem,
  removeChildrenOf,
  setProperty,
} from "./utilities";
import { FlattenedItem, SensorContext, TreeItems, TreeType } from "./types";
import { sortableTreeKeyboardCoordinates } from "./keyboardCoordinates";
import { SortableTreeItem } from "./TreeItem/SortableTreeItem";
import { CSS } from "@dnd-kit/utilities";
import { nanoid } from "nanoid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

import {
  Button,
  Field,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { Spinner } from "../../../../animations";
import { classNames, toCleanString, toNestedOptions } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";

type ILinkValue = {
  id: string;
  name: string;
  handle: string;
};
type IMenuItem = {
  id: string;
  linkType: number;
  linkValue: ILinkValue;
  menuName: string;
  subMenu: IMenuItem[];
};

// const initialItems: TreeItems = [
//   {
//     id: "Home",
//     children: [],
//   },
//   {
//     id: "Collections",
//     children: [
//       { id: "Spring", children: [] },
//       { id: "Summer", children: [] },
//       { id: "Fall", children: [] },
//       { id: "Winter", children: [] },
//     ],
//   },
//   {
//     id: "About Us",
//     children: [],
//   },
//   {
//     id: "My Account",
//     children: [
//       { id: "Addresses", children: [] },
//       { id: "Order History", children: [] },
//     ],
//   },
// ];

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface Props {
  collapsible?: boolean;
  defaultItems?: TreeItems;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
  onChange: (items: TreeItems) => void;
  className?: string;
}

export function MenuTree({
  collapsible,
  defaultItems = [],
  indicator = false,
  indentationWidth = 50,
  removable,
  onChange,
  className,
}: Props) {
  const { t } = useTranslation();
  const [items, setItems] = useState(() => defaultItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const [addForm, setAddForm] = useState<boolean>(false);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, items]);
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems]
  );
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement("onDragMove", active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement("onDragOver", active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement("onDragEnd", active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      type: null,
      link: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Required"),
      type: Yup.number().required("Required"),
      link: Yup.string().required("Required"),
    }),
    onSubmit: (
      values: any,
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      console.log(values);

      const newItem = {
        id: nanoid(11),
        title: values.title,
        link: values.link,
        type: values.type,
        children: [],
      };
      const newItems = addItem(items, newItem, null);
      setItems(newItems);
      onChange(newItems);
      setAddForm(false);
      formik.resetForm();
      actions.setSubmitting(false);
    },
  });

  return (
    <>
      {items.length > 0 ? (
        <DndContext
          accessibility={{ announcements }}
          sensors={sensors}
          collisionDetection={closestCenter}
          measuring={measuring}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={sortedIds}
            strategy={verticalListSortingStrategy}
          >
            {flattenedItems.map(
              ({ id, title, type, link, children, collapsed, depth }) => (
                <SortableTreeItem
                  key={id}
                  id={id}
                  value={id}
                  depth={id === activeId && projected ? projected.depth : depth}
                  indentationWidth={indentationWidth}
                  indicator={indicator}
                  collapsed={Boolean(collapsed && children.length)}
                  onCollapse={
                    collapsible && children.length
                      ? () => handleCollapse(id)
                      : undefined
                  }
                  onRemove={removable ? () => handleRemove(id) : undefined}
                  title={title}
                  type={type}
                  link={link}
                />
              )
            )}
            {createPortal(
              <DragOverlay
                dropAnimation={dropAnimationConfig}
                modifiers={indicator ? [adjustTranslate] : undefined}
              >
                {activeId && activeItem ? (
                  <SortableTreeItem
                    id={activeId}
                    depth={activeItem.depth}
                    clone
                    childCount={getChildCount(items, activeId) + 1}
                    value={activeId.toString()}
                    indentationWidth={indentationWidth}
                    title={activeItem.title}
                    type={activeItem.type}
                    link={activeItem.link}
                  />
                ) : null}
              </DragOverlay>,
              document.body
            )}
          </SortableContext>
        </DndContext>
      ) : (
        <div
          className={classNames(
            className ? className : "",
            "rounded-md border py-6 text-center text-sm md:py-12"
          )}
        >
          <ExclamationCircleIcon
            type="outline"
            name="exclamation-circle"
            className="mx-auto h-6 w-6 text-gray-400"
          />
          <p className="mt-4 font-medium text-gray-900">
            No menu items in the list.
          </p>
          <p className="mt-2 text-gray-500">
            Please{" "}
            <Button
              variant="text"
              onClick={() => {
                setAddForm(true);
              }}
            >
              add new
            </Button>{" "}
            item to add to the list.
          </p>
        </div>
      )}
      <div>
        <Button
          onClick={() => {
            setAddForm(true);
          }}
          className="mt-4 w-full justify-center"
        >
          Add New Item
        </Button>
      </div>
      <Transition.Root show={addForm ? true : false} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setAddForm(false);
            formik.resetForm();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 rounded-3xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <form
                  className="flex h-full flex-col"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="h-0 flex-1">
                    <div className="relative px-4 pt-6 pb-4 text-center sm:px-6">
                      <div className="absolute top-5 right-5">
                        <button
                          type="button"
                          className="appearance-none rounded-md border-gray-700 text-gray-600 transition-colors hover:text-gray-900 focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                          onClick={() => {
                            setAddForm(false);
                            formik.resetForm();
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                      <Dialog.Title className="text-lg font-normal text-black">
                        Add New Item
                      </Dialog.Title>
                      <p className="mt-1 text-sm font-light text-gray-500">
                        Add a new item to the menu
                      </p>
                    </div>
                    <div className="px-4 sm:px-6">
                      <div className="space-y-6">
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                          <div>
                            <Field
                              title={t("text_menu_title")}
                              name="title"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.title}
                              touched={formik.touched.title}
                              errors={formik.errors.title}
                            />
                          </div>
                          <div>
                            <span className="block text-sm font-normal text-gray-900">
                              {t("text_menu_type")}
                            </span>
                            <FieldType
                              onChange={(value) => {
                                formik.setFieldValue("type", value);
                                formik.setFieldValue("link", "");
                              }}
                              className={classNames(
                                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                                formik.touched.type && formik.errors.type
                                  ? "border-red-600 text-red-900"
                                  : ""
                              )}
                            />
                            {formik.touched.type && formik.errors.type ? (
                              <p
                                className="mt-2 text-sm text-red-600"
                                id="treetype-errors"
                              >
                                {formik.errors.type.toString()}
                              </p>
                            ) : null}
                          </div>
                          <div className="sm:col-span-2">
                            <span className="block text-sm font-normal text-gray-900">
                              {t("text_menu_link")}
                            </span>

                            <FieldLink
                              type={formik.values.type}
                              onChange={(value: SingleValue<OptionProps>) => {
                                if (!value) {
                                  formik.setFieldValue("title", null);
                                  formik.setFieldValue("link", null);
                                  return;
                                }
                                formik.setFieldValue("title", value.label);
                                formik.setFieldValue("link", value.value);
                              }}
                              className={classNames(
                                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                                formik.touched.link && formik.errors.link
                                  ? "border-red-600 text-red-900"
                                  : ""
                              )}
                            />
                            {formik.touched.link && formik.errors.link ? (
                              <p
                                className="mt-2 text-sm text-red-600"
                                id="treelink-errors"
                              >
                                {formik.errors.link.toString()}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setAddForm(false);
                        formik.resetForm();
                      }}
                    >
                      {t("text_cancel")}
                    </Button>
                    <Button type="submit">
                      {formik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        t("text_insert")
                      )}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      // clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId: null };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      setItems(newItems);
      onChange(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function handleRemove(id: UniqueIdentifier) {
    const newItems = removeItem(items, id);
    setItems(newItems);
    onChange(newItems);
  }

  function handleCollapse(id: UniqueIdentifier) {
    const newItems = setProperty(items, id, "collapsed", (value) => {
      return !value;
    });
    setItems(newItems);
    onChange(newItems);
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

export function FieldType({
  onChange,
  className,
}: {
  onChange: (newValue: number) => void;
  className?: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Home", value: String(TreeType.HOME) },
      { label: "Category", value: String(TreeType.CATEGORY) },
      { label: "Product", value: String(TreeType.PRODUCT) },
      { label: "Page", value: String(TreeType.PAGE) },
    ],
    []
  );

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(newValue) => {
          setValues(newValue);
          onChange(Number(newValue?.value) || TreeType.HOME);
        }}
      />
    </SelectWrapper>
  );
}

const FETCH_PRODUCTS = gql`
  query FetchProducts {
    fetchProducts {
      id
      name
      status
    }
  }
`;

const FETCH_CATEGORIES = gql`
  query FetchCategories($status: Boolean) {
    fetchCategories(status: $status) {
      id
      name
      parent {
        id
        name
      }
      status
    }
  }
`;

export function FieldLink({
  type,
  onChange,
  className,
}: {
  type: TreeType | null;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className?: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);

  const homeOptions = useMemo(() => [{ label: "Home", value: "/" }], []);
  const [categoryOptions, setCategoryOptions] = useState<
    MultiValue<OptionProps>
  >([]);
  const [productOptions, setProductOptions] = useState<MultiValue<OptionProps>>(
    []
  );

  const didFetchCategories = useRef(false);
  const didFetchProducts = useRef(false);
  const [fetchCategories] = useLazyQuery(FETCH_CATEGORIES);
  const [fetchProducts] = useLazyQuery(FETCH_PRODUCTS);

  const fetchedCategories = useCallback(() => {
    fetchCategories({
      variables: {
        status: true,
      },
      onCompleted(data) {
        console.log(data);
        if (!data?.fetchCategories) return;
        const fetchCategories: OptionsData[] = data.fetchCategories;
        const optionsWithDepth: Array<OptionsData & { depth?: number }> =
          toNestedOptions(fetchCategories);
        const updatedOptions: OptionProps[] = optionsWithDepth?.map((p) => {
          return {
            label: `${Array.from(
              Array(typeof p.depth === "number" ? p.depth : 0).keys()
            )
              .map((_) => "-")
              .join("")} ${p.name}`,
            value: p.id,
          };
        });
        setCategoryOptions(updatedOptions);
      },
      onError(error) {
        console.log(error);
      },
    });
  }, [fetchCategories]);

  const fetchedProducts = useCallback(() => {
    fetchProducts({
      onCompleted(data) {
        console.log(data);
        if (!data?.fetchProducts) return;
        const updatedOptions: OptionProps[] = data.fetchProducts.map(
          (p: OptionsData) => {
            return {
              label: p.name,
              value: p.id,
            };
          }
        );
        setProductOptions(updatedOptions);
      },
      onError(error) {
        console.log(error);
      },
    });
  }, [fetchProducts]);

  useEffect(() => {
    if (!didFetchProducts.current) {
      fetchedProducts();
      didFetchProducts.current = true;
    }
  }, [fetchedProducts]);

  useEffect(() => {
    if (!didFetchCategories.current) {
      fetchedCategories();
      didFetchCategories.current = true;
    }
  }, [fetchedCategories]);

  const switchType = useCallback(
    (type: TreeType) => {
      switch (type) {
        case TreeType.HOME:
          setOptions(homeOptions);
          setValues({
            label: toCleanString(homeOptions[0].label),
            value: homeOptions[0].value,
          });
          break;
        case TreeType.CATEGORY:
          setOptions(categoryOptions);
          setValues(null);
          break;
        case TreeType.PRODUCT:
          setOptions(productOptions);
          setValues(null);
          break;
        case TreeType.PAGE:
          setOptions(homeOptions);
          setValues({
            label: toCleanString(homeOptions[0].label),
            value: homeOptions[0].value,
          });
          break;
      }
    },
    [categoryOptions, homeOptions, productOptions]
  );

  useEffect(() => {
    if (typeof type === "object") return;
    switchType(type);
  }, [switchType, type]);

  return (
    <SelectWrapper className={classNames(className ? className : "")}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(newValue) => {
          if (!newValue) {
            setValues(null);
            onChange(null);
            return;
          }
          const updatedValue = {
            label: toCleanString(newValue.label),
            value: newValue.value,
          };
          setValues(updatedValue);
          onChange(updatedValue);
        }}
      />
    </SelectWrapper>
  );
}
