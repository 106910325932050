import { useEffect, useState } from "react";
import {
  DndContext,
  UniqueIdentifier,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { nanoid } from "nanoid";

import { useTranslation } from "react-i18next";

import { fieldItems, IField } from "./formbuilder/types";
import { DraggableItem } from "./formbuilder/DraggableItem";
import { DroppableArea } from "./formbuilder/DroppableArea";

import Item from "./formbuilder/Item";
import { arrayMove } from "../../utils";

type IFieldValues = {
  fieldName: string;
  fieldKey: string;
  fieldType: string;
  fieldOptions: string[];
};

export function FieldBuilder({
  values,
  onChange,
}: {
  values: IFieldValues[];
  onChange: (values: IFieldValues[]) => void;
}) {
  const [items, setItems] = useState<IField[]>([]);
  const { t } = useTranslation();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd({ active, over }: { active: any; over: any }) {
    if (!over) return;

    const activeContainer = active.data.current?.sortable?.containerId;
    const overContainer = over.data.current?.sortable?.containerId || over.id;

    let newElements: IField[];

    if (activeContainer === overContainer) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      newElements = arrayMove(items, oldIndex, newIndex);
    } else {
      if (activeContainer) return;
      let newElement = active.data.current;

      if (
        (active.data.current?.fieldKey === "$minimumMarkup" &&
          items.some((item) => item.fieldKey === "$minimumMarkup")) ||
        (active.data.current?.fieldKey === "$maximumMarkup" &&
          items.some((item) => item.fieldKey === "$maximumMarkup"))
      ) {
        return;
      }

      newElement.id = nanoid();
      newElements = [...items, ...[newElement]];
    }

    setItems(newElements);

    onChange(
      newElements.map((e) => {
        const { id, icon, description, ...rest } = e;
        return rest;
      })
    );
  }

  function updateItem(id: UniqueIdentifier | string, item: IField) {
    let newElements = [...items];
    let index = newElements.findIndex((e) => e.id === id);
    if (index !== -1) {
      newElements[index] = item;
      setItems(newElements);

      onChange(
        newElements.map((e) => {
          const { id, icon, description, ...rest } = e;
          return rest;
        })
      );
    }
  }

  function deleteItem(id: UniqueIdentifier | string) {
    let newElements = items.filter((e) => e.id !== id);
    setItems(newElements);

    onChange(
      newElements.map((e) => {
        const { id, icon, description, ...rest } = e;
        return rest;
      })
    );
  }

  useEffect(() => {
    if (!values.length) return;
    setItems([
      ...values.map((e) => {
        const itemOrg = fieldItems.find(
          (i) => i.fieldType === e.fieldType
        ) as IField;
        return {
          ...itemOrg,
          id: nanoid(),
          fieldName: e.fieldName,
          fieldKey: e.fieldKey,
          fieldType: e.fieldType,
          fieldOptions: e.fieldOptions,
        };
      }),
    ]);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2 rounded-md bg-gray-200">
          <div className="px-4 pt-2 pb-6">
            <DroppableArea id={`droppable-${nanoid(11)}`}>
              <SortableContext
                items={items.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                {items.length > 0 ? (
                  items.map((element) => (
                    <Item
                      key={element.id}
                      id={element.id}
                      element={element}
                      updateItem={updateItem}
                      deleteItem={deleteItem}
                    />
                  ))
                ) : (
                  <div className="h-64"></div>
                )}
              </SortableContext>
            </DroppableArea>
          </div>
        </div>
        <div className="py-4">
          <div className="space-y-2">
            <div className="mb-1 block text-sm font-medium text-gray-900">
              {t("text_builder_tools")}
            </div>
            {fieldItems.map((tool: IField) => (
              <DraggableItem key={tool.id} {...tool} />
            ))}
          </div>
        </div>
      </div>
    </DndContext>
  );
}
